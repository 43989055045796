import { Col, Drawer, Row } from "antd";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow, MDBTable, MDBTableBody } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Obtener_MatriculaEnAPI, Obtener_VeniculoEnAPI } from "../../action/actionCreatorsRequests";
import { idModeloLatam, traducirPagina } from "../../librerias/funciones";

const InformacionDetalladaCoche = ({ abrirFicha = false, cerrarFicha = () => { }, titulo = "", esBuscarMatricula = false, matricula = "" }) => {
    const [obtenerRegistroCoche, setObtenerRegistroCoche] = useState({})

    const Obtener_Veniculo = async (ID_VEHICULO) => {
        const registros = await Obtener_VeniculoEnAPI(ID_VEHICULO);


        if (registros !== undefined && registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
            setObtenerRegistroCoche(registros.REGISTROS[0])
        } else {
            setObtenerRegistroCoche(null)
        }
    }

    const ObtenerRegistrosMatricula = async (matricula) => {
        const registros = await Obtener_MatriculaEnAPI(matricula)
        if (registros !== undefined && registros !== null && Object.keys(registros.VEHICULOS_TECDOC).length > 0) {
            const { ID } = registros.VEHICULOS_TECDOC[0]
            Obtener_Veniculo(ID)
        } else {
            setObtenerRegistroCoche(null)
        }
    }




    useEffect(() => {
        if (esBuscarMatricula) {
            ObtenerRegistrosMatricula(matricula)
        } else {
            let ID_VEHICULO = idModeloLatam()
            Obtener_Veniculo(ID_VEHICULO)
        }

    }, [])

    return (
        <Drawer
            width={600}
            onClose={() => {
                cerrarFicha(false)
            }}
            visible={abrirFicha}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            closable={false}
        >



            <div style={{ backgroundColor: '#010910 ', color: '#010910', borderBottom: '0px', borderRadius: '0px' }} className="flex-container-Grow">

                <div style={{ width: '55px', display: 'inline-flex' }} >
                    <MDBBtn className="backgroundGris " aria-label="Close" style={{ color: '#000', backgroundColor: '#021323', border: 'none', width: '55px', height: '55px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                        cerrarFicha(false)
                    }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ display: 'inline-flex', width: '328px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px' }}>
                    {titulo}
                </div>
            </div>


            <Col span={24}>
                <div className="col-md-12 containerDetalleLatam">
                    <MDBRow>
                        <MDBCol md="12">
                            {obtenerRegistroCoche !== null ?
                                Object.keys(obtenerRegistroCoche).length > 0 ?
                                    <div className="mdb-skin">
                                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                            <div className="row">
                                                <MDBCol md="6">
                                                    <h4 className="mt-1">{traducirPagina("Año_fabricación")}</h4>
                                                    <MDBTable responsive hover>
                                                        <MDBTableBody>

                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Desde")}</td>
                                                                <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.DESDE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Hasta")}</td>
                                                                <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.HASTA}</td>
                                                            </tr>
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                </MDBCol>

                                                <MDBCol md="6">

                                                    <MDBCard style={{ height: "10rem", marginTop: '8px' }}>
                                                        <MDBCardBody style={{ textAlign: 'center', padding: "0rem 1rem" }}>
                                                            <img src={obtenerRegistroCoche.IMAGEN} className="img-fluid" alt="" style={{ width: '200px' }} />
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            </div>

                                            <h4 className="mt-1">{traducirPagina("Datos_técnicos")}</h4>
                                            <div className="container_grid_detalle_coche">

                                                <MDBTable responsive hover>
                                                    <MDBTableBody>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("texto_id_tecdoc")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.ID_VEHICULO_TECDOC}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_Origen")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.ORIGIN}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo_traccion")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRACTION}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia")}[KW]</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.KW}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia")}[CV]</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CV}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}[CC]</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CC}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Catalizador")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CATALIZADOR}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.COMBUSTIBLE}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindros")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CILINDROS}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.MARCA}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.MODELO}</td>
                                                        </tr>






                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_ABS")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.ABS}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_AIRBAG")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.AIRBAG}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_ALTERNATOR")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.ALTERNATOR}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_BATERY")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.BATERY}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_CANTIDAD_VALVULAS")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CANTIDAD_VALVULAS}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_CAPAS_CILINDRO")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CAPAS_CILINDRO}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_CICLO")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CICLO}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_CODIGO_MOTOR")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.CODIGO_MOTOR}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_COMPRESSION_RATE")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.COMPRESSION_RATE}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_DIAMETER")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.DIAMETER}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_DISPLACEMENT_IN_ITS")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.DISPLACEMENT_IN_ITS}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_DISTRIBUTION")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.DISTRIBUTION}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_DOOR_NUMBER")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.DOOR_NUMBER}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_FAMILIA_MOTOR")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.FAMILIA_MOTOR}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_FEEDING")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.FEEDING}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_GEARBOX_STEPS")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.GEARBOX_STEPS}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_INJECTION_TYPE")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.INJECTION_TYPE}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_POSICION_MOTOR")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.POSICION_MOTOR}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_REGLAMENTO_EMISION")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.REGLAMENTO_EMISION}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_SEGMENT")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.SEGMENT}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_SRC")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.SRC}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_STEERING")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.STEERING}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_TECNOLOGIA_EMISION")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TECNOLOGIA_EMISION}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_TIPO_VEHICULO")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TIPO_VEHICULO}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_TRACTION_TD")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRACTION_TD}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Transmición")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRANSMISSION}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_TRAVEL")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRAVEL}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_TRIM")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TRIM}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_TURBO")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.TURBO}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_VALVE_COMAND_TYPE")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.VALVE_COMAND_TYPE}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_VERSION")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.VERSION}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("txt_WORKING_VOLTAGE")}</td>
                                                            <td style={{ textAlign: 'left' }}>{obtenerRegistroCoche.WORKING_VOLTAGE}</td>
                                                        </tr>


                                                    </MDBTableBody>
                                                </MDBTable>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_hay_vehiculo_seleccionado.")}</h3> </div>
                            }
                        </MDBCol>
                    </MDBRow>
                </div>
            </Col>

        </Drawer>
    )

}

export default InformacionDetalladaCoche;