import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerFichaMantenimientoVehiculoTaller, showChildrenDrawerIntegraciones, showChildrenDrawerLevel2, showItemCar } from "../action/actionCreators"
import {  buscarMatriculaCoche, buscarBastidorCoche, getClienteVehiculosEnAPI, setClienteVehiculoTallerEnAPI } from "../action/actionCreatorsRequests"
import { MSGOK, limpiarCocheStore, MSGERROR, verificarMatricula, traducirPagina } from '../librerias/funciones'
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';
import FichaVehiculoGenerico from '../components/FichaVehiculoGenerico'
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import { Card, Input, Row, Col, Drawer, Tabs, Button } from 'antd';
import store from "../store";
const { TabPane } = Tabs;
const { Search } = Input;
const MantenimientoVehiculoTaller = ({ props, configApp, clienteTallerSeleccionado, VehiculoTallerSeleccionado, openDrawerIntegraciones, childrenDrawerLevel2 }) => {
    const [hayVehiculo, setHayVehiculo] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [buscarPorModelo, setBuscarPorModelo] = React.useState("");
    const [actualizarVehiculoPorSeleccionDGT, setActualizarVehiculoPorSeleccionDGT] = React.useState(false);
    const [actualizarVehiculoPorModelo, setActualizarVehiculoPorModelo] = React.useState(false);
    const [abrirFichaVehiculo, setAbrirFichaVehiculo] = React.useState(false);
    const [VEHICULO, setVEHICULO] = React.useState({});


    const [datosVehiculo, setDatosVehiculo] = React.useState(
        {
            ID: 0,
            ID_TALLER: 0,
            ID_TECDOC: 0,
            ID_CLIENTE: 0,
            MATRICULA: "",
            DESCRIPCION: "",
            BASTIDOR: "",
            MARCA: "",
            MODELO: "",
            MOTOR: "",
            OBSERVACIONES: ""
        }
    );

    if (!hayVehiculo && props.ABRIR_FICHA) {
        setHayVehiculo(true);
        if (!VehiculoTallerSeleccionado.esNuevoVehiculo) {
            getClienteVehiculosEnAPI(VehiculoTallerSeleccionado.id, 1, "A").then((veh) => {
                if (veh.CLIENTES_VEHICULOS !== null) {
                    setDatosVehiculo({
                        ID: veh.CLIENTES_VEHICULOS[0].ID,
                        ID_TALLER: veh.CLIENTES_VEHICULOS[0].ID_TALLER,
                        ID_TECDOC: veh.CLIENTES_VEHICULOS[0].ID_TECDOC,
                        ID_CLIENTE: veh.CLIENTES_VEHICULOS[0].ID_CLIENTE,
                        MATRICULA: veh.CLIENTES_VEHICULOS[0].MATRICULA,
                        DESCRIPCION: veh.CLIENTES_VEHICULOS[0].DESCRIPCION,
                        BASTIDOR: veh.CLIENTES_VEHICULOS[0].BASTIDOR,
                        MARCA: veh.CLIENTES_VEHICULOS[0].MARCA,
                        MODELO: veh.CLIENTES_VEHICULOS[0].MODELO,
                        MOTOR: veh.CLIENTES_VEHICULOS[0].MOTOR,
                        OBSERVACIONES: veh.CLIENTES_VEHICULOS[0].OBSERVACIONES

                    })



                    const VEHICULO = veh.CLIENTES_VEHICULOS[0].MARCA === "" && veh.CLIENTES_VEHICULOS[0].MODELO === "" ? "" : `${veh.CLIENTES_VEHICULOS[0].MARCA}/${veh.CLIENTES_VEHICULOS[0].MODELO}/${veh.CLIENTES_VEHICULOS[0].MOTOR}`
                    setBuscarPorModelo(VEHICULO)
                }
            })
        }
    } else {
        if (!props.ABRIR_FICHA && hayVehiculo) {
            setHayVehiculo(false);
            setTabPorDefecto("1");
            setDatosVehiculo({
                ID: 0,
                ID_TALLER: 0,
                ID_TECDOC: 0,
                ID_CLIENTE: 0,
                MATRICULA: "",
                DESCRIPCION: "",
                BASTIDOR: "",
                MARCA: "",
                MODELO: "",
                MOTOR: "",
                OBSERVACIONES: ""
            })

            setBuscarPorModelo("")
        }
    }

    //BUSQUEDA POR API
    if (!actualizarVehiculoPorSeleccionDGT && !openDrawerIntegraciones) {
        setActualizarVehiculoPorSeleccionDGT(true);

        datosVehiculo.MARCA = VehiculoTallerSeleccionado.vehiculoPorApi.MARCA
        datosVehiculo.MODELO = VehiculoTallerSeleccionado.vehiculoPorApi.MODELO
        datosVehiculo.MOTOR = VehiculoTallerSeleccionado.vehiculoPorApi.MOTORIZACION
        datosVehiculo.ID_TECDOC = VehiculoTallerSeleccionado.vehiculoPorApi.ID

        if (VehiculoTallerSeleccionado.vehiculoPorApi.MARCA !== undefined) {
            datosVehiculo.DESCRIPCION = VehiculoTallerSeleccionado.vehiculoPorApi.MARCA + "/" + VehiculoTallerSeleccionado.vehiculoPorApi.MODELO + "/" + VehiculoTallerSeleccionado.vehiculoPorApi.MOTORIZACION
            setBuscarPorModelo(VehiculoTallerSeleccionado.vehiculoPorApi.MARCA + "/" + VehiculoTallerSeleccionado.vehiculoPorApi.MODELO + "/" + VehiculoTallerSeleccionado.vehiculoPorApi.MOTORIZACION)
        }

        setDatosVehiculo(datosVehiculo)
    } else {
        if (openDrawerIntegraciones && actualizarVehiculoPorSeleccionDGT) {
            setActualizarVehiculoPorSeleccionDGT(false);
        }
    }

    //BUSQUEDA POR MODELO
    if (!actualizarVehiculoPorModelo && !childrenDrawerLevel2) {
        setActualizarVehiculoPorModelo(true);

        datosVehiculo.MARCA = VehiculoTallerSeleccionado.vehiculoPorModelo.MARCA
        datosVehiculo.MODELO = VehiculoTallerSeleccionado.vehiculoPorModelo.MODELO

        if (VehiculoTallerSeleccionado.vehiculoPorModelo.MOTORIZACION !== undefined) {
            datosVehiculo.ID_TECDOC = VehiculoTallerSeleccionado.vehiculoPorModelo.MOTORIZACION.ID
            datosVehiculo.MOTOR = VehiculoTallerSeleccionado.vehiculoPorModelo.MOTORIZACION.DESCRIPCION
            datosVehiculo.DESCRIPCION = VehiculoTallerSeleccionado.vehiculoPorModelo.MARCA + "/" + VehiculoTallerSeleccionado.vehiculoPorModelo.MODELO + "/" + VehiculoTallerSeleccionado.vehiculoPorModelo.MOTORIZACION.DESCRIPCION

            setBuscarPorModelo(VehiculoTallerSeleccionado.vehiculoPorModelo.MARCA + "/" + VehiculoTallerSeleccionado.vehiculoPorModelo.MODELO + "/" + VehiculoTallerSeleccionado.vehiculoPorModelo.MOTORIZACION.DESCRIPCION)
        }
        setDatosVehiculo(datosVehiculo)
    } else {
        if (childrenDrawerLevel2 && actualizarVehiculoPorModelo) {
            setActualizarVehiculoPorModelo(false);
            limpiarCocheStore()
        }
    }

    const guardarDatosCliente = (e) => {
        let modificar = {
            ID: 0,
            ID_TALLER: 0,
            ID_TECDOC: 0,
            ID_CLIENTE: 0,
            MATRICULA: "",
            DESCRIPCION: "",
            BASTIDOR: "",
            MARCA: "",
            MODELO: "",
            MOTOR: "",
            OBSERVACIONES: ""
        }

        switch (e.target.name) {
            case "ID":
                modificar.ID = e.target.value;
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "ID_TALLER":
                modificar.ID_TALLER = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "ID_TECDOC":
                modificar.ID_TECDOC = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "ID_CLIENTE":
                modificar.ID_CLIENTE = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "MATRICULA":
                modificar.MATRICULA = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "DESCRIPCION":
                modificar.DESCRIPCION = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "BASTIDOR":
                modificar.BASTIDOR = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "MARCA":
                modificar.MARCA = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "MODELO":
                modificar.MODELO = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MOTOR = datosVehiculo.MOTOR;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "MOTOR":
                modificar.MOTOR = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.OBSERVACIONES = datosVehiculo.OBSERVACIONES;
                setDatosVehiculo(modificar)
                break;
            case "OBSERVACIONES":
                modificar.OBSERVACIONES = e.target.value;
                modificar.ID = datosVehiculo.ID
                modificar.ID_TALLER = datosVehiculo.ID_TALLER;
                modificar.ID_TECDOC = datosVehiculo.ID_TECDOC;
                modificar.ID_CLIENTE = datosVehiculo.ID_CLIENTE;
                modificar.MATRICULA = datosVehiculo.MATRICULA;
                modificar.DESCRIPCION = datosVehiculo.DESCRIPCION;
                modificar.BASTIDOR = datosVehiculo.BASTIDOR;
                modificar.MARCA = datosVehiculo.MARCA;
                modificar.MODELO = datosVehiculo.MODELO;
                modificar.MOTOR = datosVehiculo.MOTOR;
                setDatosVehiculo(modificar)
                break;

        }
        //let previa = {[e.target.name]: e.target.value 
    }


    const insertarVehiculo = () => {

        //Verificar campos necesarios.
        datosVehiculo.ID_CLIENTE = clienteTallerSeleccionado.id
        //datosVehiculo.ID_CLIENTE = clienteTallerSeleccionado.id
        setClienteVehiculoTallerEnAPI(VehiculoTallerSeleccionado.esNuevoVehiculo ? 0 : 1, datosVehiculo).then((respuesta) => {
            if (respuesta.OK) {
                store.dispatch({ type: "UPDATE_CLIENTE_VEHICULO_TALLER", clienteVehiculoTaller: datosVehiculo })
                store.dispatch({ type: "UPDATE_LISTA_CLIENTE_VEHICULO", actualizarListaVehiculo: true })

                if (VehiculoTallerSeleccionado.esNuevoVehiculo) {
                    MSGOK("Vehículo añadido correctamente.")
                } else {
                    MSGOK("Vehículo modificado correctamente.")
                }
            }
        })

    }
    

    const buscarPorMatricula = (matricula) => {
        if (verificarMatricula(matricula)) {
            buscarMatriculaCoche(matricula).then(coche => {

                if (coche !== undefined && coche !== null) {
                    setVEHICULO(coche);

                    if (coche.VEHICULOS_TECDOC.length === 0 && coche.MATRICULA !== null) {
                        datosVehiculo.BASTIDOR = coche.MATRICULA.Bastidor
                        datosVehiculo.DESCRIPCION = coche.MATRICULA.Marca + "/" + coche.MATRICULA.Modelo
                        store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                        setDatosVehiculo(datosVehiculo)


                    } else {
                        let vehiculo = coche.VEHICULOS_TECDOC === null ? [] : coche.VEHICULOS_TECDOC;
                        store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                        if (vehiculo.length > 1) {
                            setAbrirFichaVehiculo(true);

                            datosVehiculo.BASTIDOR = coche.MATRICULA.Bastidor
                            setDatosVehiculo(datosVehiculo)

                        } else {
                            datosVehiculo.BASTIDOR = coche.MATRICULA.Bastidor
                            datosVehiculo.MARCA = coche.VEHICULOS_TECDOC[0].MARCA
                            datosVehiculo.MODELO = coche.VEHICULOS_TECDOC[0].MODELO
                            datosVehiculo.MOTOR = coche.VEHICULOS_TECDOC[0].MOTORIZACION
                            datosVehiculo.ID_TECDOC = coche.VEHICULOS_TECDOC[0].ID
                            datosVehiculo.DESCRIPCION = coche.VEHICULOS_TECDOC[0].MARCA + "/" + coche.VEHICULOS_TECDOC[0].MODELO + "/" + coche.VEHICULOS_TECDOC[0].MOTORIZACION
                            setBuscarPorModelo(coche.VEHICULOS_TECDOC[0].MARCA + "/" + coche.VEHICULOS_TECDOC[0].MODELO + "/" + coche.VEHICULOS_TECDOC[0].MOTORIZACION)

                        }
                    }
                }
            })

        } else {
            if (matricula.trim() !== "") {
                MSGERROR(traducirPagina("formato_introducido_incorrecto"));
            }
        }
    }


    const buscarPorBastidor = (bastidor) => {
        if (verificarMatricula(bastidor)) {
            buscarBastidorCoche(bastidor).then(coche => {
                if (coche !== undefined && coche !== null) {
                    setVEHICULO(coche);

                    if (coche.VEHICULOS_TECDOC.length === 0 && coche.MATRICULA !== null) {
                        store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                    } else {
                        let vehiculo = coche.VEHICULOS_TECDOC === null ? [] : coche.VEHICULOS_TECDOC;
                        store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })

                        if (vehiculo.length > 1) {


                            setAbrirFichaVehiculo(true);


                            setDatosVehiculo(datosVehiculo)
                        } else {
                            datosVehiculo.MARCA = coche.VEHICULOS_TECDOC[0].MARCA
                            datosVehiculo.MODELO = coche.VEHICULOS_TECDOC[0].MODELO
                            datosVehiculo.MOTOR = coche.VEHICULOS_TECDOC[0].MOTORIZACION
                            datosVehiculo.ID_TECDOC = coche.VEHICULOS_TECDOC[0].ID
                            datosVehiculo.DESCRIPCION = coche.VEHICULOS_TECDOC[0].MARCA + "/" + coche.VEHICULOS_TECDOC[0].MODELO + "/" + coche.VEHICULOS_TECDOC[0].MOTORIZACION
                            setBuscarPorModelo(coche.VEHICULOS_TECDOC[0].MARCA + "/" + coche.VEHICULOS_TECDOC[0].MODELO + "/" + coche.VEHICULOS_TECDOC[0].MOTORIZACION)
                        }
                    }
                }
            })
        } else {
            if (bastidor.trim() !== "") {
                MSGERROR(traducirPagina("formato_introducido_incorrecto"));
            }

        }
    }

    const actualizarCocheSeleccionado = (row) => {
        datosVehiculo.ID_TECDOC = row.ID;
        datosVehiculo.DESCRIPCION = row.MARCA + "/" + row.MODELO + "/" + row.MOTORIZACION;
        datosVehiculo.MARCA = row.MARCA;
        datosVehiculo.MODELO = row.MODELO;
        datosVehiculo.MOTOR = row.MOTORIZACION;

        setDatosVehiculo(datosVehiculo)

        setAbrirFichaVehiculo(false)
    }




    return (
        <div>


            <Drawer
                width={600}
                onClose={() => {
                    props.CERRAR_FICHA(false)
                    store.dispatch(DrawerFichaMantenimientoVehiculoTaller(false));

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '999999999999', color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            props.CERRAR_FICHA(false); store.dispatch(DrawerFichaMantenimientoVehiculoTaller(false));
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {traducirPagina("FICHA_DEL_VEHÍCULO").toUpperCase()}
                    </div>
                </div>

                <div className="p-2" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                    <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)} >
                        <TabPane tab={traducirPagina("Datos_generales")} key="1" >
                            <div className="ml-4 mr-4">
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={10} >
                                        <span>{traducirPagina("Matricula")} </span>
                                    </Col>

                                    <Col md={14} >
                                        <span>{traducirPagina("Bastidor")} </span>
                                    </Col>

                                    <Col md={10} >
                                        <Search name="MATRICULA" value={datosVehiculo.MATRICULA} placeholder={traducirPagina("Ingrese_una_matrícula")} allowClear enterButton onChange={(e) => guardarDatosCliente(e)} onSearch={(matricula) => buscarPorMatricula(matricula)} />
                                    </Col>


                                    <Col md={14} >
                                        <Search name="BASTIDOR" value={datosVehiculo.BASTIDOR} placeholder={traducirPagina("Ingrese_un_bastidor")} allowClear enterButton onChange={(e) => guardarDatosCliente(e)} onSearch={(bastidor) => buscarPorBastidor(bastidor)} />
                                    </Col>

                                    <Col md={24} >
                                        <span>{traducirPagina("Vehiculo")} </span>
                                    </Col>

                                    <Col md={16}>
                                        <Input placeholder={traducirPagina("Seleccione_un_vehiculo")} disabled={true} name="nombre" value={buscarPorModelo} />

                                    </Col>
                                    <Col md={4}>
                                        <Button onClick={() => {
                                            setBuscarPorModelo("");
                                            datosVehiculo.DESCRIPCION = "";
                                            datosVehiculo.BASTIDOR = "";
                                            setDatosVehiculo(datosVehiculo)
                                        }
                                        }>{traducirPagina("Limpiar")}</Button>
                                    </Col>

                                    <Col md={4}>
                                        <Button type="primary" onClick={() => {
                                            store.dispatch(showItemCar("1"));
                                            store.dispatch(showChildrenDrawerLevel2(true))
                                        }
                                        }>{traducirPagina("Buscar")}</Button>
                                    </Col>

                                    <Col md={10} >
                                        <span>{traducirPagina("Marca")} </span>
                                    </Col>

                                    <Col md={14} >
                                        <span>{traducirPagina("Modelo")} </span>
                                    </Col>

                                    <Col md={10}>
                                        <Input name="MARCA" value={datosVehiculo.MARCA} disabled={true} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>
                                    <Col md={14}>
                                        <Input name="MODELO" value={datosVehiculo.MODELO} disabled={true} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={24} >
                                        <span>{traducirPagina("Motorización")} </span>
                                    </Col>

                                    <Col md={24}>
                                        <Input name="MOTOR" value={datosVehiculo.MOTOR} disabled={true} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={24} >
                                        <span>{traducirPagina("Denominacion_comercial")} </span>
                                    </Col>

                                    <Col md={24}>
                                        <Input name="DESCRIPCION" value={datosVehiculo.DESCRIPCION} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{traducirPagina("Observaciones")}</span>
                                    </Col>

                                    <Col md={24}>
                                        <Input.TextArea allowClear rows={7} name="OBSERVACIONES" value={datosVehiculo.OBSERVACIONES} onChange={(e) => guardarDatosCliente(e)} />
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ flexGrow: '6', textAlign: 'right' }} className="ml-4 mr-4 " >
                                <button className="activebtnMenu2" style={{ height: '40px', width: '220px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer', marginTop: '10px' }} onClick={() => {
                                    if (props.GUARDAR_INTERNO) {
                                        insertarVehiculo();
                                        props.CERRAR_FICHA(false)
                                    } else {
                                        props.GUARDAR_REGISTRO(datosVehiculo)
                                    }

                                }}><SaveOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_y_salir").toUpperCase()} </span>  </button>
                            </div>

                        </TabPane>

                    </Tabs>
                </div>
            </Drawer>
            {abrirFichaVehiculo ?
                <FichaVehiculoGenerico
                    title={traducirPagina("Seleccione_un_vehiculo").toUpperCase()}
                    ABRIR_FICHA={abrirFichaVehiculo}
                    CERRAR_FICHA={setAbrirFichaVehiculo}
                    DATOS_VEHICULO={VEHICULO}
                    MODIFICAR_COCHE={actualizarCocheSeleccionado}
                >

                </FichaVehiculoGenerico>
                :

                null}

        </div>
    )
}


MantenimientoVehiculoTaller.propTypes = {
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    GUARDAR_INTERNO: PropTypes.bool,
    GUARDAR_REGISTRO: PropTypes.func,


};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaMantenimientoVehiculo: state.estadoDrawer.openDrawerFichaMantenimientoVehiculo,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado,
        openDrawerIntegraciones: state.openDrawerIntegraciones,
        childrenDrawerLevel2: state.childrenDrawerLevel2,

    };
}

export default connect(mapStateToProps)(MantenimientoVehiculoTaller);