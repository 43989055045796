import React, { useEffect } from "react";
import {
    MDBCol, MDBIcon, MDBTable, MDBTableBody, MDBNav, MDBNavItem, MDBBtn
} from 'mdbreact';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import store from "../store";
import FichaGraficoCarroceria from "./FichaGraficoCarroceria.jsx";
import Manuales from "../components/Haynes/Manuales"
import FichaStockSocio from "./FichaStockSocio.jsx";
import { ImagenArticulo } from "./ImagenArticulo"
import {
    updateColapseMarca, updatePaginaAMostrar, actualizarHistorialGrafico,
    updateNumberPagination, updateVisualizacionDescuento, updateVisualizacionFiltro, actulizarNivelGrafico, actulizarReferenciaSeleccionada, eventoFiltroReferencias,
    DrawerReferenciaGrafico, estadoAgrupacionDeMarcas, DrawerStockAlmacen, DrawerMasInformacion, efectoBotonRemove,
    DrawerReferenciaGraficoCarroceria, createRipple, detenerBusquedaDetalles, actualizarNodoTree, mostrarVistaGrafica, getListTreeGraficos,
    verFiltroPorMarca, updateGruposMarcasSeleccionadas, verFiltroPorGrupo, updateGruposMontajeSeleccionados
} from "../action/actionCreators";
import { pad, getStockDesglose, buscarDetallesReferencia, buscarPrecioReferencia, getListSuplier } from "../action/actionCreatorsRequests";
import {
    mostrarPaginaActual, addClassCoche, removeClassCoche, removeAllClassCoche, obtenerListaOrdenadaPorStock, referenciaActualSelect, convertirMoneda, buscarReferenciaYMostrarEnPantalla, copiarAlPortapapeles, verficarSiEsCatalogo, colorCabeceraPorElPrecioDeLaReferencia,
    colorCabeceraFuente, efectoLadoDeMontaje, crearLineasParaElPresupuesto, traducirPagina, MSGOK, mostarReferenciasEnPantalla, verificarSiEsMostrador, MSGERROR, obtenerGraficosCarroceria, obtenerDatosUsuarioYLicenciaActual, verficarSiEsPVP, obtenerGraficosMecanica,
    soloNumeros, obtenerTipoConbustible, recortarCadena, buscarVehiculoLocal, condicionesAbrirCesta, cestaAutomatica, dynamicSortAsc, dynamicSortDesc, filtroTree, obtenerPathNodo, obtenerTipoDeBusquedaActual, visualizarStocksRemotos,
    datosLincenciaUsuario, verificarCantidadCesta, filtroLadoMontaje, condicionesAbrirCestav2,
    ObtenerIDTipoVehiculo

} from "../librerias/funciones"
import { Pagination, Modal, Button, Col, Checkbox, Tree } from 'antd';
import {
    ShoppingOutlined,
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    FileImageOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    EyeInvisibleOutlined,
    ControlOutlined,
    ApartmentOutlined,
    LeftCircleOutlined,
    AppstoreOutlined,
    CopyOutlined,
    ClockCircleOutlined,
    FileAddOutlined,
    FilterOutlined,
    MinusOutlined,
    PlusOutlined,
    SearchOutlined,
    CloseOutlined,
    FileTextOutlined,
    FileOutlined,
    DeploymentUnitOutlined,
    BulbFilled,
    BulbOutlined,
    PercentageOutlined
} from '@ant-design/icons';
import { Layout } from 'antd';
import FichaAñadirCesta from "./FichaAñadirCesta.jsx";
import FichaNeumaticosErp from "./FichaNeumaticosErp.jsx";
import { GraficoVehiculo } from "./GraficoVehiculo";
import { FichaReferenciaSugerida } from "./Compra/FichaReferenciaSugerida";
import CPLadoMontaje from "./utils/CPLadoMontaje";
//import CatalogoArticulos from "./Catalogo/CatalogoArticulos.jsx";
import CPCantidad from "./utils/CPCantidad.jsx";
import FichaOfertaCesta from "./Cesta/FichaOfertaCesta.jsx";

const { Content } = Layout;
const { DirectoryTree } = Tree;

const ContainerReferencia = ({ props, grupoDeMarcas, colapseMarca, pagination, configApp, buscadorTree, datosGraficos, usuario, listaTree, codmotorizacion, clienteSeleccionado, FiltroGrupoPorDefecto, TipoGrafico, fichaBusquedaVehiculo, DIRECTORIO_DOCUMENTO, listaTreeFiltrada, filtrosReferencia }) => {
    let redmensionarAltura = (configApp.height - 192).toString();
    const OBTENER_DATOS_USUARIO = datosLincenciaUsuario();
    const { ACTIVAR_BOTON_VER_REFERENCIAS_CON_CRUCE } = OBTENER_DATOS_USUARIO === undefined ? { ACTIVAR_BOTON_VER_REFERENCIAS_CON_CRUCE: false } : OBTENER_DATOS_USUARIO;
    const { COMPRAR_SIN_DISPONIBILIDAD } = OBTENER_DATOS_USUARIO === undefined ? { COMPRAR_SIN_DISPONIBILIDAD: false } : OBTENER_DATOS_USUARIO;

    const { ABRIR_SIEMPRE_DESGLOSE_COMPRAR } = OBTENER_DATOS_USUARIO === undefined ? { ABRIR_SIEMPRE_DESGLOSE_COMPRAR: false } : OBTENER_DATOS_USUARIO;



    const { PERMISO_DOCUMENTACION_TECNICA } = usuario.LoginSertec.List_LicenciaSERTE[0];
    const [visible, setVisible] = React.useState(false);
    const [graficosCarroceria, setGraficosCarroceria] = React.useState({});
    const [graficosMecanica, setGraficosMecanica] = React.useState({});
    const [historialGrafivoMecanica, setHistorialGrafivoMecanica] = React.useState({});

    const [fichaCesta, setFichaCesta] = React.useState(false);
    const [fichaCestaOferta, setFichaCestaOferta] = React.useState(false);

    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});
    const [actulizarVista, setActulizarVista] = React.useState(true);
    const [actulizarPagina, setActulizarPagina] = React.useState(true);
    const [cambioGrupo, setCambioGrupo] = React.useState(true);

    const [listaDeReferenciasDeLaPaginaActual, setListaDeReferenciasDeLaPaginaActual] = React.useState([]);

    const [abrirFichaManuales, setAbrirFichaManuales] = React.useState(false);

    const [abrirFichaStockSocio, setAbrirFichaStockSocio] = React.useState(false);
    const [registroStockSocio, setRegistroStockSocio] = React.useState({});

    const [abrirFichaNeumatico, setAbrirFichaNeumatico] = React.useState(false);
    const [referenciaSugerida, setReferenciaSugerida] = React.useState(false);
    const [loaderPrecios, setLoaderPrecios] = React.useState(false);




    const { referencias: REGISTROS_REFERENCIA, auxReferencia: REGISTROS_REFERENCIA_TOTALES } = grupoDeMarcas




    const [vistaReferencia, setVistaReferencia] = React.useState({
        POR_PRECIO: false,
        POR_DISPONIBILIDAD: true,
        POR_REFERENCIA_CON_PRECIO: ACTIVAR_BOTON_VER_REFERENCIAS_CON_CRUCE
    });


    const filtroPorDefecto = (estado) => {
        store.dispatch({ type: "STATE_FILTRO_GRUPO_POR_DEFECTO", FiltroGrupoPorDefecto: estado })
        props.ACTUALIZAR_POR_DEFECTO(estado)
    }


    const buscarDetalleReferencia = async (grupo) => {


        const respuesta = await grupo.map(async (registro, i) => {

            const detalle = await registro.referencias.map(async (referencia) => {
                let data = undefined;

                if (referencia.CODIGO_MARCA !== 0 || (referencia.CODIGO_MARCA === 0 && referencia.NOMRE_MARCA === "ADICIONAL")) {
                    data = await buscarDetallesReferencia(referencia);
                    referencia.LOADER = true
                    referencia.detalles = data;

                } else {
                    referencia.LOADER = null
                }

                return data;
            })


            await Promise.all(detalle);
            return registro
        })

        Promise.all(respuesta).then(registros => {
            setListaDeReferenciasDeLaPaginaActual([...registros])
        })
    }



    const buscarPrecios = async (grupo) => {
        setLoaderPrecios(false)
        const respuesta = await grupo.map(async (registro, i) => {
            const precios = await registro.referencias.map(async (referencia) => {

                const data = await buscarPrecioReferencia(referencia);

                referencia.HAY_PRECIO = true
                if (data !== undefined) {
                    referencia.PRECIO = data.PRECIO
                    referencia.NETO = data.NETO
                    referencia.DESCUENTO = data.DESCUENTO
                    referencia.DESCUENTO_ACTUALIZADO = true
                    referencia.PVP_RECOMENDADO = data.PVP
                }
                return data;
            })

            await Promise.all(precios);


            return registro
        })


        Promise.all(respuesta).then(registros => {

            registros.map((gruposMarcas) => {
                let verdes = []
                let naranjas = []
                let rojos = []
                let rojos_sin_precio = []

                gruposMarcas.referencias.map((item) => {
                    if (item.STOCK_ALMACEN > 0) {
                        verdes.push(item)
                    }

                    if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0) {
                        naranjas.push(item)
                    }

                    if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO > 0) {
                        rojos.push(item)
                    }

                    if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0) {
                        rojos_sin_precio.push(item)
                    }
                })

                verdes.sort(dynamicSortAsc("NETO"))
                naranjas.sort(dynamicSortAsc("NETO"))
                rojos.sort(dynamicSortAsc("NETO"))
                let preciosFinal = verdes.concat(naranjas).concat(rojos).concat(rojos_sin_precio);
                gruposMarcas.referencias = preciosFinal;
            })


            setListaDeReferenciasDeLaPaginaActual([...registros])

            setLoaderPrecios(true)


        })
    }

    const actualizarVista = () => {
        setActulizarVista(true)
    }


    const visualizarReferencias = (listaDeMarcasDeLaPaginaActual) => {
        let listaDeReferenciasDeLaPaginaActual = [];
        let listaDeReferencias = [];


        //Para el boton por defecto
        if (codmotorizacion.toString() === "") {
            if (grupoDeMarcas.marcas.length > 0 && actulizarPagina) {
                if (FiltroGrupoPorDefecto && cambioGrupo) {

                    let listaTop = []
                    let adicionales = []
                    grupoDeMarcas.grupoMontaje.map((registro) => {
                        listaTop.push(
                            {
                                NOMBRE_GRUPO: registro,
                                NUMERO: grupoDeMarcas.auxReferencia.filter((referencia) => referencia.NOMBRE_GRUPO_MONTAJE === registro).length
                            })
                    })

                    let grupoMayor = listaTop.reduce((item, comparador) => item.NUMERO > comparador.NUMERO ? item : comparador)
                    let tempAdicional = grupoDeMarcas.auxReferencia.filter((registro, i) => registro.GRUPO_MONTAJE === 0)
                    tempAdicional.map((registro, i) => {
                        adicionales.push(registro.NOMBRE_GRUPO_MONTAJE);
                    })

                    adicionales.push(grupoMayor.NOMBRE_GRUPO)
                    props.actulizarGruposSeleccionadas(adicionales);

                    store.dispatch(updateGruposMontajeSeleccionados(adicionales));

                    mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia, grupoDeMarcas.conLadoMontaje);


                }
            }
        }


        listaDeMarcasDeLaPaginaActual.map((marca, i) => {
            listaDeReferencias = grupoDeMarcas.referencias.filter(referencia => referencia.NOMRE_MARCA === marca);
            listaDeReferencias = obtenerListaOrdenadaPorStock(listaDeReferencias);


            if (listaDeReferencias.length > 0) {




                listaDeReferenciasDeLaPaginaActual.push({
                    nombreMarca: marca,
                    referencias: listaDeReferencias
                })

            }
        })

        return listaDeReferenciasDeLaPaginaActual;
    }



    if (Object.keys(pagination).length > 0) {


        let listaDeMarcasDeLaPagina = mostrarPaginaActual(grupoDeMarcas, pagination, actualizarVista);


        if (actulizarVista) {

            setActulizarVista(false)

            const pagLocal = visualizarReferencias(listaDeMarcasDeLaPagina);
            //INIT CANTIDAD CLIENTE
            pagLocal.map((item) => { item.referencias.map((registro) => registro.CANTIDAD_CLIENTE = [0, undefined].includes(registro.CANTIDAD_MINIMA) ? 1 : registro.CANTIDAD_MINIMA) })

            setListaDeReferenciasDeLaPaginaActual(pagLocal)
            buscarDetalleReferencia(pagLocal)

            buscarPrecios(pagLocal)


        } else {
            if (listaDeMarcasDeLaPagina.length !== listaDeReferenciasDeLaPaginaActual.length) {
                setActulizarVista(true)
            }
        }

    }



    if (grupoDeMarcas.referencias === undefined && listaDeReferenciasDeLaPaginaActual.length > 0) {
        setListaDeReferenciasDeLaPaginaActual([])
        setActulizarPagina(true)
        setCambioGrupo(true)
    }





    const gestionarGraficos = (grafico) => {
        if (grafico.children.length > 0) {
            removeAllClassCoche();
            store.dispatch(actulizarNivelGrafico(grafico.level, grafico.tooltip));
        } else {
            let valor = grafico.key;
            if (typeof valor === 'string') {
                store.dispatch({
                    type: "STATE_NODO_GRAFICO",
                    id: grafico.key.replace("g", ""),
                    nombreGrupo: grafico.tooltip
                });
                store.dispatch(DrawerReferenciaGrafico(true));
                store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [], "grupoMontaje": [] }))
                store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });
            }
        }
    }


    const añadirALaCestaOferta = async (registro, CANTIDAD) => {
        if (CANTIDAD === "") {
            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.STOCK_TOTAL}`)
        } else {
            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.codigo === "") {
                MSGERROR(traducirPagina("Msg_vender"))
            } else {
                const VEHICULO = await buscarVehiculoLocal();
                registro.CANTIDAD_SELECCIONADA = CANTIDAD
                registro.VEHICULO = VEHICULO
                setReferenciaSeleccionada(registro)
                setFichaCestaOferta(true)
            }
        }
    }



    const añadirALaCesta = async (registro, CANTIDAD) => {
        if (CANTIDAD === "") {
            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.STOCK_TOTAL}`)
        } else {
            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.codigo === "") {
                //setVisible(true)
                MSGERROR(traducirPagina("Msg_vender"))
            } else {
                const VEHICULO = await buscarVehiculoLocal();
                //const PERMITIR_COMPRA = verificarCantidadCesta(registro, COMPRAR_SIN_DISPONIBILIDAD);
                const { TIPO_STOCK, ABRIR_FICHA } = condicionesAbrirCestav2(registro, CANTIDAD);


                if (ABRIR_FICHA || registro.TIENE_OFERTA) {
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD
                    registro.VEHICULO = VEHICULO
                    setReferenciaSeleccionada(registro)
                    setFichaCesta(true)
                } else {
                    getStockDesglose(registro.REFERENCIA, registro.CODIGO_MARCA, registro.REFERENCIA_PROVEEDOR, registro.NOMRE_MARCA).then(stock => {
                        registro.DESCRIPCION_CESTA = "[ " + registro.NOMRE_MARCA.toUpperCase() + " ] - " + registro.NOMBRE_GRUPO_MONTAJE.toUpperCase();
                        console.log("respuesta cesta", stock)
                        cestaAutomatica(stock, TIPO_STOCK, CANTIDAD, registro, VEHICULO)
                    })
                }



                /*
                if (!ABRIR_FICHA || (COMPRAR_SIN_DISPONIBILIDAD ? !PERMITIR_COMPRA : false)) {
                    getStockDesglose(registro.REFERENCIA, registro.CODIGO_MARCA, registro.REFERENCIA_PROVEEDOR).then(stock => {
                        registro.DESCRIPCION_CESTA = "[ " + registro.NOMRE_MARCA.toUpperCase() + " ] - " + registro.NOMBRE_GRUPO_MONTAJE.toUpperCase();
                        cestaAutomatica(stock, TIPO_STOCK, CANTIDAD, registro, VEHICULO)
                    })
                } else {
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD
                    registro.VEHICULO = VEHICULO
                    setReferenciaSeleccionada(registro)
                    setFichaCesta(true)
                }
                */
            }
        }
    }



    const añadirLocalmenteLadoMontaje = (lado, estaActivo) => {
        let listaSeleccionados = []

        listaSeleccionados = lado
        //console.log("LADOS", listaSeleccionados)
        store.dispatch({ type: "UPDATE_LADOS_SELECCIONADOS", ladosSeleccionados: listaSeleccionados })
        mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia, grupoDeMarcas.conLadoMontaje);
        setListaDeReferenciasDeLaPaginaActual([])
    }



    const buscarGraficosCarroceria = (nivel, key) => {
        let graficos = {}
        if (nivel === 1) {
            graficos = obtenerGraficosCarroceria(nivel);
        } else {
            if (key.children.length > 0) {
                graficos = obtenerGraficosCarroceria(nivel, key.tooltip);
            } else {
                graficos = graficosCarroceria;
                let valor = key.key;
                if (typeof valor === 'string') {


                    store.dispatch({
                        type: "STATE_NODO_GRAFICO",
                        id: key.key.replace("g", ""),
                        nombreGrupo: key.tooltip
                    });
                    store.dispatch(DrawerReferenciaGraficoCarroceria(true));
                    store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [], "grupoMontaje": [] }))
                    store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });



                }
            }
        }
        setGraficosCarroceria(graficos)
    }

    const buscarGraficosMecanica = (nivel, key) => {
        let graficos = {}
        if (nivel === 1) {
            graficos = obtenerGraficosMecanica(nivel);
        } else {

            if (nivel === 2) {
                if (key !== undefined) {
                    setHistorialGrafivoMecanica(key)
                } else {
                    key = historialGrafivoMecanica
                }
            }

            if (key.children.length > 0) {
                graficos = obtenerGraficosMecanica(nivel, key.tooltip);
            } else {
                graficos = graficosMecanica;
                let valor = key.key;
                if (typeof valor === 'string') {
                    if (valor === "NEU") {
                        setAbrirFichaNeumatico(true)
                    } else {
                        store.dispatch({
                            type: "STATE_NODO_GRAFICO",
                            id: key.key.replace("g", ""),
                            nombreGrupo: key.tooltip
                        });
                        store.dispatch(DrawerReferenciaGrafico(true));
                        store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [], "grupoMontaje": [] }))
                        store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });
                    }

                }
            }
        }

        setGraficosMecanica(graficos)
    }

    const abrirDesglose = (item) => {
        //&& !obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD
        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {
            store.dispatch(DrawerStockAlmacen(true));
            referenciaActualSelect({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REFERENCIA_PROVEEDOR: item.REFERENCIA_PROVEEDOR, REGISTROS: item });
        }
    }

    const propiedadesReferencia = (PROPIEDADES) => {
        let propiedades = "";
        if (PROPIEDADES !== undefined && PROPIEDADES !== null) {
            PROPIEDADES.split("|").map((item, i) => {
                if (i > 0) {
                    propiedades += '<span>' + item + '</span><br/>'
                }
            })
        }
        return propiedades
    }

    const modelarReferenciaComercial = (REFERENCIA_COMERCIAL) => {
        let refComerciales = "";
        if (REFERENCIA_COMERCIAL !== undefined && REFERENCIA_COMERCIAL !== null && REFERENCIA_COMERCIAL.trim() !== "") {
            refComerciales = '<span title="labelRefComercial">' + traducirPagina("Referencia_comerciales") + ":" + '</span>'
            REFERENCIA_COMERCIAL.split(",").map((item, i) => {
                if (i === REFERENCIA_COMERCIAL.split(",").length - 1) {
                    refComerciales += '<span className="refComercial"> ' + item + '</span>'

                } else {
                    refComerciales += '<span className="refComercial"> ' + item + " | " + '</span>'
                }
            })
        }
        return refComerciales;
    }

    const modelarSustituyrA = (SUSTITUYE_A) => {
        let sustituyeA = "";
        if (SUSTITUYE_A !== undefined && SUSTITUYE_A !== null && SUSTITUYE_A.trim() !== "") {
            sustituyeA = '<span title="labelSustituyeA">' + traducirPagina("Sustituye_A") + ":" + '</span>'

            SUSTITUYE_A.split(",").map((item, i) => {
                if (i === SUSTITUYE_A.split(",").length - 1) {
                    sustituyeA += '<span className="refComercial"> ' + item + '</span>'
                } else {
                    sustituyeA += '<span className="refComercial"> ' + item + " | " + '</span>'
                }
            })
        }
        return sustituyeA;
    }

    const modelarSustituyrPor = (SUSTITUYE_POR) => {
        let sustituyePor = "";
        if (SUSTITUYE_POR !== undefined && SUSTITUYE_POR !== null && SUSTITUYE_POR.trim() !== "") {
            sustituyePor = '<span title="labelSustituyePor">' + traducirPagina("Sustituido_Por") + ":" + '</span>'
            SUSTITUYE_POR.split(",").map((item, i) => {
                if (i === SUSTITUYE_POR.split(",").length - 1) {
                    sustituyePor += '<span className="refComercial"> ' + item + '</span>'
                } else {
                    sustituyePor += '<span className="refComercial"> ' + item + " | " + '</span>'
                }
            })
        }
        return sustituyePor;
    }



    const filtroReferenciasConPrecioPagination = (referencias) => {
        /*
                let BUSCAR_PRECIO_CERO = referencias.filter((registro) => registro.PRECIO === 0)
                let RESULTADO = []
                if (BUSCAR_PRECIO_CERO.length === 0) {
                    RESULTADO = referencias
                } else if (BUSCAR_PRECIO_CERO.length > 0 && BUSCAR_PRECIO_CERO.length < referencias.length) {
                    RESULTADO = referencias.filter((registro) => registro.PRECIO > 0)
                }
        */

        let RESULTADO = referencias.filter((registro) => registro.PRECIO > 0)

        return RESULTADO;
    }

    const filtroReferenciaLocal = (listaDeReferenciasDeLaPaginaActual) => {

        let RESULTADO_FINAL = listaDeReferenciasDeLaPaginaActual;



        if (vistaReferencia.POR_PRECIO) {

            let nuevasReferencia = [];
            let GRUPO = { nombreMarca: "", referencias: [] }
            let verdes = []
            let naranjas = []
            let rojos = []
            let rojos_sin_precio = []
            let rojos_sin_precio_sin_proveedor = []

            listaDeReferenciasDeLaPaginaActual.map((registro) => {
                nuevasReferencia = [...nuevasReferencia, ...registro.referencias];
            })

            nuevasReferencia.map((item) => {
                if (item.STOCK_ALMACEN > 0) {
                    verdes.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0) {
                    naranjas.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO > 0) {
                    rojos.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_PROVEEDOR !== "") {
                    rojos_sin_precio.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_PROVEEDOR === "") {
                    rojos_sin_precio_sin_proveedor.push(item)
                }
            })

            verdes.sort(dynamicSortAsc("NETO"))

            naranjas.sort(dynamicSortAsc("NETO"))

            rojos.sort(dynamicSortAsc("NETO"))

            let preciosFinal = [...verdes, ...naranjas, ...rojos, ...rojos_sin_precio, ...rojos_sin_precio_sin_proveedor];
            GRUPO.nombreMarca = "";
            GRUPO.referencias = preciosFinal

            RESULTADO_FINAL = [GRUPO]
        }



        if (vistaReferencia.POR_DISPONIBILIDAD) {
            /*
                        let listaNueva = listaDeReferenciasDeLaPaginaActual.map((item) =>  {
                            return{ORDEN_MARCA: item.referencias !== null && item.referencias.length > 0 ? item.referencias[0].ORDEN_MARCA : 0,...item}
                        })
            
                        console.log("Nueva list",listaNueva)
            
                        RESULTADO_FINAL = listaNueva.sort(dynamicSortDesc("ORDEN_MARCA"))
                        */
            RESULTADO_FINAL = listaDeReferenciasDeLaPaginaActual
        }


        if (vistaReferencia.POR_REFERENCIA_CON_PRECIO) {
            if (loaderPrecios) {

                let LISTA_FILTRADA = [];

                for (let i = 0; i < RESULTADO_FINAL.length; i++) {
                    const { nombreMarca, referencias } = RESULTADO_FINAL[i];

                    let BUSCAR_PRECIO_MAYOR = referencias.filter((registro) => registro.PRECIO > 0)

                    if (BUSCAR_PRECIO_MAYOR.length > 0) {
                        LISTA_FILTRADA = [...LISTA_FILTRADA, { "nombreMarca": nombreMarca, "referencias": BUSCAR_PRECIO_MAYOR }]
                    }
                }

                RESULTADO_FINAL = LISTA_FILTRADA
            }
        }

        return RESULTADO_FINAL
    }


    const inputCantidad = (item, cantidadPorUusario = "", cantidadMinima = 1) => {
        cantidadMinima = cantidadMinima === 0 ? 1 : cantidadMinima

        if (item.CANTIDAD_CLIENTE !== null) {
            let valor = cantidadPorUusario.trim();

            if (valor === "") {
                item.CANTIDAD_CLIENTE = valor;
            } else {
                const verificarNumero = parseInt(valor)

                item.CANTIDAD_CLIENTE = verificarNumero
            }

            setListaDeReferenciasDeLaPaginaActual([...listaDeReferenciasDeLaPaginaActual])
        }

    }

    const inputCantidadBlur = (item, cantidadMinima = 1) => {
        let valor = item.CANTIDAD_CLIENTE;

        if (valor === "") {
            item.CANTIDAD_CLIENTE = cantidadMinima
        } else {
            const verificarNumero = parseInt(valor)

            item.CANTIDAD_CLIENTE = (verificarNumero <= cantidadMinima) ? cantidadMinima : verificarNumero

        }
        setListaDeReferenciasDeLaPaginaActual([...listaDeReferenciasDeLaPaginaActual])

    }

    const gestionarCantidad = (item, cantidadMinima = 1, esNegativo) => {
        cantidadMinima = cantidadMinima === 0 ? 1 : cantidadMinima

        if (item.CANTIDAD_CLIENTE !== null) {
            let valor = item.CANTIDAD_CLIENTE
            if (esNegativo) {

                if (valor) {
                    if (parseInt(valor) <= cantidadMinima) {
                        item.CANTIDAD_CLIENTE = cantidadMinima;
                    } else {
                        item.CANTIDAD_CLIENTE = parseInt(valor) - 1;
                    }

                } else {
                    item.CANTIDAD_CLIENTE = cantidadMinima;
                }

            } else {

                if (valor) {
                    item.CANTIDAD_CLIENTE = parseInt(valor) + 1
                } else {
                    item.CANTIDAD_CLIENTE = cantidadMinima;
                }

            }


            setListaDeReferenciasDeLaPaginaActual([...listaDeReferenciasDeLaPaginaActual])
        }
    }



    const PAGINACION_REF_CON_PRECIO = filtroReferenciasConPrecioPagination(REGISTROS_REFERENCIA === undefined || REGISTROS_REFERENCIA === null ? [] : REGISTROS_REFERENCIA);
    /*
        setTimeout(() => {
            PAGINACION_REF_CON_PRECIO.map((referencia) => {
                if (document.getElementById("CANTIDAD" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                    document.getElementById("CANTIDAD" + referencia.CODIGO_MARCA + referencia.REFERENCIA).value = "1";
                }
    
                if (document.getElementById("CANTIDAD_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                    document.getElementById("CANTIDAD_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA).value = "1";
                }
            })
        }, 500)
    */


    const componente = (
        <div>
            <Content style={Object.keys(grupoDeMarcas).length > 0 && !configApp.visualizacionGrafico ? { margin: '0px 16px 0px' } : { margin: '20px', backgroundColor: obtenerTipoDeBusquedaActual() !== 'C' ? "" : "#fff" }}>

                {

                    !configApp.visualizacionGrafico && Object.keys(pagination).length > 0 ?

                        <div className="flex-container p-2" style={{ backgroundColor: '#eee', visibility: grupoDeMarcas.marcas.length > 0 ? 'visible' : 'hidden' }} >
                            <div className="flex-item-left" style={{ textAlign: 'left', padding: '0px' }}>
                                <div className="classic-tabs">
                                    <MDBNav classicTabs color="#808080" style={{ backgroundColor: '#eee' }} >
                                        <MDBNavItem>
                                            {colapseMarca ?
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000' }} onClick={() => { setActulizarVista(true); store.dispatch(updateColapseMarca(false)) }} >
                                                    <FullscreenOutlined className="estiloIcono" />
                                                </div>
                                                :
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000' }} onClick={() => { setActulizarVista(true); store.dispatch(updateColapseMarca(true)) }} >
                                                    <FullscreenExitOutlined className="estiloIcono" />
                                                </div>
                                            }
                                        </MDBNavItem>

                                        <MDBNavItem>
                                            {configApp.visualizacionDescuento ?
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000' }} onClick={() => { setActulizarVista(true); store.dispatch(updateVisualizacionDescuento(false)) }} >
                                                    <EyeOutlined className="estiloIcono" />
                                                </div>
                                                :
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000' }} onClick={() => { setActulizarVista(true); store.dispatch(updateVisualizacionDescuento(true)) }} >
                                                    <EyeInvisibleOutlined className="estiloIcono" />
                                                </div>
                                            }
                                        </MDBNavItem>

                                        {listaTree !== null && listaTree.length > 0 && codmotorizacion.toString() !== "" ?
                                            <MDBNavItem>
                                                {configApp.visualizacionFiltro ?
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000' }} onClick={() => {
                                                        store.dispatch(updateVisualizacionFiltro(false));
                                                        store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, egistrosFiltro: [] });
                                                    }}>

                                                        <ControlOutlined className="estiloIcono" />
                                                    </div>
                                                    :

                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000' }} onClick={() => { store.dispatch(updateVisualizacionFiltro(true)); store.dispatch(eventoFiltroReferencias([])); store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, egistrosFiltro: [] }) }}>
                                                        <ApartmentOutlined className="estiloIcono" />
                                                    </div>
                                                }
                                            </MDBNavItem>
                                            :
                                            null
                                        }

                                        {codmotorizacion.toString() === "" ?
                                            <MDBNavItem onClick={() => {
                                                filtroPorDefecto(!FiltroGrupoPorDefecto)
                                                setActulizarVista(true);

                                            }}>
                                                <button title="OEM"
                                                    className={`activebtnMenu2 ${FiltroGrupoPorDefecto ? 'activarBoton' : 'desactivarBoton'}`}
                                                    style={{ height: '35px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', backgroundColor: '#ccc', marginLeft: '10px' }}>  <FilterOutlined className="estiloIcono" /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Por_defecto").toUpperCase()}</span>
                                                </button>
                                            </MDBNavItem>
                                            :
                                            null
                                        }


                                        {
                                            <CPLadoMontaje
                                                LADO_MONTAJE={grupoDeMarcas.conLadoMontaje}
                                                REGISTROS_REFERENCIA={REGISTROS_REFERENCIA_TOTALES}
                                                EVENTO_SELECCIONADO={añadirLocalmenteLadoMontaje}
                                            >
                                            </CPLadoMontaje>
                                        }


                                        <button title={traducirPagina("Precio").toUpperCase()}
                                            className={`btn_CP_Referencia   ${vistaReferencia.POR_PRECIO ? 'activarBoton' : 'desactivarBoton'}`} onClick={() => {
                                                vistaReferencia.POR_PRECIO = true;
                                                vistaReferencia.POR_DISPONIBILIDAD = false;
                                                setVistaReferencia({ ...vistaReferencia })
                                            }}>  <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Precio").toUpperCase()}</span>
                                        </button>

                                        <button title={traducirPagina("Disponibilidad").toUpperCase()}
                                            className={`btn_CP_Referencia ${vistaReferencia.POR_DISPONIBILIDAD ? 'activarBoton' : 'desactivarBoton'}`} onClick={() => {
                                                vistaReferencia.POR_PRECIO = false;
                                                vistaReferencia.POR_DISPONIBILIDAD = true;
                                                setVistaReferencia({ ...vistaReferencia })
                                            }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Disponibilidad").toUpperCase()}</span>
                                        </button>


                                        {verificarSiEsMostrador() &&
                                            <button
                                                className={`btn_CP_Referencia ${vistaReferencia.POR_REFERENCIA_CON_PRECIO ? 'activarBoton' : 'desactivarBoton'}`} onClick={() => {
                                                    vistaReferencia.POR_REFERENCIA_CON_PRECIO = !vistaReferencia.POR_REFERENCIA_CON_PRECIO;
                                                    setVistaReferencia({ ...vistaReferencia })

                                                    if (vistaReferencia.POR_REFERENCIA_CON_PRECIO) {
                                                        setActulizarPagina(false);
                                                        setActulizarVista(true);
                                                        store.dispatch(updatePaginaAMostrar(0, 40));
                                                    }

                                                }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{vistaReferencia.POR_REFERENCIA_CON_PRECIO ? traducirPagina("Mostrar_todas").toUpperCase() : traducirPagina("Mostrar_con_precio").toUpperCase()}</span>
                                            </button>
                                        }


                                    </MDBNav>
                                </div>
                            </div>

                            <div className="flex-item-right" style={{ textAlign: 'right', padding: '0px', flex: '40%' }}>


                                {

                                    vistaReferencia.POR_REFERENCIA_CON_PRECIO ?

                                        <Pagination style={{ marginTop: '5px' }}
                                            showSizeChanger
                                            total={PAGINACION_REF_CON_PRECIO.length}
                                            showTotal={() => ` ${(PAGINACION_REF_CON_PRECIO.length)} ${traducirPagina("Referencias").toLowerCase()} `}
                                            defaultPageSize={40}
                                            onChange={(page, pageSize) => { setActulizarPagina(false); setActulizarVista(true); store.dispatch(updatePaginaAMostrar(page - 1, pageSize)); }}
                                        > </Pagination>

                                        :


                                        Object.keys(pagination).length > 0 ?
                                            <Pagination style={{ marginTop: '5px' }}
                                                showSizeChanger
                                                onShowSizeChange={(current, size) => { store.dispatch(updateNumberPagination(current, size)); }}
                                                total={pagination.numeroDePaginas * pagination.numeroRegistroPagina}
                                                showTotal={() => ` ${(pagination.numeroTotalRegistro + 1) + " " + traducirPagina("Referencias").toLowerCase()} `}
                                                current={pagination.paginaAMostrar + 1}
                                                defaultPageSize={40}
                                                onChange={(page, pageSize) => { setActulizarPagina(false); setActulizarVista(true); store.dispatch(updatePaginaAMostrar(page - 1, pageSize)); }}
                                            > </Pagination>
                                            : null
                                }


                            </div>
                        </div>


                        :
                        null


                }


                {

                    configApp.visualizacionGrafico ?
                        ObtenerIDTipoVehiculo() !== 2 && (
                            <GraficoVehiculo
                                esGraficoMoto={obtenerTipoDeBusquedaActual() === 'MT'}
                                descripcionCombustible={obtenerTipoConbustible(fichaBusquedaVehiculo.TIPO_COMBUSTIBLE)}
                                eventoNeumatico={() => {
                                    setAbrirFichaNeumatico(true)
                                }}
                            />
                        )
                        :
                        <div style={{ height: (configApp.height - 184) + "px", overflowY: Object.keys(grupoDeMarcas).length > 0 ? 'auto' : 'hidden', overflowX: 'hidden' }}>
                            <div className="containerReferencia">

                                {listaDeReferenciasDeLaPaginaActual.length > 0 ?

                                    filtroReferenciaLocal(listaDeReferenciasDeLaPaginaActual).map((marca, i) =>
                                        colapseMarca ?
                                            <div className="containerReferenciaItem" key={i}>
                                                <p className="marcaArticuloTitulo" style={{ backgroundColor: colorCabeceraPorElPrecioDeLaReferencia(marca), color: colorCabeceraFuente(marca) }}>{marca.nombreMarca}</p>
                                                {marca.referencias.map((item, i) =>
                                                    <div key={"titulo" + i}>
                                                        <div className="row" style={{ padding: '10px', color: item.REFERENCIA_PROVEEDOR === "" ? '#999' : '#000' }}>
                                                            <MDBCol md="2">

                                                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                                                    if (item.REFERENCIA !== "" && item.CODIGO_MARCA > 0) {
                                                                        store.dispatch(DrawerMasInformacion(true));
                                                                        store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, NUMBERTAG: "3" }));
                                                                    }
                                                                }}>


                                                                    <ImagenArticulo REGISTRO={item} DIRECTORIO_DOCUMENTO={DIRECTORIO_DOCUMENTO}></ImagenArticulo>


                                                                    <div style={{ textAlign: 'center', backgroundColor: item.detalles === undefined ? '#dcdcdc' : item.detalles.ID_ESTADO === 1 ? '#4285f4' : item.detalles.ID_ESTADO === 9 ? '#D91E18' : '#dcdcdc', color: item.detalles === undefined ? '#000' : item.detalles.ID_ESTADO === 1 || item.detalles.ID_ESTADO === 9 ? '#fff' : '#000' }}>
                                                                        {item.detalles !== undefined ? item.detalles.DESCRIPCION_ESTADO : null}
                                                                    </div>

                                                                    {
                                                                        vistaReferencia.POR_PRECIO ?
                                                                            <div style={{ textAlign: 'center', backgroundColor: '#dcdcdc', color: '#000' }}>
                                                                                {item.NOMRE_MARCA}
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>


                                                                {
                                                                    codmotorizacion !== "" && item.TIENE_ARTICULOS_SUGERIDOS ?
                                                                        <div className="herramientasAdicionales">
                                                                            <button onClick={(e) => {
                                                                                setReferenciaSugerida(true)
                                                                                setReferenciaSeleccionada(item)
                                                                            }}>

                                                                                <BulbOutlined></BulbOutlined> {traducirPagina("txt_sugeridas")}
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }


                                                            </MDBCol>

                                                            <MDBCol md="7">
                                                                <div className="nombreArticulo">
                                                                    <strong>
                                                                        <span className="refComercial" onClick={(e) => {
                                                                            document.getElementById("buscadorReferencia").value = item.REFERENCIA
                                                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: item.REFERENCIA });
                                                                            buscarReferenciaYMostrarEnPantalla(item.REFERENCIA)
                                                                            efectoBotonRemove(item.REFERENCIA, "closeBuscadorReferencia")
                                                                        }}> {item.REFERENCIA + " "}  </span>

                                                                        <CopyOutlined onClick={(e) => { copiarAlPortapapeles("", item.REFERENCIA) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />
                                                                        <span>{item.NOMBRE_GRUPO_MONTAJE}</span>
                                                                    </strong>
                                                                    <br />
                                                                    {traducirPagina("Referencia_proveedor") + ": "}
                                                                    <span >{item.REFERENCIA_PROVEEDOR}</span>
                                                                    {item.REFERENCIA_PROVEEDOR !== "" ? <CopyOutlined onClick={(e) => { copiarAlPortapapeles("", item.REFERENCIA_PROVEEDOR) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} /> : null}
                                                                    <br />
                                                                    {item.detalles === undefined || item.detalles.EAN === "" ? null :
                                                                        <>
                                                                            EAN:
                                                                            <span className="eanNumber"> {item.detalles === undefined || item.detalles.EAN === "" ? null : item.detalles.EAN}</span>
                                                                            <CopyOutlined onClick={(e) => { copiarAlPortapapeles("", item.detalles.EAN) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />
                                                                        </>
                                                                    }

                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                                <div className="atributosMarca"> {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: propiedadesReferencia(item.detalles.PROPIEDADES) }} ></div> : null}</div>

                                                                <div>
                                                                    <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                        if (e.target.title !== "labelRefComercial") {
                                                                            document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                            buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                            efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                        }

                                                                    }}  >
                                                                        {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: modelarReferenciaComercial(item.detalles !== undefined ? item.detalles.REFERENCIA_COMERCIAL : "") }} ></div> : null}

                                                                    </span>
                                                                </div>

                                                                <div>
                                                                    <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                        if (e.target.title !== "labelSustituyeA") {
                                                                            document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                            buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                            efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                        }
                                                                    }
                                                                    } >
                                                                        {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: modelarSustituyrA(item.detalles.SUSTITUYE_A) }} ></div> : null}

                                                                    </span>
                                                                </div>

                                                                <div id={"ContainerSustPor" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                    <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                        if (e.target.title !== "labelSustituyePor") {
                                                                            document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                            buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                            efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                        }
                                                                    }
                                                                    } >
                                                                        {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: modelarSustituyrPor(item.detalles !== undefined ? item.detalles.SUSTITUIDO_POR : "") }} ></div> : null}

                                                                    </span>
                                                                </div>
                                                                {item.detalles !== undefined && item.detalles.MAS_INFO !== undefined && item.detalles.MAS_INFO !== null && item.detalles.MAS_INFO !== "" ?
                                                                    <div className="containerMasInfo">
                                                                        {item.detalles.MAS_INFO}
                                                                    </div>
                                                                    :
                                                                    null}

                                                            </MDBCol>

                                                            <MDBCol md="3" style={{ textAlign: 'right' }}>
                                                                <div id={"COMPRA" + item.CODIGO_MARCA + item.REFERENCIA}>

                                                                    <Col md={configApp.width < 1450 ? 24 : 12}>
                                                                        {
                                                                            verificarCantidadCesta(item, COMPRAR_SIN_DISPONIBILIDAD) ?
                                                                                null
                                                                                :
                                                                                <>
                                                                                    <CPCantidad
                                                                                        value={item.CANTIDAD_CLIENTE}
                                                                                        event_input={(e) => {
                                                                                            inputCantidad(item, e.target.value, item.CANTIDAD_MINIMA)
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            inputCantidadBlur(item, item.CANTIDAD_MINIMA)
                                                                                        }}
                                                                                        event_minus={() => {
                                                                                            gestionarCantidad(item, item.CANTIDAD_MINIMA, true)
                                                                                        }}

                                                                                        event_plus={() => {
                                                                                            gestionarCantidad(item, item.CANTIDAD_MINIMA, false)
                                                                                        }}

                                                                                    />
                                                                                </>
                                                                        }

                                                                    </Col>


                                                                    <Col md={configApp.width < 1450 ? 24 : 12}>
                                                                        <button style={{ height: '40px', width: '150px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                            añadirALaCesta(item, item.CANTIDAD_CLIENTE);
                                                                        }}>
                                                                            <ShoppingOutlined style={{ fontSize: '20px' }} />
                                                                            <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{!verificarSiEsMostrador() ? traducirPagina("Comprar") : traducirPagina("Vender")}</span>
                                                                        </button>
                                                                    </Col>

                                                                </div>


                                                                {
                                                                    /*
                                                                    (item.TIENE_OFERTA && !verficarSiEsCatalogo(usuario)) && (
                                                                        <div className="btn_oferta">
                                                                            <button  onClick={(e) => {
                                                                                añadirALaCestaOferta(item, item.CANTIDAD_CLIENTE);
                                                                            }}>
                                                                                <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{"Oferta"}</span>
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                    */
                                                                }


                                                                <div>
                                                                    {!verificarSiEsMostrador() ?
                                                                        <div id={"PRESUPUESTO" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                            <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px' }} onClick={(e) => {

                                                                                item.CANTIDAD = item.CANTIDAD_CLIENTE
                                                                                crearLineasParaElPresupuesto(item, "A");
                                                                            }}>
                                                                                <FileAddOutlined style={{ fontSize: '20px' }} />
                                                                                <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Añadir_a_presupuesto")}</span>
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>


                                                                <div>
                                                                    <button onClick={(e) => {
                                                                        abrirDesglose(item)
                                                                    }} className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red', backgroundColor: 'transparent', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }}>
                                                                        {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                            <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'sub' }} />
                                                                            : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'baseline' }} />}

                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                                                            {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ? " " + traducirPagina("No_disponible") + " " : obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE ? traducirPagina("disponible") : obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? traducirPagina("disponible") : " " + traducirPagina("Stock") + " " + item.STOCK_ALMACEN + "/" + item.STOCK_TOTAL}
                                                                        </span>
                                                                    </button>
                                                                </div>


                                                                <div>
                                                                    {
                                                                        verificarSiEsMostrador() && visualizarStocksRemotos() ?
                                                                            <div >
                                                                                <button className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'transparent', marginBottom: '5px' }}
                                                                                    onClick={(e) => {
                                                                                        setRegistroStockSocio({ REFERENCIA_TECDOC: item.REFERENCIA, MARCA_TECDOC: item.CODIGO_MARCA, TIPO_ARTICULO: 1, ADICIONAL: item })
                                                                                        setAbrirFichaStockSocio(true)

                                                                                    }}>
                                                                                    <DeploymentUnitOutlined style={{ fontSize: '24px', marginRight: '5px' }} />

                                                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Stock_grupo")}</span>
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>



                                                                {item.REFERENCIA_PROVEEDOR !== "" ?
                                                                    <div>
                                                                        <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px', width: '100%', display: verficarSiEsPVP(usuario) ? '' : 'none' }} >{"PVP: " + convertirMoneda(item.PVP_RECOMENDADO, " €")}</div>
                                                                        <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>
                                                                        {configApp.visualizacionDescuento ?
                                                                            <div>
                                                                                <span className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px' }} >{traducirPagina("Dto") + ": " + (item.DESCUENTO === null ? 0 : item.DESCUENTO) + " %"} </span>
                                                                                <br />
                                                                                <div className="container_precio_ref">
                                                                                    {
                                                                                        item.TIENE_OFERTA && (
                                                                                            <div className="icono_oferta">
                                                                                                <div className="porcentaje_oferta">%</div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div>
                                                                                        <span className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px' }} >{traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <br />
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>

                                                                    :
                                                                    null
                                                                }
                                                                <div>
                                                                    {item.REFERENCIA === "" || item.CODIGO_MARCA === 0 ?
                                                                        null
                                                                        :
                                                                        <button className="activebtnMenu2 col-md-12 btnEstiloPersonalizado" onClick={(e) => {
                                                                            store.dispatch(DrawerMasInformacion(true));
                                                                            store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item }));
                                                                        }} style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: 'transparent', marginBottom: '5px' }}><EyeOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Mas_informacion")}</span>
                                                                        </button>
                                                                    }
                                                                </div>



                                                                {item.detalles !== undefined && item.detalles.CON_DESGLOSE ?
                                                                    <div>
                                                                        <button className="activebtnMenu2 col-md-12" onClick={() => {
                                                                            store.dispatch(DrawerMasInformacion(true));
                                                                            store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, VERDESPIECE: true, REGISTROS: item }));
                                                                        }}
                                                                            style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', marginBottom: '5px' }}><AppstoreOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Ver_desglose")}</span>
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    null}

                                                                {codmotorizacion !== "" && PERMISO_DOCUMENTACION_TECNICA ?
                                                                    <div>
                                                                        <button className={`activebtnMenu2 col-md-12 ${ObtenerIDTipoVehiculo() === 2 ? "disable_button" : ""}`} onClick={() => {
                                                                            store.dispatch(DrawerMasInformacion(true));
                                                                            store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, VERTIEMPOS: true, GRUPO_MONTAJE: item.GRUPO_MONTAJE, REGISTROS: item }));
                                                                        }}
                                                                            style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', marginBottom: '5px' }}><ClockCircleOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Tiempos_de_sustitucion")}</span>
                                                                        </button>
                                                                    </div>
                                                                    : null}
                                                            </MDBCol>
                                                        </div>
                                                        {marca.referencias.length > 1 ? (marca.referencias.length - 1) === i ? null : <hr></hr> : null}
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            <div className="containerReferenciaItem" key={i}>
                                                <p className="marcaArticuloTitulo" style={{ backgroundColor: colorCabeceraPorElPrecioDeLaReferencia(marca), color: colorCabeceraFuente(marca) }} >{marca.nombreMarca}</p>
                                                {marca.referencias !== undefined ?
                                                    marca.referencias.map((item, i) =>
                                                        <div key={i}>
                                                            <MDBTable responsive style={{ marginBottom: 0, cursor: 'pointer' }} className="tableReferencia">
                                                                <MDBTableBody>
                                                                    <tr className="rowReferencia" style={{ color: item.REFERENCIA_PROVEEDOR !== "" ? '#000' : '#999' }}>

                                                                        <td className="columnaIcono" style={{ width: '30px' }}>
                                                                            <button onClick={(e) => {
                                                                                store.dispatch(DrawerMasInformacion(true));
                                                                                store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item }));
                                                                            }} className="activebtnMenu2" style={{ border: '0', fontSize: '20px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'transparent' }}><FileImageOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} /> <span style={{ fontSize: '18px' }}></span> </button>
                                                                        </td>

                                                                        {vistaReferencia.POR_PRECIO ?
                                                                            <td style={{ width: '120px', maxWidth: '120px', verticalAlign: 'middle' }} title={item.NOMRE_MARCA}>
                                                                                {recortarCadena(item.NOMRE_MARCA, 8, "...")}
                                                                            </td>
                                                                            :
                                                                            null
                                                                        }

                                                                        <td onClick={(e) => {
                                                                            store.dispatch(DrawerMasInformacion(true));
                                                                            store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item }));
                                                                        }} style={{ verticalAlign: 'middle' }}>
                                                                            <strong>
                                                                                {item.REFERENCIA}
                                                                                {configApp.width < 1450 ?
                                                                                    <span>{" " + recortarCadena(item.NOMBRE_GRUPO_MONTAJE, 10, "...")}</span>
                                                                                    :
                                                                                    <span>{" " + item.NOMBRE_GRUPO_MONTAJE}</span>

                                                                                }
                                                                            </strong>
                                                                        </td>

                                                                        <td onClick={(e) => {
                                                                            store.dispatch(DrawerMasInformacion(true));
                                                                            store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item }));
                                                                        }} style={{ width: '160px', verticalAlign: 'middle' }}>{

                                                                                <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '20px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>
                                                                            }
                                                                        </td>

                                                                        <td onClick={(e) => {
                                                                            store.dispatch(DrawerMasInformacion(true));
                                                                            store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item }));
                                                                        }} style={{ width: '130px', verticalAlign: 'middle' }}> {
                                                                                configApp.visualizacionDescuento ?
                                                                                    <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '20px' }} >{traducirPagina("Dto") + ": " + (item.DESCUENTO === null ? 0 : item.DESCUENTO) + " %"} </div>
                                                                                    :
                                                                                    null

                                                                            }</td>

                                                                        <td onClick={(e) => {
                                                                            store.dispatch(DrawerMasInformacion(true));
                                                                            store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item }));
                                                                        }} style={{ width: '165px', textAlign: 'right', verticalAlign: 'middle' }}> {
                                                                                configApp.visualizacionDescuento ?

                                                                                    <div className={`container_precio_ref ${item.HAY_PRECIO === false ? "skeleton-box" : ""}`}>
                                                                                        {
                                                                                            item.TIENE_OFERTA && (
                                                                                                <div className="icono_oferta">
                                                                                                    <div className="porcentaje_oferta">%</div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div>
                                                                                            {traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null

                                                                            }</td>

                                                                        <td className="columnaIcono" style={{ display: verficarSiEsCatalogo(usuario) ? 'none' : '', width: '50px' }} onClick={(e) => {
                                                                            abrirDesglose(item)
                                                                        }}>
                                                                            <button className="activebtnMenu2" style={{ border: '0', fontSize: '20px', color: item.REFERENCIA_PROVEEDOR !== "" ? item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red' : '#999', backgroundColor: 'transparent' }} >
                                                                                {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                                    <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} />
                                                                                    : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} />}
                                                                                <span style={{ fontSize: '18px' }}></span>
                                                                            </button>
                                                                        </td>

                                                                        <td className="columnaIcono" style={{ display: verficarSiEsCatalogo(usuario) ? 'none' : '', width: '50px' }}>
                                                                            <div id={"COMPRA_TD" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                                <button className="activebtnMenu2" style={{ border: '0', fontSize: '20px', color: '#000', backgroundColor: 'transparent' }} onClick={(e) => {
                                                                                    añadirALaCesta(item, item.CANTIDAD_CLIENTE);
                                                                                }}>
                                                                                    <ShoppingOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} /> <span style={{ fontSize: '18px' }}></span>
                                                                                </button>
                                                                            </div>
                                                                        </td>

                                                                        <td className="columnaIcono" style={{ width: '150px' }}>

                                                                            {
                                                                                verificarCantidadCesta(item, COMPRAR_SIN_DISPONIBILIDAD) ?
                                                                                    null
                                                                                    :

                                                                                    <>
                                                                                        <CPCantidad
                                                                                            value={item.CANTIDAD_CLIENTE}
                                                                                            event_input={(e) => {
                                                                                                inputCantidad(item, e.target.value, item.CANTIDAD_MINIMA)
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                inputCantidadBlur(item, item.CANTIDAD_MINIMA)
                                                                                            }}
                                                                                            event_minus={() => {
                                                                                                gestionarCantidad(item, item.CANTIDAD_MINIMA, true)
                                                                                            }}

                                                                                            event_plus={() => {
                                                                                                gestionarCantidad(item, item.CANTIDAD_MINIMA, false)
                                                                                            }}

                                                                                        />

                                                                                    </>
                                                                            }
                                                                        </td>

                                                                    </tr>
                                                                </MDBTableBody>
                                                            </MDBTable>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                                }
                                            </div>
                                    )
                                    :

                                    grupoDeMarcas.referencias === undefined ?
                                        buscadorTree.nodoIdActual === undefined ?
                                            null
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                        :

                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
                                }
                            </div>

                        </div>


                }
            </Content>


            {/*  SOLO PARA EL GRAFICO DE CARROCERIA */}
            <FichaGraficoCarroceria title={traducirPagina("BÚSQUEDA_REFERENCIAS")}></FichaGraficoCarroceria>



            <Modal
                visible={visible}
                width={600}
                centered
                title={"Falta el cliente"}
                afterClose={() => { }}
                onCancel={() => setVisible(false)}
                style={{ zIndex: '9999' }}
                footer={[
                    <Button className="btnColor" key="submit" type="primary" onClick={() => {
                        setVisible(false)
                    }}> OK</Button>
                ]}

            >
                <p>{traducirPagina("msgCPRefMostradorSolicitandoCliente")}</p>
            </Modal>


            {fichaCesta && (
                <FichaAñadirCesta
                    title={traducirPagina("Añadir_referencia_a_la_cesta").toUpperCase()}
                    ABRIR_FICHA={fichaCesta}
                    CERRAR_FICHA={setFichaCesta}
                    DATOS_REFERENCIA={referenciaSeleccionada}
                    ES_CESTA_COMPRA={false}
                >
                </FichaAñadirCesta>
            )}

            {
                fichaCestaOferta && (
                    <FichaOfertaCesta
                        title={traducirPagina("Añadir_referencia_a_la_cesta").toUpperCase()}
                        ABRIR_FICHA={fichaCestaOferta}
                        CERRAR_FICHA={setFichaCestaOferta}

                        DATOS_REFERENCIA={referenciaSeleccionada}
                        ES_CESTA_COMPRA={false}
                    />
                )
            }


        </div>

    )


    React.useEffect(() => {
        //buscarGraficosMecanica(1);
    }, []);


    return (
        <>

            {grupoDeMarcas.marcas.length > 0 || configApp.visualizacionFiltro ?

                <div className="mdb-skin">
                    <Layout style={{ backgroundColor: '#fff' }}>
                        <div style={{ position: 'fixed', backgroundColor: '#fff', width: '400px', zIndex: '999' }} className="pr-2 pl-2">
                            <div className={!configApp.visualizacionFiltro ? "pb-1" : "pt-2 pb-2"}>
                                {configApp.visualizacionFiltro ?
                                    <div>

                                        <div className="grid-container" >
                                            <div className="grid-item">
                                                <input id={"busquedaArbol"} className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Ingrese_grupo_montaje")} value={props.textoArbol} style={{ backgroundColor: "#eee", width: '260px', border: '0' }} onChange={(e) => {
                                                    props.setTextoArbol(e.target.value)
                                                    store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, registrosFiltro: [] })
                                                    setTimeout((e) => {
                                                        if (document.getElementById("busquedaArbol") != null) {
                                                            const val = document.getElementById('busquedaArbol').value;
                                                            filtroTree(val);
                                                        }

                                                    }, 100)

                                                }} autoComplete="off" />
                                            </div>

                                            <div className="grid-item">
                                                <MDBBtn id="closeBusquedaArbol" onClick={e => {
                                                    createRipple(e);
                                                    props.efectoBotonRemoveLocal("", "closeBusquedaArbol", "ARBOL")

                                                    store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, registrosFiltro: [] })

                                                }} className="btn-matricula  estiloBotonRemove" style={{ visibility: props.textoArbol === undefined || props.textoArbol === "" ? 'hidden' : 'visible' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                                            </div>

                                            <div className="grid-item barraLateral" ></div>

                                            <div className="grid-item">
                                                <MDBBtn onClick={e => {
                                                    createRipple(e);

                                                    store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, registrosFiltro: [] })

                                                    setTimeout(() => {
                                                        filtroTree(props.textoArbol);
                                                    }, 100)
                                                }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                            </div>

                                        </div>



                                        {listaTreeFiltrada !== undefined && listaTreeFiltrada !== null && listaTreeFiltrada.openFiltroTree ?

                                            listaTreeFiltrada.registrosFiltro !== undefined && listaTreeFiltrada.registrosFiltro !== null && listaTreeFiltrada.registrosFiltro.length > 0 ?

                                                <div className="containerFilroTree" style={{ height: "200px", overflow: 'auto', backgroundColor: '#f7f7f7' }}>
                                                    <ul className="ulTree" id="ContenerdorLIArbol">

                                                        {listaTreeFiltrada.registrosFiltro.map((registro, i) =>
                                                            <li className="li_tree" style={{ paddingBottom: '5px', color: '#666', cursor: 'pointer' }} key={registro[registro.length - 1].NODE_ID} onClick={() => {

                                                                getListSuplier(registro[registro.length - 1].NODE_ID);

                                                                store.dispatch(detenerBusquedaDetalles(false));
                                                                store.dispatch(actualizarNodoTree(registro[registro.length - 1].NODE_ID));
                                                                store.dispatch(mostrarVistaGrafica(false))


                                                                props.busquedaArbolLocal(listaTree, registro, registro[registro.length - 1])

                                                            }}>{
                                                                    obtenerPathNodo(registro)
                                                                }
                                                            </li>
                                                        )}
                                                    </ul>

                                                </div>
                                                :
                                                null
                                            :
                                            null
                                        }


                                        <button title={traducirPagina("BÚSQUEDA_GRÁFICA")} id='btnBusquedaGrafica' className="estilosbotonGrafico" style={{ marginLeft: '7px', backgroundColor: '#eeeeee', visibility: obtenerTipoDeBusquedaActual() === 'C' || obtenerTipoDeBusquedaActual() === 'MT' ? 'visible' : 'hidden' }} >
                                            {configApp.visualizacionGrafico ?
                                                <ApartmentOutlined className="estiloIcono" onClick={() => { store.dispatch(mostrarVistaGrafica(false)) }} />
                                                :
                                                <img src="./imagenes/motor_moto.svg" style={{ height: '26px' }} onClick={() => { store.dispatch(mostrarVistaGrafica(true)); store.dispatch(getListTreeGraficos()) }}></img>
                                            }
                                        </button>
                                    </div>
                                    :
                                    <div>
                                    </div>
                                }
                            </div>

                            <div style={configApp.visualizacionFiltro ? { overflow: configApp.visualizacionFiltro ? 'auto' : 'hidden', height: configApp.visualizacionFiltro ? redmensionarAltura + 'px' : '', left: 0, maxWidth: '384px', overflowX: 'hidden' } : { overflow: configApp.visualizacionFiltro ? 'auto' : 'hidden', height: configApp.visualizacionFiltro ? redmensionarAltura + 'px' : '', left: 0, maxWidth: '384px' }}>
                                {!configApp.visualizacionFiltro ?
                                    <div>
                                        <div className="flex-container ">
                                            <div className="flex-item-left" style={{ textAlign: 'left', padding: '5px', flex: '45%' }}>
                                                <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                                                    <div className="grid-item">
                                                        <input id="buscadorFiltroMarca" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_marcas")} style={{ backgroundColor: "#eee", width: '200px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { props.aplicarFiltroPorMarca(e); props.efectoBotonRemoveLocal("buscadorFiltroMarca", "closeFiltroMarca", "MARCAS") }} />
                                                    </div>

                                                    <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                        <MDBBtn id="closeFiltroMarca" onClick={e => { createRipple(e); props.efectoBotonRemoveLocal("", "closeFiltroMarca", "MARCAS") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                    </div>

                                                    <div className="grid-item barraLateral" ></div>

                                                    <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                        <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-item-right" style={{ padding: '5px', flex: '10%' }}>
                                                <button onClick={(e) => {
                                                    setActulizarVista(true);
                                                    props.verficarCambiosListaMarcas();

                                                    store.dispatch(verFiltroPorMarca(filtrosReferencia.verTodasLasMarcas ? false : true));

                                                    props.actulizarMarcasSeleccionadas(filtrosReferencia.verTodasLasMarcas ? props.todasLasMarcas : []);
                                                    store.dispatch(updateGruposMarcasSeleccionadas(filtrosReferencia.verTodasLasMarcas ? props.todasLasMarcas : []));
                                                    mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia, grupoDeMarcas.conLadoMontaje);


                                                }} className="activebtnMenu2 btnEstiloFiltro" style={{ width: '80px', height: '40px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Todas")}</span> </button>
                                            </div>
                                        </div>

                                        <Checkbox.Group value={props.checkedListMarcas} style={{ width: '100%', height: (configApp.height / 3).toString() + "px", overflow: 'auto', overflowX: 'hidden' }} onChange={(e) => {
                                            setActulizarVista(true);
                                            props.actulizarMarcasSeleccionadas(e);
                                            store.dispatch(updateGruposMarcasSeleccionadas(e));

                                            mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia, grupoDeMarcas.conLadoMontaje);

                                        }}>
                                            {
                                                props.todasLasMarcas.map((row, i) =>
                                                    <div key={i}>
                                                        <Checkbox value={row} className="pl-2">{row}</Checkbox>
                                                        <br></br>
                                                    </div>
                                                )
                                            }
                                        </Checkbox.Group>

                                        <div className="flex-container">
                                            <div className="flex-item-left" style={{ textAlign: 'left', padding: '5px', flex: '45%' }}>

                                                <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                                                    <div className="grid-item">
                                                        <input id="buscadorFiltroGrupo" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_grupos")} style={{ backgroundColor: "#eee", width: '200px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { props.aplicarFiltroPorGrupo(e); props.efectoBotonRemoveLocal("buscadorFiltroGrupo", "closeFiltroGrupo", "GRUPOS") }} />
                                                    </div>

                                                    <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                        <MDBBtn id="closeFiltroGrupo" onClick={e => { createRipple(e); props.efectoBotonRemoveLocal("", "closeFiltroGrupo", "GRUPOS") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                    </div>

                                                    <div className="grid-item barraLateral" ></div>

                                                    <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                        <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-item-right" style={{ padding: '5px', flex: '10%' }}>
                                                <button onClick={(e) => {
                                                    setActulizarVista(true);
                                                    props.verficarCambiosListaGrupos();
                                                    store.dispatch(verFiltroPorGrupo(filtrosReferencia.verTodosLosGrupos ? false : true));

                                                    props.actulizarGruposSeleccionadas(filtrosReferencia.verTodosLosGrupos ? props.todosLosGrupos : []);
                                                    store.dispatch(updateGruposMontajeSeleccionados(filtrosReferencia.verTodosLosGrupos ? props.todosLosGrupos : []));
                                                    mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia, grupoDeMarcas.conLadoMontaje);

                                                }} className="activebtnMenu2 btnEstiloFiltro" style={{ width: '80px', height: '40px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Todas")}</span> </button>
                                            </div>
                                        </div>

                                        <Checkbox.Group value={props.checkedListGrupos} style={{ width: '100%', height: (configApp.height / 3).toString() + "px", overflow: 'auto', overflowX: 'hidden' }} onChange={(arrayGrupoMontaje) => {
                                            setCambioGrupo(false);
                                            setActulizarVista(true);
                                            filtroPorDefecto(false)

                                            props.actulizarGruposSeleccionadas(arrayGrupoMontaje);
                                            store.dispatch(updateGruposMontajeSeleccionados(arrayGrupoMontaje));

                                            mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia, grupoDeMarcas.conLadoMontaje);
                                        }}>
                                            {
                                                props.todosLosGrupos.map((row, i) =>
                                                    <div key={i}>
                                                        <Checkbox value={row} className=" pl-2">{row}</Checkbox>
                                                        <br></br>
                                                    </div>
                                                )
                                            }
                                        </Checkbox.Group>
                                    </div>
                                    :
                                    null
                                }

                                {props.nodoIdSeleccionado.length > 0 ?
                                    <DirectoryTree defaultSelectedKeys={props.nodoIdSeleccionado} defaultExpandedKeys={props.nodoIdSeleccionado} showIcon={false} multiple treeData={props.loop(props.filtroArbol)} style={{ display: !configApp.visualizacionFiltro ? 'none' : '' }} />
                                    :
                                    null
                                }

                                {listaTree !== null && listaTree.length > 0 && props.nodoIdSeleccionado.length === 0 ?
                                    <DirectoryTree showIcon={false} multiple treeData={props.loop(listaTree)} style={{ display: !configApp.visualizacionFiltro ? 'none' : '' }} />
                                    :
                                    listaTree === null ?
                                        <div className="container_texto_albaran">
                                            <div>{traducirPagina("No_resultados")}</div>
                                        </div>
                                        :
                                        props.nodoIdSeleccionado.length > 0 ?
                                            null
                                            :

                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                }
                            </div>

                        </div>

                        <Layout className="site-layout" style={{ marginLeft: '400px', backgroundColor: '#eee', height: (configApp.height - 129).toString() + "px" }}>

                            {componente}

                            {

                                /*
                                                                <CatalogoArticulos
                                                                    grupoDeMarcas={grupoDeMarcas}
                                                                    añadirLocalmenteLadoMontaje={añadirLocalmenteLadoMontaje}
                                                                    activarBotonVerReferenciasConCruce={ACTIVAR_BOTON_VER_REFERENCIAS_CON_CRUCE}
                                                                    pagination={pagination}
                                
                                                                    configApp={configApp}
                                                                    tipoCombustible={fichaBusquedaVehiculo.TIPO_COMBUSTIBLE}
                                                                    eventAbrirNeumatico={() => { setAbrirFichaNeumatico(true) }}
                                
                                
                                                                    buscadorTree={buscadorTree}
                                                                    DIRECTORIO_DOCUMENTO={DIRECTORIO_DOCUMENTO}
                                                                    eventSugeridos={(item) => {
                                                                        setReferenciaSugerida(true)
                                                                        setReferenciaSeleccionada(item)
                                                                    }}
                                
                                                                    añadirALaCesta={añadirALaCesta}
                                                                    abrirDesglose={abrirDesglose}
                                                                    eventStockGrupo={(item) => {
                                                                        setRegistroStockSocio({ REFERENCIA_TECDOC: item.REFERENCIA, MARCA_TECDOC: item.CODIGO_MARCA, TIPO_ARTICULO: 1, ADICIONAL: item })
                                                                        setAbrirFichaStockSocio(true)
                                                                    }}
                                                                    eventMasInformacion={(item) => {
                                                                        store.dispatch(DrawerMasInformacion(true));
                                                                        store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item }));
                                                                    }}
                                                                    eventDesglose={(item) => {
                                                                        store.dispatch(DrawerMasInformacion(true));
                                                                        store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, VERDESPIECE: true, REGISTROS: item }));
                                                                    }}
                                                                    eventTiempoSustitucion={(item) => {
                                                                        store.dispatch(DrawerMasInformacion(true));
                                                                        store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, VERTIEMPOS: true, GRUPO_MONTAJE: item.GRUPO_MONTAJE, REGISTROS: item }));
                                                                    }}
                                
                                
                                
                                                                    usuario={usuario}
                                                                    listaTree={listaTree}
                                                                    codmotorizacion={codmotorizacion}
                                                                    eventoTreeFiltro={(estado) => {
                                                                        if (estado) {
                                                                            store.dispatch(updateVisualizacionFiltro(true));
                                                                            store.dispatch(eventoFiltroReferencias([]));
                                                                            store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, registrosFiltro: [] })
                                                                        } else {
                                                                            store.dispatch(updateVisualizacionFiltro(false));
                                                                            store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, registrosFiltro: [] });
                                                                        }
                                
                                                                    }}
                                                                    FiltroGrupoPorDefecto={FiltroGrupoPorDefecto}
                                                                    filtroPorDefecto={filtroPorDefecto}
                                                                    visualizacionFiltro={configApp.visualizacionFiltro}
                                
                                                                />
                                */
                            }
                        </Layout>

                    </Layout>
                </div>
                :
                grupoDeMarcas.referencias === undefined ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
            }




            {abrirFichaManuales ?
                <Manuales
                    title={traducirPagina("Manuales_reparacion").toUpperCase()}
                    ABRIR_FICHA={abrirFichaManuales}
                    CERRAR_FICHA={setAbrirFichaManuales}
                    REFERENCIA_SELECCIONADA={referenciaSeleccionada}
                >
                </Manuales>
                :
                null
            }

            {
                abrirFichaStockSocio ?
                    <FichaStockSocio
                        title={`${traducirPagina("Stock_grupo").toUpperCase()}`}
                        ABRIR_FICHA={abrirFichaStockSocio}
                        CERRAR_FICHA={setAbrirFichaStockSocio}
                        DATOS_SELECCIONADO={registroStockSocio}
                    >
                    </FichaStockSocio>
                    :
                    null
            }


            {
                abrirFichaNeumatico ?
                    <FichaNeumaticosErp
                        title={traducirPagina("Catalogo_de_neumaticos").toUpperCase()}
                        ABRIR_FICHA={abrirFichaNeumatico}
                        CERRAR_FICHA={setAbrirFichaNeumatico}
                        TIPO={""}
                        MEDIDAS={""}
                        ID_VEHICULO={parseInt(codmotorizacion)}
                    >
                    </FichaNeumaticosErp>
                    :
                    null
            }

            {
                referenciaSugerida &&
                <FichaReferenciaSugerida
                    title={traducirPagina("txt_referencia_sugerida").toUpperCase()}
                    ABRIR_FICHA={referenciaSugerida}
                    CERRAR_FICHA={setReferenciaSugerida}
                    ID_VEHICULO={codmotorizacion}
                    GRUPO_MONTAJE={referenciaSeleccionada.GRUPO_MONTAJE}
                >
                </FichaReferenciaSugerida>
            }


        </>
    );
}
ContainerReferencia.propTypes = {
    title: PropTypes.string,
    ACTUALIZAR_POR_DEFECTO: PropTypes.func,
    ACTUALIZAR_GRUPO: PropTypes.func,
    ESTADO_GRUPO: PropTypes.bool,
    LISTA_GRUPOS: PropTypes.array,
    textoArbol: PropTypes.string,
    setTextoArbol: PropTypes.func,
    efectoBotonRemoveLocal: PropTypes.func,
    busquedaArbolLocal: PropTypes.func,
    aplicarFiltroPorMarca: PropTypes.func,
    verficarCambiosListaMarcas: PropTypes.func,
    actulizarMarcasSeleccionadas: PropTypes.func,
    todasLasMarcas: PropTypes.array,
    checkedListMarcas: PropTypes.array,
    aplicarFiltroPorGrupo: PropTypes.func,
    verficarCambiosListaGrupos: PropTypes.func,
    actulizarGruposSeleccionadas: PropTypes.func,
    todosLosGrupos: PropTypes.array,
    checkedListGrupos: PropTypes.array,
    nodoIdSeleccionado: PropTypes.array,
    loop: PropTypes.func,
    filtroArbol: PropTypes.array,

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        search: state.searchValue,
        listaTree: state.listaTree,
        expandedKeys: state.expandedKeys,
        autoExpandParent: state.autoExpandParent,

        configApp: state.configApp,
        buscadorTree: state.buscadorTree,

        pagination: state.pagination,
        grupoDeMarcas: state.grupoDeMarcas,
        colapseMarca: state.colapseMarca,

        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        datosGraficos: state.datosGraficos,
        usuario: state.usuario,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado,
        FiltroGrupoPorDefecto: state.FiltroGrupoPorDefecto,
        TipoGrafico: state.TipoGrafico,
        DIRECTORIO_DOCUMENTO: state.DIRECTORIO_DOCUMENTO,
        listaTreeFiltrada: state.listaTreeFiltrada,
        filtrosReferencia: state.filtrosReferencia,




    };
}

export default connect(mapStateToProps)(ContainerReferencia);