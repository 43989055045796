import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
    MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody
} from 'mdbreact';
import ComponentInputCoche from '../components/cpInputCoche'
import { connect } from 'react-redux';
import store from "../store";
import { getFocus, traducirPagina, efectoRemoveBuscadorReferencia } from "../librerias/funciones"

import { setHistoricApp, showChildrenDrawerLevel2, gestionarContenedorCoche, showItemReferencia, estadoAgrupacionDeMarcas, updateVisualizacionFiltro } from "../action/actionCreators";
import { getIdTecdocMotorByIdHaynes, setHistorialBusqueda } from "../action/actionCreatorsRequests";
import { Tag } from "antd";
import CPSearch from "./utils/CPSearch";
const DrawerMotorizacion = ({ enginesType, fichaBusquedaVehiculo, openDrawerFichaMantenimientoVehiculo, props }) => {
    const [textoInput, setTextoInput] = useState("")



    if (Object.keys(enginesType).length > 0) {
        getFocus("imputdropmotorizacion");
    }

    const versionColumna = (registros) => {
        let data = registros.filter((item) => item.VERSION !== undefined && item.VERSION !== null && item.VERSION !== "")
        return data.length
    }

    const verificarNull = (item) => {
        return item === null ? "" : item
    }


    return (
        <MDBRow>
            <MDBCol md="12">
                {enginesType.original === null || enginesType.original === undefined ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                    :
                    Object.keys(enginesType).length > 0 ?
                        Object.keys(enginesType.aux).length > 0 ?
                            <div className="mdb-skin">

                                <div>
                                    <CPSearch
                                        className='mdb-skin searchVehiculo'
                                        TEXTO_BUSCADOR={textoInput}
                                        placeholder={traducirPagina("TextoBuscadorDeMotorizacion")}
                                        onChangeInput={(e) => {
                                            //buscarEnTabla(e.target.value);
                                            setTextoInput(e.target.value)
                                        }}
                                        onCloseInput={() => {
                                            setTextoInput("");
                                            //buscarEnTabla("")
                                        }}
                                    />
                                </div>


                                {/*
                                <div className="mt-3 pb-2 ">
                                    <ComponentInputCoche listaValoresDrop={'{"nombreDrop":"","placeholder":"Búscador de motorización","listaitem":[],"id":"dropmotorizacion"}'} >   </ComponentInputCoche>
                                </div>

 */}
                                <div className="container_grid_coche">

                                    <MDBTable responsive hover>
                                        <MDBTableHead>
                                            <tr className="table_modelos_vehiculos">

                                                {versionColumna(enginesType.original) > 0 ? <th style={{ textAlign: 'left' }}>{traducirPagina("Versión")}</th> : null}

                                                <th style={{ textAlign: 'left' }}>{traducirPagina("Motor")}</th>
                                                <th style={{ textAlign: 'left' }}>{traducirPagina("Codigo_cliente")}</th>
                                                <th style={{ width: '80px' }}>{traducirPagina("Año")}</th>
                                                <th style={{ width: '50px' }}>KW</th>
                                                <th style={{ width: '50px' }}>CV</th>
                                                <th style={{ width: '50px' }}>CC</th>
                                                <th style={{ width: '100px', textAlign: 'center' }}></th>
                                            </tr>
                                        </MDBTableHead>

                                        <MDBTableBody>
                                            {enginesType.original
                                                .filter((item) =>
                                                    verificarNull(item.VERSION).toString().toUpperCase() === textoInput.toUpperCase() ||
                                                    verificarNull(item.VERSION).toString().toUpperCase().includes(textoInput.toUpperCase()) ||
                                                    verificarNull(item.DESCRIPCION).toString().toUpperCase() === textoInput.toUpperCase() ||
                                                    verificarNull(item.DESCRIPCION).toString().toUpperCase().includes(textoInput.toUpperCase()) ||
                                                    verificarNull(item.MOTORES).toString().toUpperCase() === textoInput.toUpperCase() ||
                                                    verificarNull(item.MOTORES).toString().toUpperCase().includes(textoInput.toUpperCase()))
                                                .map((row, i) =>
                                                    <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            efectoRemoveBuscadorReferencia("buscadorReferencia", true);

                                                            if (openDrawerFichaMantenimientoVehiculo) {
                                                                store.dispatch(showChildrenDrawerLevel2(false));

                                                                store.dispatch({
                                                                    type: "UPDATE_VEHICULO_TALLER_POR_MODELO",
                                                                    vehiculoPorModelo:
                                                                    {
                                                                        ID_MARCA: fichaBusquedaVehiculo[0].id,
                                                                        ID_MODELO: fichaBusquedaVehiculo[1].id,
                                                                        MARCA: fichaBusquedaVehiculo[0].ruta,
                                                                        MODELO: fichaBusquedaVehiculo[1].ruta,
                                                                        MOTORIZACION: row,

                                                                    }
                                                                })
                                                            } else {
                                                                store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                                                                store.dispatch(showChildrenDrawerLevel2(false));

                                                                store.dispatch(getIdTecdocMotorByIdHaynes(row.ID, row));


                                                                store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE })

                                                                store.dispatch(showItemReferencia("tree"));
                                                                store.dispatch(setHistoricApp(row, "/motorizacion", "tipomotor"));
                                                                gestionarContenedorCoche(true);
                                                                store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: {} })

                                                                //store.dispatch(getFichaCoche(row.ID.toString()));
                                                                store.dispatch(updateVisualizacionFiltro(true));
                                                                //store.dispatch(getIdVehicleByTecdocNumber(row.ID));
                                                                setHistorialBusqueda({
                                                                    MARCA: fichaBusquedaVehiculo[0].id,
                                                                    MODELO: fichaBusquedaVehiculo[1].id,
                                                                    MOTORIZACION: row.ID,

                                                                    NOMBRE_MARCA: fichaBusquedaVehiculo[0].ruta,
                                                                    NOMBRE_MODELO: fichaBusquedaVehiculo[1].ruta,
                                                                    NOMBRE_MOTOR: row.DESCRIPCION


                                                                })
                                                            }
                                                        }}>

                                                        {versionColumna(enginesType.original) > 0 ? <td style={{ textAlign: 'left' }}>{row.VERSION}</td> : null}

                                                        <td style={{ textAlign: 'left' }}>{row.DESCRIPCION}</td>
                                                        <td style={{ textAlign: 'left' }}>{row.MOTORES === "" ? "..." : row.MOTORES}</td>
                                                        <td>{row.DESDE === "" ? "..." : row.DESDE}</td>

                                                        <td>{row.KW === "" ? "..." : row.KW}</td>
                                                        <td>{row.CV === "" ? "..." : row.CV}</td>
                                                        <td>{row.CC === "" ? "..." : row.CC}</td>
                                                        <td>{row.ETIQUETA !== undefined && row.ETIQUETA !== null && row.ETIQUETA !== "" ? <Tag className="btnColor">{row.ETIQUETA} </Tag> : null}</td>

                                                    </tr>
                                                )}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                            </div>
                            : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>
                        : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>
                }
            </MDBCol>
        </MDBRow>
    );
}

DrawerMotorizacion.propTypes = {
    TIPO_VEHICULO: PropTypes.number,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        enginesType: state.fichaBusquedaVehiculo.enginesType,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo.historicapp,
        openDrawerFichaMantenimientoVehiculo: state.estadoDrawer.openDrawerFichaMantenimientoVehiculo,

    };
}


export default connect(mapStateToProps)(DrawerMotorizacion);