import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBInput } from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import 'antd/dist/antd.css';
import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs, Menu, Switch, Divider, Collapse } from 'antd';
import { DrawerFichaMantenimientoHaynes, createRipple, DrawerFichaGMVehiculoHaynes } from "../action/actionCreators";
import { GetUsuariosEnAPI, SetUsuarioEnAPI, GetIdiomasEnAPI, GetNodosRaizTiemposReparacionEnAPI, GetSubNodosTiempoReparacioEnAPI, ObtenerTareasTiempoReparacionEnnAPI, GetVehiculosHaynesByIdTecdocEnAPI, ObtenerInfoTareasTiempoReparacionEnAPI, ModificarCestaTiempoReparacionEnAPI, ObtenerSistemasMantenimientoEnAPI, ObtenerTareasMantenimientoEnAPI, obtenerPlanServicioMantinimientoEnAPI, ObtenerPlanServicioMantenimientoHTMLEnAPI } from "../action/actionCreatorsRequests";
import { convertirMoneda, MSGERROR, MSGOK, traducirPagina, dynamicSortAsc, crearLineasParaElPresupuesto, redondeo, imprimirDocumentoHTML, descargarDocPasandoBase64Generico, buscarVehiculoLocal, gestionarPlanServicioHaynesPro, obtenerTablasDocumento } from "../librerias/funciones"
import FichaGMVehiculoHaynesPro from '../components/FichaGMYVehiculoHaynesPro'

import {
    MailOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    FileAddOutlined,
    PlusCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined,
    EyeOutlined,
    DownOutlined,
    UpOutlined,
    CheckSquareOutlined,
    PrinterOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import store from '../store'
import FichaImpresionGenerico from './FichaImpresionGenerico.jsx';

const { Option } = Select;
const { TabPane } = Tabs;
const { Meta } = Card;
const { Panel } = Collapse;
const FichaManteniminetoHaynesPro = ({ props, configApp, openDrawerFichaMantenimientoHaynesPro, codmotorizacion }) => {
    const [mode, setMode] = React.useState('inline');
    const [theme, setTheme] = React.useState('light');

    const [verDetalles, setVerDetalles] = React.useState(false);
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [totales, setTotales] = React.useState({ tiempoEstandar: 0, tiempoAdicional: 0, tiempoTotal: 0 });
    const [nodos, setNodos] = React.useState([]);
    const [nodoActivo, setNodoActivo] = React.useState("");
    const [tareasCesta, setTareasCesta] = React.useState([]);

    const [tareasTiempoReparacion, setTareasTiempoReparacion] = React.useState([]);

    const [grupoMontaje, setGrupoMontaje] = React.useState("");
    const [Vivid, setVivid] = React.useState(0);
    const [tiempoReparacion, setTiempoReparacion] = React.useState("");
    const [visible, setVisible] = React.useState(false);


    const [sistemaMantenimiento, setSistemaMantenimiento] = React.useState([]);
    const [opcionMantenimiento, setOpcionMantenimiento] = React.useState(0);
    const [verPiezas, setVerPiezas] = React.useState(false);
    const [verTrabajos, setVerTrabajos] = React.useState(false);
    const [idPeriodo, setIdPeriodo] = React.useState(0);
    const [nombreMantenimiento, setNombreMantenimiento] = React.useState("");



    const [modalPrint, setModalPrint] = useState({
        ABRIR_MODAL: false,

        VEHICULO_DESCRIPCION: "",
        VEHICULO_KILOMETRAJE: "",
        VEHICULO_FECHA_REGISTRO: "",
        VEHICULO_MATRICULA: "",
        VEHICULO_NUMERO_CALCULO_COSTE_FACTURA: "",
        VEHICULO_VIN: "",
        NOMBRE_MANTENIMIENTO: "",

        CLIENTE_NOMBRE: "",
        CLIENTE_TELEFONO: "",
        CLIENTE_MAIL: "",
        CLIENTE_DIRECCION: "",

        LOADING: false
    })
    const [fichaImpresion, setFichaImpresion] = useState({
        abrirFichaImpresion: false,
        html: "",
        pdf: ""
    })


    if (!hayRegistros && openDrawerFichaMantenimientoHaynesPro) {
        setHayRegistros(true);
        let tiempo = ""
        let vivid = 0

        GetVehiculosHaynesByIdTecdocEnAPI(codmotorizacion).then(tiempos => {
            if (tiempos !== undefined) {
                if (tiempos !== null && tiempos.length > 0) {
                    tiempo = tiempos[0].IdTiempoReparacion;
                    vivid = tiempos[0].Vivid
                    setTiempoReparacion(tiempo)
                    setVivid(vivid)

                    ObtenerSistemasMantenimientoEnAPI(vivid, tiempo).then(sistemas => {
                        if (sistemas !== undefined) {
                            if (sistemas !== null && sistemas.length > 0) {
                                setSistemaMantenimiento(sistemas)

                                let sistemaPorDefecto = sistemas[0]

                                //let buscarTiempo =  sistemaPorDefecto.PeriodosMantenimiento.filter((tiempo) => tiempo.Tiempos.length > 0)

                                if (sistemaPorDefecto.PeriodosMantenimiento[0].Tiempos.length > 0) {
                                    //let tiempoPorDefecto = sistemaPorDefecto.PeriodosMantenimiento[0].Tiempos[0].Valor;
                                    let tiempoPorDefecto = sistemaPorDefecto.PeriodosMantenimiento[0].Tiempos[0].Valor;

                                    setOpcionMantenimiento(sistemaPorDefecto.Id)
                                    mostrarArbol(sistemaPorDefecto.PeriodosMantenimiento, vivid, tiempo, sistemaPorDefecto.Id);

                                    setTotales({ tiempoEstandar: tiempoPorDefecto, tiempoAdicional: 0, tiempoTotal: tiempoPorDefecto });
                                } else {
                                    setSistemaMantenimiento(null)
                                }

                            } else {

                                setSistemaMantenimiento(null)
                            }
                        }
                    })
                }
            }
        })




    } else {
        if (!openDrawerFichaMantenimientoHaynesPro && hayRegistros) {
            setVerPiezas(false)
            setVerTrabajos(false)
            setSistemaMantenimiento([])
            setVivid(0)
            setTiempoReparacion("")
            setNodoActivo("")
            setVisible(false)
            setGrupoMontaje("")
            setTareasCesta([])
            setVerDetalles(false)
            setHayRegistros(false);
            setNodos([])
            setTotales({ tiempoEstandar: 0, tiempoAdicional: 0, tiempoTotal: 0 })
            setTareasTiempoReparacion([])
        }
    }


    //console.log("manteni", tareasTiempoReparacion, " TRABAJ", trabajos)



    const añadirItemDentroDeArticulo = (articulos) => {
        let modificarArticulo = [];
        articulos.map((articulo) => {
            let nuevoArticulo = { Id: articulo.Id, Descripcion: articulo.Descripcion, Obligatorio: articulo.Obligatorio, Precio: 0, ReferenciaSeleccionado: {}, Referencia: "", Marca: "" }
            modificarArticulo.push(nuevoArticulo);
        })

        return modificarArticulo;
    }

    const ObtenerTareasTiempoReparacion = (tiempo, nodo) => {
        let listaTemporal = [];
        if (nodo !== undefined) {
            let buscarNodoPadre = tareasTiempoReparacion.find((registro) => registro.Id === nodo);

            if (buscarNodoPadre.tareas.length === 0) {
                ObtenerTareasTiempoReparacionEnnAPI(tiempo, nodo, false).then(tareas => {
                    if (tareas !== undefined) {
                        if (tareas !== null && tareas.length > 0) {
                            tareas = tareas.map((obj, index) => (
                                {
                                    ...obj, Articulos: añadirItemDentroDeArticulo(obj.Articulos), Seleccionado: false, Nivel: 1, Orden: ((index + 1) * 100), BuscarTareas: true, Enabled: false, visible: true, ValorOrigen: obj.Valor, Descripcion2: ""
                                }
                            ))

                            tareasTiempoReparacion.map((registro) => {
                                if (registro.Id === nodo) {
                                    registro.tareas = tareas;
                                }
                            });

                            listaTemporal = [...tareasTiempoReparacion];

                            setTareasTiempoReparacion(listaTemporal)
                        } else {
                            setTareasTiempoReparacion(null)
                        }
                    }
                })
            }
        }
    }


    const obtenerCestaDeLaAPI = (tiempoReparacion, Vivid, tareas, nodo) => {
        let listaIds = [];
        tareas.map((registro) => {
            registro.tareas.map((tarea, index) => {
                if (tarea.Nivel === 1 && tarea.Seleccionado) {
                    listaIds.push(tarea.Id)

                }
            })
        })

        if (listaIds.length > 0) {
            ModificarCestaTiempoReparacionEnAPI(tiempoReparacion, Vivid, listaIds).then(cesta => {
                if (cesta !== undefined) {

                    if (cesta !== null) {
                        totales.trabajos = cesta.Tareas.length;
                        totales.tiempoTotal = cesta.TiempoTotal;
                        totales.piezasNecesarias = cesta.PiezasNecesarias;
                        setTareasCesta(cesta.Tareas);
                        setTotales({ ...totales });
                    }


                    if (nodo !== undefined) {
                        if (cesta !== null) {
                            let nuevoCantidad = modificarPrecioCestaPorId(nodo.Id, nodo.Valor, cesta.Tareas);
                            tareasTiempoReparacion.map((registro, index) => {
                                if (registro.tareas.length > 0) {
                                    registro.tareas.map(tarea => {
                                        if (nodo.Id === tarea.Id && nodo.Nivel === 1) {
                                            if (tarea !== undefined) {
                                                if (tarea.Valor !== nuevoCantidad) {
                                                    tarea.Valor = nuevoCantidad;
                                                    tarea.Descripcion2 = traducirPagina("El_tiempo_predeterminado") + " " + tarea.ValorOrigen + " " + traducirPagina("ha_cambiado")
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                            setTareasTiempoReparacion([...tareasTiempoReparacion])
                        }
                    }

                }
            })
        } else {
            setVerDetalles(false)
            setTotales({ trabajos: 0, tiempoTotal: 0, piezasNecesarias: 0 })
        }

    }

    const modificarPrecioCestaPorId = (idTarea, precio, tareasCesta) => {
        let precioTarea = precio
        if (tareasCesta !== null) {
            if (tareasCesta.length > 0) {
                let buscarTarea = tareasCesta.find(tarea => tarea.Id === idTarea)
                if (buscarTarea !== undefined) {
                    precioTarea = buscarTarea.TiempoCalculado
                }

            }
        }
        return precioTarea;
    }


    const obtenerTrabajos = () => {
        let trabajos = "";
        tareasTiempoReparacion.map((registro, index) => {
            registro.SubTareas.filter(subTarea => subTarea.Seleccionado === true).map((tarea, indice) => {

                trabajos = trabajos + tarea.Nombre + "\n"
            })
        })

        return trabajos;
    }

    const obtenerArticulos = () => {
        let trabajos = [];
        tareasTiempoReparacion.map((registro, index) => {
            registro.SubTareas.map((tarea, indice) => {
                let articulos = tarea.Articulos.filter(articulo => articulo.Visible === true)
                articulos.map(item => {
                    trabajos.push(item)
                })
            })
        })


        return trabajos;
    }

    const comprobarSiHayPiezasSinReferencia = () => {
        let articulos = obtenerArticulos();
        let continuar = false;
        let verficarSiHayPrecio = articulos.filter(articulo => Object.keys(articulo.ReferenciaSeleccionado).length === 0)
        if (verficarSiHayPrecio.length === 0) {
            continuar = true;
        }
        return continuar
    }


    const añadirApresupuesto = () => {
        let manoObra = obtenerTrabajos();
        let articulos = obtenerArticulos();

        let Obra = {
            Tiempo: totales.tiempoTotal,
            Descripcion: manoObra,
            TAREAS_REPARACION: [],
        };

        crearLineasParaElPresupuesto(Obra, "T", true);


        articulos.map((articulo, indice) => {
            let itemArticulo = {};
            if (Object.keys(articulo.ReferenciaSeleccionado).length > 0) {
                itemArticulo = {
                    PRECIO: articulo.ReferenciaSeleccionado.PRECIO,
                    REFERENCIA_PROVEEDOR: articulo.ReferenciaSeleccionado.REFERENCIA_PROVEEDOR,
                    REFERENCIA: articulo.ReferenciaSeleccionado.REFERENCIA,
                    CODIGO_MARCA_ERP: articulo.ReferenciaSeleccionado.CODIGO_MARCA_ERP,
                    NOMRE_MARCA: articulo.ReferenciaSeleccionado.NOMRE_MARCA,
                    CODIGO_MARCA: articulo.ReferenciaSeleccionado.CODIGO_MARCA,
                    NOMBRE_GRUPO_MONTAJE: articulo.ReferenciaSeleccionado.NOMBRE_GRUPO_MONTAJE,
                    DESCUENTO: articulo.ReferenciaSeleccionado.DESCUENTO,

                }


            } else {
                itemArticulo = {
                    PRECIO: 0,
                    REFERENCIA_PROVEEDOR: "",
                    REFERENCIA: "",
                    CODIGO_MARCA_ERP: "",
                    NOMRE_MARCA: "",
                    CODIGO_MARCA: 0,
                    NOMBRE_GRUPO_MONTAJE: articulo.Descripcion,
                    DESCUENTO: 0
                }
            }

            crearLineasParaElPresupuesto(itemArticulo, "A", true);
        })

        MSGOK(traducirPagina("Referencia_agregada"))
    }

    const actualizarItemSeleccionado = (nodo, padre) => {
        let listaTemporal = [];
        let resultPadre = tareasTiempoReparacion.find(registro => registro.Id === padre);

        resultPadre.tareas.map((tarea, index) => {
            if (tarea.Id === nodo.Id) {
                if (tarea.Nivel === 1) {
                    tarea.Seleccionado = tarea.Seleccionado ? false : true;
                    tarea.Valor = tarea.ValorOrigen;
                    tarea.Descripcion2 = "";

                    if (nodo.Nivel === 1) {
                        for (let i = (index + 1); i < resultPadre.tareas.length; i++) {
                            if (resultPadre.tareas[i].Nivel === 2) {
                                resultPadre.tareas[i].visible = nodo.Seleccionado;

                            } else {
                                break;
                            }
                        }
                    }


                } else {
                    tarea.Enabled = tarea.Enabled ? false : true;
                }
            }
        });

        listaTemporal = [...tareasTiempoReparacion];

        setTareasTiempoReparacion(listaTemporal)
        obtenerCestaDeLaAPI(tiempoReparacion, Vivid, listaTemporal, nodo)

    }


    const ObtmnerHijosDeLasTareas = (tiempo, nodo, padre) => {
        let listaTemporal = [];
        let unirTareas = []


        //si es falso ir a buscar tareas
        if (nodo.BuscarTareas && !nodo.Seleccionado) {
            actualizarItemSeleccionado(nodo, padre)
            nodo.Seleccionado = true;

            ObtenerInfoTareasTiempoReparacionEnAPI(tiempo, nodo.Id, nodo.TieneInfo).then(tareas => {
                if (tareas !== null) {
                    nodo.BuscarTareas = false;
                    nodo.Enabled = false;

                    if (tareas !== undefined) {
                        nodo.TieneSubNodos = true;
                        let TareasPosteriores = tareas.TareasPosteriores === null ? [] : tareas.TareasPosteriores
                        let TareasIncluidas = tareas.TareasIncluidas === null ? [] : tareas.TareasIncluidas
                        TareasPosteriores = TareasPosteriores.map(obj => ({ ...obj, Enabled: false, Seleccionado: false, Nivel: 2, Orden: 0, BuscarTareas: false, TieneSubNodos: false, TipoTrabajo: "", visible: true, ValorOrigen: obj.Valor, Descripcion2: "" }))
                        TareasIncluidas = TareasIncluidas.map(obj => ({ ...obj, Enabled: false, Seleccionado: false, Nivel: 2, Orden: 0, BuscarTareas: false, TieneSubNodos: false, TipoTrabajo: "", visible: true, ValorOrigen: obj.Valor, Descripcion2: "" }))

                        unirTareas = TareasIncluidas.concat(TareasPosteriores);

                        let ordenContador = nodo.Orden + 1;
                        unirTareas.map(tarea => {
                            tarea.Orden = ordenContador;
                            ordenContador = ordenContador + 1;
                            tarea.Enabled = tarea.Direccion === "F" ? buscarTareaActivadaPorId(tarea.Numero, padre) : false

                        })

                        let resultPadre = tareasTiempoReparacion.find(registro => registro.Id === padre);
                        unirTareas.map((registro, index) => {
                            resultPadre.tareas.push({
                                Seleccionado: false,
                                Articulos: registro.Articulos,
                                Descripcion: registro.Descripcion,
                                Id: registro.Numero,
                                Numero: registro.Numero,
                                Orden: registro.Orden,
                                TieneInfo: registro.TieneInfo,
                                TieneSubNodos: registro.TieneSubNodos,
                                TipoTrabajo: registro.TipoTrabajo,
                                Valor: registro.Valor,
                                Direccion: registro.Direccion,
                                Enabled: registro.Enabled,
                                visible: registro.visible,
                                Nivel: registro.Nivel,
                                ValorOrigen: registro.ValorOrigen,
                                Descripcion2: registro.Descripcion2
                            })
                        }
                        )
                        listaTemporal = [...tareasTiempoReparacion];
                        setTareasTiempoReparacion(listaTemporal)
                    }
                }
                //calcularTotales(listaTemporal, padre)
                obtenerCestaDeLaAPI(tiempoReparacion, Vivid, listaTemporal, nodo)

            })
        } else {
            actualizarItemSeleccionado(nodo, padre)
        }
    }

    const buscarTareaActivadaPorId = (idTarea, padre) => {
        let tarea = false;
        let resultPadre = tareasTiempoReparacion.find(registro => registro.Id === padre);
        /*
        let filtro = resultPadre.tareas.filter(tarea => (tarea.Nivel === 1 && tarea.Id === idTarea))
        if(filtro.length > 0){
            tarea = filtro.Seleccionado
        }
        */
        for (let i = 0; i < resultPadre.tareas.length; i++) {
            if (resultPadre.tareas[i].Nivel === 1) {
                if (resultPadre.tareas[i].Id === idTarea) {
                    tarea = resultPadre.tareas[i].Seleccionado
                    break;
                }
            }
        }
        return tarea;
    }


    const verificarSiDebeBuscarUnSubnodo = (nodoId) => {
        let buscarNodo = true;
        let existeElSubnodo = tareasTiempoReparacion.find(subodo => subodo.Nodo === nodoId);
        if (existeElSubnodo === undefined) {
            buscarNodo = true;
        } else {
            buscarNodo = false;
        }

        return buscarNodo;
    }

    const obtenerSubtareas = (subTareas) => {
        let trabajos = [];

        subTareas.map((registro, index) => {
            registro.Seleccionado = registro.IncluidoPorDefecto;
            if (registro.Articulos.length > 0) {
                registro.Articulos.map((articulo) => {
                    articulo.Precio = 0;
                    articulo.Referencia = "";
                    articulo.Marca = "";
                    articulo.ReferenciaSeleccionado = {};

                    if (registro.IncluidoPorDefecto && articulo.Obligatorio) {
                        articulo.Seleccionado = false;
                        articulo.Visible = true;
                    } else {
                        articulo.Seleccionado = true;
                        articulo.Visible = false;
                    }
                })
            }
            trabajos.push(registro);
        })
        return trabajos;
    }

    const tareasDeUnMantenimiento = (ID_SISTEMA, ID_PERIODO, Vivid, tiempoReparacion) => {
        const { Nombre, Tiempos } = ID_PERIODO
        let codigoTiempo = Tiempos.length > 0 ? Tiempos[0].Codigo : ""

        setTareasTiempoReparacion([])
        setIdPeriodo(ID_PERIODO.Id)

        setNombreMantenimiento(`${Nombre} ${[null,""].includes(codigoTiempo) ? "": `(OE: ${codigoTiempo})`}`)

        ObtenerTareasMantenimientoEnAPI(Vivid, tiempoReparacion, ID_SISTEMA, ID_PERIODO.Id).then(listaTemporal => {
            if (listaTemporal !== undefined) {
                if (listaTemporal !== null) {
                    //let concatenar = []
                    listaTemporal.map((obj, index) => {

                        let tareaPorDefecto = obj.SubTareas.filter(tarea => tarea.IncluidoPorDefecto === true);

                        obj.Seleccionado = (tareaPorDefecto.length > 0);
                        obj.SubTareas = obtenerSubtareas(obj.SubTareas)
                    })


                    let tiempoPorDefecto = ID_PERIODO.Tiempos[0].Valor;

                    setTotales({ tiempoEstandar: tiempoPorDefecto, tiempoAdicional: 0, tiempoTotal: tiempoPorDefecto });

                    //setTrabajos(concatenar)
                    setTareasTiempoReparacion(listaTemporal)
                } else {
                    setTareasTiempoReparacion(null)
                }
            }
        })
    }



    const mostrarArbol = (Periodo, Vivid, tiempoReparacion, opcionMantenimiento) => {
        setNodos(Periodo);
        let primerPeriodo = Periodo[0]

        tareasDeUnMantenimiento(opcionMantenimiento, primerPeriodo, Vivid, tiempoReparacion)
    }


    const cambiarSeleccionadoTrabajo = (trabajo, indicePrincipal) => {
        let modificarTrabajoPorId = tareasTiempoReparacion[indicePrincipal]
        let objeto = modificarTrabajoPorId.SubTareas.find(obj => obj.DescipcionId === trabajo.DescipcionId)
        let obtenerValor = objeto.Tiempos[0].Valor
        objeto.Seleccionado = objeto.Seleccionado ? false : true;

        objeto.Articulos.map((art) => {
            if (art.Obligatorio) {
                art.Visible = !art.Visible
                art.Seleccionado = false

            }
        })

        let tareaPorDefecto = modificarTrabajoPorId.SubTareas.filter(tarea => tarea.IncluidoPorDefecto === true);
        if (tareaPorDefecto.length === 0) {
            modificarTrabajoPorId.Seleccionado = modificarTrabajoPorId.Seleccionado ? false : true;
        }

        setTareasTiempoReparacion([...tareasTiempoReparacion])

        //Recalculando los totales
        if (objeto.Seleccionado) {
            let localEstandar = totales.tiempoEstandar;
            let localAdicional = redondeo((totales.tiempoAdicional + obtenerValor), 2)
            let localTotal = redondeo((localEstandar + localAdicional), 2)

            setTotales({ tiempoEstandar: localEstandar, tiempoAdicional: localAdicional, tiempoTotal: localTotal });
        } else {
            let localEstandar = totales.tiempoEstandar;
            let localAdicional = redondeo((totales.tiempoAdicional - obtenerValor), 2)
            let localTotal = redondeo((localEstandar - localAdicional), 2)

            setTotales({ tiempoEstandar: localEstandar, tiempoAdicional: localAdicional, tiempoTotal: localTotal });
        }
    }


    const modificarPiezaSeleccionada = (pieza, trabajo, indicePrincipal) => {
        let laPiezaEstaSeleccionada = !pieza.Seleccionado;

        let modificarTrabajoPorId = tareasTiempoReparacion[indicePrincipal]
        let objeto = modificarTrabajoPorId.SubTareas.find(obj => obj.DescipcionId === trabajo.DescipcionId);
        let obtenerPiezaPorId = objeto.Articulos.find(art => art.Id === pieza.Id);
        obtenerPiezaPorId.Seleccionado = laPiezaEstaSeleccionada

        setTareasTiempoReparacion([...tareasTiempoReparacion])
    }
    const descargarDocumento = async () => {

        // modalPrint.ABRIR_MODAL = false;
        modalPrint.LOADING = true
        setModalPrint({ ...modalPrint })

        const respuesta = await ObtenerPlanServicioMantenimientoHTMLEnAPI(tiempoReparacion, Vivid, opcionMantenimiento, idPeriodo, modalPrint,nombreMantenimiento)
        //imprimirDocumentoHTML(respuesta.HTML)
        fichaImpresion.abrirFichaImpresion = true
        fichaImpresion.html = respuesta.HTML
        fichaImpresion.pdf = respuesta.PDF
        setFichaImpresion({ ...fichaImpresion })
        setTimeout(() => {
            //gestionan los eventos de los checkbox.
            //const idsTabla = ["tabla_1", "tabla_2", "tabla_3", "tabla_4", "tabla_5", "tabla_6"];
            const idsTabla = obtenerTablasDocumento();
            idsTabla.map((item) => {
                gestionarPlanServicioHaynesPro(item)
            })
        }, 100)

        // modalPrint.ABRIR_MODAL = false;
        modalPrint.LOADING = false
        modalPrint.ABRIR_MODAL = false


        modalPrint.VEHICULO_DESCRIPCION = ""
        modalPrint.VEHICULO_KILOMETRAJE = ""
        modalPrint.VEHICULO_FECHA_REGISTRO = ""
        modalPrint.VEHICULO_MATRICULA = ""
        modalPrint.VEHICULO_NUMERO_CALCULO_COSTE_FACTURA = ""
        modalPrint.VEHICULO_VIN = ""
        modalPrint.NOMBRE_MANTENIMIENTO = ""
        
        modalPrint.CLIENTE_NOMBRE = ""
        modalPrint.CLIENTE_TELEFONO = ""
        modalPrint.CLIENTE_MAIL = ""
        modalPrint.CLIENTE_DIRECCION = ""
        setModalPrint({ ...modalPrint })
    }

    return (
        <div>
            <Drawer
                width={1200}
                onClose={() => {
                    store.dispatch(DrawerFichaMantenimientoHaynes(false));
                    store.dispatch(DrawerFichaGMVehiculoHaynes(false));
                }}
                visible={openDrawerFichaMantenimientoHaynesPro}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >

                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            store.dispatch(DrawerFichaMantenimientoHaynes(false));
                            store.dispatch(DrawerFichaGMVehiculoHaynes(false));
                            createRipple(e)
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>


                    <div style={{ textAlign: 'left', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button className="col-md-12 btnColor"
                            style={{
                                height: '40px', width: '300px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                                color: '#fff',
                                /*backgroundColor: '#4285f4',*/
                                marginBottom: '5px', marginRight: '5px'
                            }} onClick={(e) => {
                                let verificar = comprobarSiHayPiezasSinReferencia();
                                if (verificar) {
                                    añadirApresupuesto();
                                } else {
                                    setVisible(true);
                                }
                            }}>
                            <FileAddOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Añadir_presupuesto").toUpperCase()}</span>
                        </button>

                    </div>
                </div>

                {/*mantenimiento.PeriodosMantenimiento  tareasDeUnMantenimiento(mantenimiento.Id, periodo.Id)*/}
                <div style={{ height: (configApp.height - 150).toString() + "px", }} className="ml-2 mr-2 mt-2" >

                    <Card
                        type="inner"
                        bordered={false}
                        style={{ height: (configApp.height - 85).toString() + "px", overflow: 'auto' }}
                    >
                        <div>

                            <div className="headerTiempoRMI" style={{ padding: '0px 0px 20px 0px', gap: '20px', justifyContent: 'flex-start' }}>

                                <div style={{ width: '324px' }}>
                                    {sistemaMantenimiento === null ?
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("No_sistemas")}</h4>
                                        </div>
                                        :
                                        sistemaMantenimiento.length > 0 ?
                                            <Select
                                                showSearch
                                                style={{ width: "100%", textAlign: 'left' }}
                                                placeholder={traducirPagina("Seleccione_una_opcion").toUpperCase()}
                                                optionFilterProp="children"
                                                className="mb-3"
                                                size="large"
                                                onChange={(e) => { }}
                                                defaultValue={sistemaMantenimiento[0].Id}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>

                                                {sistemaMantenimiento.map(mantenimiento =>
                                                    <Option key={mantenimiento.Id} value={mantenimiento.Id} onClick={() => { mostrarArbol(mantenimiento.PeriodosMantenimiento, Vivid, tiempoReparacion, mantenimiento.Id); setOpcionMantenimiento(mantenimiento.Id) }}>{mantenimiento.Nombre}</Option>
                                                )
                                                }
                                            </Select>

                                            : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                    }
                                </div>

                                <div className="item2_tiempoHaynes">
                                    <div className='item2_tiempoHaynes_container'>
                                        <div>
                                            <span>{traducirPagina("Tiempo_estandar").toUpperCase()} </span>
                                            <br />
                                            <span style={{
                                                fontSize: '20px',
                                                fontWeight: 'bold'
                                            }}>{convertirMoneda(totales.tiempoEstandar)} </span>
                                        </div>

                                        <div>
                                            <span>{traducirPagina("Tiempo_adicional").toUpperCase()} </span>
                                            <br />
                                            <span style={{
                                                fontSize: '20px',
                                                fontWeight: 'bold'
                                            }}>{convertirMoneda(totales.tiempoAdicional)} </span>
                                        </div>

                                        <div>
                                            <span>{traducirPagina("Tiempo_total_H").toUpperCase()} </span>
                                            <br />
                                            <span style={{
                                                fontSize: '20px',
                                                fontWeight: 'bold'
                                            }}>{convertirMoneda(totales.tiempoTotal)} </span>
                                        </div>
                                    </div>


                                    <div>
                                        {totales.trabajos > 0 ?
                                            <div>
                                                <span style={{ cursor: 'pointer' }} onClick={(e) => { setVerDetalles(verDetalles ? false : true) }}><EyeOutlined style={{ fontSize: '20px', verticalAlign: 'text-top' }} /> {traducirPagina("Ver_detalles")} </span>
                                            </div>

                                            : null
                                        }

                                        <div className="container_doc_mantenimientos">
                                            <button onClick={() => {
                                                modalPrint.ABRIR_MODAL = true; setModalPrint({ ...modalPrint })

                                                buscarVehiculoLocal().then((registros) => {
                                                    const { MATRICULA = "", BASTIDOR = "", MARCA_VEHICULO = "", MODELO_VEHICULO = "", MOTOR_VEHICULO = "", VEHICULO_TECDOC } = registros
                                                    modalPrint.VEHICULO_DESCRIPCION = `${MARCA_VEHICULO} ${MODELO_VEHICULO} ${MOTOR_VEHICULO}`
                                                    modalPrint.VEHICULO_KILOMETRAJE = ""
                                                    modalPrint.VEHICULO_FECHA_REGISTRO = ""
                                                    modalPrint.VEHICULO_MATRICULA = MATRICULA
                                                    modalPrint.VEHICULO_NUMERO_CALCULO_COSTE_FACTURA = ""
                                                    modalPrint.VEHICULO_VIN = BASTIDOR


                                                    setModalPrint({ ...modalPrint })

                                                })
                                            }}>
                                                <PrinterOutlined /> {traducirPagina("Plan_servicio_mantenimiento")}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* 
                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', marginTop: '10px' }}>
                                <Col md={7}>
                                    {sistemaMantenimiento === null ?

                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("No_sistemas")}</h4>
                                        </div>

                                        :
                                        sistemaMantenimiento.length > 0 ?
                                            <Select
                                                showSearch
                                                style={{ width: "100%", textAlign: 'left' }}
                                                placeholder={traducirPagina("Seleccione_una_opcion").toUpperCase()}
                                                optionFilterProp="children"
                                                className="mb-3"
                                                size="large"
                                                onChange={(e) => { }}
                                                defaultValue={sistemaMantenimiento[0].Id}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>

                                                {sistemaMantenimiento.map(mantenimiento =>
                                                    <Option key={mantenimiento.Id} value={mantenimiento.Id} onClick={() => { mostrarArbol(mantenimiento.PeriodosMantenimiento, Vivid, tiempoReparacion, mantenimiento.Id); setOpcionMantenimiento(mantenimiento.Id) }}>{mantenimiento.Nombre}</Option>
                                                )
                                                }
                                            </Select>

                                            : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '40vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                    }

                                </Col>
                                <Col md={1}>

                                </Col>

                                <Col md={4}>
                                    <span>{traducirPagina("Tiempo_estandar").toUpperCase()} </span>
                                    <br />
                                    <span style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold'
                                    }}>{convertirMoneda(totales.tiempoEstandar)} </span>
                                </Col>

                                <Col md={4}>
                                    <span>{traducirPagina("Tiempo_adicional").toUpperCase()} </span>
                                    <br />
                                    <span style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold'
                                    }}>{convertirMoneda(totales.tiempoAdicional)} </span>
                                </Col>

                                <Col md={5}>
                                    <span>{traducirPagina("Tiempo_total_H").toUpperCase()} </span>
                                    <br />
                                    <span style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold'
                                    }}>{convertirMoneda(totales.tiempoTotal)} </span>
                                </Col>

                                {totales.trabajos > 0 ?
                                    <Col md={3} style={verDetalles ? { backgroundColor: '#f6f6f6', borderTop: '1px solid rgb(219, 219, 219)', borderLeft: '1px solid rgb(219, 219, 219)', borderRight: '1px solid rgb(219, 219, 219)', paddingLeft: '10px' } : { paddingLeft: '10px' }}>
                                        <span style={{ cursor: 'pointer' }} onClick={(e) => { setVerDetalles(verDetalles ? false : true) }}><EyeOutlined style={{ fontSize: '20px', verticalAlign: 'text-top' }} /> {traducirPagina("Ver_detalles")} </span>
                                    </Col>

                                    : null
                                }

                            </Row>
*/}

                            <div className={verDetalles ? "repair-times-detail active" : "repair-times-detail"} >
                                {verDetalles ?
                                    <div>
                                        {obtenerTrabajos().map((tarea, indice) =>
                                            <div>
                                                {indice === 0 ?
                                                    <h4 style={{ margin: '0px 20px 10px 20px' }}>Trabajos</h4>
                                                    : null
                                                }

                                                {tarea.Nivel === 1 && tarea.Seleccionado ?
                                                    tarea.visible ?
                                                        <li key={indice} className={"mt-1 ml-3 mr-3 mb-1"} style={{ listStyle: 'none' }}>
                                                            <div className={"site-collapse-custom-tareas"}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Col md={19}>
                                                                        {tarea.Direccion === undefined ?
                                                                            <div>{tarea.Numero + " - " + tarea.Descripcion} </div>
                                                                            :
                                                                            <Row gutter={[8, 8]}>
                                                                                <Col md={4}>
                                                                                    {tarea.Direccion === "U" ?
                                                                                        <span className="included">Incluido</span>
                                                                                        :
                                                                                        <span className="followup">Secuencial</span>
                                                                                    }
                                                                                </Col>
                                                                                <Col md={20}>
                                                                                    <div>
                                                                                        {tarea.Direccion === "U" ?
                                                                                            tarea.Descripcion
                                                                                            :
                                                                                            tarea.Numero + " - " + tarea.Descripcion
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                    <Col md={4} style={{ textAlign: 'right' }}>
                                                                        {tarea.Direccion === undefined ?
                                                                            <div>{convertirMoneda(tarea.Valor, "horas")}</div>
                                                                            :
                                                                            tarea.Direccion === "U" ?
                                                                                <div>{ }</div>
                                                                                :
                                                                                <div><Checkbox disabled={tarea.Enabled} checked={tarea.Enabled} onChange={(e) => { actualizarItemSeleccionado(tarea, tarea.NodoPadre) }} style={{ marginRight: '10px' }} /> {convertirMoneda(tarea.Valor, traducirPagina("Horas").toLowerCase())}</div>
                                                                        }
                                                                    </Col>
                                                                    <Col md={1} style={{ textAlign: 'right' }}>
                                                                        {tarea.Direccion === undefined ?
                                                                            <div style={{ cursor: 'pointer' }} onClick={(e) => { ObtmnerHijosDeLasTareas(tiempoReparacion, tarea, tarea.NodoPadre); /*ObtenerInfoTareasTiempoReparacion(tiempoReparacion, tarea);*/ }}> {tarea.Seleccionado ? <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '20px', color: 'green' }} />} </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </li>
                                                        : null
                                                    : null
                                                }
                                            </div>
                                        )}
                                        {obtenerArticulos().map((articulo, ind) =>
                                            <div>
                                                {ind === 0 ?
                                                    <div>
                                                        <h4 style={{ margin: '0px 20px 10px 20px' }}>Piezas necesarias</h4>

                                                    </div>
                                                    : null}


                                                <li key={ind} className={"mt-1 ml-3 mr-3 mb-1"} style={{ listStyle: 'none' }}>
                                                    <div className={"site-collapse-custom-tareas"}>
                                                        <Row gutter={[8, 8]}>
                                                            <Col md={14}>

                                                                <div>{articulo.Referencia === "" ? articulo.Descripcion : articulo.Descripcion + " (" + articulo.Referencia + " - " + articulo.Marca + ")"} </div>

                                                            </Col>
                                                            <Col md={2} style={{ textAlign: 'center' }}>
                                                                <div>{convertirMoneda(articulo.Precio, "€")}</div>

                                                            </Col>
                                                            <Col md={2} style={{ textAlign: 'center' }}>
                                                                <div>{convertirMoneda(1)}</div>
                                                            </Col>
                                                            <Col md={4} style={{ textAlign: 'center' }}>
                                                                <div style={{ cursor: 'pointer' }} onClick={(e) => { setGrupoMontaje(articulo.Id); store.dispatch(DrawerFichaGMVehiculoHaynes(true)); }}> {<span style={{ verticalAlign: 'middle', fontSize: '16px' }}> <SearchOutlined style={{ fontSize: '20px' }} />{traducirPagina("Buscar_referencia")}</span>} </div>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </li>

                                            </div>
                                        )}

                                    </div>

                                    : null}
                            </div>


                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', marginTop: '10px' }}>
                                <Col md={7}>
                                    {nodos === null ?
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("No_nodos")}</h4>
                                        </div>
                                        :
                                        nodos.length > 0 ?
                                            <Menu
                                                defaultSelectedKeys={['1']}
                                                defaultOpenKeys={['sub1']}
                                                mode={mode}
                                                theme={theme}
                                            >
                                                {nodos.map((nodo, i) =>
                                                    <Menu.Item key={(i + 1)} onClick={(e) => { tareasDeUnMantenimiento(opcionMantenimiento, nodo, Vivid, tiempoReparacion) }}
                                                        style={{ marginTop: '0px', marginBottom: '0px', height: '28px', lineHeight: '28px' }}>
                                                        {nodo.Nombre}
                                                    </Menu.Item>
                                                )}
                                            </Menu>
                                            :
                                            null
                                    }
                                </Col>

                                <Col md={17} className="conatinerTiempos">
                                    {tareasTiempoReparacion === null ?
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("No_resultados")}</h4>
                                        </div>
                                        :

                                        tareasTiempoReparacion.length > 0 ?
                                            <div>

                                                <div style={{ paddingBottom: '10px' }}>
                                                    <div className="flex-container" style={{ padding: '4px', border: '1px solid #d9d9d9', fontSize: '16px', color: '#000', backgroundColor: '#d9d9d9' }}>
                                                        <div style={{ flexGrow: '6', textAlign: 'left' }}>
                                                            {traducirPagina("Piezas").toUpperCase()}
                                                        </div>

                                                        <div>
                                                            {verPiezas ?
                                                                <span onClick={() => setVerPiezas(!verPiezas)}> {traducirPagina("Ocultar")} <UpOutlined style={{ fontSize: '18px' }} /> </span>
                                                                :
                                                                <span onClick={() => setVerPiezas(!verPiezas)}> {traducirPagina("Mostrar")} <DownOutlined style={{ fontSize: '18px' }} /> </span>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {verPiezas ?
                                                            <table>
                                                                {tareasTiempoReparacion.map((subnodo, indice) =>
                                                                    subnodo.SubTareas.map((sub) =>
                                                                        sub.Articulos.filter((objPieza => objPieza.Visible === true)).map((pieza) =>
                                                                            <tr>
                                                                                <td style={{ width: '400px', border: '1px solid #d9d9d9', padding: '4px' }}>
                                                                                    <div style={{ fontWeight: 500 }}>{pieza.Referencia === "" ? pieza.Descripcion : pieza.Descripcion + " (" + pieza.Referencia + " - " + pieza.Marca + ")"} </div>

                                                                                </td>
                                                                                <td style={{ width: '80px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'right' }}>{convertirMoneda(pieza.Precio, "€")} </td>
                                                                                <td style={{ width: '80px', border: '1px solid #d9d9d9', padding: '4px' }}>{"1,00 Und."} </td>
                                                                                <td style={{ width: '50px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} onClick={() => { modificarPiezaSeleccionada(pieza, sub, indice) }}> {pieza.Seleccionado ? <PlusCircleOutlined style={{ fontSize: '20px', color: 'green' }} /> : <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} />}</td>
                                                                                <td style={{ width: '200px', textAlign: 'center', border: '1px solid #d9d9d9', padding: '4px' }}>{
                                                                                    <div style={{ cursor: 'pointer' }} onClick={(e) => { setGrupoMontaje(pieza.Id); store.dispatch(DrawerFichaGMVehiculoHaynes(true)); }}> {<span style={{ verticalAlign: 'middle', fontSize: '16px' }}> <SearchOutlined style={{ fontSize: '20px' }} />{traducirPagina("Buscar_referencia")}</span>} </div>
                                                                                } </td>
                                                                            </tr>

                                                                        )
                                                                    )
                                                                )}
                                                            </table>
                                                            : null}
                                                    </div>
                                                </div>


                                                <div style={{ paddingBottom: '10px' }}>
                                                    <div className="flex-container" style={{ padding: '4px', border: '1px solid #d9d9d9', fontSize: '16px', color: '#000', backgroundColor: '#d9d9d9' }}>
                                                        <div style={{ flexGrow: '6', textAlign: 'left' }}>
                                                            {traducirPagina("Trabajos_adicionales").toUpperCase()}
                                                        </div>

                                                        <div >
                                                            {verTrabajos ?
                                                                <span onClick={() => setVerTrabajos(!verTrabajos)}> {traducirPagina("Ocultar")} <UpOutlined style={{ fontSize: '18px' }} /> </span>

                                                                :
                                                                <span onClick={() => setVerTrabajos(!verTrabajos)}> {traducirPagina("Mostrar")} <DownOutlined style={{ fontSize: '18px' }} /> </span>
                                                            }
                                                        </div>
                                                    </div>


                                                    <div>
                                                        {verTrabajos ?
                                                            <table>
                                                                {tareasTiempoReparacion.map((subnodo, indice) =>
                                                                    subnodo.SubTareas.filter((objTarea => objTarea.IncluidoPorDefecto === false)).map((trabajo) =>
                                                                        <tr>
                                                                            <td style={{ width: '100px', border: '1px solid #d9d9d9', padding: '4px', verticalAlign: 'top' }}>{trabajo.Tiempos.length > 0 ? trabajo.Tiempos[0].Codigo : null} </td>
                                                                            <td style={{ width: '600px', border: '1px solid #d9d9d9', padding: '4px' }}>
                                                                                <div style={{ fontWeight: 500 }}> {trabajo.Nombre}</div>
                                                                                <div>{trabajo.Resaltar}</div>
                                                                            </td>
                                                                            <td style={{ width: '50px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} >{trabajo.Tiempos.length > 0 ? convertirMoneda(trabajo.Tiempos[0].Valor) : null} </td>
                                                                            <td style={{ width: '50px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} onClick={(e) => { cambiarSeleccionadoTrabajo(trabajo, indice); }}> {!trabajo.Seleccionado ? <PlusCircleOutlined style={{ fontSize: '20px', color: 'green' }} /> : <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} />}  </td>
                                                                        </tr>
                                                                    )

                                                                )}
                                                            </table>
                                                            : null
                                                        }
                                                    </div>

                                                </div>


                                                <div style={{ padding: '4px', border: '1px solid #d9d9d9', color: '#000', backgroundColor: '#d9d9d9' }}>

                                                    {traducirPagina("Trabajos_del_mantenimiento").toUpperCase()}


                                                </div>



                                                {tareasTiempoReparacion.map((subnodo, indice) =>
                                                    subnodo.Seleccionado ?
                                                        <div>
                                                            <div key={subnodo.Id} style={{ padding: '4px', border: '1px solid #d9d9d9', backgroundColor: '#f7f7f7' }}>
                                                                {subnodo.Nombre}
                                                            </div>
                                                            <div>
                                                                {subnodo.SubTareas.map((subTarea) =>
                                                                    subTarea.Seleccionado ?
                                                                        subTarea.Tiempos.length > 0 ?
                                                                            <div>
                                                                                <table>
                                                                                    <tr>
                                                                                        <td style={{ width: '100px', border: '1px solid #d9d9d9', padding: '4px' }}>{subTarea.Tiempos.length > 0 ? subTarea.Tiempos[0].Codigo : null} </td>
                                                                                        <td style={{ width: '646px' }}>
                                                                                            <div style={{ fontWeight: 500, border: '1px solid #d9d9d9', padding: '4px' }}> {subTarea.Nombre}</div>
                                                                                            <div style={{ border: '1px solid #d9d9d9', padding: '4px' }}>{subTarea.Resaltar}</div>
                                                                                            {subTarea.InfoAdicional.length > 0 ?
                                                                                                <div style={{ border: '1px solid #d9d9d9', padding: '4px' }}>

                                                                                                    <table>
                                                                                                        {subTarea.InfoAdicional.map((info, indice) => (
                                                                                                            info.Id1 !== 0 ?
                                                                                                                <tr>
                                                                                                                    <td>{traducirPagina("Ir_a_la_pagina_de")} </td>

                                                                                                                </tr>
                                                                                                                :
                                                                                                                <tr>
                                                                                                                    <td>{info.Texto !== null ? info.Texto.toString() : ""} </td>

                                                                                                                </tr>
                                                                                                        ))
                                                                                                        }
                                                                                                    </table>

                                                                                                </div>
                                                                                                : null}

                                                                                        </td>
                                                                                        <td style={{ width: '50px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} >{subTarea.Tiempos.length > 0 ? convertirMoneda(subTarea.Tiempos[0].Valor) : null} </td>

                                                                                    </tr>
                                                                                </table>

                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {subTarea.IncluidoPorDefecto ? <div style={{ border: '1px solid #d9d9d9', padding: '4px', color: '#333' }}> <CheckSquareOutlined style={{ fontSize: '18px', verticalAlign: 'initial' }} /> {subTarea.Nombre}</div> : null}
                                                                                {subTarea.IncluidoPorDefecto ?
                                                                                    subTarea.InfoAdicional.length > 0 ?
                                                                                        <div style={{ border: '1px solid #d9d9d9', padding: '4px' }}>
                                                                                            <table>
                                                                                                {subTarea.InfoAdicional.map((info, indice) => (
                                                                                                    info.Id1 !== 0 ?
                                                                                                        <tr>
                                                                                                            <td>{traducirPagina("Ir_a_la_pagina_de")} </td>
                                                                                                            <td></td>
                                                                                                            <td></td>
                                                                                                            <td></td>
                                                                                                        </tr>
                                                                                                        :
                                                                                                        <tr>
                                                                                                            <td>{info.Texto !== null ? info.Texto.toString() : ""} </td>
                                                                                                            <td></td>
                                                                                                            <td></td>
                                                                                                            <td></td>
                                                                                                        </tr>
                                                                                                ))
                                                                                                }
                                                                                            </table>
                                                                                        </div>
                                                                                        : null

                                                                                    : null}
                                                                            </div>
                                                                        :
                                                                        null
                                                                )}
                                                            </div>
                                                        </div>

                                                        : null
                                                )
                                                }
                                            </div>
                                            : null
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </div>
            </Drawer>


            {openDrawerFichaMantenimientoHaynesPro ?
                <FichaGMVehiculoHaynesPro title={traducirPagina("Buscar_referencias").toUpperCase()} vehiculoId={codmotorizacion.toString()} grupoMontaje={grupoMontaje.toString()} modificarArticulo={setTareasTiempoReparacion} listaTiempoReparacion={tareasTiempoReparacion} componentePadre={"MANTENIMIENTOS"}></FichaGMVehiculoHaynesPro>
                : null
            }

            <Modal
                visible={visible}
                width={600}
                centered
                title={traducirPagina("Tiene_articulos_ sinreferenciar")}
                afterClose={() => { }}
                onCancel={() => setVisible(false)}
                style={{ zIndex: '9999' }}
                footer={[
                    <Button key="back" onClick={() => { setVisible(false) }}>
                        {traducirPagina("NO")}
                    </Button>,
                    <Button className="btnColor" key="submit" type="primary" onClick={() => {
                        añadirApresupuesto();
                        setVisible(false)
                    }}> {traducirPagina("SI")}</Button>
                ]}

            >
                <p>{traducirPagina("msgFicManHaynes_01")}</p>
                <p>{traducirPagina("msgFicManHaynes_02")}</p>
                <p>{traducirPagina("msgFicManHaynes_03")}</p>
                <p>{traducirPagina("msgFicManHaynes_04")}</p>
            </Modal>



            {
                modalPrint.ABRIR_MODAL &&

                <Modal
                    title={<div className="containerModalTitulo" style={{ fontSize: '16px' }}>{traducirPagina("Informacion")}</div>}
                    centered
                    visible={modalPrint.ABRIR_MODAL}
                    onOk={() => { modalPrint.ABRIR_MODAL = false; setModalPrint({ ...modalPrint }) }}
                    onCancel={() => { modalPrint.ABRIR_MODAL = false; setModalPrint({ ...modalPrint }) }}
                    footer={null}
                    width={"50%"}
                >

                    <div className="container_articulo_tiempo">
                        {
                            <div className="articulo_tiempo" >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>

                                    <Col md={8}>
                                        <span>{`${traducirPagina("Matricula")}:  `} </span>
                                    </Col>

                                    <Col md={8}>
                                        <span>{`${traducirPagina("Bastidor")}:  `} </span>
                                    </Col>
                                    <Col md={8}>
                                        <span>{`${traducirPagina("Vehiculo")}:  `} </span>
                                    </Col>
                                    <Col md={8}>
                                        <Input name="VEHICULO_MATRICULA" value={modalPrint.VEHICULO_MATRICULA} onChange={(e) => { modalPrint.VEHICULO_MATRICULA = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>
                                    <Col md={8}>
                                        <Input name="VEHICULO_VIN" value={modalPrint.VEHICULO_VIN} onChange={(e) => { modalPrint.VEHICULO_VIN = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>

                                    <Col md={8}>
                                        <Input name="VEHICULO_DESCRIPCION" value={modalPrint.VEHICULO_DESCRIPCION} onChange={(e) => { modalPrint.VEHICULO_DESCRIPCION = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>

                                    <Col md={8}>
                                        <span>{`${traducirPagina("Kilometraje")}:  `} </span>
                                    </Col>
                                    <Col md={8}>
                                        <span>{`${traducirPagina("Fecha_de_matriculacion")}:  `} </span>
                                    </Col>
                                    <Col md={8}>
                                        <span>{`${traducirPagina("Numero_coste_factura")}:  `} </span>
                                    </Col>

                                    <Col md={8}>
                                        <Input name="VEHICULO_KILOMETRAJE" value={modalPrint.VEHICULO_KILOMETRAJE} onChange={(e) => { modalPrint.VEHICULO_KILOMETRAJE = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>
                                    <Col md={8}>
                                        <Input name="VEHICULO_FECHA_REGISTRO" value={modalPrint.VEHICULO_FECHA_REGISTRO} onChange={(e) => { modalPrint.VEHICULO_FECHA_REGISTRO = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>
                                    <Col md={8}>
                                        <Input name="VEHICULO_NUMERO_CALCULO_COSTE_FACTURA" value={modalPrint.VEHICULO_NUMERO_CALCULO_COSTE_FACTURA} onChange={(e) => { modalPrint.VEHICULO_NUMERO_CALCULO_COSTE_FACTURA = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>




                                    <Col md={8}>
                                        <span>{`${traducirPagina("Nombre")}:  `} </span>
                                    </Col>
                                    <Col md={8}>
                                        <span>{`${traducirPagina("Telefono")}:  `} </span>
                                    </Col>
                                    <Col md={8}>
                                        <span>{`${traducirPagina("Email")}:  `} </span>
                                    </Col>

                                    <Col md={8}>
                                        <Input name="CLIENTE_NOMBRE" value={modalPrint.CLIENTE_NOMBRE} onChange={(e) => { modalPrint.CLIENTE_NOMBRE = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>
                                    <Col md={8}>
                                        <Input name="CLIENTE_TELEFONO" value={modalPrint.CLIENTE_TELEFONO} onChange={(e) => { modalPrint.CLIENTE_TELEFONO = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>
                                    <Col md={8}>
                                        <Input name="CLIENTE_MAIL" value={modalPrint.CLIENTE_MAIL} onChange={(e) => { modalPrint.CLIENTE_MAIL = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{`${traducirPagina("Direccion")}:  `} </span>
                                    </Col>
                                    <Col md={24}>
                                        <Input name="CLIENTE_DIRECCION" value={modalPrint.CLIENTE_DIRECCION} onChange={(e) => { modalPrint.CLIENTE_DIRECCION = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                    </Col>

                                </Row>

                            </div>
                        }
                    </div>

                    <div className="btn_articulo_tiempos container_doc_mantenimientos">
                        <button className={modalPrint.LOADING ? "disable_button" : ""} onClick={() => {

                            descargarDocumento()

                        }}> {modalPrint.LOADING ? <LoadingOutlined /> : <PrinterOutlined />} {traducirPagina("Plan_servicio_mantenimiento")}</button>
                    </div>
                </Modal>


            }



            {fichaImpresion.abrirFichaImpresion ?
                <FichaImpresionGenerico
                    title={traducirPagina("Imprimir_documento").toUpperCase()}
                    ABRIR_FICHA={fichaImpresion.abrirFichaImpresion}
                    CERRAR_FICHA={() => { fichaImpresion.abrirFichaImpresion = false; setFichaImpresion({ ...fichaImpresion }) }}

                    DOCUMENTO={fichaImpresion.html}
                    MODIFICAR_DOCUMENTO={() => {
                        fichaImpresion.abrirFichaImpresion = false
                        fichaImpresion.html = ""
                        fichaImpresion.pdf = ""
                        setFichaImpresion({ ...fichaImpresion })
                    }}
                    DESCARGAR_DOCUMENTO={() => { descargarDocPasandoBase64Generico(fichaImpresion.pdf, "pdf") }}
                >
                </FichaImpresionGenerico>
                :
                null
            }


        </div>
    )
}

FichaManteniminetoHaynesPro.propTypes = {
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    activeItemOuterTabs: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerFichaMantenimientoHaynesPro: state.estadoDrawer.openDrawerFichaMantenimientoHaynesPro,
        codmotorizacion: state.codmotorizacion

    };
}

export default connect(mapStateToProps)(FichaManteniminetoHaynesPro);