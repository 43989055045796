import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ListaTee from '../pages/List_Tree';

import { efectoBotonRemove } from "../action/actionCreators";
import { GetAnuncioEnAPI } from "../action/actionCreatorsRequests";
import {
    obtenerToken,
    buscarReferenciaYMostrarEnPantalla,
    datosLincenciaUsuario,
    datosLincenciaSertec
} from "../librerias/funciones"
import { Card, Input, Row, Col, Drawer, Tabs, Carousel } from 'antd';
import store from "../store";
const { TabPane } = Tabs;


const ContainerApp = ({ props, configApp, activeItemReferencia, PreLoaderPage, usuario }) => {
    const OBTENER_DATOS_USUARIO = datosLincenciaUsuario();
    const GLOBARL_USER = datosLincenciaSertec()
    const DIRECTORIO = "./imagenes/home/";
    const { PLANTILLA } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { PLANTILLA: false } : OBTENER_DATOS_USUARIO;
    const { ID_PAIS, ID_SOCIO } = GLOBARL_USER === undefined || OBTENER_DATOS_USUARIO === null ? { ID_PAIS: 1, ID_SOCIO: 0 } : GLOBARL_USER

    /*
    const [imgCarousel, setImgCarousel] = React.useState(
        [
            "academy.jpg",
            "assist.jpg",
            "data.jpg",
            "control.jpg",
            "creacion.jpg"
        ]
    );
    */

    const [imgCarousel, setImgCarousel] = React.useState([]);

    const [buscarRegistro, setBuscarRegistro] = React.useState(false);
    const { MARKETING } = usuario.LoginSertec;
    /*
        const [loadingCarousel, setCarousel] = React.useState(true);
        const counterCarousel = React.useRef(0);
    
        const imageLoaded = () => {
            counterCarousel.current += 1;
            if (counterCarousel.current >= imgCarousel.length) {
                setCarousel(false);
            }
        }*/




    const buscarImagenes = async (registros) => {
        const imagenes = await registros.map(async (referencia) => {
            const data = await GetAnuncioEnAPI(referencia.ID, referencia.TIPO);
            return data;
        })


        const lista = await Promise.all(imagenes);
        lista.map((data) => {
            if (data !== undefined && data !== null && data.REGISTROS !== undefined && data.REGISTROS !== null && data.REGISTROS.length > 0) {
                const REGISTRO = data.REGISTROS[0];

                const { ID, TIPO, REFERENCIA, TIPO_ACCION, TXTALT } = registros.find((item) => item.ID === REGISTRO.ID)

                const ENCODE = window.btoa(obtenerToken())

                let IMAGEN = {
                    ID: ID,
                    TIPO: TIPO,
                    TOKEN: ENCODE,

                    URL_IMAGEN: `data:${REGISTRO.IMAGEN_CONTENT_TYPE};base64,${REGISTRO.IMAGEN}`,
                    NOMBRE: REGISTRO.NOMBRE,
                    URL: REGISTRO.URL !== null && REGISTRO.URL !== "" ? REGISTRO.URL : "",
                    DOCUMENTO: REGISTRO.DOCUMENTO !== null && REGISTRO.DOCUMENTO !== "" ? REGISTRO.DOCUMENTO : "",
                    TIPO_ACCION: TIPO_ACCION,
                    REFERENCIA: REFERENCIA,
                    TXTALT: TXTALT

                }

                imgCarousel.push(IMAGEN)
            }
        })

        setImgCarousel([...imgCarousel])
        return lista
    }

    const abrirNuevoTab = (URL) => {
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = URL;
        a.click();
    }

    const buscarReferencia = (REFERENCIA) => {
        if (document.getElementById("buscadorReferencia") !== null) {
            document.getElementById("buscadorReferencia").value = REFERENCIA
        }

        store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: REFERENCIA });
        buscarReferenciaYMostrarEnPantalla(REFERENCIA)
        efectoBotonRemove(REFERENCIA, "closeBuscadorReferencia")
    }

    const accioneImagen = (registro) => {
        const { TIPO_ACCION, URL, TIPO, ID, TOKEN, REFERENCIA } = registro;

        switch (TIPO_ACCION) {
            case 1:
                abrirNuevoTab(URL)
                break;
            case 2:
                buscarReferencia(REFERENCIA)
                break;
            case 3:
                window.open(`https://testsertec.servicenext.eu/api/Marketing/DescargarArchivo/${ID}/${TIPO}/${TOKEN}`, '_blank')
                break;
        }
    }



    const gestionarImagenContainer1 = () => {
        let imagen = ""
        let registro = {}


        if (PLANTILLA !== "" && PLANTILLA.toUpperCase() === "NEXUS") {
            registro.TIPO_ACCION = 0
            registro.URL = ""

            //Poner para BMC va por ID_SOCIO 

            if ([91, 113, 114].includes(ID_SOCIO)) {
                imagen = `${DIRECTORIO}repair_fr.png`
            } else if (ID_SOCIO === 180) {
                imagen = `${DIRECTORIO}assist_180.png`
            } else if ([182, 174, 175].includes(ID_SOCIO)) {//182 - NEXUS ITALIA | 174 - TELOS GS SPA | 175 - TELOS LOMBARDIA SRL
                imagen = `${DIRECTORIO}repair_data_182.png`
            } else if ([92, 73].includes(ID_SOCIO)) {//92 - NEXUS AUTOMOTIVE INTERNATIONAL | 73 - VETURILO 
                imagen = `${DIRECTORIO}wop_promo.png`
            } else if ([90].includes(ID_SOCIO)) {//90 - IMPORTADORAS ASOCIADAS
                imagen = `${DIRECTORIO}infotechnic.png`
            } else {
                imagen = `${DIRECTORIO}repair_data.png`
            }

        }else if(PLANTILLA.toUpperCase() === "NEXUS_IDR"){
            imagen = `${DIRECTORIO}assist_180.png`
        }else {
            registro.TIPO_ACCION = 1

            switch (ID_PAIS) {
                case 3:
                    imagen = `${DIRECTORIO}assist_pt.png`
                    registro.URL = "https://servicenext.eu/nextassist/"
                    break;
                default:
                    imagen = `${DIRECTORIO}infotechnic.png`
                    registro.URL = "https://infotechnic.es/"
                    break;
            }

        }

        return { imagen: imagen, registro: registro }
    }



    const gestionarImagenContainer2 = () => {
        let imagen = ""
        let registro = {}

        
        if (PLANTILLA !== "" && PLANTILLA.toUpperCase() === "NEXUS") {
            registro.TIPO_ACCION = 0
            registro.URL = ""

            //Poner para BMC va por ID_SOCIO 

            if ([91, 113, 114].includes(ID_SOCIO)) {
                imagen = `${DIRECTORIO}academy_fr.png`
            } else if (ID_SOCIO === 180) {
                imagen = `${DIRECTORIO}academy_180.png`
            } else if ([182, 174, 175].includes(ID_SOCIO)) {//182 - NEXUS ITALIA | 174 - TELOS GS SPA | 175 - TELOS LOMBARDIA SRL
                imagen = `${DIRECTORIO}academy_182.png`
            } else if ([90].includes(ID_SOCIO)) {//90 - IMPORTADORAS ASOCIADAS
                imagen = `${DIRECTORIO}academy_vr.png`
            }  else {
                imagen = `${DIRECTORIO}academy_learning.png`
                registro.TIPO_ACCION = 1
                registro.URL = "https://servicenext.eu/nextacademy-cloud/"
            }

        }else if(PLANTILLA.toUpperCase() === "NEXUS_IDR"){
            imagen = `${DIRECTORIO}academy_180.png`
        } else {
            registro.TIPO_ACCION = 1

            switch (ID_PAIS) {
                case 3:
                    imagen = `${DIRECTORIO}academy_pt.png`
                    registro.URL = "https://servicenext.eu/nextacademy-cloud/"
                    break;
                default:
                    imagen = `${DIRECTORIO}academy_vr.png`
                    registro.URL = "https://servicenext.eu/"
                    break;
            }

        }

        return { imagen: imagen, registro: registro }
    }




    if (!buscarRegistro) {
        setBuscarRegistro(true);
        buscarImagenes(MARKETING);
    }

    return (
        <div>
            {(() => {
                switch (activeItemReferencia) {
                    case 'tree':
                        return <ListaTee></ListaTee>
                    case '3':
                        return <div></div>;
                    default:
                        return PreLoaderPage ?
                            <>
                                <div style={{ height: (configApp.height - 127) + "px", overflow: 'auto', overflowX: 'hidden' }} className="ctPublicidad">
                                    <div className='ctPublicidad_item_1'>
                                        {/**autoplay */}

                                        {
                                            (MARKETING !== undefined && MARKETING !== null && MARKETING.length > 0) ?
                                                imgCarousel.length > 0 ?
                                                    <Carousel effect="fade" autoplaySpeed={5000} autoplay={true}>
                                                        {imgCarousel.map((registro, i) =>
                                                            <>
                                                                <img
                                                                    key={i}
                                                                    className={registro.TIPO_ACCION !== 0 && "soloCursor"}
                                                                    onClick={() => {
                                                                        accioneImagen(registro)
                                                                    }}
                                                                    loading="lazy"
                                                                    src={registro.URL_IMAGEN}
                                                                    alt={registro.NOMBRE}
                                                                    title={registro.TXTALT}
                                                                    style={{ height: (configApp.height - 207) + "px" }}
                                                                />
                                                            </>
                                                        )
                                                        }
                                                    </Carousel>
                                                    :
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                :
                                                null
                                        }
                                    </div>

                                    <div className='ctPublicidad_item_2'>
                                        <div className='ctPublicidad_item_2_child_1'>
                                            <img
                                                loading="lazy"
                                                className={"soloCursor"}
                                                src={gestionarImagenContainer1().imagen}
                                                onClick={() => {
                                                    accioneImagen(gestionarImagenContainer1().registro)
                                                }}

                                                alt="Next"

                                            />
                                        </div>

                                        <div className='ctPublicidad_item_2_child_2'>
                                            <img
                                                loading="lazy"
                                                className={"soloCursor"}

                                                src={gestionarImagenContainer2().imagen}
                                                onClick={() => {
                                                    let registro = {}
                                                    accioneImagen(gestionarImagenContainer2().registro)

                                                }}

                                                alt="Next"

                                            />
                                        </div>
                                    </div>

                                </div>

                            </>
                            :
                            <div></div>

                }
            })()}
        </div >
    )
}


ContainerApp.propTypes = {
    title: PropTypes.string

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado,
        datosFacturacion: state.datosFacturacion,


        activeItemReferencia: state.activeItemReferencia,
        usuario: state.usuario,
        PreLoaderPage: state.PreLoaderPage
    };
}

export default connect(mapStateToProps)(ContainerApp);