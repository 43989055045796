import React, { useState } from "react";
import locale from 'antd/es/date-picker/locale/es_ES';
import {
    traducirPagina,
    convertirMoneda,
    crearLineasParaElPresupuesto,
    MSGOK,
    obtenerFechaYHora,
    imprimirDocumentoHTML,
    obtenerSoloFecha,
    buscarVehiculoLocal

} from "../../librerias/funciones"
import store from '../../store'

import { Drawer, Modal, InputNumber, Select, Radio, DatePicker, Button, Checkbox, Col, Input, Row } from 'antd';
import {
    CloseCircleOutlined,
    DownOutlined,
    PlusCircleOutlined,
    FileTextOutlined,
    FileAddOutlined,
    RightOutlined,
    SearchOutlined,
    DashboardOutlined,
    CalendarOutlined,
    SettingOutlined,
    ToolOutlined,
    DownloadOutlined,
    FilePdfOutlined,
    PrinterOutlined,
    LoadingOutlined
} from "@ant-design/icons";
import { MDBBtn } from 'mdbreact';
import {
    ObtenerPiezasTrabajosEnAPI,
    ObtenerPosibleCodigoCajaCambiosEnAPI,
    ObtenerPropuestaServicioCooGruposEnAPI,
    ObtenerServicioPropuestoEnAPI,
    ObtenerMantenimientosEnAPI,
    ObtenerPiezasCestaEnAPI,
    obtenerPlanServicioMantinimientoEnAPI
} from "../../action/actionCreatorsRequests";
import { DrawerFichaGMVehiculoHaynes } from "../../action/actionCreators";
import FichaGMYVehiculoHaynesPro from "../FichaGMYVehiculoHaynesPro";
import { Card_RMI } from "./Card_RMI";

const { Option } = Select;
const { MonthPicker } = DatePicker;

export const RMIMantenimientos = (props) => {
    const [initComponent, setInitComponent] = React.useState({
        ABRIR_MODAL: false,
        TITULO_MODAL: "",

        KM: "",
        FECHA: "",
        FECHA_MOMENT: null,
        ID_FILTRO_TIPO: 0,
        REGISTROS_FILTRO_TIPO: [],
        ID_VARIANTE: 0,
        REGISTROS_FILTRO_VARIANTE: [],

        ULTIMO_SERVICIO_ANTES_KM_MES: [],
        SIGUIENTE_SERVICIO_DESPUES_KM_MES: [],

        //PIEZAS: [],
        TRABAJOS_ADICIONALES: [],
        TRABAJOS_MANTENIMIENTO: [],
        TRABAJOS: [],
        ABRIR_PIEZAS: false,
        ABRIR_ADICIONALES: false,
        ARTICULOS_MODAL: {},
        HISTORIAL: [],
        TIPO_SERVICIO: 0,

        BTN_1: 0,
        BTN_2: 0,
        BTN_3: 0,


    })

    const init_print = {
        ABRIR_MODAL: false,

        MOSTRAR_TABLA_NEUMATICOS: false,
        MOSTRAR_MANUALES_REPARACION: false,
        NOMBRE: "",
        APELLIDOS: "",
        TELEFONO: "",
        MATRICULA: "",
        KM: "",
        NUM_PEDIDO: "",

        LOADING: false
    }

    const [modalPrint, setModalPrint] = useState({
        ABRIR_MODAL: false,

        MOSTRAR_TABLA_NEUMATICOS: false,
        MOSTRAR_FOTOS_VEHICULOS:false,
        MOSTRAR_MANUALES_REPARACION: false,
        NOMBRE: "",
        APELLIDOS: "",
        TELEFONO: "",
        MATRICULA: "",
        KM: "",
        NUM_PEDIDO: "",

        LOADING: false
    })


    const [grupoMontaje, setGrupoMontaje] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [lineasCesta, setLineasCesta] = React.useState({
        //TRABAJOS: [],
        PIEZAS: [],

        TOTAL_PIEZAS: 0,
        TOTAL_TIEMPO: 0,
        TOTAL_TRABAJO: 0
    });

    const monthFormat = 'MM/YYYY';
    const ref = React.useRef(null);
    const ID_VEHICULO_TECDOC = props.ID_VEHICULO != 0 ? props.ID_VEHICULO : ""

    const resetCesta = () => {
        initComponent.HISTORIAL = []
        initComponent.TRABAJOS_ADICIONALES = []
        initComponent.TRABAJOS_MANTENIMIENTO = []
        initComponent.TRABAJOS = []
        setInitComponent({ ...initComponent })
        setLineasCesta({
            PIEZAS: [],

            TOTAL_PIEZAS: 0,
            TOTAL_TIEMPO: 0,
            TOTAL_TRABAJO: 0
        })
    }


    const ObtenerPosibleCodigoCajaCambios = async (ID_VEHICULO_TECDOC) => {
        const registros = await ObtenerPosibleCodigoCajaCambiosEnAPI(ID_VEHICULO_TECDOC)
        const { OK, CAJA_CAMBIOS } = registros === undefined ? { OK: false, CAJA_CAMBIOS: [] } : registros

        if (OK && CAJA_CAMBIOS.length > 0) {
            initComponent.REGISTROS_FILTRO_TIPO = CAJA_CAMBIOS
        }
        setInitComponent({ ...initComponent })
    }


    const ObtenerPropuestaServicioCooGrupos = async (ID_VEHICULO_TECDOC, LISTA_TIPO_FILTROS, FECHA_REGISTRO_INICIAL) => {
        const registros = await ObtenerPropuestaServicioCooGruposEnAPI(ID_VEHICULO_TECDOC, LISTA_TIPO_FILTROS, FECHA_REGISTRO_INICIAL)
        const { OK, LISTA_SREVICIOS_PROPUESTOS_COO_GROUPS } = registros === undefined ? { OK: false, LISTA_SREVICIOS_PROPUESTOS_COO_GROUPS: [] } : registros

        if (OK && LISTA_SREVICIOS_PROPUESTOS_COO_GROUPS.length > 0) {
            initComponent.REGISTROS_FILTRO_VARIANTE = LISTA_SREVICIOS_PROPUESTOS_COO_GROUPS
        }
        setInitComponent({ ...initComponent })

    }

    const ObtenerServicioPropuesto = async (ID_VEHICULO_TECDOC, ID_COO_GRUPO, ID_CARROCERIA_QUALCOL, KILOMETRAJE_ACTUAL, FECHA_REGISTRO_INICIAL) => {
        const registros = await ObtenerServicioPropuestoEnAPI(ID_VEHICULO_TECDOC, ID_COO_GRUPO, ID_CARROCERIA_QUALCOL, KILOMETRAJE_ACTUAL, FECHA_REGISTRO_INICIAL)
        const { OK, SREVICIO_PROPUESTO } = registros === undefined ? { OK: false, SREVICIO_PROPUESTO: {} } : registros
        if (OK && Object.keys(SREVICIO_PROPUESTO).length > 0) {
            const { ULTIMO_SERVICIO_ANTES_KM_MES, SIGUIENTE_SERVICIO_DESPUES_KM_MES } = SREVICIO_PROPUESTO

            initComponent.ULTIMO_SERVICIO_ANTES_KM_MES = ULTIMO_SERVICIO_ANTES_KM_MES === null ? [] : ULTIMO_SERVICIO_ANTES_KM_MES
            initComponent.SIGUIENTE_SERVICIO_DESPUES_KM_MES = SIGUIENTE_SERVICIO_DESPUES_KM_MES === null ? [] : SIGUIENTE_SERVICIO_DESPUES_KM_MES
        }

        setInitComponent({ ...initComponent })
    }



    const ObtenerMantenimientos = async (ID_VEHICULO_TECDOC, ID_COO_GRUPO, ID_CARROCERIA_QUALCOL, KILOMETRAJE_ACTUAL, FECHA_REGISTRO_INICIAL) => {

        resetCesta()


        const registros = await ObtenerMantenimientosEnAPI(ID_VEHICULO_TECDOC, ID_COO_GRUPO, ID_CARROCERIA_QUALCOL, KILOMETRAJE_ACTUAL, FECHA_REGISTRO_INICIAL)

        const { OK, MANUAL_MANTENIMIENTO } = registros === undefined ? { OK: false, MANUAL_MANTENIMIENTO: [] } : registros

        if (OK && MANUAL_MANTENIMIENTO.length > 0) {

            const TRABAJOS = MANUAL_MANTENIMIENTO[0].TRABAJOS

            const objeto = TRABAJOS === undefined && TRABAJOS === null ? { SERVICIOS: [], TRABAJO_ADICIONAL: [] } : TRABAJOS
            objeto.TRABAJO_ADICIONAL.map((item) => item.PASOS_TRABAJO_ADICIONAL.map((registro) => { registro.SELECCIONADO = true; registro.COMPRAR = false; return registro }))

            initComponent.TRABAJOS_ADICIONALES = objeto.TRABAJO_ADICIONAL
            initComponent.TRABAJOS_MANTENIMIENTO = objeto.SERVICIOS
            initComponent.TRABAJOS = MANUAL_MANTENIMIENTO
        }

        setInitComponent({ ...initComponent })
    }


    function actualizarPaso2() {
        let LISTA_TIPO_FILTROS = []
        const { ID_FILTRO_TIPO, REGISTROS_FILTRO_TIPO, FECHA } = initComponent
        if (ID_FILTRO_TIPO !== 0) {
            let buscar = REGISTROS_FILTRO_TIPO.find((item) => item.ID === ID_FILTRO_TIPO)
            if (buscar !== undefined) {
                LISTA_TIPO_FILTROS = [buscar.ID]
            }
        } else {
            LISTA_TIPO_FILTROS = REGISTROS_FILTRO_TIPO.map((item) => item.ID)
        }

        ObtenerPropuestaServicioCooGrupos(ID_VEHICULO_TECDOC, LISTA_TIPO_FILTROS, FECHA)
    }


    function filtrarTipo(registros, ID) {
        let texto = "";
        let filtro = registros.find((i) => i.ID === ID)
        if (filtro !== undefined) {
            texto = filtro.NOMBRE
        }
        return texto
    }


    function filtrarVariante(registros, ID) {
        let texto = "";

        let filtro = registros.find((i) => i.ID_QUALCOL === ID)
        if (filtro !== undefined) {
            texto = filtro.TEXTO_QUALCOL
        }
        return texto
    }


    const seleccionarPiezasAdicionales = ({ SELECCIONADO, PIEZA_TRABAJO, POSICION_TRABAJO_EXCLUSIVO }) => {

        return (
            SELECCIONADO ?
                <>
                    {
                        PIEZA_TRABAJO === undefined || PIEZA_TRABAJO === null ?
                            null :
                            PIEZA_TRABAJO.length === 0 ?
                                <div className="center_item_alert" style={{ height: '100%' }} > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <div className={`container_tiempo_pieza`}>
                                    {
                                        PIEZA_TRABAJO.map((registro) =>
                                            registro.CONJUNTO_PIEZAS_QUALCOL.map((item) =>
                                                <div className="sub_item_tiempo_desglose" onClick={() => {

                                                    item.COMPRAR = !item.COMPRAR

                                                    if (item.COMPRAR) {
                                                        addPieza(registro, item.ID_QUALCOL)
                                                    } else {
                                                        deletePieza(registro, item.ID_QUALCOL, true)
                                                    }
                                                    setInitComponent({ ...initComponent })


                                                }}>
                                                    <div>
                                                        {`${registro.TEXTO_GRUPO_MONTAJE} ${item.TEXTO_QUALCOL}`}
                                                    </div>

                                                    <div>{item.COMPRAR ? <CloseCircleOutlined style={{ fontSize: '22px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '22px', color: 'green' }} />}</div>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                    }


                    {POSICION_TRABAJO_EXCLUSIVO.length > 0 &&
                        <div className="sub_item_tiempo_desglose">
                            <div>{`${traducirPagina("txt_contiene")}:`}</div>
                        </div>}

                    {POSICION_TRABAJO_EXCLUSIVO.map((reg) =>
                        <div className="sub_item_tiempo_desglose">
                            <div>
                                {`${reg.TEXTO_GRUPO_MONTAJE} ${reg.TEXTO_TIPO_TRABAJO}`}
                            </div>

                            <div>{`${convertirMoneda(reg.TIEMPO_TRABAJO_EXCLUSIVO, "")} ${traducirPagina("Horas").toLowerCase()}`}</div>
                        </div>
                    )}
                </>
                :
                null

        );
    }

    const ObtenerPiezasTrabajos = async (ID_VEHICULO_TECDOC, ID_TRABAJOS_SELECCIONADOS) => {
        const registros = await ObtenerPiezasTrabajosEnAPI(ID_VEHICULO_TECDOC, ID_TRABAJOS_SELECCIONADOS);
        let RESULTADO = registros === undefined ? { OK: false, PIEZA_TRABAJO: null } : registros
        return RESULTADO;
    }

    const ObtenerPiezasCesta = async (TRABAJOS_PIEZAS) => {
        const ID_VEHICULO_TECDOC = props.ID_VEHICULO != 0 ? props.ID_VEHICULO : ""
        const registros = await ObtenerPiezasCestaEnAPI(ID_VEHICULO_TECDOC, TRABAJOS_PIEZAS);
        let RESULTADO = registros === undefined ? { OK: false, TRABAJOS: null, PIEZAS: null, TOTALES: null } : registros
        const { TOTAL_PIEZAS, TOTAL_TIEMPO, TOTAL_TRABAJO } = RESULTADO.TOTALES === null ? { TOTAL_PIEZAS: 0, TOTAL_TIEMPO: 0, TOTAL_TRABAJO: 0 } : RESULTADO.TOTALES


        lineasCesta.TRABAJOS = RESULTADO.TRABAJOS !== null ? RESULTADO.TRABAJOS : []
        lineasCesta.PIEZAS = RESULTADO.PIEZAS !== null ? RESULTADO.PIEZAS.map((item) => {
            item.PRECIO = 0;
            item.DESCRIPCION = "";
            item.REFERENCIA = "";
            item.MARCA = "";
            item.PRECIO = "";
            item.CANTIDAD_PIEZA = 1
            item.REGISTRO_SELECCIONADO = {};

            return item
        }) : []
        lineasCesta.TOTAL_PIEZAS = TOTAL_PIEZAS
        lineasCesta.TOTAL_TIEMPO = TOTAL_TIEMPO
        lineasCesta.TOTAL_TRABAJO = TOTAL_TRABAJO

        setLineasCesta({ ...lineasCesta })
    }


    function addTrabajo({ ID_TRABAJO, PIEZA_TRABAJO }) {
        let PIEZAS = []
        if (PIEZA_TRABAJO !== null) {
            PIEZAS = PIEZA_TRABAJO.filter((reg) => reg.CONJUNTO_PIEZAS_QUALCOL.length === 1).map((item) => {
                return {
                    ID_GRUPO_MONTAJE_PIEZA: item.ID_GRUPO_MONTAJE,
                    ID_QUALCOL_CONJUNTO_PIEZA: item.CONJUNTO_PIEZAS_QUALCOL[0].ID_QUALCOL
                }
            })
        }

        initComponent.HISTORIAL = [...initComponent.HISTORIAL, { ID_TRABAJO: ID_TRABAJO, PIEZAS: PIEZAS }]
        ObtenerPiezasCesta(initComponent.HISTORIAL)
    }

    function deleteTrabajo(ID_TRABAJO) {
        initComponent.HISTORIAL = initComponent.HISTORIAL.filter((item) => item.ID_TRABAJO !== ID_TRABAJO);

        ObtenerPiezasCesta(initComponent.HISTORIAL)
        setInitComponent({ ...initComponent })
    }



    function addPieza({ ID_TRABAJO, ID_GRUPO_MONTAJE }, ID_QUALCOL) {
        let registos = initComponent.HISTORIAL.find((item) => item.ID_TRABAJO === ID_TRABAJO)
        if (registos !== undefined) {
            registos.PIEZAS = [...registos.PIEZAS, {
                ID_GRUPO_MONTAJE_PIEZA: ID_GRUPO_MONTAJE,
                ID_QUALCOL_CONJUNTO_PIEZA: ID_QUALCOL
            }
            ]
            setInitComponent({ ...initComponent })
            ObtenerPiezasCesta(initComponent.HISTORIAL)
        }
    }

    function actualizarRegistroPiezaTECDOC(registro) {
        const { NOMBRE_GRUPO_MONTAJE, REFERENCIA, NOMRE_MARCA, PRECIO } = registro
        let PIEZA = lineasCesta.PIEZAS.find((item) => item.ID_GRUPO_MONTAJE === grupoMontaje)
        if (PIEZA !== undefined) {
            PIEZA.DESCRIPCION = NOMBRE_GRUPO_MONTAJE
            PIEZA.REFERENCIA = REFERENCIA
            PIEZA.MARCA = NOMRE_MARCA
            PIEZA.PRECIO = PRECIO
            PIEZA.REGISTRO_SELECCIONADO = registro
            setLineasCesta({ ...lineasCesta })
        }
    }


    function deletePieza({ ID_TRABAJO, ID_GRUPO_MONTAJE }, ID_QUALCOL, PERMITIR_ELIMINAR) {
        let registos = initComponent.HISTORIAL.find((item) => item.ID_TRABAJO === ID_TRABAJO)
        if (registos !== undefined) {
            registos.PIEZAS = registos.PIEZAS.filter((item) => (item.ID_GRUPO_MONTAJE_PIEZA !== ID_GRUPO_MONTAJE || item.ID_QUALCOL_CONJUNTO_PIEZA !== ID_QUALCOL))

            if (PERMITIR_ELIMINAR) {
                ObtenerPiezasCesta(initComponent.HISTORIAL)
            }
        }
        setInitComponent({ ...initComponent })
    }



    const comprobarSiHayPiezasSinReferencia = () => {
        let articulos = lineasCesta.PIEZAS;
        let continuar = false;
        let verficarSiHayPrecio = articulos.filter(articulo => Object.keys(articulo.REGISTRO_SELECCIONADO).length === 0)
        if (verficarSiHayPrecio.length === 0) {
            continuar = true;
        }
        return continuar
    }



    const añadirApresupuesto = () => {

        lineasCesta.TRABAJOS.forEach((tarea) => {
            let Obra = {
                Tiempo: tarea.TIEMPO,
                Descripcion: tarea.NOMBRE_TRABAJO,
                TAREAS_REPARACION: tarea.PASOS_TRABAJO.map((tar) => { return { Descripcion: tar.NOMBRE_TRABAJO } })
            };
            crearLineasParaElPresupuesto(Obra, "T", true);
        })


        lineasCesta.PIEZAS.forEach((articulo) => {

            let articuloFinal = {
                PRECIO: 0,
                REFERENCIA_PROVEEDOR: "",
                REFERENCIA: "",
                CODIGO_MARCA_ERP: "",
                NOMRE_MARCA: "",
                CODIGO_MARCA: 0,
                NOMBRE_GRUPO_MONTAJE: articulo.NOMBRE_PIEZA,
                DESCUENTO: 0
            };

            if (Object.keys(articulo.REGISTRO_SELECCIONADO).length > 0) {
                articuloFinal = Object.assign(
                    {
                        PRECIO: articulo.REGISTRO_SELECCIONADO.PRECIO,
                        REFERENCIA_PROVEEDOR: articulo.REGISTRO_SELECCIONADO.REFERENCIA_PROVEEDOR,
                        REFERENCIA: articulo.REGISTRO_SELECCIONADO.REFERENCIA,
                        CODIGO_MARCA_ERP: articulo.REGISTRO_SELECCIONADO.CODIGO_MARCA_ERP,
                        NOMRE_MARCA: articulo.REGISTRO_SELECCIONADO.NOMRE_MARCA,
                        CODIGO_MARCA: articulo.REGISTRO_SELECCIONADO.CODIGO_MARCA,
                        NOMBRE_GRUPO_MONTAJE: articulo.REGISTRO_SELECCIONADO.NOMBRE_GRUPO_MONTAJE,
                        DESCUENTO: articulo.REGISTRO_SELECCIONADO.DESCUENTO,
                    }
                )
            }
            crearLineasParaElPresupuesto(articuloFinal, "A", true);
        })

        MSGOK(traducirPagina("Referencia_agregada"))
    }


    const modificarEstadoPiezas = async (item) => {
        item.COMPRAR = !item.COMPRAR
        item.SELECCIONADO = true
        if (item.COMPRAR) {
            item.PIEZA_TRABAJO = []

            ObtenerPiezasTrabajos(props.ID_VEHICULO, [item.ID_TRABAJO]).then((piezas) => {
                let RESULTADO = piezas === undefined ? { OK: false, PIEZA_TRABAJO: null } : piezas
                const { PIEZA_TRABAJO } = RESULTADO

                item.PIEZA_TRABAJO = PIEZA_TRABAJO === null || PIEZA_TRABAJO.length === 0 ? null : PIEZA_TRABAJO;

                if (piezas.PIEZA_TRABAJO !== null && piezas.PIEZA_TRABAJO.length > 0) {
                    piezas.PIEZA_TRABAJO.map((reg) => { reg.CONJUNTO_PIEZAS_QUALCOL.map((item) => item.COMPRAR = reg.CONJUNTO_PIEZAS_QUALCOL.length === 1) })
                    let abrir_modal_articulo = piezas.PIEZA_TRABAJO.filter((item) => item.CONJUNTO_PIEZAS_QUALCOL.length > 1)
                    if (abrir_modal_articulo.length > 0) {
                        initComponent.ABRIR_MODAL = true;
                        initComponent.ARTICULOS_MODAL = item
                    }
                }

                addTrabajo(item, initComponent.HISTORIAL);
                setInitComponent({ ...initComponent })
            })
        } else {
            item.PIEZA_TRABAJO = null
            deleteTrabajo(item.ID_TRABAJO)

        }
        setInitComponent({ ...initComponent })
    }

    const descargarDocumento = async () => {
        // modalPrint.ABRIR_MODAL = false;
        modalPrint.LOADING = true
        setModalPrint({ ...modalPrint })



        const { TIPO_SERVICIO, ULTIMO_SERVICIO_ANTES_KM_MES, SIGUIENTE_SERVICIO_DESPUES_KM_MES } = initComponent
        let ID_TRABAJO_SERVICIO = []
        let ID_TRABAJO_ADICIONAL = []

        //TIPO_SERVICIO |  1-ULTIMO_SERVICIO_ANTES_KM_MES 2-SIGUIENTE_SERVICIO_DESPUES_KM_MES
        if (TIPO_SERVICIO === 1) {
            ID_TRABAJO_SERVICIO = ULTIMO_SERVICIO_ANTES_KM_MES.filter((item) => item.TEXTO_TIPO_TRABAJO === "realizar").map((registro) => registro.ID_TRABAJO)
            ID_TRABAJO_ADICIONAL = ULTIMO_SERVICIO_ANTES_KM_MES.filter((item) => item.TEXTO_TIPO_TRABAJO === "cambiar").map((registro) => registro.ID_TRABAJO)
        } else if (TIPO_SERVICIO === 2) {
            ID_TRABAJO_SERVICIO = SIGUIENTE_SERVICIO_DESPUES_KM_MES.filter((item) => item.TEXTO_TIPO_TRABAJO === "realizar").map((registro) => registro.ID_TRABAJO)
            ID_TRABAJO_ADICIONAL = SIGUIENTE_SERVICIO_DESPUES_KM_MES.filter((item) => item.TEXTO_TIPO_TRABAJO === "cambiar").map((registro) => registro.ID_TRABAJO)
        }


        const {
            MOSTRAR_TABLA_NEUMATICOS,
            MOSTRAR_FOTOS_VEHICULOS,
            MOSTRAR_MANUALES_REPARACION,
            NOMBRE,
            APELLIDOS,
            TELEFONO,
            MATRICULA,
            KM,
            NUM_PEDIDO
        } = modalPrint

        const payloadAdicional = {
            CABECERA_CLIENTE: `${NOMBRE} ${APELLIDOS}`,
            CABECERA_TELEFONO: TELEFONO,
            CABECERA_CODIGO_SERVICIO_MANTENIMIENTO: NUM_PEDIDO,
            CABECERA_MATRICULA: MATRICULA,
            CABECERA_RENDIMIENTO: KM,
            CABECERA_FECHA: obtenerSoloFecha(),

            MOSTRAR_TABLA_NEUMATICOS: MOSTRAR_TABLA_NEUMATICOS,
            MOSTRAR_FOTOS_VEHICULOS:MOSTRAR_FOTOS_VEHICULOS,
            MOSTRAR_MANUALES_REPARACION: MOSTRAR_MANUALES_REPARACION,
        }
        const respuesta = await obtenerPlanServicioMantinimientoEnAPI(ID_TRABAJO_SERVICIO, ID_TRABAJO_ADICIONAL, ID_VEHICULO_TECDOC, payloadAdicional)

        if (respuesta.OK) {
            imprimirDocumentoHTML(respuesta.PLAN_SERVICIO)
        }

        // modalPrint.ABRIR_MODAL = false;
        modalPrint.LOADING = false
        modalPrint.ABRIR_MODAL = false

        modalPrint.MOSTRAR_TABLA_NEUMATICOS = false
        modalPrint.MOSTRAR_FOTOS_VEHICULOS = false
        modalPrint.MOSTRAR_MANUALES_REPARACION = false
        modalPrint.NOMBRE = ""
        modalPrint.APELLIDOS = ""
        modalPrint.TELEFONO = ""
        modalPrint.MATRICULA = ""
        modalPrint.KM = ""
        modalPrint.NUM_PEDIDO = ""
        setModalPrint({ ...modalPrint })
    }




    React.useEffect(() => {
        setTimeout(() => {
            if (ref.current !== null) {
                ref.current.focus()
            }
        }, 1000)

        ObtenerPosibleCodigoCajaCambios(ID_VEHICULO_TECDOC)

        let { txtBuscadorCoche } = store.getState().buscadorCoche;
        if (txtBuscadorCoche !== undefined && txtBuscadorCoche !== null && txtBuscadorCoche !== "" && txtBuscadorCoche != "0") {
            modalPrint.MATRICULA = txtBuscadorCoche
            setModalPrint({ ...modalPrint })
        }


    }, [])



    const { TRABAJOS_ADICIONALES, TRABAJOS_MANTENIMIENTO, TRABAJOS, ABRIR_ADICIONALES, ABRIR_PIEZAS, ARTICULOS_MODAL } = initComponent
    const { PIEZAS: PIEZAS_CESTA, TOTAL_PIEZAS, TOTAL_TIEMPO, TOTAL_TRABAJO } = lineasCesta
    const { PIEZA_TRABAJO } = ARTICULOS_MODAL


    return <div>
        <Drawer
            width={1230}
            onClose={() => {
                if (props.TIPO_FICHA === "MASTER") {
                    props.CERRAR.setState({ ABRIR_FICHA_RMI_MANTENIMIENTOS: false })
                } else {
                    props.CERRAR_FICHA(false)
                }
            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >

                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            if (props.TIPO_FICHA === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_RMI_MANTENIMIENTOS: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>

                <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                    <button className={`col-md-12 ${!TOTAL_TRABAJO > 0 ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`} disabled={TOTAL_TRABAJO > 0 ? false : true}
                        style={{
                            height: '40px', width: '300px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                            color: TOTAL_TRABAJO ? '#fff' : '#999',
                            marginBottom: '5px', marginRight: '5px'
                        }} onClick={(e) => {
                            let verificar = comprobarSiHayPiezasSinReferencia();
                            if (verificar) {
                                añadirApresupuesto();
                            } else {
                                setVisible(true);
                            }
                        }}>
                        <FileAddOutlined style={{ fontSize: '20px' }} />
                        <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Añadir_presupuesto").toUpperCase()}</span>
                    </button>
                </div>
            </div>



            <div className="containerRMIMantenimientos">

                <div className='item_2_MantenimientosRMI'>
                    {/*PASO 1 */}
                    <Card_RMI
                        header={
                            <div className="card_mantenimiento_header">
                                <FileTextOutlined></FileTextOutlined>
                                <p>{traducirPagina("txt_propuesta_mantenimiento")}</p>
                            </div>
                        }
                        footer={
                            <div className="card_mantenimiento_footer">
                                <button className={initComponent.BTN_1 === 0 ? "bloquear_mantenimiento" : ""}
                                    onClick={() => {
                                        initComponent.BTN_1 = initComponent.BTN_1 === 2 ? 1 : 2
                                        initComponent.ID_VARIANTE = 0
                                        initComponent.BTN_2 = 0
                                        initComponent.BTN_3 = 0
                                        initComponent.TIPO_SERVICIO = 0
                                        initComponent.ULTIMO_SERVICIO_ANTES_KM_MES = []
                                        initComponent.SIGUIENTE_SERVICIO_DESPUES_KM_MES = []
                                        if (initComponent.BTN_1 === 2) {
                                            actualizarPaso2()
                                        }

                                        setInitComponent({ ...initComponent })
                                    }}
                                >
                                    {initComponent.BTN_1 === 2 ? `${traducirPagina("txt_editar")}` : <> <SearchOutlined />  {traducirPagina("txt_siguiente")}</>}
                                </button>
                            </div>
                        }
                    >
                        <div className="card_mantenimiento_body">
                            <div>
                                {
                                    initComponent.BTN_1 === 2 ?
                                        <span className="info_mantenimieto"><DashboardOutlined /> {`${initComponent.KM} Km`}</span>
                                        :
                                        <InputNumber
                                            ref={ref}
                                            clear={true}
                                            style={{ width: '100%' }}
                                            placeholder="Km"
                                            value={initComponent.KM}
                                            min={1}
                                            onChange={(value) => {
                                                initComponent.KM = value;
                                                if (initComponent.FECHA !== "" && initComponent.KM !== null && initComponent.KM !== "") {
                                                    initComponent.BTN_1 = 1
                                                } else {
                                                    initComponent.BTN_1 = 0
                                                }

                                                setInitComponent({ ...initComponent })
                                            }} />

                                }
                            </div>

                            <div>
                                {
                                    initComponent.BTN_1 === 2 ?
                                        <span className="info_mantenimieto"><CalendarOutlined /> {`${initComponent.FECHA}`}</span>
                                        :
                                        <MonthPicker
                                            locale={locale}
                                            value={initComponent.FECHA_MOMENT}
                                            format={monthFormat}
                                            className="select_month"
                                            onChange={(date, dateString) => {
                                                initComponent.FECHA = dateString;
                                                initComponent.FECHA_MOMENT = date
                                                if (dateString !== "" && initComponent.KM !== null && initComponent.KM !== "") {
                                                    initComponent.BTN_1 = 1
                                                } else {
                                                    initComponent.BTN_1 = 0
                                                }

                                                setInitComponent({ ...initComponent })
                                            }}
                                            placeholder={traducirPagina("Fecha_de_matriculacion")} />

                                }

                            </div>

                            <div>
                                {
                                    initComponent.BTN_1 === 2 ?
                                        <span className="info_mantenimieto">{initComponent.ID_FILTRO_TIPO !== 0 ? <SettingOutlined /> : ""}  {`${filtrarTipo(initComponent.REGISTROS_FILTRO_TIPO, initComponent.ID_FILTRO_TIPO)}`}</span>
                                        :
                                        <Select
                                            showSearch
                                            allowClear={true}
                                            style={{ width: "100%", textAlign: 'left' }}
                                            placeholder={traducirPagina("Seleccione_una_opcion")}
                                            value={initComponent.ID_FILTRO_TIPO === 0 ? traducirPagina("Seleccione_una_opcion") : initComponent.ID_FILTRO_TIPO}
                                            onChange={(e) => {
                                                const seleccionado = e === undefined || e === null ? 0 : e
                                                initComponent.ID_FILTRO_TIPO = seleccionado
                                                setInitComponent({ ...initComponent })

                                            }}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {initComponent.REGISTROS_FILTRO_TIPO.map((registro, i) =>
                                                <Option key={i} value={registro.ID}>{registro.NOMBRE}</Option>
                                            )
                                            }
                                        </Select>
                                }
                            </div>

                        </div>
                    </Card_RMI>

                    {/*PASO 2 */}
                    <Card_RMI
                        clase_card={`card_mantenimiento ${initComponent.BTN_1 !== 2 ? "bloquear_mantenimiento" : ""}`}
                        header={
                            <div className="card_mantenimiento_header">
                                <FileTextOutlined></FileTextOutlined>
                                <p>{`${traducirPagina("Configuracion")} - ${traducirPagina("txt_paso")} 2`}</p>
                            </div>
                        }
                        footer={
                            initComponent.BTN_2 === 1 &&
                            <div className="card_mantenimiento_footer" onClick={() => {
                                initComponent.BTN_2 = 0
                                initComponent.BTN_3 = 0
                                initComponent.TIPO_SERVICIO = 0
                                initComponent.ID_VARIANTE = 0
                                initComponent.ULTIMO_SERVICIO_ANTES_KM_MES = []
                                initComponent.SIGUIENTE_SERVICIO_DESPUES_KM_MES = []

                                ObtenerPiezasCesta([])

                                setInitComponent({ ...initComponent })

                            }}>
                                <button>{traducirPagina("txt_editar")}</button>
                            </div>
                        }
                    >

                        <div className="card_mantenimiento_body">
                            <div>
                                {initComponent.BTN_2 === 1 ?
                                    <span className="info_mantenimieto"><ToolOutlined /> {`${filtrarVariante(initComponent.REGISTROS_FILTRO_VARIANTE, initComponent.ID_VARIANTE)}`}</span>
                                    :
                                    <Select
                                        showSearch
                                        style={{ width: "100%", textAlign: 'left' }}
                                        placeholder={traducirPagina("Seleccione_una_opcion")}
                                        value={initComponent.ID_VARIANTE === 0 ? traducirPagina("Seleccione_una_opcion") : initComponent.ID_VARIANTE}
                                        onChange={(e) => {
                                            const seleccionado = e === undefined || e === null ? 0 : e
                                            if (seleccionado !== 0) {

                                                //ObtenerServicioPropuesto(ID_VEHICULO_TECDOC, seleccionado, 0, initComponent.KM, initComponent.FECHA)

                                                let buscar = initComponent.REGISTROS_FILTRO_VARIANTE.find((item) => item.ID_QUALCOL === seleccionado)
                                                if (buscar !== undefined) {
                                                    const { ID_COO, ID_QUALCOL } = buscar

                                                    ObtenerServicioPropuesto(ID_VEHICULO_TECDOC, ID_COO, ID_QUALCOL, initComponent.KM, initComponent.FECHA)
                                                }

                                            }

                                            initComponent.ID_VARIANTE = seleccionado
                                            initComponent.BTN_2 = 1
                                            initComponent.BTN_3 = 1
                                            setInitComponent({ ...initComponent })
                                        }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>

                                        {
                                            initComponent.REGISTROS_FILTRO_VARIANTE.map((registro, i) =>
                                                <Option key={i} value={registro.ID_QUALCOL}>{registro.TEXTO_QUALCOL}</Option>
                                            )
                                        }
                                    </Select>
                                }
                            </div>
                        </div>
                    </Card_RMI>

                    {/*PASO 3 */}
                    <Card_RMI
                        clase_card={`card_mantenimiento ${initComponent.BTN_3 === 0 ? "bloquear_mantenimiento" : ""}`}
                        header={
                            <div className="card_mantenimiento_header">
                                <FileTextOutlined></FileTextOutlined>
                                <p>{`${traducirPagina("Configuracion")} - ${traducirPagina("txt_paso")} 3`}</p>
                            </div>
                        }
                    >
                        <div className="card_mantenimiento_body">
                            <div>
                                <Radio.Group
                                    onChange={(e) => {
                                        initComponent.TIPO_SERVICIO = e.target.value;
                                        initComponent.BTN_3 = 2;
                                        setInitComponent({ ...initComponent })

                                        let buscar = initComponent.REGISTROS_FILTRO_VARIANTE.find((item) => item.ID_QUALCOL === initComponent.ID_VARIANTE)
                                        if (buscar !== undefined) {
                                            const { ID_COO, ID_QUALCOL } = buscar
                                            ObtenerMantenimientos(ID_VEHICULO_TECDOC, ID_COO, ID_QUALCOL, initComponent.KM, initComponent.FECHA)

                                        }
                                    }}
                                    value={initComponent.TIPO_SERVICIO}>


                                    {
                                        initComponent.ULTIMO_SERVICIO_ANTES_KM_MES.length > 0 &&
                                        <Radio value={1}>
                                            {traducirPagina("txt_servicio_anterior")}

                                            <ul className="list_servicio">
                                                {
                                                    initComponent.ULTIMO_SERVICIO_ANTES_KM_MES.map((item) =>
                                                        <li>{`${item.TEXTO_GRUPO_MONTAJE}, ${item.TEXTO_TIPO_TRABAJO}`}</li>
                                                    )
                                                }
                                            </ul>
                                        </Radio>
                                    }

                                    {
                                        initComponent.SIGUIENTE_SERVICIO_DESPUES_KM_MES.length > 0 &&
                                        <Radio value={2}>
                                            {traducirPagina("txt_proximo_servicio")}

                                            <ul className="list_servicio">
                                                {
                                                    initComponent.SIGUIENTE_SERVICIO_DESPUES_KM_MES.map((item) =>
                                                        <li>{`${item.TEXTO_GRUPO_MONTAJE}, ${item.TEXTO_TIPO_TRABAJO}`}</li>
                                                    )
                                                }
                                            </ul>
                                        </Radio>
                                    }
                                </Radio.Group>
                            </div>
                        </div>
                    </Card_RMI>
                </div>

                <div className='item_3_MantenimientosRMI'>
                    {
                        initComponent.BTN_3 !== 2 ?
                            <div className="center_item_mantenimiento"> <h2>{traducirPagina("msg_propuesta_mantenimiento")}</h2> </div>
                            :
                            TRABAJOS === null ?
                                <div className="center_item_alert" style={{ height: '100%' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                                :
                                TRABAJOS.length === 0 ?
                                    <div className="center_item_alert" style={{ height: '100%' }} > <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                    :
                                    <>

                                        {/*TOTALES MANTENIMIENTOS */}
                                        <div className="headerTiempoRMI" style={{ padding: '0px 0px 20px 0px' }}>
                                            <div className="item2_tiempoRMI">
                                                <div>
                                                    {/*Tiempo_estandar  */}
                                                    <span>{traducirPagina("Piezas_necesarias")} </span>
                                                    <br />
                                                    <span >{convertirMoneda(TOTAL_PIEZAS)} </span>
                                                </div>

                                                <div>
                                                    {/*Tiempo_adicional*/}
                                                    <span>{traducirPagina("Trabajos")} </span>
                                                    <br />
                                                    <span >{convertirMoneda(TOTAL_TRABAJO)} </span>
                                                </div>


                                                <div>
                                                    <span>{traducirPagina("Tiempo_total_H")} </span>
                                                    <br />
                                                    <span >{convertirMoneda(TOTAL_TIEMPO)} </span>
                                                </div>

                                                <div></div>
                                            </div>

                                            <div className="container_doc_mantenimientos">
                                                <button onClick={() => {
                                                    modalPrint.ABRIR_MODAL = true; setModalPrint({ ...modalPrint })
                                                }}>
                                                    <PrinterOutlined /> {traducirPagina("Plan_servicio_mantenimiento")}
                                                </button>
                                            </div>

                                        </div>


                                        <div style={{ overflow: 'auto', height: 'calc(100% - 77px)' }}>

                                            {/*PIEZAS */}
                                            <div className="item_tiempo header_card_mantenimiento">
                                                <div className="card_mantenimiento_titulo" onClick={() => {
                                                    initComponent.ABRIR_PIEZAS = !initComponent.ABRIR_PIEZAS
                                                    setInitComponent({ ...initComponent })
                                                }}>
                                                    {ABRIR_PIEZAS ? <DownOutlined></DownOutlined> : <RightOutlined></RightOutlined>}
                                                    {`${traducirPagina("Piezas").toUpperCase()}`}
                                                </div>

                                                {
                                                    ABRIR_PIEZAS &&
                                                    <table className="tabla_ajuestes" style={{ width: '100%' }}>
                                                        <thead>
                                                            <th></th>
                                                            <th style={{ width: '70px' }}></th>
                                                            <th style={{ width: '50px' }}></th>
                                                            <th style={{ width: '165px' }}></th>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                PIEZAS_CESTA.map((articulo) =>
                                                                    <tr>
                                                                        <td>{articulo.DESCRIPCION === "" ? `${articulo.NOMBRE_PIEZA}` : `${articulo.DESCRIPCION} (${articulo.REFERENCIA} - ${articulo.MARCA})`}</td>
                                                                        <td>{convertirMoneda(articulo.PRECIO, "€")}</td>
                                                                        <td>{convertirMoneda(articulo.CANTIDAD_PIEZA)}</td>
                                                                        <td>
                                                                            <div className="buscar_referencia_tecrmi" onClick={(e) => { setGrupoMontaje(articulo.ID_GRUPO_MONTAJE); store.dispatch(DrawerFichaGMVehiculoHaynes(true)); }}>
                                                                                <SearchOutlined />
                                                                                <div>
                                                                                    {traducirPagina("Buscar_referencia")}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                )
                                                            }
                                                        </tbody>
                                                    </table>

                                                }
                                            </div>

                                            {/*TRABAJOS ADICIONALES */}
                                            <div className="item_tiempo header_card_mantenimiento">

                                                <div className="card_mantenimiento_titulo" onClick={(e) => {
                                                    initComponent.ABRIR_ADICIONALES = !initComponent.ABRIR_ADICIONALES
                                                    setInitComponent({ ...initComponent })
                                                }}>
                                                    {ABRIR_ADICIONALES ? <DownOutlined></DownOutlined> : <RightOutlined></RightOutlined>}
                                                    {`${traducirPagina("Trabajos_adicionales").toUpperCase()}`}
                                                </div>

                                                {
                                                    ABRIR_ADICIONALES &&
                                                    TRABAJOS_ADICIONALES.map((registro) =>
                                                        <>
                                                            <div className="item_tiempo">

                                                                <div className="item_tiempo_item" >
                                                                    {<DownOutlined></DownOutlined>}

                                                                    {`${registro.TEXTO_GRUPO_MONTAJE} ${registro.TEXTO_TIPO_TRABAJO} [${registro.TEXTO_TRABAJO_ADICIONAL}]`}
                                                                </div>
                                                                {
                                                                    registro.PASOS_TRABAJO_ADICIONAL.map((item, i) =>
                                                                        <>
                                                                            <div key={i} style={{ padding: '0px 20px', paddingBottom: '10px' }}>
                                                                                <div className="item_tiempo" style={{ background: '#fff', padding: '10px' }}>
                                                                                    <div className="sub_item_tiempo">
                                                                                        <div onClick={() => {
                                                                                            if (item.POSICION_TRABAJO_EXCLUSIVO.length > 0) {
                                                                                                item.SELECCIONADO = !item.SELECCIONADO
                                                                                                setInitComponent({ ...initComponent })
                                                                                            }
                                                                                        }}>

                                                                                            {/*item.POSICION_TRABAJO_EXCLUSIVO.length > 0 ? item.SELECCIONADO ? <DownOutlined /> : <RightOutlined /> : ""*/}
                                                                                            {`${item.TEXTO_GRUPO_MONTAJE} ${item.TEXTO_TIPO_TRABAJO}${item.ID_QUALCOR_TRABAJO !== 0 ? `, ${item.TEXTO_QUALCOL_TRABAJO}` : ""}`}

                                                                                        </div>

                                                                                        <div className={`${TOTAL_TIEMPO === 0 ? "bloquear_mantenimiento" : ""}`} onClick={() => { modificarEstadoPiezas(item) }}>
                                                                                            {`${convertirMoneda(item.TIEMPO_TRABAJO, "")} ${traducirPagina("Horas").toLowerCase()}`}
                                                                                            {item.COMPRAR ? <CloseCircleOutlined style={{ fontSize: '22px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '22px', color: 'green' }} />}
                                                                                        </div>
                                                                                    </div>
                                                                                    {seleccionarPiezasAdicionales(item)}
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                            </div>

                                            {/*TRABAJOS DEL MANTENIMIENTO */}
                                            <div className="item_tiempo header_card_mantenimiento">
                                                <div className="card_mantenimiento_titulo">
                                                    {`${traducirPagina("Trabajos_del_mantenimiento").toUpperCase()}`}
                                                </div>

                                                {
                                                    TRABAJOS_MANTENIMIENTO.map((registro) =>
                                                        <>
                                                            <div className="item_tiempo">

                                                                <div className="item_tiempo_item" >
                                                                    {<DownOutlined></DownOutlined>}

                                                                    {`${registro.TEXTO_GRUPO_MONTAJE} ${registro.TEXTO_TIPO_TRABAJO} `}
                                                                </div>
                                                                {
                                                                    registro.PASOS_TRABAJO.map((item, i) =>
                                                                        <>
                                                                            <div key={i} style={{ padding: '0px 20px', paddingBottom: '10px' }}>

                                                                                <div className={`item_tiempo ${item.TRABAJO_PROPUESTO_PARA_MANTENIMIENTO ? "preseleccionar_pieza" : ""}`} style={{ background: '#fff', padding: '10px' }}>
                                                                                    <div className="sub_item_tiempo">
                                                                                        <div onClick={() => {
                                                                                            if (item.POSICION_TRABAJO_EXCLUSIVO.length > 0) {
                                                                                                item.SELECCIONADO = !item.SELECCIONADO
                                                                                                setInitComponent({ ...initComponent })
                                                                                            }
                                                                                        }}>

                                                                                            {/*item.POSICION_TRABAJO_EXCLUSIVO.length > 0 ? item.SELECCIONADO ? <DownOutlined /> : <RightOutlined /> : ""*/}
                                                                                            {`${item.TEXTO_GRUPO_MONTAJE} ${item.TEXTO_TIPO_TRABAJO}${item.ID_QUALCOR_TRABAJO !== 0 ? `, ${item.TEXTO_QUALCOL_TRABAJO}` : ""}`}
                                                                                        </div>

                                                                                        <div onClick={() => { modificarEstadoPiezas(item) }}>
                                                                                            {`${convertirMoneda(item.TIEMPO_TRABAJO, "")} ${traducirPagina("Horas").toLowerCase()}`}
                                                                                            {item.COMPRAR ? <CloseCircleOutlined style={{ fontSize: '22px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '22px', color: 'green' }} />}
                                                                                        </div>
                                                                                    </div>
                                                                                    {seleccionarPiezasAdicionales(item)}
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    )

                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                            </div>

                                        </div>
                                    </>

                    }
                </div>
            </div>
        </Drawer>


        {
            /*Componente para buscar la referencia y detalles de la misma, se utiliza en la cesta al buscar una referencia */
            <FichaGMYVehiculoHaynesPro
                title={traducirPagina("Buscar_referencias").toUpperCase()}
                vehiculoId={props.ID_VEHICULO.toString()}
                grupoMontaje={grupoMontaje.toString()}
                listaTiempoReparacion={[]}
                componentePadre={"TECRMI"}
                registroSeleccionado={actualizarRegistroPiezaTECDOC}
            >

            </FichaGMYVehiculoHaynesPro>
        }

        {
            /**MOdal informativo, avisa la usuario que no a seleccionado referencia */
            <Modal
                visible={visible}
                width={600}
                centered
                title={traducirPagina("Tiene_articulos_ sinreferenciar")}
                onCancel={() => setVisible(false)}
                style={{ zIndex: '9999' }}
                footer={[
                    <Button key="back" onClick={() => { setVisible(false) }}>
                        {traducirPagina("NO")}
                    </Button>,
                    <Button className="btnColor" key="submit" type="primary" onClick={() => {
                        añadirApresupuesto();
                        setVisible(false)
                    }}> {traducirPagina("SI")}</Button>
                ]}

            >
                <p>{traducirPagina("msgManPre_material_no_referenciado_01")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_02")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_03")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_04")}</p>
            </Modal>
        }



        {
            /*Modal de TECRMI para seleccionar una pieza */
            initComponent.ABRIR_MODAL &&
            <Modal
                title={<div className="containerModalTitulo" style={{ fontSize: '16px' }}>{traducirPagina("txt_seleccion_articulos")}</div>}
                centered
                visible={initComponent.ABRIR_MODAL}
                onOk={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}
                onCancel={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}
                footer={null}
                width={700}
            >

                <div className="container_articulo_tiempo">
                    {
                        PIEZA_TRABAJO !== undefined &&
                            PIEZA_TRABAJO === null ?
                            <div className="center_item_alert"  > <h3>{traducirPagina("No_resultados")}</h3> </div>
                            :
                            PIEZA_TRABAJO.length === 0 ?
                                <div className="center_item_alert"> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <div className="articulo_tiempo" >
                                    <h2>{initComponent.TITULO_MODAL}</h2>
                                    {
                                        PIEZA_TRABAJO.filter((item) => item.CONJUNTO_PIEZAS_QUALCOL.length > 1).map((registro) =>
                                            <div className="item_articulo_tiempo">
                                                <Radio.Group >
                                                    {registro.CONJUNTO_PIEZAS_QUALCOL.map((item, i) =>
                                                        <Radio value={i}
                                                            onClick={(e) => {
                                                                registro.CONJUNTO_PIEZAS_QUALCOL.map((reg) => { reg.COMPRAR = false; deletePieza(registro, reg.ID_QUALCOL, false) });
                                                                addPieza(registro, item.ID_QUALCOL)

                                                                item.COMPRAR = !item.COMPRAR;
                                                                setInitComponent({ ...initComponent })
                                                            }}
                                                        > {`${registro.TEXTO_GRUPO_MONTAJE} ${item.TEXTO_QUALCOL}`}</Radio>
                                                    )}
                                                </Radio.Group>
                                            </div>
                                        )
                                    }
                                </div>
                    }
                </div>

                <div className="btn_articulo_tiempos">
                    <button onClick={() => { initComponent.ABRIR_MODAL = false; setInitComponent({ ...initComponent }) }}>OK</button>
                </div>
            </Modal>


        }


        {
            /*Modal de TECRMI para descargar documento */

            modalPrint.ABRIR_MODAL &&

            <Modal
                title={<div className="containerModalTitulo" style={{ fontSize: '16px' }}>{traducirPagina("Informacion")}</div>}
                centered
                visible={modalPrint.ABRIR_MODAL}
                onOk={() => { modalPrint.ABRIR_MODAL = false; setModalPrint({ ...modalPrint }) }}
                onCancel={() => { modalPrint.ABRIR_MODAL = false; setModalPrint({ ...modalPrint }) }}
                footer={null}
                width={700}
            >

                <div className="container_articulo_tiempo">
                    {

                        <div className="articulo_tiempo" >

                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>

                                <Col md={8}>
                                    <span>{`${traducirPagina("Nombre")}:  `} </span>
                                </Col>
                                <Col md={8}>
                                    <span>{`${traducirPagina("Matricula")}:  `} </span>
                                </Col>
                                <Col md={8}>
                                    <span>{`${traducirPagina("txt_info_adicional")}:  `} </span>
                                </Col>

                                <Col md={8}>
                                    <Input name="NOMBRE" value={modalPrint.NOMBRE} onChange={(e) => { modalPrint.NOMBRE = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                </Col>
                                <Col md={8}>
                                    <Input name="MATRICULA" value={modalPrint.MATRICULA} onChange={(e) => { modalPrint.MATRICULA = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                </Col>
                                <Col md={8}>
                                    <Input name="NUM_PEDIDO" value={modalPrint.NUM_PEDIDO} onChange={(e) => { modalPrint.NUM_PEDIDO = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                </Col>


                                <Col md={8}>
                                    <span>{`${traducirPagina("txt_apellido")}:  `} </span>
                                </Col>
                                <Col md={8}>
                                    <span>{`${traducirPagina("txt_km")}:  `} </span>
                                </Col>
                                <Col md={8}>
                                    <span>{`${traducirPagina("Telefono")}:  `} </span>
                                </Col>

                                <Col md={8}>
                                    <Input name="APELLIDOS" value={modalPrint.APELLIDOS} onChange={(e) => { modalPrint.APELLIDOS = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                </Col>

                                <Col md={8}>
                                    <Input name="KM" value={modalPrint.KM} onChange={(e) => { modalPrint.KM = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                </Col>
                                <Col md={8}>
                                    <Input name="TELEFONO" value={modalPrint.TELEFONO} onChange={(e) => { modalPrint.TELEFONO = e.target.value; setModalPrint({ ...modalPrint }) }} />
                                </Col>

                                <Col span={24}>
                                    <Checkbox value={1}
                                        checked={modalPrint.MOSTRAR_TABLA_NEUMATICOS}
                                        onChange={(e) => {
                                            modalPrint.MOSTRAR_TABLA_NEUMATICOS = e.target.checked
                                            setModalPrint({ ...modalPrint })
                                        }}>{traducirPagina("txt_neumatico_tecrmi")}</Checkbox>
                                </Col>
                            </Row>



                            {
                                <div className="item_articulo_tiempo" style={{ marginTop: '20px' }}>

                                    <Row>
                                        <Col span={24}>
                                            <Checkbox value={2}
                                                checked={modalPrint.MOSTRAR_FOTOS_VEHICULOS}
                                                onChange={(e) => {
                                                    modalPrint.MOSTRAR_FOTOS_VEHICULOS = e.target.checked
                                                    setModalPrint({ ...modalPrint })

                                                }}>{traducirPagina("txt_mostrar_fotos_vehiculo")}</Checkbox>
                                        </Col>

                                        <Col span={24}>
                                            <Checkbox value={2}
                                                checked={modalPrint.MOSTRAR_MANUALES_REPARACION}
                                                onChange={(e) => {
                                                    modalPrint.MOSTRAR_MANUALES_REPARACION = e.target.checked
                                                    setModalPrint({ ...modalPrint })

                                                }}>{traducirPagina("txt_manuales_tecrmi")}</Checkbox>
                                        </Col>
                                    </Row>
                                </div>

                            }
                        </div>
                    }
                </div>

                <div className="btn_articulo_tiempos container_doc_mantenimientos">
                    <button className={modalPrint.LOADING ? "disable_button" : ""} onClick={() => {

                        descargarDocumento()

                    }}> {modalPrint.LOADING ? <LoadingOutlined /> : <PrinterOutlined />} {traducirPagina("Plan_servicio_mantenimiento")}</button>
                </div>
            </Modal>


        }

    </div>;
};