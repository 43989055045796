import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { MDBBtn, MDBNav, MDBNavItem, MDBCol, MDBTable, MDBTableBody } from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import 'antd/dist/antd.css';

import { Drawer, Checkbox, Col, Layout, Pagination } from 'antd';
import {
    DrawerFichaGMVehiculoHaynes,
    createRipple, DrawerCarroCompra, DrawerMasInformacion,
    actulizarReferenciaMasInfo2, actulizarReferenciaSeleccionada,
    DrawerFichaMasInformacion2, DrawerStockAlmacen, efectoBotonRemove
} from "../action/actionCreators";
import {
    traducirPagina, mostrarPaginaActual, obtenerListaOrdenadaPorStock, referenciaActualSelect, convertirMoneda, obtenerPaginas, limpiarGrupoDrawer, MSGOK, limpiarCocheStore, efectoLadoDeMontaje,
    mostarReferenciasGenericasEnPantalla, verficarSiEsCatalogo, colorCabeceraPorElPrecioDeLaReferencia, colorCabeceraFuente, buscarReferenciaYMostrarEnPantalla, copiarAlPortapapeles, filtroLadoMontaje
} from "../librerias/funciones"

import { ObtenerReferenciasByGMAndVehicleEnAPI, buscarDetallesReferencia, pad, abortFetch, ObtenerEquivalenciasArticuloByArtoOEMEnAPI, buscarPrecioReferencia, } from "../action/actionCreatorsRequests";

import store from '../store'
import {
    CloseOutlined,
    SearchOutlined,
    ShoppingOutlined,
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    FileImageOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    EyeInvisibleOutlined,
    ControlOutlined,
    ApartmentOutlined,
    CopyOutlined,
    ClockCircleOutlined,
    FileAddOutlined,
    SelectOutlined
} from '@ant-design/icons';

const { Content } = Layout;

const FichaGMYVehiculoHaynesPro = ({ props, openDrawerFichaGMYVehiculoHaynesPro, openDrawerReferenciaGrafico, codmotorizacion, filtrosReferencia, usuario, openDrawerMasInfo, openDrawerFichaReferenciaVehiculo, openDrawerFichaEquivalenciaArticulo, configApp, DIRECTORIO_DOCUMENTO }) => {
    const [colapseMarca, setColapseMarca] = React.useState(true);
    const [visualizacionDescuento, setVisualizacionDescuento] = React.useState(true);
    const [grupoMarcasLocal, setGrupoMarcasLocal] = React.useState({});
    const [esConLado, setEsConLado] = React.useState(false);
    const [hayReferencias, setHayReferencias] = React.useState(false);
    const [verFiltros, setVerFiltros] = React.useState(false);
    const [obtenerReferencia, setObtenerReferencia] = React.useState({});
    const [pagination, setPagination] = React.useState({});
    const [todasLasMarcas, setMarcas] = React.useState([]);
    const [todosLosGrupos, setGrupos] = React.useState([]);
    const [mostrarFilrosComponente, setMostrarFilrosComponente] = React.useState(true);
    const [checkedListMarcas, setCheckedListMarcas] = React.useState([]);
    const [checkedListGrupos, setCheckedListGrupos] = React.useState([]);
    const [listaLadosSeleccionados, setListaLadosSeleccionados] = React.useState([]);


    const [listaDeReferenciasDeLaPaginaActual, setListaDeReferenciasDeLaPaginaActual] = React.useState([]);
    const [hayReferenicaAMostrar, setHayReferenicaAMostrar] = React.useState(false);
    let listaDeMarcasDeLaPaginaActual = [];
    let listaDeReferencias = [];
    let crearListaTemporalDeReferencia = []

    const verficarCambiosListaMarcas = () => {
        setCheckedListMarcas(filtrosReferencia.verTodasLasMarcas ? todasLasMarcas : []);

    };

    const actulizarMarcasSeleccionadas = list => {
        setCheckedListMarcas(list);
        actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, list, checkedListGrupos, esConLado)
    };

    const actulizarGruposSeleccionadas = list => {
        setCheckedListGrupos(list);
        actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, checkedListMarcas, list, esConLado)
    };


    const mostrarReferenciaEnPantalla = (obtenerReferencia) => {
        if (obtenerReferencia !== null && !hayReferenicaAMostrar) {
            if (hayReferencias && Object.keys(obtenerReferencia).length > 0) {

                listaDeMarcasDeLaPaginaActual = mostrarPaginaActual(obtenerReferencia, pagination);

                listaDeMarcasDeLaPaginaActual.map((marca, i) => {
                    listaDeReferencias = obtenerReferencia.referencias.filter(referencia => referencia.NOMRE_MARCA === marca);
                    listaDeReferencias = obtenerListaOrdenadaPorStock(listaDeReferencias);
                    if (listaDeReferencias.length > 0) {
                        listaDeReferencias.map((item, pos) => {

                            if (item.CODIGO_MARCA !== 0 || (item.CODIGO_MARCA === 0 && item.NOMRE_MARCA === "ADICIONAL" && item.CODIGO_MARCA_ERP !== "0")) {
                                let itemTemporal = item;
                                if (itemTemporal.CODIGO_MARCA === 0) {
                                    itemTemporal.CODIGO_MARCA = itemTemporal.CODIGO_MARCA_ERP;
                                }

                                buscarDetallesReferencia(itemTemporal).then(data => {
                                    if (data !== undefined) {
                                        //Se busca los detalles y se le asigna a cada referencia sus detalles.
                                        crearListaTemporalDeReferencia.map((referencia) => {
                                            let actualizarDetallesReferencia = referencia.referencias.find(referencia => referencia.CODIGO_MARCA === data.MARCA && referencia.REFERENCIA === data.ARTICULO);
                                            if (actualizarDetallesReferencia !== undefined) {
                                                actualizarDetallesReferencia.detalles = data;
                                            }
                                        })

                                        let contruirImagen = data.IMAGEN === "" ? "./imagenes/sinimagen.jpg" : DIRECTORIO_DOCUMENTO + pad(parseInt(data.MARCA), 4).toString() + "/" + data.IMAGEN
                                        let dom = document.getElementById("IMGE" + data.MARCA + data.ARTICULO)

                                        if (dom !== null) {
                                            dom.src = contruirImagen;
                                            document.getElementById("ESTADOGE" + item.CODIGO_MARCA + item.REFERENCIA).style.backgroundColor = data.ID_ESTADO === 1 ? '#4285f4' : data.ID_ESTADO === 9 ? '#D91E18' : '#dcdcdc';
                                            document.getElementById("ESTADOGE" + item.CODIGO_MARCA + item.REFERENCIA).style.textAlign = "center";
                                            document.getElementById("ESTADOGE" + item.CODIGO_MARCA + item.REFERENCIA).style.color = data.ID_ESTADO === 1 || data.ID_ESTADO === 9 ? '#fff' : '#000'
                                            document.getElementById("ESTADOGE" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = data.DESCRIPCION_ESTADO;
                                            document.getElementById("EANGE" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = data.EAN;
                                            if (data.EAN === "") {
                                                if (document.getElementById("EANIono" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                    document.getElementById("EANIono" + item.CODIGO_MARCA + item.REFERENCIA).style.display = "none";
                                                }
                                            }

                                            let propiedades = "";
                                            let refComerciales = ""
                                            let sustituyeA = ""
                                            let sustituyePor = ""
                                            let masInfo = ""

                                            data.PROPIEDADES.split("|").map((item, i) => { if (i > 0) { propiedades += '<span>' + item + '</span><br/>' } })


                                            if (data.REFERENCIA_COMERCIAL.trim() !== "") {
                                                refComerciales = '<span title="labelRefComercial">' + traducirPagina("Referencia_comerciales") + ":" + '</span>'
                                                data.REFERENCIA_COMERCIAL.split(",").map((item, i) => {
                                                    if (i === data.REFERENCIA_COMERCIAL.split(",").length - 1) {
                                                        refComerciales += '<span class="refComercial"> ' + item + '</span>'

                                                    } else {
                                                        refComerciales += '<span class="refComercial"> ' + item + " | " + '</span>'
                                                    }
                                                })
                                            }

                                            if (data.SUSTITUYE_A.trim() !== "") {
                                                sustituyeA = '<span title="labelSustituyeA">' + traducirPagina("Sustituye_A") + ":" + '</span>'

                                                data.SUSTITUYE_A.split(",").map((item, i) => {
                                                    if (i === data.SUSTITUYE_A.split(",").length - 1) {
                                                        sustituyeA += '<span class="refComercial"> ' + item + '</span>'
                                                    } else {
                                                        sustituyeA += '<span class="refComercial"> ' + item + " | " + '</span>'
                                                    }
                                                })
                                            }

                                            if (data.SUSTITUIDO_POR.trim() !== "") {
                                                sustituyePor = '<span title="labelSustituyePor">' + traducirPagina("Sustituido_Por") + ":" + '</span>'
                                                data.SUSTITUIDO_POR.split(",").map((item, i) => {
                                                    if (i === data.SUSTITUIDO_POR.split(",").length - 1) {
                                                        sustituyePor += '<span class="refComercial"> ' + item + '</span>'
                                                    } else {
                                                        sustituyePor += '<span class="refComercial"> ' + item + " | " + '</span>'
                                                    }
                                                })
                                            }

                                            if (data.MAS_INFO.trim() !== "") {
                                                masInfo = "";
                                                masInfo = '<div class="containerMasInfo">' + data.MAS_INFO + '</div>'
                                            }


                                            document.getElementById("PROPIEDADESGE" + data.MARCA + data.ARTICULO).innerHTML = propiedades;

                                            if (document.getElementById("RefComGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                document.getElementById("RefComGE" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = refComerciales !== "" ? refComerciales : "";
                                            }
                                            if (document.getElementById("SustituyeGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                document.getElementById("SustituyeGE" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = sustituyeA !== "" ? sustituyeA : "";
                                            }
                                            if (document.getElementById("SustPorGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                document.getElementById("SustPorGE" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = sustituyePor !== "" ? sustituyePor : "";;
                                            }

                                            if (document.getElementById("ContainerMasInfoGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                document.getElementById("ContainerMasInfoGE" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = masInfo !== "" ? masInfo : "";
                                            }

                                            if (document.getElementById("btnDesgloseGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                if (data.CON_DESGLOSE) {
                                                    document.getElementById("btnDesgloseGE" + item.CODIGO_MARCA + item.REFERENCIA).style.display = null;
                                                }
                                            }

                                        }
                                    }
                                });
                            }


                            buscarPrecioReferencia(item).then(data => {
                                if (data !== undefined) {
                                    item.PRECIO = data.PRECIO
                                    item.NETO = data.NETO
                                    item.DESCUENTO = data.DESCUENTO

                                    //CONDICION PARA REYNASA
                                    if (document.getElementById("CT_PVP" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_PVP" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                        document.getElementById("CT_PVP" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = "PVP: " + convertirMoneda(data.PRECIO, " €")
                                    }
                                    if (document.getElementById("CT_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                        document.getElementById("CT_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = traducirPagina("Precio") + ": " + convertirMoneda(data.PRECIO, " €")
                                    }

                                    if (document.getElementById("CT_DTO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_DTO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                        document.getElementById("CT_DTO" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = "Dto. : " + data.DESCUENTO + " %"
                                    }
                                    if (document.getElementById("CT_NETO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_NETO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                        document.getElementById("CT_NETO" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = "Neto : " + convertirMoneda(data.NETO, " €")
                                    }



                                    if (document.getElementById("TD_GE_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("TD_GE_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                        document.getElementById("TD_GE_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = traducirPagina("Precio") + ": " + convertirMoneda(data.PRECIO, " €")
                                    }
                                    if (document.getElementById("TD_GE_DTO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("TD_GE_DTO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                        document.getElementById("TD_GE_DTO" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = "Dto. : " + data.DESCUENTO + " %"
                                    }
                                    if (document.getElementById("TD_GE_NETO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("TD_GE_NETO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                        document.getElementById("TD_GE_NETO" + item.CODIGO_MARCA + item.REFERENCIA).innerHTML = "Neto : " + convertirMoneda(data.NETO, " €")
                                    }
                                } else {
                                    if (document.getElementById("CT_PVP" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_PVP" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                    }
                                    if (document.getElementById("CT_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                    }
                                    if (document.getElementById("CT_DTO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_DTO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                    }
                                    if (document.getElementById("CT_NETO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("CT_NETO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                    }


                                    if (document.getElementById("TD_GE_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("TD_GE_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                    }
                                    if (document.getElementById("TD_GE_DTO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("TD_GE_DTO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                    }
                                    if (document.getElementById("TD_GE_NETO" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                        document.getElementById("TD_GE_NETO" + item.CODIGO_MARCA + item.REFERENCIA).classList.remove("skeleton-box")
                                    }
                                }
                            })
                        })


                        crearListaTemporalDeReferencia.push({
                            nombreMarca: marca,
                            referencias: listaDeReferencias
                        })
                    }
                })

                if (crearListaTemporalDeReferencia.length > 0) {
                    setListaDeReferenciasDeLaPaginaActual(crearListaTemporalDeReferencia)
                    setHayReferenicaAMostrar(true)
                    setMostrarFilrosComponente(true)
                }
            }
        }
    }

    const actualizarRegistros = (referencia, marcasSeleccionadas, gruposSeleccionados, esConLado, ladosSeleccionados) => {
        let objetoRegistro = mostarReferenciasGenericasEnPantalla(referencia, marcasSeleccionadas, gruposSeleccionados, ladosSeleccionados)
        setHayReferenicaAMostrar(false)
        setEsConLado(esConLado);
        setGrupoMarcasLocal(objetoRegistro)
        setObtenerReferencia(objetoRegistro.Referencias)
        setPagination(objetoRegistro.Pagination)
        setMarcas(objetoRegistro.Referencias.marcas);
        setGrupos(objetoRegistro.Referencias.grupoMontaje);
        return objetoRegistro;
    }


    const limpiarEstadoLocal = () => {
        setMarcas([]);
        setGrupos([]);
        setObtenerReferencia({})
        setHayReferencias(false)
        setVerFiltros(false)

        setListaDeReferenciasDeLaPaginaActual([])
        setHayReferenicaAMostrar(false)
        setPagination({})
        abortFetch()

        setCheckedListMarcas([])
        setCheckedListGrupos([])
        setMostrarFilrosComponente(true)
    }



    if (!hayReferencias && openDrawerFichaGMYVehiculoHaynesPro) {
        setHayReferencias(true);
        setObtenerReferencia({})
        if (props.componentePadre === "PRESUPUESTO") {
            ObtenerEquivalenciasArticuloByArtoOEMEnAPI("", props.grupoMontaje, "").then(referencia => {
                if (referencia !== undefined) {
                    if (referencia.EQUIVALENCIAS !== null && referencia.EQUIVALENCIAS !== undefined) {
                        if (referencia.EQUIVALENCIAS.length > 0) {

                            actualizarRegistros(referencia.EQUIVALENCIAS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                }
            })

        } else {
            ObtenerReferenciasByGMAndVehicleEnAPI(props.grupoMontaje, props.vehiculoId).then(referencia => {
                if (referencia !== undefined) {
                    if (referencia.ARTICULOSNODOS !== null && referencia.ARTICULOSNODOS !== undefined) {
                        if (referencia.ARTICULOSNODOS.length > 0) {
                            actualizarRegistros(referencia.ARTICULOSNODOS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                } else {
                    setObtenerReferencia(null)
                    setMarcas([]);
                    setGrupos([]);
                }
            })
        }



    } else {
        if (!openDrawerFichaGMYVehiculoHaynesPro && hayReferencias) {
            limpiarEstadoLocal()
        }
    }



    const aplicarFiltroPorMarca = (e) => {
        let texto = e.target.value.toUpperCase();
        if (texto.length > 2) {
            let marcas = obtenerReferencia.marcas.filter(marca => marca.toUpperCase().includes(texto));
            if (marcas.length > 0) {
                setMarcas(marcas);
            }
        }
    }

    const efectoBotonRemoveLocal = (idControl, idBoton, tipoId) => {
        let controlBuscador = idControl === "" ? "" : document.getElementById(idControl) !== null ? document.getElementById(idControl).value : "";
        let activarEfecto = controlBuscador.trim() === "" ? false : true;

        if (activarEfecto) {
            document.getElementById(idBoton).style.visibility = "visible";
        } else {
            if (tipoId === "MARCAS") {
                document.getElementById("buscadorFiltroMarcaGE").value = "";
                setMarcas(obtenerReferencia.marcas);

            } else {
                if (document.getElementById("buscadorFiltroGrupoGE") !== null) {
                    setGrupos(obtenerReferencia.grupoMontaje);
                }
            }

            document.getElementById(idBoton).style.visibility = "hidden";
        }
    }

    const aplicarFiltroPorGrupo = (e) => {
        let texto = e.target.value.toUpperCase();
        if (texto.length > 2) {
            let grupos = obtenerReferencia.grupoMontaje.filter(grupo => grupo.toUpperCase().includes(texto));
            if (grupos.length > 0) {
                setGrupos(grupos);

            }
        }
    }

    const verficarCambiosListaGrupos = () => {
        setCheckedListGrupos(filtrosReferencia.verTodosLosGrupos ? todosLosGrupos : []);
    };


    const updateNumberPage = (current, size) => {
        let listaPaginas = obtenerPaginas(grupoMarcasLocal.Referencias, size);
        //console.log("objeto ref", listaPaginas)
        setPagination({
            "numeroTotalRegistro": grupoMarcasLocal.Referencias.referencias.length - 1,
            "numeroTotalRegistroPaginacion": listaPaginas.length * size,
            "numeroRegistroPagina": size,
            "numeroDePaginas": listaPaginas.length,
            "paginas": listaPaginas,
            "paginaAMostrar": current - 1
        })

        setHayReferenicaAMostrar(false)
    }



    const actualizarReferenciasEnPantalla = (page, pageSize) => {
        setHayReferenicaAMostrar(false)
        pagination.paginaAMostrar = page - 1;
        pagination.numeroRegistroPagina = pageSize;
        setPagination(pagination);
        mostrarReferenciaEnPantalla(obtenerReferencia);
    }

    const añadirLocalmenteLadoMontaje = (lado, estaActivo) => {
        let listaSeleccionados = []
        if (estaActivo) {
            if (listaLadosSeleccionados.length === 0) {
                listaSeleccionados.push(lado)
                //setLadoMontajeSeleccionados(listaSeleccionados)
            } else {
                let seEncuentra = listaLadosSeleccionados.find(registro => registro === lado);
                if (seEncuentra === undefined) {
                    listaSeleccionados = listaLadosSeleccionados
                    listaSeleccionados.push(lado)
                    //setLadoMontajeSeleccionados(listaSeleccionados);
                }
            }

        } else {
            if (listaLadosSeleccionados.length > 0) {
                listaSeleccionados = listaLadosSeleccionados;
                let i = listaSeleccionados.indexOf(lado);
                listaSeleccionados.splice(i, 1);
                //setLadoMontajeSeleccionados(listaSeleccionados)

            }
        }


        setListaLadosSeleccionados(listaSeleccionados);
        let objetoRegistro = actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, checkedListMarcas, checkedListGrupos, esConLado, listaSeleccionados)

        if (objetoRegistro.Referencias.referencias.length === 0) {
            setListaDeReferenciasDeLaPaginaActual([])
            setHayReferenicaAMostrar(false)
            setMostrarFilrosComponente(false)
            setMarcas([]);
            setGrupos([]);
        }
    }

    const actulizarEstadoTiempoReparacio = (objetoLocal) => {
        if (props.listaTiempoReparacion.length > 0) {

            if (props.componentePadre === "TIEMPOS") {

                props.listaTiempoReparacion.map(tiempo => {
                    if (tiempo.tareas.length > 0) {
                        tiempo.tareas.map(tarea => {
                            if (tarea.Articulos.length > 0) {
                                tarea.Articulos.map(articulo => {
                                    if (articulo.Id.toString() === props.grupoMontaje) {
                                        articulo.Precio = objetoLocal.PRECIO
                                        articulo.Referencia = objetoLocal.REFERENCIA
                                        articulo.Marca = objetoLocal.NOMRE_MARCA

                                        articulo.ReferenciaSeleccionado = objetoLocal
                                    }

                                })
                            }
                        })
                    }
                })
            } else if (props.componentePadre === "MANTENIMIENTOS") {
                props.listaTiempoReparacion.map(tiempo => {
                    if (tiempo.SubTareas.length > 0) {
                        tiempo.SubTareas.map(tarea => {
                            if (tarea.Articulos.length > 0) {
                                tarea.Articulos.map(articulo => {
                                    if (articulo.Id.toString() === props.grupoMontaje) {
                                        articulo.Precio = objetoLocal.PRECIO
                                        articulo.Referencia = objetoLocal.REFERENCIA
                                        articulo.Marca = objetoLocal.NOMRE_MARCA

                                        articulo.ReferenciaSeleccionado = objetoLocal
                                    }

                                })
                            }
                        })
                    }

                })
            }
            props.modificarArticulo = [...props.listaTiempoReparacion];

        } else if (props.componentePadre === "PRESUPUESTO") {
            props.modificarArticulo(objetoLocal)
        }


        if (props.componentePadre === "TECRMI") {
            props.registroSeleccionado(objetoLocal)
        }



        store.dispatch(DrawerFichaGMVehiculoHaynes(false));
    }

    mostrarReferenciaEnPantalla(obtenerReferencia);





    return (
        <Drawer
            width={1200}
            onClose={() => { store.dispatch(DrawerFichaGMVehiculoHaynes(false)); }}
            visible={openDrawerFichaGMYVehiculoHaynesPro}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '9999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaGMVehiculoHaynes(false)); createRipple(e) }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="p-3">
                <Content>
                    {Object.keys(pagination).length > 0 ?
                        <div className="flex-container p-2" style={{ backgroundColor: '#eee' }} >
                            <div className="flex-item-left" style={{ textAlign: 'left', padding: '0px', flex: '40%' }}>
                                <div className="classic-tabs">
                                    <MDBNav classicTabs color="#808080" style={{ backgroundColor: '#eee' }} >
                                        <MDBNavItem>
                                            {
                                                colapseMarca ?
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setColapseMarca(false); setHayReferenicaAMostrar(false) }} >
                                                        <FullscreenOutlined className="estiloIcono" />
                                                    </div>
                                                    :
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setColapseMarca(true); setHayReferenicaAMostrar(false) }} >
                                                        <FullscreenExitOutlined className="estiloIcono" />
                                                    </div>

                                            }
                                        </MDBNavItem>

                                        <MDBNavItem>
                                            {
                                                visualizacionDescuento ?
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => setVisualizacionDescuento(false)} >
                                                        <EyeOutlined className="estiloIcono" />
                                                    </div>
                                                    :
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setVisualizacionDescuento(true) }} >
                                                        <EyeInvisibleOutlined className="estiloIcono" />
                                                    </div>

                                            }
                                        </MDBNavItem>

                                        <MDBNavItem>
                                            {
                                                verFiltros ?
                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => setVerFiltros(false)} >
                                                        <ControlOutlined className="estiloIcono" />
                                                    </div>
                                                    :

                                                    <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => {
                                                        setVerFiltros(true);
                                                    }}>
                                                        <ApartmentOutlined className="estiloIcono" />
                                                    </div>

                                            }
                                        </MDBNavItem>



                                        <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                                            {esConLado !== undefined ?
                                                esConLado ?
                                                    <img id={"imgDelantero" + props.tipoComponente} title={filtroLadoMontaje("V", obtenerReferencia.referencias)} src="./imagenes/delantero.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/delantero.svg) center center no-repeat' }} onClick={() => {
                                                        let estaActivo = efectoLadoDeMontaje("imgDelantero" + props.tipoComponente);
                                                        añadirLocalmenteLadoMontaje("V", estaActivo);
                                                    }}></img>
                                                    : null
                                                : null}
                                        </MDBNavItem>

                                        <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                                            {esConLado !== undefined ?
                                                esConLado ?
                                                    <img id={"imgDerecho" + props.tipoComponente} title={filtroLadoMontaje("D", obtenerReferencia.referencias)} src="./imagenes/derecha.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/derecha.svg) center center no-repeat' }} onClick={() => {
                                                        let estaActivo = efectoLadoDeMontaje("imgDerecho" + props.tipoComponente);
                                                        añadirLocalmenteLadoMontaje("D", estaActivo)
                                                    }}></img>
                                                    : null
                                                : null}
                                        </MDBNavItem>

                                        <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                                            {esConLado !== undefined ?
                                                esConLado ?
                                                    <img id={"imgIzquierdo" + props.tipoComponente} title={filtroLadoMontaje("L", obtenerReferencia.referencias)} src="./imagenes/izquierda.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/izquierda.svg) center center no-repeat' }} onClick={() => {
                                                        let estaActivo = efectoLadoDeMontaje("imgIzquierdo" + props.tipoComponente);
                                                        añadirLocalmenteLadoMontaje("L", estaActivo)
                                                    }}></img>
                                                    : null
                                                : null}
                                        </MDBNavItem>

                                        <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                                            {esConLado !== undefined ?
                                                esConLado ?
                                                    <img id={"imgTrasero" + props.tipoComponente} title={filtroLadoMontaje("H", obtenerReferencia.referencias)} src="./imagenes/trasero.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/trasero.svg) center center no-repeat' }} onClick={() => {
                                                        let estaActivo = efectoLadoDeMontaje("imgTrasero" + props.tipoComponente);
                                                        añadirLocalmenteLadoMontaje("H", estaActivo)
                                                    }}></img>
                                                    : null
                                                : null}
                                        </MDBNavItem>

                                    </MDBNav>
                                </div>
                            </div>

                            <div className="flex-item-right" style={{ textAlign: 'right', padding: '0px', flex: '90%' }}>
                                {Object.keys(pagination).length > 0 ?
                                    <Pagination style={{ marginTop: '5px' }}
                                        showSizeChanger
                                        onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}
                                        total={pagination.numeroDePaginas * pagination.numeroRegistroPagina}
                                        showTotal={() => ` ${(pagination.numeroTotalRegistro + 1) + " " + traducirPagina("Referencias").toLowerCase()} `}
                                        current={pagination.paginaAMostrar + 1}
                                        defaultPageSize={40}
                                        onChange={(page, pageSize) => { actualizarReferenciasEnPantalla(page, pageSize) }}
                                    > </Pagination>
                                    : null
                                }
                            </div>
                        </div>
                        :
                        null
                    }

                    <div style={props.tipoComponente === "graficos" || props.tipoComponente === "referenciaMarca" || props.tipoComponente === "equivalenciaArticulo" || props.tipoComponente === undefined ? { height: (configApp.height - 158) + "px", overflow: 'auto', overflowX: 'hidden' } : {}}>
                        <Col span={verFiltros ? 7 : 0} style={{ backgroundColor: '#fff', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }}>
                            {verFiltros ?
                                <div className="mdb-skin">
                                    <div className="flex-container ">
                                        <div className="flex-item-left" style={{ textAlign: 'left', padding: '5px', flex: '45%' }}>
                                            <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                                                <div className="grid-item">
                                                    <input id="buscadorFiltroMarcaGE" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_marcas")} style={{ backgroundColor: "#eee", width: '135px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { aplicarFiltroPorMarca(e); efectoBotonRemoveLocal("buscadorFiltroMarcaGE", "closeFiltroMarcaGE", "MARCAS") }} />
                                                </div>

                                                <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                    <MDBBtn id="closeFiltroMarcaGE" onClick={e => { createRipple(e); efectoBotonRemoveLocal("", "closeFiltroMarcaGE", "MARCAS") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                </div>

                                                <div className="grid-item barraLateral" ></div>

                                                <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                    <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                </div>
                                            </div>
                                        </div>

                                        {/* onClick={(e) => {store.dispatch(verFiltroPorMarca(filtrosReferencia.verTodasLasMarcas ? false:true))}}  */}
                                        <div className="flex-item-right" style={{ padding: '5px', flex: '10%' }}>
                                            <button onClick={(e) => {
                                                setCheckedListMarcas([])
                                                efectoBotonRemoveLocal("", "closeFiltroMarcaGE", "MARCAS")
                                                actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, [], checkedListGrupos, esConLado)
                                            }} className="activebtnMenu2 btnEstiloFiltro" style={{ width: '80px', height: '40px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Todas")}</span> </button>
                                        </div>
                                    </div>

                                    <Checkbox.Group value={checkedListMarcas} style={{ width: '100%', height: (configApp.height / 3).toString() + "px", overflow: 'auto', overflowX: 'hidden' }} onChange={(e) => { actulizarMarcasSeleccionadas(e);  }}>
                                        {
                                            todasLasMarcas.map((row, i) =>
                                                <div key={i}>
                                                    <Checkbox value={row} className="pl-2" >{row}</Checkbox>
                                                    <br></br>
                                                </div>
                                            )
                                        }
                                    </Checkbox.Group>

                                    <div className="flex-container">
                                        <div className="flex-item-left" style={{ textAlign: 'left', padding: '5px', flex: '45%' }}>

                                            <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                                                <div className="grid-item">
                                                    <input id="buscadorFiltroGrupoGE" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_grupos")} style={{ backgroundColor: "#eee", width: '135px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { aplicarFiltroPorGrupo(e); efectoBotonRemoveLocal("buscadorFiltroGrupoGE", "closeFiltroGrupoGE", "GRUPOS") }} />
                                                </div>

                                                <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                    <MDBBtn id="closeFiltroGrupoGE" onClick={e => { createRipple(e); efectoBotonRemoveLocal("", "closeFiltroGrupoGE", "GRUPOS") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                </div>

                                                <div className="grid-item barraLateral" ></div>

                                                <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                    <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-item-right" style={{ padding: '5px', flex: '10%' }}>
                                            <button onClick={(e) => {
                                                setCheckedListGrupos([])
                                                efectoBotonRemoveLocal("", "closeFiltroGrupoGE", "GRUPOS")
                                                actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, checkedListMarcas, [], esConLado)
                                            }} className="activebtnMenu2 btnEstiloFiltro" style={{ width: '80px', height: '40px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }} >{traducirPagina("Todas")}</span> </button>
                                        </div>
                                    </div>

                                    <Checkbox.Group value={checkedListGrupos} style={{ width: '100%', height: (configApp.height / 3).toString() + "px", overflow: 'auto', overflowX: 'hidden' }} onChange={(arrayGrupoMontaje) => { actulizarGruposSeleccionadas(arrayGrupoMontaje); /*mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia);*/ }}>
                                        {
                                            todosLosGrupos.map((row, i) =>
                                                <div key={i}>
                                                    <Checkbox value={row} className=" pl-2">{row}</Checkbox>
                                                    <br></br>
                                                </div>
                                            )
                                        }
                                    </Checkbox.Group>
                                </div>
                                :
                                null
                            }
                        </Col>
                        <Col span={verFiltros ? 17 : 24} style={{ paddingLeft: '10px' }}>
                            <div className="containerReferencia">
                                {obtenerReferencia === null ?
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
                                    :
                                    Object.keys(obtenerReferencia).length > 0 ?
                                        listaDeReferenciasDeLaPaginaActual.map((marca, i) =>
                                            colapseMarca ?
                                                <div className="containerReferenciaItem" key={i}>
                                                    <p className="marcaArticuloTitulo" style={{ backgroundColor: colorCabeceraPorElPrecioDeLaReferencia(marca), color: colorCabeceraFuente(marca) }}>{marca.nombreMarca}</p>
                                                    {marca.referencias.map((item, i) =>
                                                        <div key={"titulo" + i}>
                                                            <div className="row" style={{ padding: '10px', color: item.REFERENCIA_PROVEEDOR === "" ? '#999' : '#000' }}>
                                                                <MDBCol md="2">
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                                        store.dispatch(DrawerMasInformacion(true));
                                                                        store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, NUMBERTAG: "3" }));
                                                                    }}>
                                                                        <img id={"IMGE" + item.CODIGO_MARCA + item.REFERENCIA} src={""} alt={item.CODIGO_MARCA + item.REFERENCIA} onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top' }} />
                                                                        <div id={"ESTADOGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                        </div>
                                                                    </div>
                                                                </MDBCol>

                                                                <MDBCol md="7">
                                                                    <div className="nombreArticulo">
                                                                        <strong>
                                                                            <span id={"referenciaGECopiarAPortapapel" + item.CODIGO_MARCA + item.REFERENCIA} className="refComercial" onClick={(e) => {
                                                                                limpiarGrupoDrawer();
                                                                                limpiarCocheStore();

                                                                                setTimeout(() => {
                                                                                    document.getElementById("buscadorReferencia").value = item.REFERENCIA
                                                                                    store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: item.REFERENCIA });
                                                                                    buscarReferenciaYMostrarEnPantalla(item.REFERENCIA)
                                                                                    efectoBotonRemove(item.REFERENCIA, "closeBuscadorReferencia")
                                                                                }, 500)

                                                                            }}> {item.REFERENCIA + " "} </span>

                                                                            <CopyOutlined onClick={(e) => { copiarAlPortapapeles("referenciaGECopiarAPortapapel" + item.CODIGO_MARCA + item.REFERENCIA) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />

                                                                            <span>{item.NOMBRE_GRUPO_MONTAJE}</span>
                                                                        </strong>
                                                                        <br />
                                                                        {traducirPagina("Referencia_proveedor") + ": "}
                                                                        <span id={"referenciaProveedorGECopiarAPortapapel" + item.CODIGO_MARCA + item.REFERENCIA}>{item.REFERENCIA_PROVEEDOR}</span>
                                                                        {item.REFERENCIA_PROVEEDOR !== "" ? <CopyOutlined onClick={(e) => { copiarAlPortapapeles("referenciaProveedorGECopiarAPortapapel" + item.CODIGO_MARCA + item.REFERENCIA) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} /> : null}

                                                                        <br />
                                                                        {"EAN: "}
                                                                        <span id={"EANGE" + item.CODIGO_MARCA + item.REFERENCIA} className="eanNumber"> </span>
                                                                        <CopyOutlined id={"EANIono" + item.CODIGO_MARCA + item.REFERENCIA} onClick={(e) => { copiarAlPortapapeles("EANGE" + item.CODIGO_MARCA + item.REFERENCIA) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />

                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                    <div className="atributosMarca" id={"PROPIEDADESGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                    </div>

                                                                    <div id={"ContainerRefGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                        <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                            if (e.target.title !== "labelRefComercial") {
                                                                                document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                                store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                                buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                                efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                            }

                                                                        }} id={"RefComGE" + item.CODIGO_MARCA + item.REFERENCIA} className={"RefComGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                        </span>
                                                                    </div>

                                                                    <div id={"ContainerSustituyeGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                        <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                            if (e.target.title !== "labelSustituyeA") {
                                                                                document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                                store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                                buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                                efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                            }
                                                                        }
                                                                        } id={"SustituyeGE" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                        </span>
                                                                    </div>

                                                                    <div id={"ContainerSustPorGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                        <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                            if (e.target.title !== "labelSustituyePor") {
                                                                                document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                                store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                                buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                                efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                            }
                                                                        }
                                                                        } id={"SustPorGE" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                        </span>
                                                                    </div>

                                                                    <div id={"ContainerMasInfoGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                    </div>
                                                                </MDBCol>

                                                                <MDBCol md="3" style={{ textAlign: 'right' }}>

                                                                    <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {

                                                                        actulizarEstadoTiempoReparacio(item);
                                                                    }}>
                                                                        <SelectOutlined style={{ fontSize: '20px' }} />
                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Seleccionar")}</span>
                                                                    </button>

                                                                    {/*
                                                                   
                                                                    <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                               

                                                                    }}>
                                                                        <ShoppingOutlined style={{ fontSize: '20px' }} />
                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>Comprar</span>
                                                                    </button>

  */}
                                                                    {/*
                                                                    <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                        crearLineasParaElPresupuesto(item, "A");
                                                                    }}>
                                                                        <FileAddOutlined style={{ fontSize: '20px' }} />
                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{" Añadir a presupuesto"}</span>
                                                                    </button>
 */}


                                                                    <button

                                                                        onClick={(e) => {
                                                                            store.dispatch(DrawerStockAlmacen(true));
                                                                            referenciaActualSelect({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REFERENCIA_PROVEEDOR: item.REFERENCIA_PROVEEDOR, REGISTROS: item });

                                                                        }}
                                                                        className="activebtnMenu2 col-md-12"
                                                                        style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red', backgroundColor: 'transparent', display: verficarSiEsCatalogo(usuario) ? 'none' : '', marginBottom: '5px' }}
                                                                    >
                                                                        {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                            <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'sub' }} />
                                                                            : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'baseline' }} />}
                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                                                            {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ? traducirPagina("No_disponible") : traducirPagina("Stock") + item.STOCK_ALMACEN + "/" + item.STOCK_TOTAL}
                                                                        </span>
                                                                    </button>


                                                                    {item.REFERENCIA_PROVEEDOR !== "" ?
                                                                        <div>
                                                                            <div id={"CT_PVP" + item.CODIGO_MARCA + item.REFERENCIA} className="skeleton-box" style={{ height: '25px', width: '100%' }} >{traducirPagina("PVP").toUpperCase() + ": " + convertirMoneda(item.PRECIO, " €")} </div>

                                                                            <div id={"CT_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA} className="skeleton-box" style={{ height: '25px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>

                                                                            <div>
                                                                                <span id={"CT_DTO" + item.CODIGO_MARCA + item.REFERENCIA} className="skeleton-box" style={{ height: '25px', display: visualizacionDescuento ? '' : 'none' }} >{traducirPagina("Dto") + ": " + item.DESCUENTO + " %"} </span>
                                                                                <br />
                                                                                <span id={"CT_NETO" + item.CODIGO_MARCA + item.REFERENCIA} className="skeleton-box" style={{ height: '25px', display: visualizacionDescuento ? '' : 'none' }} >{traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}</span>
                                                                                <br />
                                                                            </div>


                                                                        </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    <button className="activebtnMenu2 col-md-12 btnEstiloPersonalizado" onClick={(e) => {

                                                                        store.dispatch(DrawerMasInformacion(true))

                                                                        store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }))


                                                                    }} style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: 'transparent', marginBottom: '5px' }}><EyeOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }} >{traducirPagina("Mas_informacion")}</span> </button>


                                                                    {codmotorizacion !== "" ?
                                                                        <div>
                                                                            <button className="activebtnMenu2" onClick={() => {
                                                                                store.dispatch(DrawerMasInformacion(true))
                                                                                store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, VERTIEMPOS: true, GRUPO_MONTAJE: item.GRUPO_MONTAJE }))
                                                                            }}
                                                                                style={{ height: '40px', width: '200px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', marginBottom: '5px' }}><ClockCircleOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Tiempos_de_sustitucion")}</span>
                                                                            </button>
                                                                        </div>
                                                                        : null}

                                                                </MDBCol>
                                                            </div>
                                                            {marca.referencias.length > 1 ? (marca.referencias.length - 1) === i ? null : <hr></hr> : null}
                                                        </div>
                                                    )}
                                                </div>
                                                :
                                                <div className="containerReferenciaItem" key={i}>
                                                    <p className="marcaArticuloTitulo" style={{ backgroundColor: colorCabeceraPorElPrecioDeLaReferencia(marca), color: colorCabeceraFuente(marca) }}>{marca.nombreMarca}</p>
                                                    {marca.referencias !== undefined ?
                                                        marca.referencias.map((item, i) =>
                                                            <div key={i}>
                                                                <MDBTable responsive style={{ marginBottom: 0, cursor: 'pointer' }}>
                                                                    <MDBTableBody>
                                                                        <tr className="rowReferencia" style={{ color: item.REFERENCIA_PROVEEDOR !== "" ? '#000' : '#999' }}>
                                                                            <td className="columnaIcono">
                                                                                <button onClick={(e) => {
                                                                                    store.dispatch(DrawerFichaMasInformacion2(true));
                                                                                    store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }));
                                                                                }} className="activebtnMenu2" style={{ border: '0', fontSize: '20px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'transparent' }}><FileImageOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} /> <span style={{ fontSize: '18px' }}></span> </button>
                                                                            </td>

                                                                            <td style={{ width: '590px' }} onClick={(e) => {
                                                                                store.dispatch(DrawerFichaMasInformacion2(true));
                                                                                store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }));
                                                                            }}>
                                                                                <strong>
                                                                                    {item.REFERENCIA}
                                                                                    <span>{" " + item.NOMBRE_GRUPO_MONTAJE}</span>
                                                                                </strong>
                                                                            </td>

                                                                            <td onClick={(e) => {
                                                                                store.dispatch(DrawerFichaMasInformacion2(true));
                                                                                store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }))
                                                                            }}
                                                                                style={{ width: '180px' }}>
                                                                                <div id={"TD_GE_PRECIO" + item.CODIGO_MARCA + item.REFERENCIA} className="skeleton-box" style={{ height: '20px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>
                                                                            </td>

                                                                            <td onClick={(e) => {
                                                                                store.dispatch(DrawerFichaMasInformacion2(true));
                                                                                store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }))

                                                                            }} style={{ width: '120px' }}>
                                                                                <div id={"TD_GE_DTO" + item.CODIGO_MARCA + item.REFERENCIA} className="skeleton-box" style={{ height: '20px' }} >{traducirPagina("Dto") + ": " + item.DESCUENTO + " %"} </div>
                                                                            </td>

                                                                            <td onClick={(e) => {
                                                                                store.dispatch(DrawerFichaMasInformacion2(true));
                                                                                store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }))

                                                                            }} style={{ width: '170px' }}>
                                                                                <div id={"TD_GE_NETO" + item.CODIGO_MARCA + item.REFERENCIA} className="skeleton-box" style={{ height: '20px' }} >{traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}</div>
                                                                            </td>

                                                                            <td className="columnaIcono" style={{ display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                                store.dispatch(DrawerStockAlmacen(true));
                                                                                referenciaActualSelect({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REFERENCIA_PROVEEDOR: item.REFERENCIA_PROVEEDOR, REGISTROS: item });

                                                                            }}>
                                                                                <button className="activebtnMenu2" style={{ border: '0', fontSize: '20px', color: item.REFERENCIA_PROVEEDOR !== "" ? item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red' : '#999', backgroundColor: 'transparent' }} >
                                                                                    {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                                        <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} />
                                                                                        : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} />}
                                                                                    <span style={{ fontSize: '18px' }}></span>
                                                                                </button>
                                                                            </td>

                                                                            <td className="columnaIcono" style={{ display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                                createRipple(e);
                                                                                store.dispatch(DrawerCarroCompra(true));
                                                                                referenciaActualSelect({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REFERENCIA_PROVEEDOR: item.REFERENCIA_PROVEEDOR, TITULO: traducirPagina("Editar_referencia_a_la_cesta").toUpperCase(), REGISTROS: item });
                                                                            }}>
                                                                                <button className="activebtnMenu2" style={{ border: '0', fontSize: '20px', color: '#000', backgroundColor: 'transparent' }}>
                                                                                    <ShoppingOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} /> <span style={{ fontSize: '18px' }}></span>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </MDBTableBody>
                                                                </MDBTable>
                                                            </div>
                                                        )
                                                        :
                                                        null
                                                    }
                                                </div>
                                        )
                                        :
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                }
                            </div>
                        </Col>
                    </div>
                </Content>
            </div>
        </Drawer>
    )
}

FichaGMYVehiculoHaynesPro.propTypes = {
    modificarArticulo: PropTypes.func,
    listaTiempoReparacion: PropTypes.array,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    activeItemOuterTabs: PropTypes.string,
    grupoMontaje: PropTypes.string,
    vehiculoId: PropTypes.string,
    componentePadre: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        datosGraficos: state.datosGraficos,
        configApp: state.configApp,

        props: props,
        openDrawerFichaGMYVehiculoHaynesPro: state.estadoDrawer.openDrawerFichaGMYVehiculoHaynesPro,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor,
        buscadorTree: state.buscadorTree,
        nodoGraficoSeleccionado: state.nodoGraficoSeleccionado,
        codmotorizacion: state.codmotorizacion,


        openDrawerReferenciaGrafico: state.estadoDrawer.openDrawerReferenciaGrafico,
        openDrawerFichaReferenciaVehiculo: state.estadoDrawer.openDrawerFichaReferenciaVehiculo,
        openDrawerFichaEquivalenciaArticulo: state.estadoDrawer.openDrawerFichaEquivalenciaArticulo,
        openDrawerMasInfo: state.estadoDrawer.openDrawerMasInfo,
        openDrawerMasInfo2: state.estadoDrawer.openDrawerMasInfo2,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor,
        grupoDeMarcas: state.grupoDeMarcas,

        buscadorTree: state.buscadorTree,
        nodoGraficoSeleccionado: state.nodoGraficoSeleccionado,
        codmotorizacion: state.codmotorizacion,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        DIRECTORIO_DOCUMENTO: state.DIRECTORIO_DOCUMENTO


    };
}

export default connect(mapStateToProps)(FichaGMYVehiculoHaynesPro);