import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { buscarDetallesReferencia, getStockDesglose, setCestaLineasEnAPI, ObtenerArticuloERPEnAPI } from "../../action/actionCreatorsRequests"
import { traducirPagina, MSGOK, MSGERROR, obtenerDatosUsuarioYLicenciaActual } from '../../librerias/funciones'
import { MDBBtn } from 'mdbreact';
import { Drawer } from 'antd';
import store from "../../store";
import IdentificadorCesta from './compra/IdentificadorCesta.jsx';
import TableCesta from './compra/TableCesta.jsx';
import HeaderCesta from './compra/HeaderCesta.jsx';


const FichaOfertaCesta = ({ props, DIRECTORIO_DOCUMENTO }) => {
    const {
        CODIGO_MARCA,
        REFERENCIA,
        REFERENCIA_PROVEEDOR,
        CLIENTE,
        NOMBRE_GRUPO_MONTAJE,
        NOMRE_MARCA,
        CODIGO_MARCA_ERP,
        DESCUENTO,
        EAN,
        ALMACEN,
        CANTIDAD,
        CANTIDAD_SELECCIONADA,
        CANTIDAD_MINIMA = 0,
        TIENE_OFERTA
    } = props.DATOS_REFERENCIA


    const initArticulosERP = {
        CODIGO_MARCA: CODIGO_MARCA,
        CODIGO_MARCA_ERP: "",
        DESCUENTO_1: 0,
        DESCUENTO_2: 0,
        DESCUENTO_3: 0,
        EAN: "",
        GRUPO_MONTAJE: 0,
        IMAGEN_MARCA: "",
        LADO_MONTAJE: "",
        MARCA_PATROCINADA: false,
        NOMBRE_GRUPO_MONTAJE: "",
        NOMRE_MARCA: "",
        PRECIO: 0,
        DESCUENTO: 0,
        NETO: 0,
        REFERENCIA: REFERENCIA,
        REFERENCIA_PROVEEDOR: "",
        STOCK_ALMACEN: 0,
        STOCK_TOTAL: 0,
        MENSAJE_OFERTA: ""
    }
    const [datosReferencia, setDatosReferencia] = React.useState({ ...initArticulosERP });
    const [esperandoDesglose, setesperandoDesglose] = React.useState(true);
    const [detalles, setDetalles] = React.useState({});
    const [desglose, setDesglose] = React.useState([]);
    const [PVP, setPVP] = React.useState(0);
    const [NETO, setNETO] = React.useState(0);
    const [textoIdentificador, setTextoIdentificador] = React.useState("");


    const ObtenerArticuloERP = async (CANTIDAD_SELECCIONADA) => {
        setDatosReferencia({ ...initArticulosERP });
        setPVP(initArticulosERP.PRECIO)
        setNETO(initArticulosERP.NETO)


        if (CLIENTE === undefined) {
            let cliente = store.getState().clienteSeleccionado;
            props.DATOS_REFERENCIA.CLIENTE = cliente !== undefined && cliente !== null && Object.keys(cliente).length > 0 && cliente.codigo !== "" ? cliente.codigo : ""
        }
        

        const registros = await ObtenerArticuloERPEnAPI(false, REFERENCIA_PROVEEDOR, "", props.DATOS_REFERENCIA.CLIENTE,CANTIDAD_SELECCIONADA,NOMRE_MARCA)
        if (registros !== undefined && registros !== null && registros.length > 0) {
            let referencia = registros[0];

            if (referencia !== undefined) {
                datosReferencia.CODIGO_MARCA = referencia.Codigo.MarcaTecdoc
                datosReferencia.CODIGO_MARCA_ERP = referencia.Codigo.marcaERPCodigo

                datosReferencia.NOMBRE_GRUPO_MONTAJE = NOMBRE_GRUPO_MONTAJE === undefined ? "" : NOMBRE_GRUPO_MONTAJE
                datosReferencia.NOMRE_MARCA = NOMRE_MARCA === undefined ? referencia.Codigo.MarcaERPDescripcion : NOMRE_MARCA
                datosReferencia.PRECIO = referencia.precio
                datosReferencia.DESCUENTO = referencia.descuento
                datosReferencia.NETO = referencia.precioNeto
                //Verificar si el socio envia bien la referencia
                datosReferencia.REFERENCIA = referencia.Codigo.ReferenciaTecdoc
                datosReferencia.REFERENCIA_PROVEEDOR = referencia.Codigo.CodigoERP
                datosReferencia.STOCK_ALMACEN = 0
                datosReferencia.STOCK_TOTAL = 39
                datosReferencia.MENSAJE_OFERTA = referencia.MensajeOferta

            }
        }


        setDatosReferencia({ ...datosReferencia });
        setPVP(datosReferencia.PRECIO)
        setNETO(datosReferencia.NETO)
    }




    const obtenerDesgloseStock = async () => {
        if (REFERENCIA_PROVEEDOR === "") {
            setesperandoDesglose(false)
        } else {
            let registrosStock = await getStockDesglose(REFERENCIA, CODIGO_MARCA, REFERENCIA_PROVEEDOR,NOMRE_MARCA)

            if (registrosStock !== undefined && registrosStock !== null && registrosStock.length > 0 && registrosStock.filter((registros) => registros.Stock > 0).length > 0) {
                //inicializar lista de stock a 0
                registrosStock.map((item, i) => { item.CANTIDAD = 0; item.CANTIDAD_MINIMA = 0; return item; })

                if (props.ES_CESTA_COMPRA) {
                    const posicionParaCantidad = registrosStock.findIndex((registro) => registro.CodigoAlmacen === ALMACEN)
                    if (posicionParaCantidad !== -1) {
                        registrosStock[posicionParaCantidad].CANTIDAD = CANTIDAD
                        registrosStock = registrosStock.filter((registro) => registro.CANTIDAD > 0);
                    }

                } else {
                    let TOTAL_CANTIDAD_SELECCIONADA = CANTIDAD_SELECCIONADA;

                    //se buscar el primer registro que cumpla la condicón para añadirle la cantidad minima
                    const posicionParaCantidadMinima = registrosStock.findIndex((registro) => registro.CodigoAlmacen !== null && registro.CodigoAlmacen !== "" && registro.Stock > 0)

                    registrosStock.map((item, i) => {
                        if (item.CodigoAlmacen !== null && item.CodigoAlmacen !== "" && item.Stock > 0) {
                            let result = TOTAL_CANTIDAD_SELECCIONADA - item.Stock

                            if (result < 0) {
                                item.CANTIDAD = TOTAL_CANTIDAD_SELECCIONADA;
                                TOTAL_CANTIDAD_SELECCIONADA = 0;
                            } else {
                                TOTAL_CANTIDAD_SELECCIONADA = TOTAL_CANTIDAD_SELECCIONADA - item.Stock
                                item.CANTIDAD = item.Stock;
                            }

                            if (i === posicionParaCantidadMinima) { item.CANTIDAD_MINIMA = CANTIDAD_MINIMA }
                        }
                    })
                }

                setesperandoDesglose(true);
                setDesglose([...registrosStock]);

            } else {
                setesperandoDesglose(false)
            }

        }
    }

    const obtenerDetallesReferencia = async () => {
        const registros = await buscarDetallesReferencia({ REFERENCIA: REFERENCIA, CODIGO_MARCA: CODIGO_MARCA })
        if (registros !== undefined) {
            setDetalles(registros)
        } else {
            setDetalles({})
        }
    }

    const añadirALaCesta = () => {
        let resultaCesta = []
        if (desglose !== null && desglose !== undefined) {

            const DESCRIPCION = datosReferencia.NOMRE_MARCA === undefined ? props.DATOS_REFERENCIA.REGISTROS.DESCRIPCION : "[ " + datosReferencia.NOMRE_MARCA.toUpperCase() + " ] - " + datosReferencia.NOMBRE_GRUPO_MONTAJE.toUpperCase();
            desglose.map((registro) => {
                if (registro.CANTIDAD > 0) {
                    setCestaLineasEnAPI(
                        datosReferencia.REFERENCIA_PROVEEDOR,
                        datosReferencia.CODIGO_MARCA_ERP,
                        datosReferencia.REFERENCIA,
                        datosReferencia.CODIGO_MARCA,
                        DESCRIPCION,
                        registro.CANTIDAD,//Cantidad
                        0,//texto libre
                        datosReferencia.PRECIO,
                        datosReferencia.DESCUENTO,

                        datosReferencia.NETO,
                        //calcularPrecioNeto(registro.CANTIDAD, datosReferencia.PRECIO, datosReferencia.DESCUENTO),
                        registro.CodigoAlmacen,//ALMACEN
                        registro.DescripcionAlmacen,//DESCRIPCION ALMACEN
                        props.ES_CESTA_COMPRA ? 1 : 0,//ACCION
                        props.DATOS_REFERENCIA.IDCESTA,
                        props.ES_CESTA_COMPRA ? props.DATOS_REFERENCIA.CLIENTE : "",

                        props.DATOS_REFERENCIA.VEHICULO,
                        textoIdentificador //IDENTIFICADOR

                    ).then(data => {
                        if (data.OK) {

                            resultaCesta.push(data)
                        }
                    })
                }
            })

            let estadoCesta = resultaCesta.filter((resultado) => resultado.OK === false)
            if (estadoCesta.length === 0) {
                if (props.ES_CESTA_COMPRA) {
                    setTimeout(() => {
                        props.CARGAR_CESTA()
                    }, 1000)
                }

                props.CERRAR_FICHA(false)
                MSGOK(traducirPagina("Referencia_agregada"));

            } else {
                MSGOK(JSON.stringify(estadoCesta));
            }


        } else {
            MSGERROR(traducirPagina("msg_stock_suficiente"));
        }


    }


    const insertarConsula = (datosReferencia) => {
        let almacen = obtenerDatosUsuarioYLicenciaActual();

        //sa agrego la referencia de proveedor.
        setCestaLineasEnAPI(
            datosReferencia.REFERENCIA_PROVEEDOR,
            "",
            "CONSULTA",
            "",
            `[ ${datosReferencia.NOMRE_MARCA.toUpperCase()} ] - ( ${datosReferencia.REFERENCIA} ) ${datosReferencia.NOMBRE_GRUPO_MONTAJE.toUpperCase()}`,
            1,
            1,
            datosReferencia.PRECIO,
            datosReferencia.DESCUENTO,
            datosReferencia.NETO,
            almacen.ALMACEN,
            "",
            props.ES_CESTA_COMPRA ? 1 : 0,
            "",
            "",
            props.DATOS_REFERENCIA.VEHICULO,

        ).then(data => {
            if (data.OK) {
                MSGOK("Consulta añadida.", "botton-left");
                props.CERRAR_FICHA(false)
            }
        })
    }

    

    const inputCantidad = (item, cantidadPorUusario = "", cantidadMinima = 0) => {
        if (item.CANTIDAD !== null) {
            let valor = cantidadPorUusario.trim();

            if (valor === "") {
                item.CANTIDAD = valor;
            } else {
                const verificarNumero = parseInt(valor)
                let resultado = item.Stock > verificarNumero ? verificarNumero : item.Stock;

                item.CANTIDAD = resultado
            }
            setDesglose([...desglose])
        }
    }

    const inputCantidadBlur = (item, cantidadMinima = 0) => {
        let valor = item.CANTIDAD;

        if (valor === "") {
            item.CANTIDAD = cantidadMinima
        } else {
            const verificarNumero = parseInt(valor)
            let resultado = item.Stock > verificarNumero ? verificarNumero : item.Stock;

            item.CANTIDAD = (verificarNumero <= cantidadMinima) ? cantidadMinima : resultado

        }
        setDesglose([...desglose])
    }

    const gestionarCantidad = (item, cantidadMinima = 0, esNegativo) => {
        if (item.CANTIDAD !== null) {
            let valor = item.CANTIDAD

            if (esNegativo) {

                if (valor) {
                    if (parseInt(valor) <= cantidadMinima) {
                        item.CANTIDAD = cantidadMinima;
                    } else {
                        item.CANTIDAD = parseInt(valor) - 1;
                    }
                } else {
                    item.CANTIDAD = cantidadMinima;
                }
            } else {
                const NUEVA_CANTIDAD = item.Stock > parseInt(valor) ? parseInt(valor) + 1 : parseInt(valor);
                item.CANTIDAD = NUEVA_CANTIDAD
            }
            setDesglose([...desglose])
        }
    }






    useEffect(() => {
        if (REFERENCIA_PROVEEDOR === "") {
            if (props.DATOS_REFERENCIA !== undefined && props.DATOS_REFERENCIA !== null && Object.keys(props.DATOS_REFERENCIA).length > 0) {
                datosReferencia.CODIGO_MARCA = CODIGO_MARCA
                datosReferencia.CODIGO_MARCA_ERP = CODIGO_MARCA_ERP
                datosReferencia.DESCUENTO = DESCUENTO
                datosReferencia.EAN = EAN
                datosReferencia.NOMBRE_GRUPO_MONTAJE = NOMBRE_GRUPO_MONTAJE
                datosReferencia.NOMRE_MARCA = NOMRE_MARCA
                datosReferencia.REFERENCIA = REFERENCIA
                datosReferencia.REFERENCIA_PROVEEDOR = REFERENCIA_PROVEEDOR
                setDatosReferencia({ ...datosReferencia });
                setPVP(datosReferencia.PRECIO)
                setNETO(datosReferencia.NETO)
            }
        } else {
            ObtenerArticuloERP(CANTIDAD_SELECCIONADA)
        }


        obtenerDesgloseStock()
        obtenerDetallesReferencia()



        return () => {
            setesperandoDesglose(true);
            setDesglose([]);
            setDatosReferencia({ ...initArticulosERP })
        }

    }, [])


    return (
        <Drawer
            width={680}
            onClose={() => {
                props.CERRAR_FICHA(false);
            }}
            closable={false}

            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999999999999', color: '#fff' }}>
            <div>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            props.CERRAR_FICHA(false);
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="containerArticuloGenerico" style={{ overflow: 'auto' }}>
                    <HeaderCesta
                        detalles={detalles}
                        directorio={DIRECTORIO_DOCUMENTO}
                        codigoMarca={CODIGO_MARCA}
                        datosReferencia={datosReferencia}
                        esperandoDesglose={esperandoDesglose}
                        referencia={REFERENCIA}
                        pvp={PVP}
                        neto={NETO}
                    />

                    {
                        TIENE_OFERTA && (
                            <div className="ContainerDetalles" style={{ height: '150px', padding: '0px', display: 'block' }}>

                                <div className='titulo_adicional_oferta'>
                                    {`${traducirPagina("Texto_Oferta_Articulo")}`}
                                </div>

                                <div className='descripcion_adicional_oferta'>{`${datosReferencia.MENSAJE_OFERTA}`}</div>
                            </div>
                        )
                    }

                    {props.ES_CESTA_COMPRA && (
                        <IdentificadorCesta
                            identificador={textoIdentificador}
                            eventIdentificador={(e) => {
                                setTextoIdentificador(e.target.value)
                            }}
                        />
                    )}



                    <TableCesta
                        esCestaCompra={props.ES_CESTA_COMPRA}
                        desglose={desglose}
                        setDesglose={setDesglose}
                        esperandoDesglose={esperandoDesglose}
                        añadirALaCesta={añadirALaCesta}
                        eventInsertarConsulta={() => { insertarConsula(datosReferencia) }}
                        event_input={(item,textoInput,cantidadMinima)=>{inputCantidad(item,textoInput,cantidadMinima)}}
                        inputCantidadBlur={(item,cantidadMinima)=>{inputCantidadBlur(item,cantidadMinima)}}
                        gestionarCantidad={(item,cantidadMinima,esMenos)=>{gestionarCantidad(item,cantidadMinima,esMenos)}}
                        alturaTabla={TIENE_OFERTA ? 390:props.ES_CESTA_COMPRA ? 370 : 220}

                    />

                </div>

            </div>
        </Drawer>
    )
}

FichaOfertaCesta.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    DATOS_REFERENCIA: PropTypes.object,
    ES_CESTA_COMPRA: PropTypes.bool,
    CARGAR_CESTA: PropTypes.func,

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        DIRECTORIO_DOCUMENTO: state.DIRECTORIO_DOCUMENTO,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        codmotorizacion: state.codmotorizacion

    };
}

export default connect(mapStateToProps)(FichaOfertaCesta);