import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Tabs, Col, Layout, Pagination, Card, Select, Row, Tree, Popover } from 'antd';
import { ObtenerDetalleGraficoEnAPI, ObtenerListaMapaGraficosEnAPI, getPricePageOEMEnAPI } from "../action/actionCreatorsRequests";
import {
    DrawerFichaEquivalenciaArticulo
} from "../action/actionCreators";

import VisorDocumentos from '../components/VisorDocumentos'
import {
    traducirPagina, datosLincenciaSertec, MSGOK, verificarSiEsMostrador,
    convertirMoneda,
    hayVehiculoSelecionado,

} from "../librerias/funciones"

import store from '../store'
import {
    ShoppingOutlined,
    EyeOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { FichaDisponibilidad } from './Compra/FIchaDisponibilidad';
import history from '../history';


const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { DirectoryTree } = Tree;
const { Meta } = Card;


const FichaMasInfoYQ = ({ props, configApp, codmotorizacion, buscadorCoche,HISTORIAL_VEHICULO }) => {
    const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
    const { ID_SOCIO } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { ID_SOCIO: 0 } : OBTENER_DATOS_USUARIO;


    const [abrirFichaDisponibilidad, setAbrirFichaDisponibilidad] = React.useState(false);
    const [disponibilidadSeleccionada, setDisponibilidadSeleccionada] = React.useState({
        REFERENCIA: "",
        MCR: "",
        MARCA_VEH: ""
    });


    const [vehiculoActual, setVehiculoActual] = React.useState({
        EXISTE_VEHICULO: false,
        VEHICULO: {}
    });


    const [hayReferencias, setHayReferencias] = React.useState(false);
    const [detalleGrafico, setDetalleGrafico] = React.useState([]);
    const [coordenadasGrafico, setCoordenadasGrafico] = React.useState([]);
    const [numImagenSeleccionada, setNumImagenSeleccionada] = React.useState("");
    const [abrirVisor, setAbrirVisor] = React.useState(false);
    const [documentoSeleccionado, setDocumentoSeleccionado] = React.useState({});

    const [permitirComprar, setPermitirComprar] = React.useState(false);
    const { NOMBRE_FABRICANTE, EUCON_ID, ID_FABRICANTE = "" } = Object.keys(props.MARCA_SELECCIONADA).length > 0 ? props.MARCA_SELECCIONADA : props.PROPIEDADES_COCHE

    const existeVehiculo = Object.keys(buscadorCoche).length > 0






    const buscarCoordenadasGraficos = () => {
        setCoordenadasGrafico([])
        ObtenerListaMapaGraficosEnAPI(props.GRAFICO_SELECCIONADO.ID_UNIDAD, props.PROPIEDADES_COCHE.MARCA, props.GRAFICO_SELECCIONADO.SSD).then(registros => {
            if (registros !== undefined) {
                if (registros.RESULTADOS !== null && registros.RESULTADOS.length > 0) {
                    setCoordenadasGrafico(registros.RESULTADOS)

                } else {
                    setCoordenadasGrafico(null)
                }
            }
        })
    }

    const pintarRecuadroImagen = (coordenadas) => {
        let img = document.getElementById("grafico");

        let scala = 0;
        let resultado = {
            marginTop: '',
            marginLeft: '',
            height: '',
            width: '',
            color: '#000',
        }

        if (img !== null) {
            // console.log("prop", img)
            scala = img.width / img.naturalWidth

            resultado.marginTop = (coordenadas.Y1 * scala) + "px";
            resultado.marginLeft = (coordenadas.X1 * scala) + "px";
            resultado.width = (coordenadas.X2 * scala - coordenadas.X1 * scala) + "px";
            resultado.height = (coordenadas.Y2 * scala - coordenadas.Y1 * scala) + "px";
        }
        return resultado;
    }


    const ObtenerDetalleGrafico = async (ID_UNIDAD, MARCA, SSD) => {
        const registros = await ObtenerDetalleGraficoEnAPI(ID_UNIDAD, MARCA, SSD)

        if (registros !== undefined && registros.DETALLE_UNIDAD !== null && registros.DETALLE_UNIDAD.length > 0) {
            const registrosPrecios = registros.DETALLE_UNIDAD.map((registro) => { return { PRECIO: null, ...registro } })
            setDetalleGrafico(registrosPrecios)
            setPermitirComprar(registros.PERMITIR_COMPRAR)

        } else {
            setDetalleGrafico(null)
            setPermitirComprar(false)
        }


        getPricePageOEM(registros.DETALLE_UNIDAD);

    }

    const getPricePageOEM = async (detallesUnidad) => {
        let listaArticulos = detallesUnidad.map((registro) => { return { EuconId: EUCON_ID, NOM_FABRICANTE: NOMBRE_FABRICANTE, PartNo: registro.OEM } })


        let nuevoListadoConPrecios = []
        const registros = await getPricePageOEMEnAPI(listaArticulos)
        if (registros !== undefined && registros.ListaPreciosOEM !== null && registros.ListaPreciosOEM.length > 0) {
            nuevoListadoConPrecios = detallesUnidad.map((item) => {
                let referenciaPrecio = registros.ListaPreciosOEM.find((registro) => registro.PartNo === item.OEM)
                let nuevoRegistro = { PRECIO: referenciaPrecio === undefined ? 0 : referenciaPrecio.Price, ...item }
                return nuevoRegistro
            })
        } else {
            nuevoListadoConPrecios = detallesUnidad.map((item) => item.PRECIO = `${convertirMoneda(0, "€")}`)
        }

        setDetalleGrafico([...nuevoListadoConPrecios])
    }

    const gestionarIDTipoVehiculo = (registroHistorial) =>{
        const {TIPO_VEHICULO} = registroHistorial;
        let idTipo = 0;
        if(TIPO_VEHICULO === undefined){
            //Historial local

        }else{
            //Historial seleccionado desde OEM
            idTipo = TIPO_VEHICULO
        }
    }



    if (!hayReferencias && props.ABRIR_FICHA) {
        setHayReferencias(true);
        //console.log("props", props.PROPIEDADES_COCHE, "selecc", props.GRAFICO_SELECCIONADO)
        // console.log("list", props.LISTA_GRAFICOS)

        ObtenerDetalleGrafico(props.GRAFICO_SELECCIONADO.ID_UNIDAD, props.PROPIEDADES_COCHE.MARCA, props.GRAFICO_SELECCIONADO.SSD)
        buscarCoordenadasGraficos();

    } else {
        if (!props.ABRIR_FICHA && hayReferencias) {
            setDetalleGrafico([])
            setHayReferencias(false);

        }
    }



    React.useEffect(() => {

        let VEHICULO = store.getState().VEHICULO_OEM;

        if (VEHICULO !== undefined && VEHICULO !== null && VEHICULO.BASTIDOR !== undefined && VEHICULO.BASTIDOR !== null && VEHICULO.BASTIDOR !== "") {
            let VEHICULO_ACTUAL = { MATRICULA: "", BASTIDOR: VEHICULO.BASTIDOR, MARCA_VEHICULO: VEHICULO.MARCA, MODELO_VEHICULO: VEHICULO.MODELO, MOTOR_VEHICULO: "", VEHICULO_TECDOC: { ID: 0 } }
            vehiculoActual.EXISTE_VEHICULO = true;
            vehiculoActual.VEHICULO = VEHICULO_ACTUAL
            setVehiculoActual({ ...vehiculoActual })
        } else {
            vehiculoActual.EXISTE_VEHICULO = false;
            vehiculoActual.VEHICULO = {}

            setVehiculoActual({ ...vehiculoActual })
        }


    }, [])

    console.log("ID_FABRICANTE", ID_FABRICANTE,"HISTORIAL_VEHICULO",HISTORIAL_VEHICULO)


    return (
        <div>
            <Drawer
                width={"80%"}
                onClose={() => {
                    props.CERRAR_FICHA(false)
                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            props.CERRAR_FICHA(false)
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {Object.keys(props.GRAFICO_SELECCIONADO).length > 0 ? props.GRAFICO_SELECCIONADO.NOMBRE.toUpperCase() : ""}
                    </div>
                </div>



                <Content>
                    {Object.keys(props.GRAFICO_SELECCIONADO).length ?
                        <div>
                            <Col span={10} style={{ backgroundColor: '#fff', height: (configApp.height - 65) + "px", overflow: 'auto' }}>

                                <img id="grafico" onClick={(e) => {
                                    setAbrirVisor(true);
                                    setDocumentoSeleccionado(
                                        {
                                            TITULO: Object.keys(props.GRAFICO_SELECCIONADO).length > 0 ? props.GRAFICO_SELECCIONADO.NOMBRE.toUpperCase() : "",
                                            URL: props.TAB_SELECCIONADO === "1" ? props.GRAFICO_SELECCIONADO.IMAGEN_URL : props.GRAFICO_SELECCIONADO.IMAGEN_LARGE
                                        }
                                    )

                                }}
                                    src={props.TAB_SELECCIONADO === "1" ? props.GRAFICO_SELECCIONADO.IMAGEN_URL : props.GRAFICO_SELECCIONADO.IMAGEN_LARGE}
                                    style={{ width: '580px' }} />


                                {coordenadasGrafico === null ?
                                    null
                                    :
                                    coordenadasGrafico.length > 0 ?
                                        coordenadasGrafico.map((registro, i) =>
                                            <a
                                                key={i}
                                                className={`piezaImagenDocOE ${registro.CODIGO === numImagenSeleccionada ? 'piezaImagenHover' : ''}`
                                                }
                                                style={pintarRecuadroImagen(registro)}
                                                onMouseOver={(e) => {
                                                    /*
                                                      setNumImagenSeleccionada(registro.CODIGO)
                                                      history.push(`/referencia#${registro.CODIGO}`)
                                                   */
                                                }}
                                                onMouseLeave={(e) => {
                                                    setNumImagenSeleccionada("")
                                                    history.push(`/referencia`)
                                                }}
                                                href={`/referencia#${registro.CODIGO}`}
                                                onClick={() => {
                                                    setNumImagenSeleccionada(registro.CODIGO)
                                                }}
                                            >

                                            </a>
                                        )
                                        :
                                        null
                                }

                            </Col>

                            <Col span={14} style={{ height: (configApp.height - 65) + "px", overflow: 'auto', overflowX: 'hidden', backgroundColor: '#f7f7f7', padding: '20px' }}>

                                {detalleGrafico === null ?
                                    <h2>No hay resultados para el vehículo seleccionado </h2>
                                    :
                                    detalleGrafico.length > 0 ?
                                        <table style={{ width: '100%', color: '#000' }} >
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '30px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }} > {traducirPagina("Num")}</th>
                                                    <th style={{ width: '150px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'left', fontSize: '16px' }} onClick={(e) => { }}> {traducirPagina("Referencia")}</th>
                                                    <th style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'left', fontSize: '16px' }} onClick={(e) => { }}> {traducirPagina("Nombre")}</th>
                                                    <th style={{ width: '100px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }}> {traducirPagina("Precio")}</th>


                                                    {permitirComprar && vehiculoActual.EXISTE_VEHICULO ? <th style={{ width: '90px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }}>{traducirPagina("Comprar")}</th> : null}

                                                    <th style={{ width: '200px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }} > { }</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detalleGrafico.map((registro, i) =>
                                                    <tr
                                                        id={registro.CODIGO_EN_IMAGEN}
                                                        style={{ height: '40px' }}
                                                        key={i}
                                                        className={`${registro.CODIGO_EN_IMAGEN === numImagenSeleccionada ? 'piezaTablaHover' : ""}`}


                                                        onMouseOver={(e) => {
                                                            setNumImagenSeleccionada(registro.CODIGO_EN_IMAGEN)
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            setNumImagenSeleccionada("")
                                                        }}
                                                    >
                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} >
                                                            {registro.CODIGO_EN_IMAGEN}
                                                        </td>

                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'left', fontSize: '16px' }}>
                                                            {registro.OEM}
                                                        </td>
                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'left', fontSize: '16px' }}>
                                                            {registro.NOMBRE}
                                                        </td>
                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'right', fontSize: '16px', color: [undefined, null].includes(registro.PRECIO) ? "#000" : registro.PRECIO.toString().includes("0.00") ? "#999" : "" }}>
                                                            {registro.PRECIO === null ? <span className="skeleton-box" style={{ width: '70px' }}></span> : registro.PRECIO}
                                                        </td>

                                                        {
                                                            permitirComprar && vehiculoActual.EXISTE_VEHICULO ?
                                                                <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={() => {

                                                                    disponibilidadSeleccionada.REFERENCIA = registro.OEM
                                                                    disponibilidadSeleccionada.MCR = props.PROPIEDADES_COCHE.MCR_ID
                                                                    disponibilidadSeleccionada.MARCA_VEH = registro.NOMBRE
                                                                    setDisponibilidadSeleccionada({ ...disponibilidadSeleccionada })

                                                                    setAbrirFichaDisponibilidad(true)

                                                                }}>{<ShoppingOutlined style={{ fontSize: '20px', color: '#007bff', }}></ShoppingOutlined>}</td>
                                                                : null}


                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }}>

                                                            <div className="flex-container-Grow-Simple">

                                                                <div style={{ cursor: 'pointer', color: '#007bff', flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <Popover placement="topLeft" title={traducirPagina("Mas_info")} content={
                                                                        <div>
                                                                            {registro.ATRIBUTOS.map((registro) =>
                                                                                <p>{`${registro.CLAVE}: ${registro.VALOR}`}</p>
                                                                            )}

                                                                        </div>}>
                                                                        {<span style={{ verticalAlign: 'text-bottom', fontSize: '14px' }}><EyeOutlined style={{ fontSize: '20px', marginRight: '10px' }} /></span>}
                                                                    </Popover>
                                                                </div>


                                                                <div style={{ cursor: 'pointer', color: '#007bff', flexGrow: '3', textAlign: 'left', margin: '0px 2px' }} onClick={(e) => {
                                                                    if (registro.OEM !== "") {

                                                                        store.dispatch({ type: "STATE_UPDATE_EQUIVALENCIA_ARTICULO_SELECCIONADO", marca: "", referencia: registro.OEM, artno: "", vehiculoTecdoc:existeVehiculo ? codmotorizacion:"", idFabricante:existeVehiculo?"": ID_FABRICANTE,tipoVehiculo:HISTORIAL_VEHICULO?.TIPO_VEHICULO });
                                                                        store.dispatch(DrawerFichaEquivalenciaArticulo(true));
                                                                    }
                                                                }}> {<span style={{ verticalAlign: 'middle', fontSize: '14px' }}><SearchOutlined style={{ fontSize: '20px', marginRight: '10px' }} />{traducirPagina("Buscar_referencia")}</span>}
                                                                </div>

                                                            </div>

                                                        </td>

                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        :
                                        null
                                }
                            </Col>
                        </div>
                        :
                        null
                    }
                </Content>



                {
                    abrirVisor ?
                        <VisorDocumentos
                            title={traducirPagina("Visor_de_imagen").toUpperCase()}
                            ABRIR_VISOR={abrirVisor}
                            CERRAR_VISOR={setAbrirVisor}
                            TIPO_DOCUMENTO={"IMG"}
                            PROPIEDADES_DOC={documentoSeleccionado}
                            ZOOM_DOCUMENTO={true}
                        ></VisorDocumentos>
                        :
                        null
                }


            </Drawer>



            {
                abrirFichaDisponibilidad &&
                <FichaDisponibilidad
                    title={traducirPagina("Comprar").toUpperCase()}
                    ABRIR_FICHA={abrirFichaDisponibilidad}
                    CERRAR_FICHA={setAbrirFichaDisponibilidad}
                    REFERENCIA={disponibilidadSeleccionada.REFERENCIA}
                    MCR_ID={disponibilidadSeleccionada.MCR}
                    VEHICULO={vehiculoActual.VEHICULO}

                    MARCA_VEH={disponibilidadSeleccionada.MARCA_VEH}

                >
                </FichaDisponibilidad>
            }



        </div>

    )
}

FichaMasInfoYQ.propTypes = {

    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    GRAFICO_SELECCIONADO: PropTypes.object,
    LIMPIAR_COCHE: PropTypes.func,
    TAB_SELECCIONADO: PropTypes.string,
    PROPIEDADES_COCHE: PropTypes.object,
    LISTA_GRAFICOS: PropTypes.object,
    MARCA_SELECCIONADA: PropTypes.object

};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado,
        listaTree: state.listaTree,
        buscadorCoche: state.buscadorCoche

    };
}

export default connect(mapStateToProps)(FichaMasInfoYQ);