import React from "react";
import PropTypes from 'prop-types';
import {
    MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBCol
} from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import { connect } from 'react-redux';
import store from "../store";
import { setHistoricApp, showItemCar, estadoAgrupacionDeMarcas, showChildrenDrawerLevel2, showItemReferencia, mostrarVistaGrafica, gestionarContenedorCoche, updateVisualizacionFiltro } from "../action/actionCreators";
import { getIdTecdocMotorByIdHaynes } from "../action/actionCreatorsRequests";
import { getFocus, traducirPagina, limpiarCocheStore, efectoRemoveBuscadorReferencia, recortarCadena } from "../librerias/funciones"
import {
    CloseOutlined,
    SearchOutlined
} from '@ant-design/icons';
import CPSearch from "./utils/CPSearch.jsx";

const { useState, useRef } = React;
const ContainerMotor = ({ configApp, props, childrenDrawerLevel2, BusquedaPorModelo, fichaBusquedaVehiculo }) => {
    const [textoMotor, setTextoMotor] = React.useState("");
    const [fichaAbierta, setFichaAbierta] = React.useState(false);
    const [motoresAux, setMotoresAux] = React.useState([]);

    if (props.MOTORES !== null) {
        if (!fichaAbierta && childrenDrawerLevel2 && props.MOTORES.length > 0) {
            setFichaAbierta(true);
            setMotoresAux(props.MOTORES)

        } else {
            if (!childrenDrawerLevel2 && fichaAbierta) {
                setFichaAbierta(false);

            }
        }
    }

    const buscarEnTabla = (palabra, listaCliente) => {
        let registrosEncontrados = [];
        if (palabra !== "") {
            if (listaCliente !== null) {
                listaCliente.find(function (registro, i) {

                    if (BusquedaPorModelo.tipoBusqueda === "M") {

                        if (registro.ID_MOTOR.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID_MOTOR === registro.ID_MOTOR)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (registro.CILINDROS.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID_MOTOR === registro.ID_MOTOR)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (registro.CILINDRADA.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID_MOTOR === registro.ID_MOTOR)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (registro.CV.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID_MOTOR === registro.ID_MOTOR)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (registro.KW.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID_MOTOR === registro.ID_MOTOR)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }
                    } else {
                        if (registro.CARGA_MAXIMA.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (registro.DESCRIPCION.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (registro.ESTILO.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                        if (registro.TIPO_FRENO.toString().toLowerCase().includes(palabra.toLowerCase())) {
                            let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                            if (existeRegistro === undefined) {
                                registrosEncontrados.push(registro)
                            }
                        }

                    }
                })
            }
            if (registrosEncontrados.length > 0) {
                props.MODIFICAR_MOTORES(registrosEncontrados);
            } else {
                props.MODIFICAR_MOTORES(null);
            }
        } else {
            props.MODIFICAR_MOTORES(motoresAux);
        }
    }

    return (
        <div>

            <CPSearch
                className='mdb-skin searchVehiculo'
                TEXTO_BUSCADOR={textoMotor}
                placeholder={["M","I","T"].includes(BusquedaPorModelo.tipoBusqueda)? traducirPagina("Busqueda_por_motor"):traducirPagina("Busqueda_por_eje")}
                onChangeInput={(e) => {
                    buscarEnTabla(e.target.value, props.MOTORES);
                    setTextoMotor(e.target.value)
                }}
                onCloseInput={() => {
                    setTextoMotor(""); props.MODIFICAR_MOTORES(motoresAux);
                }}

            />


{/* 
            <div className="mdb-skin">
                <div className="mt-3 pb-2">
                    <div className="grid-container">
                        <div className="grid-item">
                            <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={BusquedaPorModelo.tipoBusqueda === "M" || BusquedaPorModelo.tipoBusqueda === "I" || BusquedaPorModelo.tipoBusqueda === "T" ? traducirPagina("Busqueda_por_motor") : traducirPagina("Busqueda_por_eje")} style={{ backgroundColor: "#eee", width: '500px', border: '0' }} autoComplete="off" onKeyPress={(e) => { }} onChange={(e) => { buscarEnTabla(e.target.value, props.MOTORES); setTextoMotor(e.target.value) }} value={textoMotor} />
                        </div>

                        <div className="grid-item">
                            <button onClick={e => { setTextoMotor(""); props.MODIFICAR_MOTORES(motoresAux); }} className="btn-matricula  estiloBotonRemove" style={{ visibility: textoMotor.length > 0 ? 'visible' : 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </button>
                        </div>

                        <div className="grid-item barraLateral" ></div>

                        <div className="grid-item">
                            <button onClick={e => { }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                        </div>
                    </div>
                </div>
            </div>
*/}


            {props.MOTORES === null ?
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("msgMotor")}</h3> </div>
                :
                props.MOTORES.length > 0 ?
                    BusquedaPorModelo.tipoBusqueda === "M" ?
                    <div className="container_grid_coche">

                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>{traducirPagina("Cod_motor")}</th>
                                    <th>{traducirPagina("Cilindros")}</th>
                                    <th>{traducirPagina("Cilindrada")}</th>
                                    <th>{"CV"}</th>
                                    <th>{"KV"}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {props.MOTORES.map((row, i) =>
                                    <tr key={row.ID_MOTOR} id={row.ID_MOTOR} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={(e) => {
                                        efectoRemoveBuscadorReferencia("buscadorReferencia", true);
                                        limpiarCocheStore()
                                        store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                                        store.dispatch(showChildrenDrawerLevel2(false));
                                        store.dispatch(getIdTecdocMotorByIdHaynes(row.ID_MOTOR, row));
                                        store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE })

                                        store.dispatch(showItemReferencia("tree"));
                                        store.dispatch(setHistoricApp(row, "/motorizacion", "tipomotor"));

                                        store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: {} })
                                        store.dispatch(mostrarVistaGrafica(false))
                                        store.dispatch(updateVisualizacionFiltro(true));

                                    }}>
                                        <td style={{ textAlign: 'left' }}>{row.CODIGO_MOTOR}</td>
                                        <td>{row.CILINDROS}</td>
                                        <td>{row.CILINDRADA}</td>
                                        <td>{row.CV}</td>
                                        <td>{row.KW}</td>
                                    </tr>
                                )}
                            </MDBTableBody>
                        </MDBTable>
                        </div>
                        :

                        BusquedaPorModelo.tipoBusqueda === "I" || BusquedaPorModelo.tipoBusqueda === "T" ?
                        <div className="container_grid_coche">

                            <MDBTable responsive hover>
                                <MDBTableHead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>{traducirPagina("Motor")}</th>
                                        <th style={{ textAlign: 'left' }}>{traducirPagina("Codigo_cliente")}</th>
                                        <th>{traducirPagina("Año")}</th>
                                        <th>KW</th>
                                        <th>CV</th>
                                        <th>CC</th>
                                    </tr>
                                </MDBTableHead>

                                <MDBTableBody>
                                    {props.MOTORES.map((row, i) =>
                                        <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={() => {
                                            efectoRemoveBuscadorReferencia("buscadorReferencia", true);

                                            store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                                            store.dispatch(showChildrenDrawerLevel2(false));
                                            store.dispatch(getIdTecdocMotorByIdHaynes(row.ID, row));
                                            store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE })

                                            store.dispatch(showItemReferencia("tree"));
                                            store.dispatch(setHistoricApp(row, "/motorizacion", "tipomotor"));
                                            gestionarContenedorCoche(true);
                                            store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: {} })
                                            store.dispatch(mostrarVistaGrafica(false))

                                            //store.dispatch(getFichaCoche(row.ID.toString()));
                                            store.dispatch(updateVisualizacionFiltro(true));
                                            //store.dispatch(getIdVehicleByTecdocNumber(row.ID));

                                            let marca = {
                                                ID: props.FABRICANTE.ID,
                                                DESCRIPCION: recortarCadena(props.FABRICANTE.Marca, 10, "...")
                                            }

                                            let modelo = {
                                                ID: props.MODELO_SELECCIONADO.ID,
                                                DESCRIPCION: recortarCadena(props.MODELO_SELECCIONADO.DESCRIPCION, 10, "..."),
                                            }

                                            let objetoVehiculo = {
                                                ID: row.ID,
                                                DESCRIPCION: recortarCadena(row.DESCRIPCION, 10, "...")
                                            };

                                            store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));
                                            store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
                                            store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));


                                        }}>

                                            <td style={{ textAlign: 'left' }}>{row.DESCRIPCION}</td>
                                            <td style={{ textAlign: 'left' }}>{row.MOTORES === "" ? "..." : row.MOTORES}</td>
                                            <td>{row.DESDE === "" ? "..." : row.DESDE}</td>

                                            <td>{row.KW === "" ? "..." : row.KW}</td>
                                            <td>{row.CV === "" ? "..." : row.CV}</td>
                                            <td>{row.CC === "" ? "..." : row.CC}</td>
                                        </tr>
                                    )}
                                </MDBTableBody>
                            </MDBTable>
</div>
                            :
                            <div className="container_grid_coche">

                            <MDBTable hover>
                                <MDBTableHead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>{"Carga Maxima"}</th>
                                        <th>{"Descripción"}</th>
                                        <th>{"Estilo"}</th>
                                        <th>{"Tipo de freno"}</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {props.MOTORES.map((row, i) =>
                                        <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={(e) => {
                                            efectoRemoveBuscadorReferencia("buscadorReferencia", true);

                                            limpiarCocheStore()
                                            store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                                            store.dispatch(showChildrenDrawerLevel2(false));
                                            store.dispatch(getIdTecdocMotorByIdHaynes(row.ID, row));
                                            store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE })

                                            store.dispatch(showItemReferencia("tree"));

                                            store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: {} })
                                            store.dispatch(mostrarVistaGrafica(false))
                                            store.dispatch(updateVisualizacionFiltro(true));

                                            let marca = {
                                                ID: props.FABRICANTE.ID,
                                                DESCRIPCION: props.FABRICANTE.Marca
                                            }

                                            let modelo = {
                                                ID: props.MODELO_SELECCIONADO.ID,
                                                DESCRIPCION: props.MODELO_SELECCIONADO.DESCRIPCION,
                                            }

                                            let objetoVehiculo = {
                                                ID: row.ID,
                                                DESCRIPCION: row.DESCRIPCION
                                            };

                                            store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));
                                            store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
                                            store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));



                                        }}>
                                            <td style={{ textAlign: 'left' }}>{row.CARGA_MAXIMA}</td>
                                            <td>{row.DESCRIPCION}</td>
                                            <td>{row.ESTILO}</td>
                                            <td>{row.TIPO_FRENO}</td>

                                        </tr>
                                    )}
                                </MDBTableBody>
                            </MDBTable>
                            </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>

            }
        </div>

    );
}

ContainerMotor.propTypes = {
    MOTORES: PropTypes.array,
    MODIFICAR_MOTORES: PropTypes.func,
    FABRICANTE: PropTypes.object,
    MODELO_SELECCIONADO: PropTypes.object

};


const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        childrenDrawerLevel2: state.childrenDrawerLevel2,
        BusquedaPorModelo: state.BusquedaPorModelo,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo
    };
}



export default connect(mapStateToProps)(ContainerMotor);