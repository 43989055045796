
import store from '../store'
import { getDateToday, sortFunction } from "../librerias/herramientas"
import alertify from 'alertifyjs'
import { getDataUser, getFichaCoche } from '../action/actionCreatorsRequests'
import { objetoGraficoV2, ordenarGruposMarcas, obtenerPaginas, mostrarPaginaActual, findNode, dynamicSortAsc } from '../librerias/funciones'

const removeAllEngineType = () => {
    return {
        type: "REMOVE_ALL_ENGINE",
    }
}
const removeAllModel = () => {
    return {
        type: "REMOVE_ALL_MODEL",
    }
}

const changeDimensionsApp = (width, height) => {
    return {
        type: "CHANGE_DIMENSIONS_APP",
        width,
        height
    }
}

const hideSpinnerAcademy = estado => {
    return {
        type: "SPINNNERACADEMY",
        spinnerAcademy: estado
    }
}
const hideSpinnerLoginHaynes = estado => {
    return {
        type: "SPINNNERLOGINHAYNES",
        spinnerLoginHaynes: estado
    }
}

const changeColorListItem = (id, listmodels) => {
    listmodels.aux.map((row, i) =>
        document.getElementById(row.id === undefined ? row.ID : row.id) !== null ? document.getElementById(row.id === undefined ? row.ID : row.id).style.backgroundColor = null : null
    )
    document.getElementById(id).style.backgroundColor = "rgb(188, 186, 187)";
    return {
        type: "CHANGE_COLOR_LIST",
        isColorListItem: true,
    }
}

const changeColorListItemFalse = () => {
    return {
        type: "CHANGE_COLOR_LIST",
        isColorListItem: false,
    }
}


const loadVehicleHaynes = () => {
    return {
        type: "LOAD_VEHICLE_HAYNES",
        mostrarCoche: true,
    }
}

const getHistoricApp = historicapp => {
    return {
        type: "GET_HISTORIC_APP",
        historicapp: historicapp,
    }
}


const setHistoricApp = (ruta, link, posicion) => {
    var listahistory = new Array();
    listahistory = store.getState().fichaBusquedaVehiculo.historicapp;
    var obtenerModelos = store.getState().fichaBusquedaVehiculo.models;
    //var cadenaTipoMotor = posicion === "tipomotor" ? ruta.fullName + " (" + ruta.desde + " " + ruta.hasta + ")" + " (" + ruta.Motores + ")" : typeof ruta === 'string' ? ruta : ruta.fullName;
    var cadenaTipoMotor = (posicion === "tipomotor") ? ruta.DESCRIPCION === undefined ? ruta : ruta.DESCRIPCION
        : typeof ruta === 'string' ? ruta : ruta.DESCRIPCION;

    var deleteItem = listahistory.findIndex(item => item.posicion === posicion);

    //Verificar cambio cuando se inicia sesion y solo se presiona el coche de marcas turmismo.
    var datosLinea = obtenerModelos.original === undefined ? ruta : obtenerModelos.original.find(item => item.ID === (ruta.id === undefined ? ruta : ruta.id));

    if (deleteItem !== -1) {
        listahistory[deleteItem].ruta = cadenaTipoMotor;
        listahistory[deleteItem].urlimagen = datosLinea !== undefined ? datosLinea.image : "";
        listahistory[deleteItem].id = ruta.id !== undefined ? ruta.id : ruta.ID;
    }


    var resultado = listahistory.find(item => posicion === "tipomotor" ? item.ruta === cadenaTipoMotor : item.ruta === (ruta.DESCRIPCION === undefined ? ruta : ruta.DESCRIPCION));

    if (resultado === undefined && posicion !== "marcas") {
        listahistory.push({ registros: ruta, ruta: posicion === "tipomotor" ? cadenaTipoMotor : typeof ruta === 'string' ? ruta : ruta.DESCRIPCION, link: link, posicion: posicion, urlimagen: datosLinea !== undefined ? datosLinea.image : "", id: ruta.ID !== undefined ? ruta.ID : 0 });
    } else {
        if (posicion === "tipomotor") {
            let existeId = listahistory.filter((registro) => registro.id === ruta.ID)
            if (existeId.length == 0) {
                listahistory.push({ registros: ruta, ruta: posicion === "tipomotor" ? cadenaTipoMotor : typeof ruta === 'string' ? ruta : ruta.DESCRIPCION, link: link, posicion: posicion, urlimagen: datosLinea !== undefined ? datosLinea.image : "", id: ruta.ID !== undefined ? ruta.ID : 0 });
            }
        }
    }
    return dispatch => {
        dispatch({
            type: "SET_HISTORIC_APP",
            historicapp: listahistory,
        })
    }
}



const deleteItemHistoricApp = (posicion, objeto) => {
    var listahistory = new Array(); listahistory = objeto; var continuarEliminando = false;

    //Se busca que coincida la misma posión para eliminar la siguientes últimas.


    listahistory.map(function (item, i) {
        if (posicion === "referencia") {
            delete listahistory[i];
        } else {
            if (item.posicion === posicion || continuarEliminando) {
                continuarEliminando = true;
                if (item.posicion !== posicion) {
                    delete listahistory[i];
                }
            }
        }
    })

    //Se elimina los espacios vacios del array
    var todelete = [];
    for (var i = 0; i < listahistory.length; i++) {
        if (typeof listahistory[i] == 'undefined') todelete.push(i);
    }

    todelete.sort(function (a, b) { return b - a }); // hacer los índices de grandes a pequeños
    for (var i = 0; i < todelete.length; i++) {
        listahistory.splice(todelete[i], 1);
    }

    //Se vuelve a obtener los valores por defecto porque se pierden cuando se vuelve a la raiz del historial
    store.dispatch(getLocalStorageVehicle());

    return dispatch => {
        dispatch({
            type: "DELETE_ITEM_HISTORIC_APP",
            historicapp: listahistory
        })
    }
}

const selectCoche = (lista) => {

    lista.map((item, posicion) => {
        var conv = item.id.toString();
        if (item.id > 0) {
            setTimeout(() => {
                if (document.getElementById(conv) !== null) {
                    document.getElementById(conv).style.backgroundColor = "rgb(188, 186, 187)"
                }
            }, 100)
        }
    }
    )

    return {
        type: "CHANGE_COLOR_LIST",
        isColorListItem: true,
    }
}


const saveLocalStorageReferences = (reference) => {
    var refUpper = reference.toUpperCase();
    var objetoreferenmces = [];

    if (localStorage.getItem("references") === null) {

        objetoreferenmces.push({ nomReferencia: refUpper, fecha: getDateToday() });
        localStorage.setItem('references', JSON.stringify(objetoreferenmces));
    } else {
        var getdatareferences = localStorage.getItem("references");
        objetoreferenmces = JSON.parse(getdatareferences)

        var resultado = objetoreferenmces.find(item => item.nomReferencia === refUpper);
        if (resultado === undefined) {
            objetoreferenmces.push({ nomReferencia: refUpper, fecha: getDateToday() });
        }
        localStorage.setItem('references', JSON.stringify(objetoreferenmces));

        objetoreferenmces = objetoreferenmces.sort((a, b) => b.fecha - a.fecha).reverse();
    }
    return {
        type: "SAVE_REFERENCE_TO_LOCAL",
        localreferences: objetoreferenmces
    }
}


const saveLocalStorageVehicle = (historial, models, enginesType, codmotorhaynes, identificadorHaynesPro, idtecdocmotorbyhaynes) => {
    var nombrecoche = "";
    historial.map((item, posicion) => {
        if (item.posicion === "modelo" || item.posicion === "tipomotor") {
            nombrecoche += item.ruta;
        }
    })

    var objetovehicle = [];
    if (localStorage.getItem("vehicle") === null) {
        objetovehicle.push({
            nombre: nombrecoche, historic: historial, models: models, enginesType: enginesType, codmotorhaynes: codmotorhaynes,
            identificadorHaynesPro: identificadorHaynesPro, idtecdocmotorbyhaynes: idtecdocmotorbyhaynes, fecha: getDateToday()
        });

        localStorage.setItem('vehicle', JSON.stringify(objetovehicle));
    } else {
        var getdatareferences = localStorage.getItem("vehicle");
        objetovehicle = JSON.parse(getdatareferences)

        var resultado = objetovehicle.find(item => item.nombre === nombrecoche);

        if (resultado === undefined) {
            objetovehicle.push({
                nombre: nombrecoche, historic: historial, models: models, enginesType: enginesType, codmotorhaynes: codmotorhaynes,
                identificadorHaynesPro: identificadorHaynesPro, idtecdocmotorbyhaynes: idtecdocmotorbyhaynes, fecha: getDateToday()
            });
        }
        localStorage.setItem('vehicle', JSON.stringify(objetovehicle));

        objetovehicle = objetovehicle.sort((a, b) => b.fecha - a.fecha).reverse();
    }
    return {
        type: "SAVE_VEHICLE_TO_LOCAL",
        localvehicles: objetovehicle
    }
}

const getLocalStorageReferences = () => {
    var objetoreferenmces = [];
    if (localStorage.getItem("references") !== null) {
        var getdatareferences = localStorage.getItem("references");
        objetoreferenmces = JSON.parse(getdatareferences)

        for (var i in objetoreferenmces) {
            if (objetoreferenmces[i].nomReferencia === undefined) {
                localStorage.removeItem('references');
                break;
            }
        }

        objetoreferenmces = objetoreferenmces.sort((a, b) => b.fecha - a.fecha).reverse();
    }
    return {
        type: "GET_LOCAL_REFERENCES",
        localreferences: objetoreferenmces
    }
}

const getLocalStorageVehicle = () => {
    var objetovehicle = [];
    if (localStorage.getItem("vehicle") !== null) {
        var getdatavehicle = localStorage.getItem("vehicle");
        objetovehicle = JSON.parse(getdatavehicle)

        for (var i in objetovehicle) {
            if (objetovehicle[i].fecha === undefined) {
                localStorage.removeItem('vehicle');
                break;
            }
        }
        objetovehicle = objetovehicle.sort((a, b) => b.fecha - a.fecha).reverse();
        //objetovehicle = objetovehicle.sort((a, b) => b.fecha - a.fecha)
    }

    return {
        type: "GET_LOCAL_VEHICLE",
        localvehicles: objetovehicle,
    }
}




const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some((item) => item.title.includes(key) || item.title === key)) {
                parentKey = node.key;

            } else if (getParentKey(key, node.children)) {

                parentKey = getParentKey(key, node.children);
            }
        }
    }

    return parentKey;
};



const onExpand = expandedKeys => {
    return {
        type: "FILTRO_TREE",
        buscadorTree: { expandedKeys, searchValue: "", autoExpandParent: false },
    }
};

const showModal = () => {
    return {
        type: "SHOW_MODAL",
        visiblerespuesta: true,
    }
}

const handleOkRespuesta = () => {
    return {
        type: "HANDLE_OK_MODAL",
        visiblerespuesta: false,
    }
}

const handleCancelRespuesta = () => {
    return {
        type: "HANDLE_CANCEL_MODAL",
        visiblerespuesta: false,
    }
}

const stateVisibleDrawer = (visibleDrawer) => {
    return {
        type: "HANDLE_STATE_DRAWER_BRANDS",
        visibleDrawer: visibleDrawer,
    }
}



const showChildrenDrawerLevel2 = (estado) => {
    setTimeout(() => {
        var header = document.getElementById("containerBtnModal");
        if (header !== null) {
            var btns = header.getElementsByClassName("estilosbotonesModal");
            for (var i = 0; i < btns.length; i++) {
                btns[i].addEventListener("click", function () {
                    var current = document.getElementsByClassName("activeModal");
                    current[0].className = current[0].className.replace(" activeModal", "");
                    this.className += " activeModal";
                });
            }
        }

    }, 1000)

    return {
        type: "SHOW_DRAWER_LEVEL2",
        childrenDrawerLevel2: estado,
    }
}

const onChildrenDrawerCloseLevel2 = (estado) => {
    return {
        type: "CLOSE_DRAWER_LEVEL2",
        childrenDrawerLevel2: estado,
    }
}

const showChildrenDrawerIntegraciones = (estado) => {
    return {
        type: "SHOW_DRAWER_INTEGRACIONES",
        openDrawerIntegraciones: estado,
    }
}

const onChildrenDrawerCloseIntegraciones = (estado) => {
    return {
        type: "CLOSE_DRAWER_INTEGRACIONES",
        openDrawerIntegraciones: estado,
    }
}


const DrawerHistoricoCoche = (estado) => {
    return {
        type: "STATE_DRAWER_HISTORICO_COCHE",
        openDrawerHistoricoCoche: estado,
    }
}

const DrawerCarroCompra = (estado) => {
    return {
        type: "STATE_DRAWER_CARRO_COMPRA",
        openDrawerCarroCompra: estado,
    }
}

const DrawerMasInformacion = (estado) => {
    return {
        type: "STATE_DRAWER_MAS_INFO",
        openDrawerMasInfo: estado,
    }
}
const DrawerFichaMasInformacion2 = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_MAS_INFO2",
        openDrawerMasInfo2: estado,
    }
}

const DrawerFichaEmpresa = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_EMPRESA",
        openDrawerEmpresa: estado,
    }
}

const DrawerStockAlmacen = (estado) => {
    return {
        type: "STATE_DRAWER_STOCK_ALMACEN",
        openDrawerStockAlmacen: estado,
    }
}

const DrawerFichaCliente = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_CLIENTE",
        openDrawerFichaCliente: estado,
    }
}

const DrawerFichaCocheDGT = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_COCHE_DGT",
        openDrawerFichaCocheDGT: estado,
    }
}

const DrawerFichaClienteTaller = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_CLIENTE_TALLER",
        openDrawerFichaClienteTaller: estado,
    }
}

const DrawerFichaPresupuestoTaller = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_PRESUPUESTO_TALLER",
        openDrawerFichaPresupuestoTaller: estado,
    }
}

const DrawerFichaAlbaranTaller = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_ALBARANES_TALLER",
        openDrawerFichaAlbaranesTaller: estado,
    }
}
const DrawerFichaHistorialPedido = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_HISTORIAL_PEDIDOS",
        openDrawerFichaHistorialPedidos: estado,
    }
}

const DrawerFichaProveedores = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_PROVEEDORES",
        openDrawerFichaProveedores: estado,
    }
}

const DrawerFichaOrden = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_ORDENES",
        openDrawerFichaOrdenTaller: estado,
    }
}
const DrawerFichaFactura = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_FACTURA",
        openDrawerFichaFacturaTaller: estado,
    }
}
const DrawerFichaGTEstimate = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_GT_ESTIMATE",
        openDrawerFichaGTEstimate: estado,
    }
}

const DrawerFichaDatosCliente = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_DATOS_CLIENTES",
        openDrawerFichaDatosClientes: estado,
    }
}

const DrawerFichaMarcasVehiculo = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_MARCAS_VEHICULO",
        openDrawerFichaMarcasVehiculo: estado,
    }
}


const DrawerFichaReferenciaVehiculo = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_REFERENCIAS_VEHICULO",
        openDrawerFichaReferenciaVehiculo: estado,
    }
}

const DrawerFichaBateriasVehiculo = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_BATERIAS_VEHICULO",
        openDrawerFichaBateriasVehiculo: estado,
    }
}

const DrawerFichaEquivalenciaArticulo = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_EQUIVALENCIA_ARTICULO",
        openDrawerFichaEquivalenciaArticulo: estado,
    }
}

const DrawerFichaMantenimientoPresupuesto = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_MANTENIMIENTO_PRESUPUESTO",
        openDrawerFichaMantenimientoPresupuesto: estado,
    }
}

const DrawerFichaVehiculoPresupuesto = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_VEHICULO_PRESUPUESTO",
        openDrawerFichaVehiculoCliente: estado,
    }
}

const DrawerFichaManoDeObra = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_MANO_OBRA",
        openDrawerFichaManoObra: estado,
    }
}

const DrawerFichaArticulo = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_ARTICULO",
        openDrawerFichaArticulo: estado,
    }
}

const DrawerFichaImpresionDocumento = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_IMPRESION_DOCUMENTO",
        openDrawerFichaImpresionDocumento: estado,
    }
}

const DrawerFichaEnvioDocumento = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_ENVIO_DOCUMENTO",
        openDrawerFichaEnvioDocumento: estado,
    }
}

const DrawerFichaTiemposReparacion = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_TIEMPOS_REPARACION",
        openDrawerFichaTiemposReparacion: estado,
    }
}

const DrawerFichaLubricantes = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_LUBRICANTES",
        openDrawerFichaLubricantes: estado,
    }
}

const DrawerFichaNeumaticos = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_NEUMATICOS",
        openDrawerFichaNeumaticos: estado,
    }
}

const DrawerFichaGMVehiculoHaynes = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_GM_VEHICULO_HAYNES",
        openDrawerFichaGMYVehiculoHaynesPro: estado,
    }
}

const DrawerFichaMantenimientoHaynes = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_MANTENIMIENTO_HAYNES",
        openDrawerFichaMantenimientoHaynesPro: estado,
    }
}

const DrawerFichaPublicidad = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_PUBLICIDAD",
        openDrawerFichaPublicidad: estado,
    }
}

const DrawerFichaMantenimientoClienteTaller = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_MANTENIMIENTO_CLIENTE",
        openDrawerFichaMantenimientoCliente: estado,
    }
}

const DrawerFichaMantenimientoVehiculoTaller = (estado) => {
    return {
        type: "STATE_DRAWER_FICHA_MANTENIMIENTO_VEHICULO",
        openDrawerFichaMantenimientoVehiculo: estado,
    }
}



const DrawerHaynesPro = (estado) => {
    return {
        type: "STATE_DRAWER_HAYNES_PRO",
        openDrawerHaynesPro: estado,
    }
}

const DrawerIntranet = (estado) => {
    return {
        type: "STATE_DRAWER_INTRANET",
        openDrawerIntranet: estado,
    }
}

const DrawerNextAssist = (estado) => {
    return {
        type: "STATE_DRAWER_NEXT_ASSIST",
        openDrawerNextAssist: estado,
    }
}

const DrawerAcademyCloud = (estado) => {
    return {
        type: "STATE_DRAWER_ACADEMY_CLOUD",
        openDrawerAcademyCloud: estado,
    }
}


const DrawerPerfilUsuario = (estado) => {
    return {
        type: "STATE_DRAWER_PERFIL_USUARIO",
        openDrawerPerfilUsuario: estado,
    }
}

const DrawerReferenciaGrafico = (estado) => {
    return {
        type: "STATE_DRAWER_REFERENCIA_GRAFICO",
        openDrawerReferenciaGrafico: estado,
    }
}
const DrawerReferenciaGraficoCarroceria = (estado) => {
    return {
        type: "STATE_DRAWER_REFERENCIA_GRAFICO_CARROCERIA",
        openDrawerReferenciaGraficoCarroceria: estado,
    }
}

const showChildrenDrawerLevel3 = (estado) => {
    return {
        type: "SHOW_DRAWER_LEVEL3",
        childrenDrawerLevel3: estado,
    }
}

const onChildrenDrawerCloseLevel3 = (estado) => {
    return {
        type: "CLOSE_DRAWER_LEVEL3",
        childrenDrawerLevel3: estado,
    }
}


const changeStateImageViewer = (mostrarComponente, propiedades) => {
    return {
        type: "HANDLE_STATE_VIEWE_IMAGE",
        mostrarComponente: mostrarComponente,
        propiedadesVisor: propiedades
    }
}

const updateStateHistoric = (historicapp) => {
    return {
        type: "UPDATE_HISTORIC_APP",
        historic: historicapp.historic,
        models: historicapp.models,
        enginesType: historicapp.enginesType,

        codmotorhaynes: historicapp.codmotorhaynes,
        identificadorHaynesPro: historicapp.identificadorHaynesPro,
        idtecdocmotorbyhaynes: historicapp.idtecdocmotorbyhaynes,

    }
}


const updateStateMasterPage = (estado) => {
    return {
        type: "HANDLE_STATE_MASTER_PAGE",
        showmasterpage: estado
    }
}
const activeTabApp = (tab) => {
    return {
        type: "HANDLE_STATE_TAB",
        activetab: tab
    }
}


const checkuserlogin = (user, password) => {
    if (user === "" || password === "") {
        alertify.set('notifier', 'position', 'top-right');
        alertify.warning('Falta usuario y contraseña')
    } else {
        getDataUser(user, password)
    }
}

const generateList = (data) => {
    var listaTemp = [];

    if (data !== null) {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const { key } = node;
            listaTemp.push({ key, title: data[i].title.trim() });
            if (node.children) {
                generateList(node.children);
            }
        }
    }

    return listaTemp
}






const filterList = (palabra, lista, clave, tipoDispacth) => {
    let listaAux = new Array();
    listaAux.splice(0, listaAux.length)
    if (palabra !== "") {
        lista.aux.find(function (item, i) {
            /*
            let tempObjeto = "";
            for(var i =0; i < ejemplo.length; i ++){
                let condicion = (i === (ejemplo.length-1))? "" :   " || ";

                tempObjeto += ejemplo[i]+".includes('" + palabra.toUpperCase() + "')" + condicion;
            }

            console.log("creando objeto",tempObjeto)
            let palabr = eval(tempObjeto);
            console.log("creando pal",palabr)
            */


            /*
            for (var i = 0; i < ejemplo.length; i++) {
                let palabraClave = eval(ejemplo[i]).trim().toUpperCase();

                if (palabraClave.includes(palabra.toUpperCase())) {
                    listaAux.push(item)
                }
            }
            */


            let palabraClave = eval(clave).trim().toUpperCase();


            if (palabraClave.includes(palabra.toUpperCase())) {
                listaAux.push(item)
            }
        });
    }
    return {
        type: tipoDispacth,
        objetoGenerico: { original: listaAux.length > 0 || palabra.length > 0 ? listaAux : lista.aux, aux: lista.aux }

    }
}


const showAllBrands = (estado) => {
    return {
        type: "SHOW_ALL_BRANDS",
        mostarTodasLasMarcas: estado,
    }
}

const showItemCar = (activeItemCar) => {
    return {
        type: "STATE_ACTIVEITEM_BRANDS",
        activeItemCar: activeItemCar,
    }
}


const showItemReferencia = (activeItemReferencia) => {
    return {
        type: "STATE_ACTIVEITEM_REFERENCIA",
        activeItemReferencia: activeItemReferencia,
    }
}


const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
    } catch (err) {
        // errors
    }
}


const createRipple = (event) => {
    const button = event.currentTarget;


    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
        ripple.remove();
    }
    button.appendChild(circle);
}

const cambioNombreBusscadorCoche = (event) => {
    //nombreDrop
    let etiqueta = document.getElementById("busquedaVehiculo");
    if (etiqueta !== undefined) {
        let att = document.createAttribute("title");
        att.value = event.textContent.trim().toUpperCase();
        etiqueta.setAttributeNode(att);
    }

    return {
        type: "STATE_SELECCION_DROP", NAME_Drop: event.innerText, IDENTIFICADOR: event.id
    }
}


const cambioImagenPais = (REGISTRO_BANDERA) => {
    const { ID, DESCRIPCION, URL_IMAGEN } = REGISTRO_BANDERA;

    let etiqueta = document.getElementById("paisVehiculo");
    if (etiqueta !== undefined) {
        let att = document.createAttribute("title");
        att.value = DESCRIPCION;
        etiqueta.setAttributeNode(att);
    }

    return {
        type: "REPLACE_BANDERA_PAIS",
        banderaImagen: URL_IMAGEN,
        codPaisIdioma: ID === "SUI" ? "CH" : ID.charAt(0)
    }


}

const estadoAgrupacionDeMarcas = (lista) => {
    return {
        type: "STATE_BRANDS_GROUPING",
        grupoDeMarcas: lista,
    }
}

const actualizarAgrupacionDeMarcas = (lista) => {
    return {
        type: "UPDATE_BRANDS_GROUPING",
        marcas: lista,
    }
}

const changeCocheModal = (link) => {
    return {
        type: "STATE_ACTIVEITEM_BRANDS",
        activeItemCar: link === "/marcas" ? "1" : link === "/modelos" ? "2" : "3",
    }
}

const efectoBotonRemove = (e, id) => {
    let activarEfecto = e.trim() === "" ? false : true;
    if (activarEfecto) {
        document.getElementById(id).style.visibility = "visible";
    } else {
        document.getElementById(id).style.visibility = "hidden";

    }
}



const gestionarCambiosInput = (nombretxtBuscador, idCoche, e) => {
    let obtenerBuscadores = store.getState().buscadorCoche;
    let resultado = {};

    if (Object.keys(obtenerBuscadores).length > 0) {


        if (eval("obtenerBuscadores." + nombretxtBuscador) === undefined) {

            obtenerBuscadores[nombretxtBuscador] = e
            obtenerBuscadores["actual"] = { key: idCoche, value: e }
            resultado = obtenerBuscadores;

        } else {
            //  let cadenaConv = obtenerBuscadores+"."+ nombretxtBuscador;
            // let convertirObjeto = eval(cadenaConv);
            // let param = eval(nombretxtBuscador);
            obtenerBuscadores[nombretxtBuscador] = e
            //   convertirObjeto  = e;
            obtenerBuscadores.actual = { key: idCoche, value: e };
            resultado = obtenerBuscadores;
        }
    } else {
        resultado = { nombretxtBuscador: e, "actual": { key: idCoche, value: e } };
    }
    return resultado
}


const changeStateInputCoche = (e, id) => {
    let resultado = {};
    let obtenerBuscadores = store.getState().buscadorCoche;

    if (e === "") {
        if (document.getElementById("imput" + id).value !== null) {
            document.getElementById("imput" + id).value = "";
        }
    }

    switch (id) {
        case "dropcoche":
            // resultado = gestionarCambiosInput("txtBuscadorCoche","dropcoche",e);
            if (Object.keys(obtenerBuscadores).length > 0) {
                if (obtenerBuscadores.txtBuscadorCoche === undefined) {
                    obtenerBuscadores["txtBuscadorCoche"] = e
                    obtenerBuscadores["actual"] = { key: "dropcoche", value: e }
                    resultado = obtenerBuscadores;
                } else {
                    obtenerBuscadores.txtBuscadorCoche = e;
                    obtenerBuscadores.actual = { key: "dropcoche", value: e };
                    resultado = obtenerBuscadores;
                }

            } else {
                resultado = { "txtBuscadorCoche": e, "actual": { key: "dropcoche", value: e } };
            }

            break;
        case "dropcliente":
            //resultado = gestionarCambiosInput("txtBuscadorCliente","dropcliente",e);

            if (Object.keys(obtenerBuscadores).length > 0) {
                if (obtenerBuscadores.txtBuscadorCliente === undefined) {
                    obtenerBuscadores["txtBuscadorCliente"] = e
                    obtenerBuscadores["actual"] = { key: "dropcliente", value: e }
                    resultado = obtenerBuscadores;

                } else {
                    obtenerBuscadores.txtBuscadorCliente = e;
                    obtenerBuscadores.actual = { key: "dropcliente", value: e };
                    resultado = obtenerBuscadores;
                }
            } else {
                resultado = { "txtBuscadorCliente": e, "actual": { key: "dropcliente", value: e } };
            }
            break;
        case "dropmarcas":
            // resultado = gestionarCambiosInput("txtBuscadorCoche","dropcoche",e);
            let allbManufactur = store.getState().fichaBusquedaVehiculo.allManufactur;
            store.dispatch(filterList(e, allbManufactur, 'item.Term', "REPLACE_BRANDS"))

            if (Object.keys(obtenerBuscadores).length > 0) {
                if (obtenerBuscadores.txtBuscadorMarca === undefined) {
                    obtenerBuscadores["txtBuscadorMarca"] = e
                    obtenerBuscadores["actual"] = { key: "dropmarcas", value: e }
                    resultado = obtenerBuscadores;
                } else {
                    obtenerBuscadores.txtBuscadorMarca = e;
                    obtenerBuscadores.actual = { key: "dropmarcas", value: e };
                    resultado = obtenerBuscadores;
                }

            } else {
                resultado = { "txtBuscadorMarca": e, "actual": { key: "dropmarcas", value: e } };
            }

            break;
        case "dropmodelos":
            let models = store.getState().fichaBusquedaVehiculo.models;

            store.dispatch(filterList(e, models, 'item.DESCRIPCION', "GET_MODEL_VEHICLE"))

            if (Object.keys(obtenerBuscadores).length > 0) {
                if (obtenerBuscadores.txtBuscadorModelos === undefined) {
                    obtenerBuscadores["txtBuscadorModelos"] = e
                    obtenerBuscadores["actual"] = { key: "dropmodelos", value: e }
                    resultado = obtenerBuscadores;

                } else {
                    obtenerBuscadores.txtBuscadorModelos = e;
                    obtenerBuscadores.actual = { key: "dropmodelos", value: e };
                    resultado = obtenerBuscadores;
                }
            } else {
                resultado = { "txtBuscadorModelos": e, "actual": { key: "dropmodelos", value: e } };
            }
            break;
        case "dropmotorizacion":
            let enginesType = store.getState().fichaBusquedaVehiculo.enginesType;
            store.dispatch(filterList(e, enginesType, 'item.DESCRIPCION', "GET_ENGINE_TYPE"))

            if (Object.keys(obtenerBuscadores).length > 0) {
                if (obtenerBuscadores.txtBuscadorMotorizacion === undefined) {
                    obtenerBuscadores["txtBuscadorMotorizacion"] = e
                    obtenerBuscadores["actual"] = { key: "dropmotorizacion", value: e }
                    resultado = obtenerBuscadores;

                } else {
                    obtenerBuscadores.txtBuscadorMotorizacion = e;
                    obtenerBuscadores.actual = { key: "dropmotorizacion", value: e };
                    resultado = obtenerBuscadores;
                }
            } else {
                resultado = { "txtBuscadorMotorizacion": e, "actual": { key: "dropmotorizacion", value: e } };
            }
            break;
        case "droptree":
            //filtroTree(e)
            //filtroTree(e);
            if (Object.keys(obtenerBuscadores).length > 0) {
                if (obtenerBuscadores.txtBuscadorTree === undefined) {
                    obtenerBuscadores["txtBuscadorTree"] = e
                    obtenerBuscadores["actual"] = { key: "droptree", value: e }
                    resultado = obtenerBuscadores;

                } else {
                    obtenerBuscadores.txtBuscadorTree = e;
                    obtenerBuscadores.actual = { key: "droptree", value: e };
                    resultado = obtenerBuscadores;
                }
            } else {
                resultado = { "txtBuscadorTree": e, "actual": { key: "droptree", value: e } };
            }
            break;
    }

    return {
        type: "STATE_SEARCH_COCHE",
        buscadorCoche: resultado,
    }
}

const gestionarContenedorCoche = (estado) => {

    if (document.getElementById("contenedordropcoche") !== null) {
        document.getElementById("contenedordropcoche").style.display = estado ? 'none' : '';
    }

    if (document.getElementById("btnBuscarPorModelo") !== null) {
        document.getElementById("btnBuscarPorModelo").style.display = estado ? 'none' : '';
    }

    if (document.getElementById("contenedorHistorialCoche") !== null) {
        document.getElementById("contenedorHistorialCoche").style.display = estado ? '' : 'none';
    }

}


const updateColapseMarca = (estado) => {
    return {
        type: "STATE_COLAPSE_MARCA",
        colapseMarca: estado,
    }
}

const updateVisualizacionDescuento = (estado) => {
    return {
        type: "STATE_COLAPSE_DESCUENTO",
        visualizacionDescuento: estado,
    }
}

const updateVisualizacionFiltro = (estado) => {
    return {
        type: "STATE_COLAPSE_FILTRO",
        visualizacionFiltro: estado,
    }
}


const updateRegistroPagination = (size) => {
    return {
        type: "UPADETE_REGISTRO_PAGINA",
        numeroRegistroPagina: size,
    }
}

const updateGruposMontajeSeleccionados = (lista) => {
    return {
        type: "UPDATE_GRUPO_MONTAJE_SELECCIONADOS",
        gruposMontajeSeleccionados: lista,
    }
}

const updateGruposMarcasSeleccionadas = (lista) => {
    return {
        type: "UPDATE_MARCAS_SELECCIONADAS",
        marcasSeleccionadas: lista,
    }
}

const updateNumberPagination = (current, size) => {
    let estadoAgrupacion = store.getState().grupoDeMarcas;
    //let actualizarAgrupaacionMarcas = ordenarGruposMarcas(estadoAgrupacion.marcas);

    let listaPaginas = obtenerPaginas(estadoAgrupacion, size);

    return {
        type: "UPDATE_PAGINATION",
        pagination: {
            "numeroTotalRegistro": estadoAgrupacion.referencias.length - 1,
            "numeroTotalRegistroPaginacion": listaPaginas.length * size,
            "numeroRegistroPagina": size,
            "numeroDePaginas": listaPaginas.length,
            "paginas": listaPaginas,
            "paginaAMostrar": current - 1
        }
    }
}



const updatePaginaAMostrar = (numeroPagina, pageSize) => {
    return {
        type: "UPDATE_PAGINA_AMOSTRAR",
        paginaAMostrar: numeroPagina,
        numeroRegistroPagina: pageSize
    }
}

const activeItemTipoIntegracion = (tipoIntegracion) => {
    return {
        type: "STATE_ACTIVEITEM_INTEGRACION",
        activeItemTipoIntegracion: tipoIntegracion
    }
}

const activeItemTipoIntegracionDatos = (tipoIntegracion) => {
    return {
        type: "STATE_ACTIVEITEM_INTEGRACION_MAS_DATOS",
        datos: tipoIntegracion
    }
}

const eventoFiltroReferencias = (e) => {
    return {
        type: "STATE_FILTRO_REFERENCIA",
        tipoFiltroPorMarca: e
    }
}

const actualizarNodoTree = (e) => {
    return {
        type: "UPDATE_NODE_TREE",
        nodoIdActual: e
    }
}


const actulizarCheckFiltoMarcas = (e) => {
    return {
        type: "STATE_CHECKED_MARCA",
        checkedmarcas: e
    }
}

const actulizarReferenciaSeleccionada = (e) => {
    return {
        type: "STATE_SELECTED_REFERENCE",
        referenciaSeleccionada: e
    }
}

const actulizarReferenciaMasInfo2 = (e) => {
    return {
        type: "STATE_SELECTED_REFERENCE_MAS_INFO_2",
        referenciaMasInfo2: e
    }
}

const referenciaActual = (e) => {
    return {
        type: "STATE_REFERENCE_ACTUAL",
        referenciaActual: e
    }
}
const actualizarCodigoGT = (CODIGO) => {
    return {
        type: "STATE_GT_ESTIMATE",
        CODIGO: CODIGO
    }
}

const actualizarCocheIdentificador = (IDENTIFICADOR_COCHE) => {
    return {
        type: "STATE_GT_ESTIMATE_COCHE",
        IDENTIFICADOR_COCHE: IDENTIFICADOR_COCHE
    }
}
const actualizarCocheID = (ID) => {
    return {
        type: "STATE_GT_ESTIMATE_ID",
        ID: ID
    }
}


const actualizarHistorialGrafico = (e) => {

    let historial = store.getState().datosGraficos.historialGrafico;
    historial.pop();

    store.dispatch({
        type: "STATE_HISTORY_GRAFIC_CAR",
        historialGrafico: historial
    })


    store.dispatch(actulizarNivelGrafico(historial[(historial.length - 1)].nivel, historial[(historial.length - 1)].nomGrupo))

}

const actulizarNivelGrafico = (nivel, nombreGrupo) => {
    let listHistorial = [];
    let historial = store.getState().datosGraficos;

    if (historial.historialGrafico.length === 0) {
        //listHistorial.push({ nivel: 0, nomGrupo: "" })
        listHistorial.push({ nivel: nivel, nomGrupo: nombreGrupo })
    } else {
        listHistorial = historial.historialGrafico;
        const found = listHistorial.find(element => element.nomGrupo === nombreGrupo);

        if (found === undefined) {
            listHistorial.push({ nivel: nivel, nomGrupo: nombreGrupo })
        }
    }

    return {
        type: "STATE_CLICK_POLYGON",
        nivel: nivel,
        tooltip: nombreGrupo,
        historialGrafico: listHistorial
    }
}


const mostrarVistaGrafica = (e) => {
    return {
        type: "STATE_COLAPSE_BUSQUEDA_GRAFICA",
        visualizacionGrafico: e
    }
}


const detenerBusquedaDetalles = (estado) => {
    return {
        type: "STOP_BUSQUEDA_DETALLES",
        detenerBusquedaDetalles: estado
    }
}

const verFiltroPorMarca = (estado) => {
    return {
        type: "UPDATE_FILTROS_REFERENCIA_MARCAS",
        verTodasLasMarcas: estado
    }
}

const clienteSeleccionado = (nombre, codigo, cif, registroCliente) => {
    return {
        type: "STATE_FICHA_CLIENTE_SELECCIONADO",
        registroCliente: registroCliente,
        nombre: nombre,
        codigo: codigo,
        cif: cif
    }
}

const verFiltroPorGrupo = (estado) => {
    return {
        type: "UPDATE_FILTROS_REFERENCIA_GRUPOS",
        verTodosLosGrupos: estado
    }
}

const getListTreeGraficos = () => {
    return dispatch => {
        let listaGraficos = objetoGraficoV2(0);

        var crearTree = Array();
        listaGraficos[0].children.map((i) => {
            var nivel2 = new Array();

            i.children.map((x) => {
                var nivel3 = new Array();

                x.children.map((z) => {
                    var nivel4 = new Array();
                    z.children.map((y) => {
                        var nivel5 = new Array();
                        nivel4.push({ title: y.tooltip.toLowerCase(), key: y.key, level: 0, sortnr: 0, children: nivel5 })
                    })
                    nivel3.push({ title: z.tooltip.toLowerCase(), key: z.key, level: 0, sortnr: 0, children: nivel4 })
                })
                nivel2.push({ title: x.tooltip.toLowerCase(), key: x.key, level: 0, sortnr: 0, children: nivel3 })

            })

            crearTree.push({ title: i.tooltip.toLowerCase(), key: i.key, level: 0, sortnr: 0, children: nivel2 })
        })
        dispatch({
            type: "LIST_TREE_GRAFICOS",
            listaTreeGraficos: crearTree
        })
    }
}


export {
    removeAllEngineType, removeAllModel, changeColorListItem, changeStateImageViewer, updateStateMasterPage, activeTabApp, checkuserlogin, showItemReferencia, generateList, updatePaginaAMostrar, updateRegistroPagination, DrawerHistoricoCoche, mostrarVistaGrafica, detenerBusquedaDetalles,
    changeColorListItemFalse, onExpand, hideSpinnerAcademy, saveLocalStorageVehicle, updateStateHistoric, cambioNombreBusscadorCoche, efectoBotonRemove, changeStateInputCoche, gestionarContenedorCoche, estadoAgrupacionDeMarcas, updateNumberPagination, actulizarNivelGrafico, getListTreeGraficos,
    showModal, handleOkRespuesta, handleCancelRespuesta, hideSpinnerLoginHaynes, saveLocalStorageReferences, getLocalStorageReferences, showChildrenDrawerLevel3, onChildrenDrawerCloseLevel3, showItemCar, createRipple, changeCocheModal, actualizarHistorialGrafico,
    loadVehicleHaynes, getHistoricApp, setHistoricApp, deleteItemHistoricApp, stateVisibleDrawer, changeDimensionsApp, selectCoche, getLocalStorageVehicle, filterList, showChildrenDrawerLevel2, onChildrenDrawerCloseLevel2, showAllBrands, updateColapseMarca, actualizarAgrupacionDeMarcas,
    updateVisualizacionDescuento, onChildrenDrawerCloseIntegraciones, showChildrenDrawerIntegraciones, activeItemTipoIntegracion, updateVisualizacionFiltro, eventoFiltroReferencias, actualizarNodoTree, actulizarCheckFiltoMarcas, activeItemTipoIntegracionDatos, actulizarReferenciaSeleccionada,
    cambioImagenPais, updateGruposMontajeSeleccionados, updateGruposMarcasSeleccionadas, verFiltroPorMarca, verFiltroPorGrupo, DrawerCarroCompra, DrawerMasInformacion, DrawerStockAlmacen, referenciaActual, DrawerReferenciaGrafico, DrawerHaynesPro, DrawerPerfilUsuario, DrawerNextAssist,
    DrawerAcademyCloud, DrawerFichaCliente, clienteSeleccionado, DrawerFichaCocheDGT, DrawerFichaClienteTaller, DrawerFichaMantenimientoClienteTaller, DrawerFichaMantenimientoVehiculoTaller, DrawerFichaPresupuestoTaller, DrawerFichaMasInformacion2, actulizarReferenciaMasInfo2,
    DrawerFichaDatosCliente, DrawerFichaMarcasVehiculo, DrawerFichaReferenciaVehiculo, DrawerFichaEquivalenciaArticulo, DrawerFichaAlbaranTaller, DrawerFichaMantenimientoPresupuesto, DrawerFichaEmpresa, DrawerFichaVehiculoPresupuesto, DrawerFichaManoDeObra,
    DrawerFichaArticulo, DrawerFichaImpresionDocumento, DrawerFichaEnvioDocumento, DrawerFichaTiemposReparacion, DrawerFichaGMVehiculoHaynes, DrawerFichaMantenimientoHaynes, DrawerFichaPublicidad, DrawerReferenciaGraficoCarroceria, DrawerFichaLubricantes,
    DrawerFichaNeumaticos, DrawerFichaHistorialPedido, DrawerFichaProveedores, DrawerFichaOrden, DrawerFichaFactura, DrawerFichaGTEstimate, actualizarCodigoGT, actualizarCocheIdentificador, actualizarCocheID, DrawerFichaBateriasVehiculo, DrawerIntranet

};