import React from "react";
import PropTypes from 'prop-types';
import { MDBBreadcrumbItem, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';

import { Drawer, Col } from 'antd';
import { connect } from 'react-redux';
import store from "../store";
import { traducirPagina, copiarAlPortapapeles } from "../librerias/funciones"
import { CopyOutlined } from '@ant-design/icons';

const FichaDatosCocheYQ = ({ configApp, props }) => {

    return (
        <Drawer
            width={"40%"}
            closable={false}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '9999', color: '#fff' }}>

            <div style={{ backgroundColor: '#010910 ', color: '#010910', borderBottom: '0px', borderRadius: '0px' }}>

                <div style={{ width: '55px', display: 'inline-flex' }} >
                    <MDBBtn className="backgroundGris " aria-label="Close" style={{ color: '#000', backgroundColor: '#021323', border: 'none', width: '55px', height: '55px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                        props.CERRAR_FICHA(false)
                    }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ display: 'inline-flex', width: '328px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px' }}>
                    {props.title}
                </div>
            </div>

            <div className="ant-drawer-header" style={{ backgroundColor: '#dcdcdc', color: '#021323', borderBottom: '0px', borderRadius: '0px', padding: '8px 24px' }}>
                <div className="flex-container row">
                    <div className="flex-item-left" style={{ textAlign: 'left', fontSize: '15px', padding: '10px 5px' }}>

                        <div className="breadcrumbNew" style={{ background: '#4285f4', color: '#fff' }}>
                      

                            <MDBBreadcrumbItem style={{ background: 'fff' }} > <a> {Object.keys(props.MARCA_SELECCIONADA).length > 0 ? props.MARCA_SELECCIONADA.NOMBRE : ""} </a></MDBBreadcrumbItem>
                            <MDBBreadcrumbItem style={{ background: 'fff' }} > <a> {props.COCHE_ACTUAL.NOMBRE} </a></MDBBreadcrumbItem>
                        </div>

                    </div>
                </div>
            </div>



            <Col span={24}>
                <div style={{ height: (configApp.height - 130).toString() + "px", overflowY: 'scroll' }} className="col-md-12">
                    <MDBRow>
                        <MDBCol md="12">
                            {props.COCHE_ACTUAL.ATRIBUTOS !== null ?

                                props.COCHE_ACTUAL.ATRIBUTOS.length > 0 ?
                                    <div className="mdb-skin">
                                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>

                                            <h4 className="mt-1">{traducirPagina("Datos_técnicos")}</h4>
                                            <MDBTable responsive hover>
                                                <MDBTableBody>
                                                    {props.COCHE_ACTUAL.ATRIBUTOS.map((registro) =>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{registro.NOMBRE}</td>
                                                            <td style={{ textAlign: 'left' }}>{registro.VALOR}</td>
                                                        </tr>

                                                    )}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                                :
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_hay_vehiculo_seleccionado.")}</h3> </div>
                            }
                        </MDBCol>
                    </MDBRow>
                </div>
            </Col>
        </Drawer>
    );
}

FichaDatosCocheYQ.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    COCHE_ACTUAL: PropTypes.object,
    MARCA_SELECCIONADA: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props
    };
}


export default connect(mapStateToProps)(FichaDatosCocheYQ);

