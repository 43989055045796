import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Checkbox, Col, Layout, Pagination, Input, Select, Row, Button } from 'antd';
import { ObtenerMarcasIQEnAPI, ObtenerModelosIQEnAPI, BucarVehiculoPorModeloEnAPI, BuscarVehiculoPorVinMarcaEnAPI, buscarMatriculaCoche, SetHistorialBusquedaOEMEnAPI } from "../action/actionCreatorsRequests";
import FichaHistorialVehYQ from './FichaHistorialVehYQ';
import {
    createRipple
} from "../action/actionCreators";
import {
    traducirPagina, MSGERROR, recortarCadena,buscarVehiculoLocal
} from "../librerias/funciones"

import SeleccionCocheIQ from "./SeleccionCocheIQ"
import store from '../store'


import {
    ShoppingOutlined,

    CloseOutlined,
    SearchOutlined,
    FieldTimeOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';

const { Content } = Layout;
const { Option } = Select;

const ManteniminetoVehiculoYQ = ({ props, configApp, buscadorCoche,SET_HISTORIAL_VEHICULO }) => {


    const [hayReferencias, setHayReferencias] = React.useState(false);
    const [paginaActual, setPaginaActual] = React.useState(0);

    const [textoVim, setTextoVim] = React.useState("");
    const [filtroMarket, setFiltroMarket] = React.useState("");
    const [marcaSeleccionada, setMarcaSeleccionada] = React.useState({});

    const [marcas, setMarcas] = React.useState([]);
    const [modelos, setModelos] = React.useState([]);
    const [vehiculoPorModelo, setVehiculoPorModelo] = React.useState([]);
    const [abrirFichaHistorialVeh, setAbrirFichaHistorialVeh] = React.useState(false);
    const [loading, setLoading] = React.useState(true);


        
    const [abrirFichaCocheIQ, setAbrirFichaCocheIQ] = React.useState(false);
    const [registroCocheIQ, setRegistroCocheIQ] = React.useState([]);




    const counter = React.useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= marcas.length) {
            setLoading(false);
        }
    }



    const obtenerMarcas = () => {
        setMarcas([])
        ObtenerMarcasIQEnAPI().then(registro => {
            if (registro !== undefined) {
                if (registro.MARCAS !== null && registro.MARCAS.length > 0) {
                    setMarcas(registro.MARCAS)
                } else {
                    setMarcas(null)
                }
            }
        })
    }


    const vehiculoSeleccionado = (vehiculo) => {
        SET_HISTORIAL_VEHICULO(vehiculo)
        setTextoVim(vehiculo.BASTIDOR);
        setAbrirFichaHistorialVeh(false)
    }


    const modelosVehiculo = (FILTRO, CODIGO) => {
        setModelos([])
        ObtenerModelosIQEnAPI(FILTRO, CODIGO).then(registro => {
            if (registro !== undefined) {
                if (registro.MODELOS !== null && registro.MODELOS.length > 0) {
                    setModelos(registro.MODELOS)
                } else {
                    setModelos(null)
                }
            }
        })
    }


    const filtroVehiculoPorModelo = (SSD, MARCA) => {
        setVehiculoPorModelo([])
        BucarVehiculoPorModeloEnAPI(SSD, MARCA).then(registro => {
            if (registro !== undefined) {
                if (registro.RESULTADOS !== null && registro.RESULTADOS.length > 0) {
                    setVehiculoPorModelo(registro.RESULTADOS)
                } else {
                    setVehiculoPorModelo(null)
                }
            }
        })
    }


    const buscarvehiculoPorBastidor = (bastidor) => {

        store.dispatch({ type: 'STATE_VEHICLE_OEM', VEHICULO_OEM: { BASTIDOR: bastidor } })


        BuscarVehiculoPorVinMarcaEnAPI(bastidor).then(registro => {
            if (registro !== undefined) {
                if (registro.VEHICULO !== null && registro.VEHICULO.length > 0) {

                    if(registro.VEHICULO.length === 1){

                        props.COCHE_SELECCIONADO(registro.VEHICULO[0]);


                    }else{
                        setRegistroCocheIQ(registro.VEHICULO)
                        setAbrirFichaCocheIQ(true)
                    }
                } else {
                    MSGERROR(traducirPagina("sin_resultados_vehiculo"));
                }
            }
        })
    }

    const gestionarVehiculoSeleccionada = async (registro) => {
        props.COCHE_SELECCIONADO(registro);
        const { MARCA, NOMBRE } = registro

        //console.log("da",props.MARCA_SELECCIONADA)

        const resultado = await SetHistorialBusquedaOEMEnAPI("M", "", MARCA, NOMBRE);
    }


    const EVENTO_SELECCIONADO = (VEHICULO) =>{
        props.COCHE_SELECCIONADO(VEHICULO);
    }


    if (!hayReferencias && props.ABRIR_FICHA) {
        setHayReferencias(true);
        if (Object.keys(buscadorCoche).length > 0) {
            if (buscadorCoche.txtBuscadorCoche !== undefined && buscadorCoche.txtBuscadorCoche !== null) {
                if (buscadorCoche.txtBuscadorCoche.length > 12) {
                    setTextoVim(buscadorCoche.txtBuscadorCoche);
                    buscarvehiculoPorBastidor(buscadorCoche.txtBuscadorCoche)
                } else {
                    buscarMatriculaCoche(buscadorCoche.txtBuscadorCoche).then(registro => {
                        setTextoVim(registro.MATRICULA.Bastidor)
                        buscarvehiculoPorBastidor(registro.MATRICULA.Bastidor)
                    })
                }
            }

        }

    } else {
        if (!props.ABRIR_FICHA && hayReferencias) {
            setHayReferencias(false);
            setMarcas([])
            setPaginaActual(0)
            setTextoVim("")
            setFiltroMarket("")
            //setMarcaSeleccionada({})
            setMarcas([])
            setModelos([])
            setVehiculoPorModelo([])
            setAbrirFichaHistorialVeh(false)
            setLoading(true)
        }
    }



    return (
        <div>
            <Drawer
                width={"80%"}
                onClose={() => {
                    props.CERRAR_FICHA(false)

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {

                            props.CERRAR_FICHA(false)


                        }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                    <div style={{ marginRight: '20px', marginLeft: 'auto' }}>
                        {paginaActual > 0 ?
                            <div style={{ padding: '10px', backgroundColor: '#dcdcdc', color: '#000', borderRadius: '3px', fontSize: '13px', textAlign: 'center', width: '100px' }} onClick={() => {
                                setPaginaActual(paginaActual > 0 ? paginaActual - 1 : 0)
                                setVehiculoPorModelo([])
                            }}>
                                {traducirPagina("Volver").toUpperCase()}
                            </div>
                            :
                            null
                        }
                    </div>
                </div>

                <Content>
                    <Col span={24} style={{ backgroundColor: '#fff' }} >
                        <div>
                            {paginaActual === 0 ?

                                <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
                                    <div style={{ textAlign: 'left', fontSize: '22px', fontWeight: '500', color: '#666', padding: '10px' }}>
                                        {traducirPagina("msgManVehYQ_01")}
                                        {traducirPagina("msgManVehYQ_02")}
                                    </div>

                                    <div style={{ paddingTop: '40px' }}>

                                        <Col span={12} style={{ fontSize: '18px', fontWeight: '500', color: '#000', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={"./imagenes/car.png"} style={{ width: '256px' }} />

                                            <div style={{ color: '#1790ff' }}>{traducirPagina("Seleccionar_vehiculo")}</div>

                                            <div>
                                                {/*
                                                <Button style={{ marginTop: '20px', width: '250px' }} key="submit" type="primary" block onClick={() => {
                                                    obtenerMarcas()
                                                    setPaginaActual(1)
                                                }}>{traducirPagina("Buscar_por_marca_modelo")}</Button>
                                            */}

                                                <div  className={`btn_iq_buscar_vehiculo`} onClick={() => {
                                                    obtenerMarcas()
                                                    setPaginaActual(1)

                                                    store.dispatch({ type: 'STATE_VEHICLE_OEM', VEHICULO_OEM: {} })

                                                }}>
                                                    {traducirPagina("Buscar_por_marca_modelo")}
                                                </div>


                                            </div>

                                        </Col>

                                        <Col span={1}>
                                            <div style={{ borderRight: '1px solid #dcdcdc', width: '1px', height: '500px' }}
                                            ></div>

                                        </Col>

                                        <Col span={11} style={{ fontSize: '18px', fontWeight: '500', color: '#000', padding: '10px', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={"./imagenes/VIN.png"} style={{ width: '256px' }} />

                                            <div style={{ color: '#1790ff' }}>

                                                {traducirPagina("Seleccionar_vehiculo_por_bastidor")}


                                                <div className="grid-container mdb-skin" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                                                    <div className="grid-item">
                                                        <input className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Introduce_17dig_VIN")} style={{ backgroundColor: "transparent", width: '360px', border: '0px', height: '100%', color: '#000' }} name="txtInputCliente" autoComplete="off" value={textoVim} onChange={(e) => { setTextoVim(e.target.value) }} />
                                                    </div>

                                                    <div className="grid-item">
                                                        <MDBBtn onClick={e => { createRipple(e); setTextoVim("") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: textoVim.trim() !== "" ? 'visible' : 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                    </div>


                                                    <div className="grid-item barraLateral"></div>

                                                    <div className="grid-item">
                                                        <MDBBtn onClick={e => { createRipple(e); setAbrirFichaHistorialVeh(true) }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <FieldTimeOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                                    </div>

                                                </div>

                                            </div>


                                            {/*
                                            <Button style={{ marginTop: '20px', width: '250px' }} disabled={textoVim === "" ? true : false} block key="submit" type="primary"
                                                onClick={() => {
                                                    buscarvehiculoPorBastidor(textoVim)

                                                }}>{traducirPagina("Buscar_por_bastidor")}</Button>
                                            */}


                                            <div className={`btn_iq_buscar_vehiculo ${textoVim === "" ? "btn_iq_buscar_vehiculo_bloqueo" : ""}`} onClick={() => {
                                                buscarvehiculoPorBastidor(textoVim)


                                            }}>
                                                {traducirPagina("Buscar_por_bastidor")}
                                            </div>

                                        </Col>


                                    </div>
                                </div>
                                :
                                paginaActual === 1 ?
                                    <div style={{ height: (configApp.height - 100) + "px", overflow: 'auto', overflowX: 'hidden' }}>
                                        {marcas === null ?
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <h3>{traducirPagina("No_marcas")}</h3> </div>
                                            :
                                            marcas.length > 0 ?
                                                <Row gutter={[8, 8]} style={{ display: loading ? "block" : "block" }}>
                                                    {marcas.map((registro, i) =>
                                                        <Col span={4} style={{ padding: '10px' }} onClick={() => {
                                                            setPaginaActual(2); modelosVehiculo("", registro.CODIGO);

                                                            props.MODIFICAR_MARCA_SELECCIONADA(registro)
                                                        }}>
                                                            <img src={"./imagenes/marcasYQ/" + registro.NOMBRE_IMAGEN + ".png"} onLoad={imageLoaded} style={{ width: '80px', display: 'block', margin: 'auto' }} />

                                                            <div style={{ color: '#000', fontSize: '12px', fontWeight: '500', textAlign: 'center' }}>
                                                                {recortarCadena(registro.NOMBRE, 15, "...")}
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                                :
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <Col md={6}>
                                            {modelos === null ?
                                                null
                                                :
                                                modelos.length > 0 ?
                                                    <div style={{ height: (configApp.height - 100) + "px", overflow: 'auto', overflowX: 'hidden', padding: '20px' }}>
                                                        {modelos.map((registro, i) =>
                                                            <div >
                                                                <Col md={24} style={{ color: '#1890ff', fontSize: '16px', fontWeight: '500' }}>{
                                                                    registro.VALOR === null ? registro.NOMBRE.toUpperCase() :
                                                                        <div>
                                                                            <span style={{ color: '#1890ff', fontWeight: '500' }}>{registro.NOMBRE.toUpperCase()}</span>
                                                                            <span style={{ color: '#333' }}>{" " + registro.VALOR.toUpperCase()}</span>
                                                                        </div>
                                                                }
                                                                </Col>
                                                                {registro.VALOR === null && registro.OPCIONES_MODELOS.RESULTADOS.length > 0 ?
                                                                    <Col md={24}>
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: "100%", textAlign: 'left', marginBottom: '10px' }}
                                                                            optionFilterProp="children"
                                                                            onChange={(e) => {
                                                                                setFiltroMarket(e === undefined ? "" : e);
                                                                                modelosVehiculo(e, props.MARCA_SELECCIONADA.CODIGO)
                                                                                filtroVehiculoPorModelo(e, props.MARCA_SELECCIONADA.CODIGO)

                                                                                //setVehiculoPorModelo([])
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {registro.OPCIONES_MODELOS.RESULTADOS.map((registro, i) =>
                                                                                <Option value={registro.CODIGO} key={i}>{registro.DESCRIPCION}</Option>
                                                                            )
                                                                            }
                                                                        </Select>

                                                                    </Col>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        )
                                                        }

                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>

                                            }
                                        </Col>

                                        <Col md={18}>
                                            <div style={{ backgroundColor: '#f7f7f7', height: (configApp.height - 70) + "px", overflow: 'auto', overflowX: 'hidden', padding: '20px' }}>
                                                {vehiculoPorModelo === null ?
                                                    <h4>{traducirPagina("No_resultados")}</h4>
                                                    :
                                                    vehiculoPorModelo.length > 0 ?
                                                        <table style={{ width: '100%', color: '#000' }} >
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: '60px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }} > {traducirPagina("Mercado")}</th>
                                                                    <th style={{ width: '120px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }}> {traducirPagina("Fabricacion")}</th>
                                                                    <th style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'left', fontSize: '16px' }} onClick={(e) => { }}> {traducirPagina("Modelo")}</th>
                                                                    <th style={{ width: '100px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }} > {traducirPagina("Motor")}</th>
                                                                    <th style={{ width: '250px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }} > {traducirPagina("Info")}</th>
                                                                    <th style={{ width: '100px', borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }} onClick={(e) => { }} > {traducirPagina("Transmision")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {vehiculoPorModelo.map((registro, i) =>
                                                                    <tr key={i} onClick={(e) => {
                                                                        gestionarVehiculoSeleccionada(registro);
                                                                    }}>
                                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }}>
                                                                            {registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "market") !== undefined ?
                                                                                registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "market").VALOR
                                                                                :
                                                                                ""
                                                                            }
                                                                        </td>
                                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }}>
                                                                            {registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "prodrange") !== undefined ?
                                                                                registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "prodrange").VALOR
                                                                                :
                                                                                ""
                                                                            }
                                                                        </td>
                                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'left', fontSize: '16px' }}>
                                                                            {registro.NOMBRE}
                                                                        </td>
                                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }}>
                                                                            {registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "engine") !== undefined ?
                                                                                registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "engine").VALOR
                                                                                :
                                                                                ""
                                                                            }
                                                                        </td>
                                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }}>
                                                                            {registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "engine_info") !== undefined ?
                                                                                registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "engine_info").VALOR
                                                                                :
                                                                                ""
                                                                            }
                                                                        </td>
                                                                        <td style={{ borderBottom: '1px solid #d9d9d9', padding: '4px', textAlign: 'center', fontSize: '16px' }}>
                                                                            {registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "transmission") !== undefined ?
                                                                                registro.ATRIBUTOS.find((mercado) => mercado.CLAVE === "transmission").VALOR
                                                                                :
                                                                                ""
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        modelos === null ? null : modelos.filter((registro) => registro.PERMITE_LISTA_VEHICULOS === true).length > 0 ?
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                                                            :
                                                            null
                                                }
                                            </div>
                                        </Col>
                                    </div>
                            }
                        </div>
                    </Col>
                </Content>
            </Drawer>
            {abrirFichaHistorialVeh ?
                <FichaHistorialVehYQ
                    title={traducirPagina("Historial_de_vehiculos").toUpperCase()}
                    ABRIR_FICHA={abrirFichaHistorialVeh}
                    CERRAR_FICHA={setAbrirFichaHistorialVeh}

                    SELECCIONADO={vehiculoSeleccionado}
                >
                </FichaHistorialVehYQ>
                :
                null
            }

{
                abrirFichaCocheIQ ?
                    <SeleccionCocheIQ
                        title={traducirPagina("Seleccione_el_vehiculo").toUpperCase()}
                        ABRIR_FICHA={abrirFichaCocheIQ}
                        CERRAR_FICHA={setAbrirFichaCocheIQ}
                        REGISTROS={registroCocheIQ}
                        EVENTO_SELECCIONADO={EVENTO_SELECCIONADO}
                    >

                    </SeleccionCocheIQ>
                    :
                    null
            }
        </div>
    )
}

ManteniminetoVehiculoYQ.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    COCHE_SELECCIONADO: PropTypes.func,
    COCHE_ACTUAL: PropTypes.object,

    MODIFICAR_MARCA_SELECCIONADA: PropTypes.func,
    MARCA_SELECCIONADA: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado,
        buscadorCoche: state.buscadorCoche


    };
}

export default connect(mapStateToProps)(ManteniminetoVehiculoYQ);