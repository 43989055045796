import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol } from 'mdbreact';
import { Drawer, Col, Pagination, Button, Input } from 'antd';
import { getStockDesglose, ObtenerLubricantesERPEnAPI, Obtener_Filtros_LubricantesEnAPI } from "../action/actionCreatorsRequests";
import { DrawerStockAlmacen } from "../action/actionCreators";
import FichaAñadirCesta from "./FichaAñadirCesta.jsx";
import FichaStockSocio from "./FichaStockSocio.jsx";
import 'antd/dist/antd.css';
import {
    traducirPagina, referenciaActualSelect, verificarSiEsMostrador,
    verficarSiEsCatalogo, copiarAlPortapapeles, crearLineasParaElPresupuesto, MSGERROR, obtenerDatosUsuarioYLicenciaActual,
    verificarCantidadCesta, datosLincenciaUsuario, dynamicSortMultipleDesc, convertirMoneda, verficarSiEsPVP,
    soloNumeros, condicionesAbrirCesta, cestaAutomatica, dynamicSortAsc, condicionesAbrirCestav2
} from "../librerias/funciones"
import store from '../store'
import {
    ShoppingOutlined,
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    EyeInvisibleOutlined,
    CopyOutlined,
    FileAddOutlined,
    MinusOutlined,
    PlusOutlined,
    ClearOutlined
} from '@ant-design/icons';
import { ImagenArticulo } from './ImagenArticulo.jsx';
import { CPCard } from './utils/Card/CPCard.jsx'
import CPSelect from './utils/CPSelect.jsx';
import CPCantidad from './utils/CPCantidad.jsx';

const FichaLubricantesERP = ({ props, configApp, usuario, clienteSeleccionado, VISCOSIDAD = "", CALIDAD = "" }) => {
    const [txtViscosidad, setTxtViscosidad] = React.useState("");
    const [txtCalidad, setTxtCalidad] = React.useState("");


    const [abrirFichaStockSocio, setAbrirFichaStockSocio] = React.useState(false);
    const [registroStockSocio, setRegistroStockSocio] = React.useState({});
    const [datosPaginacion, setDatosPaginacion] = React.useState({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 });
    const [visualizacionDescuento, setVisualizacionDescuento] = React.useState(true);

    const [referencias, setReferencia] = React.useState([]);
    const [referenciasAux, setReferenciaAux] = React.useState([]);
    const form_filterServer = {
        viscocidad: [],
        homologacion: [],
        txt_viscosidad: undefined,
        txt_homologacion: undefined
    }
    const form_filterLocal = {
        marcas: [],
        estandar: [],
        embalaje: [],
        segmento: [],
        txt_marcas: undefined,
        txt_estandar: undefined,
        txt_embalaje: undefined,
        txt_segmento: undefined
    }

    const [filterServer, setFilterServer] = React.useState({ ...form_filterServer });
    const [filterLocal, setFilterLocal] = React.useState({ ...form_filterLocal });



    const OBTENER_DATOS_USUARIO = datosLincenciaUsuario();
    const [fichaCesta, setFichaCesta] = React.useState(false);
    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});
    const { COMPRAR_SIN_DISPONIBILIDAD } = OBTENER_DATOS_USUARIO === undefined ? { COMPRAR_SIN_DISPONIBILIDAD: false } : OBTENER_DATOS_USUARIO;


    const inputRef = React.useRef(null);
    const sharedProps = {
        ref: inputRef,
    };




    const paginarNeumatico = (page, pageSize) => {
        if (referencias.length > 0) {
            let pInicio = (pageSize * page) - (datosPaginacion.numPagina);
            let pFinal = pageSize * page;
            setDatosPaginacion(
                {
                    iniPage: page,
                    inicio: pInicio,
                    final: pFinal,
                    numPagina: datosPaginacion.numPagina
                }
            )
        }
    }

    const filterRegisters = (registros) => {
        let registros_marcas = [], registros_estandar = [], registros_empaquetado = [], registros_segmento = []

        registros.forEach(item => {
            if (item.MARCA !== null) { registros_marcas.push(item.MARCA) }
            if (item.ESTANDAR !== null) { registros_estandar.push(item.ESTANDAR) }
            if (item.EMPAQUETADO !== null) { registros_empaquetado.push(item.EMPAQUETADO) }
            if (item.SEGMENTO_MERCADO !== null) { registros_segmento.push(item.SEGMENTO_MERCADO) }
        })


        let agrupacionMarcas = registros_marcas.filter((value, index, self) => self.indexOf(value) === index)
        agrupacionMarcas = agrupacionMarcas.sort((a, b) => a.localeCompare(b));

        let agrupacionEstandar = registros_estandar.filter((value, index, self) => self.indexOf(value) === index)
        agrupacionEstandar = agrupacionEstandar.sort((a, b) => a.localeCompare(b));

        let agrupacionEmbalaje = registros_empaquetado.filter((value, index, self) => self.indexOf(value) === index)
        agrupacionEmbalaje = agrupacionEmbalaje.sort((a, b) => a.localeCompare(b));

        let agrupacionSegmento = registros_segmento.filter((value, index, self) => self.indexOf(value) === index)
        agrupacionSegmento = agrupacionSegmento.sort((a, b) => a.localeCompare(b));



        filterLocal.marcas = agrupacionMarcas
        filterLocal.estandar = agrupacionEstandar
        filterLocal.embalaje = agrupacionEmbalaje
        filterLocal.segmento = agrupacionSegmento



        setFilterLocal({ ...filterLocal })
    }

    const filtrarReferencias = (registros, filterLocal) => {
        const {
            txt_marcas,
            txt_estandar,
            txt_embalaje,
            txt_segmento
        } = filterLocal


        let listaDeRegistrosFiltrados = referenciasAux
        if (registros !== null && registros.length > 0) {


            if (txt_marcas !== undefined) { listaDeRegistrosFiltrados = listaDeRegistrosFiltrados.filter(registro => registro.MARCA === txt_marcas) }
            if (txt_estandar !== undefined) { listaDeRegistrosFiltrados = listaDeRegistrosFiltrados.filter(registro => registro.ESTANDAR === txt_estandar) }
            if (txt_embalaje !== undefined) { listaDeRegistrosFiltrados = listaDeRegistrosFiltrados.filter(registro => registro.EMPAQUETADO === txt_embalaje) }
            if (txt_segmento !== undefined) { listaDeRegistrosFiltrados = listaDeRegistrosFiltrados.filter(registro => registro.SEGMENTO_MERCADO === txt_segmento) }



        }

        if (listaDeRegistrosFiltrados.length > 0) {

            setReferencia(listaDeRegistrosFiltrados)
            filterRegisters(listaDeRegistrosFiltrados)

        } else {
            setReferencia(null)
        }

        resetPagination()
    }


    const updateNumberPage = (current, size) => {
        setDatosPaginacion(
            {
                iniPage: 1,
                inicio: 1,
                final: size,
                numPagina: size
            }
        )
    }



    const añadirALaCesta = async (registro, CANTIDAD) => {

        if (CANTIDAD === "") {
            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.STOCK_TOTAL}`)
        } else {
            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.nombre === "") {
                //setVisible(true)
                MSGERROR(traducirPagina("Msg_vender"))
            } else {

                const PERMITIR_COMPRA = verificarCantidadCesta(registro, COMPRAR_SIN_DISPONIBILIDAD);

                const { TIPO_STOCK, ABRIR_FICHA } = condicionesAbrirCestav2(registro, CANTIDAD);
                if (ABRIR_FICHA) {
                    setReferenciaSeleccionada(
                        {
                            CANTIDAD_SELECCIONADA: CANTIDAD,
                            REFERENCIA: "",
                            CODIGO_MARCA: "",
                            REFERENCIA_PROVEEDOR: registro.CODIGO,
                            REGISTROS: {}
                        }
                    )
                    setFichaCesta(true)


                } else {
                    getStockDesglose("", "", registro.CODIGO,registro.DESCRIPCION).then(stock => {

                        const REGISTRO = {
                            REFERENCIA_PROVEEDOR: registro.CODIGO,
                            CODIGO_MARCA: registro.MARCA,
                            REFERENCIA: registro.CODIGO,
                            CODIGO_MARCA: "0",
                            NOMRE_MARCA: registro.DESCRIPCION,
                            CANTIDAD: CANTIDAD,
                            PRECIO: registro.PRECIO,
                            DESCUENTO: registro.DESCUENTO,
                            NETO: registro.NETO,
                            DESCRIPCION_CESTA: "[ " + registro.MARCA.toUpperCase() + " ] - " + registro.DESCRIPCION.toUpperCase(),


                        }
                        cestaAutomatica(stock, TIPO_STOCK, CANTIDAD, REGISTRO);
                    })

                }
            }
        }
    }





    const abrirDesglose = (item) => {
        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {
            store.dispatch(DrawerStockAlmacen(true));
            referenciaActualSelect({ REFERENCIA: "", CODIGO_MARCA: "", REFERENCIA_PROVEEDOR: item.CODIGO, REGISTROS: {} });
        }
    }




    const agregarAPresupuesto = (ITEM) => {
        let objeto = {
            REFERENCIA: "",
            REFERENCIA_PROVEEDOR: "",
            CODIGO_MARCA_ERP: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            NOMBRE_GRUPO_MONTAJE: "",
            PRECIO: 0,
            CANTIDAD: 1
        };

        objeto.REFERENCIA = ITEM.REFERENCIA_COMERCIAL;
        objeto.REFERENCIA_PROVEEDOR = ITEM.REFERENCIA_COMERCIAL;
        objeto.CODIGO_MARCA_ERP = "";
        objeto.NOMRE_MARCA = ITEM.MARCA;
        objeto.CODIGO_MARCA = 0;
        objeto.NOMBRE_GRUPO_MONTAJE = "LUBRICANTE: " + ITEM.DESCRIPCION;
        objeto.PRECIO = ITEM.PRECIO;
        objeto.CANTIDAD = ITEM.CANTIDAD
        crearLineasParaElPresupuesto(objeto, "A");
    }


    const vistaPrevia = (viscocidad, calidad) => {

        buscarLubricantes(viscocidad, calidad)

    }

    const resetPagination = () => {
        setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })
    }

    const clearAllFilters = () => {
        filterServer.txt_viscosidad = undefined
        filterServer.txt_homologacion = undefined
        setFilterServer({ ...filterServer })
        setReferencia(null)
        setReferenciaAux(null)
        resetPagination()

        setFilterLocal({ ...form_filterLocal })
    }

    const clearFiltersLocal = () => {
        filterLocal.txt_embalaje = undefined
        filterLocal.txt_estandar = undefined
        filterLocal.txt_marcas = undefined
        filterLocal.txt_segmento = undefined
        setFilterLocal({ ...filterLocal })

        filtrarReferencias(referenciasAux, filterLocal);
    }


    const buscarLubricantes = (viscocidad, calidad) => {
        setReferencia([])
        setReferenciaAux([])

        resetPagination()
        ObtenerLubricantesERPEnAPI(viscocidad, calidad).then(registros => {
            if (registros !== undefined) {
                if (registros.LUBRICANTES !== null && registros.LUBRICANTES.length > 0) {

                    registros.LUBRICANTES.map((item) => item.CANTIDAD_CLIENTE = 1)
                    setReferencia(registros.LUBRICANTES)
                    setReferenciaAux(registros.LUBRICANTES)


                    filterRegisters(registros.LUBRICANTES)
                } else {
                    setReferencia(null)
                    setReferenciaAux([])
                }
            }
        })
    }



    const filtroReferenciaLocal = (listaDeReferenciasDeLaPaginaActual) => {

        listaDeReferenciasDeLaPaginaActual = listaDeReferenciasDeLaPaginaActual.sort(dynamicSortMultipleDesc("STOCK_ALMACEN", "STOCK_TOTAL"))

        let verdes = []
        let naranjas = []
        let rojos = []
        let rojos_sin_precio = []
        let rojos_sin_precio_sin_proveedor = []



        listaDeReferenciasDeLaPaginaActual.map((item) => {
            if (item.STOCK_ALMACEN > 0) {
                verdes.push(item)
            }

            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0) {
                naranjas.push(item)
            }

            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO > 0) {
                rojos.push(item)
            }
            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_COMERCIAL !== "") {
                rojos_sin_precio.push(item)
            }

            if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_COMERCIAL === "") {
                rojos_sin_precio_sin_proveedor.push(item)
            }
        })

        verdes.sort(dynamicSortAsc("NETO"))
        naranjas.sort(dynamicSortAsc("NETO"))
        rojos.sort(dynamicSortAsc("NETO"))

        let preciosFinal = [...verdes, ...naranjas, ...rojos, ...rojos_sin_precio, ...rojos_sin_precio_sin_proveedor];


        return preciosFinal;
    }


    const propiedadesReferencia = (PROPIEDADES) => {
        let propiedades = "";
        if (PROPIEDADES !== undefined && PROPIEDADES !== null) {
            PROPIEDADES.split("|").map((item, i) => {
                if (i > 0) {
                    propiedades += '<span>' + item + '</span><br/>'
                }
            })
        }
        return propiedades
    }

    const Obtener_Filtros_Lubricantes = async (props) => {
        const request = await Obtener_Filtros_LubricantesEnAPI();
        if (request !== undefined) {
            const viscocidad_props = props.VISCOSIDAD
            const calidad_props = props.CALIDAD

            const { HOMOLOGACION, VISCOSIDAD } = request
            const resp_homologacion = HOMOLOGACION !== null ? HOMOLOGACION.map((item) => item.VALORES) : []
            const resp_viscocidad = VISCOSIDAD !== null ? VISCOSIDAD.map((item) => item.VALORES) : []

            filterServer.homologacion = resp_homologacion
            filterServer.viscocidad = resp_viscocidad
            if (viscocidad_props !== undefined) {
                filterServer.homologacion = [...resp_homologacion, calidad_props];
                filterServer.viscocidad = [...resp_viscocidad, viscocidad_props]
                filterServer.txt_homologacion = calidad_props
                filterServer.txt_viscosidad = viscocidad_props
            }

            setFilterServer({ ...filterServer })
        }

    }

    useEffect(() => {
        //Obtener_Filtros_Lubricantes(props)


        if ((VISCOSIDAD !== null && VISCOSIDAD !== "") || (CALIDAD !== null && CALIDAD !== "")) {
            //"5W30"
            buscarLubricantes(VISCOSIDAD, CALIDAD)

            setTxtViscosidad(VISCOSIDAD)
            setTxtCalidad(CALIDAD)

        } else {
            setReferencia(null)
            setReferenciaAux([])
        }

    }, [])


    return (
        <div>
            <Drawer
                width={"80%"}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_LUBRICANTES: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }

                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>



                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_LUBRICANTES: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className='header_lubricantes'>
                    <div className="flex-container p-2" style={{ backgroundColor: '#eee' }} >
                        <div className="flex-item-left" style={{ textAlign: 'left', padding: '0px', flex: '40%' }}>
                            <div className="classic-tabs">
                                <MDBNav classicTabs color="#808080" style={{ backgroundColor: '#eee' }} >
                                    <MDBNavItem>
                                        {
                                            visualizacionDescuento ?
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: 'visible' }} onClick={() => setVisualizacionDescuento(false)} >
                                                    <EyeOutlined className="estiloIcono" />
                                                </div>
                                                :
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: 'visible' }} onClick={() => { setVisualizacionDescuento(true) }} >
                                                    <EyeInvisibleOutlined className="estiloIcono" />
                                                </div>

                                        }
                                    </MDBNavItem>

                                    <MDBNavItem>

                                        <button
                                            className='clear_lubricante'
                                            onClick={() => {
                                                clearAllFilters()
                                            }}
                                        >
                                            <ClearOutlined /> {traducirPagina("Limpiar")}
                                        </button>


                                    </MDBNavItem>
                                </MDBNav>
                            </div>
                        </div>

                        <div className="flex-item-right" style={{ textAlign: 'right', padding: '0px', flex: '90%' }}>
                            {
                                referencias !== null && referencias.length > 0 ?
                                    <Pagination style={{ marginTop: '5px' }}
                                        showSizeChanger
                                        current={datosPaginacion.iniPage}
                                        onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}
                                        total={referencias.length}
                                        showTotal={total => ` ${total} ${traducirPagina("Referencias")}`}
                                        defaultPageSize={40}
                                        onChange={(page, pageSize) => { paginarNeumatico(page, pageSize) }}
                                    > </Pagination>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>


                <div className="body_lubricantes">

                    <div className='menu_lubricantes'>
                        {
                            <>
                                <CPCard>
                                    <div className='container_form_lubricante'>
                                        <span>{traducirPagina("Viscosidad")} </span>
                                        {/*
                                        <CPSelect
                                            registers={filterServer.viscocidad}
                                            value={filterServer.txt_viscosidad}

                                            onChange={(e) => {
                                                filterServer.txt_viscosidad = e === undefined ? "" : e
                                                setFilterServer({ ...filterServer })
                                            }}
                                        />
 */}

                                        <Input allowClear  {...sharedProps} onChange={(e) => setTxtViscosidad(e.target.value === null ? "" : e.target.value)} value={txtViscosidad} placeholder={traducirPagina("Ingrese_la_viscosidad")} style={{ marginBottom: '10px' }} onKeyPress={(e) => e.key === 'Enter' ? vistaPrevia(e.target.value, txtCalidad) : {}} />


                                        <div className='container_form_separator'></div>

                                        <span>{traducirPagina("Calidad")} </span>
                                        {/**
                                        <CPSelect
                                            registers={filterServer.homologacion}
                                            value={filterServer.txt_homologacion}
                                            onChange={(e) => {
                                                filterServer.txt_homologacion = e === undefined ? "" : e
                                                setFilterServer({ ...filterServer })
                                            }}
                                        />
 */}
                                        <Input allowClear onChange={(e) => setTxtCalidad(e.target.value === null ? "" : e.target.value)} value={txtCalidad} placeholder={traducirPagina("Calidad")} style={{ marginBottom: '10px' }} onKeyPress={(e) => e.key === 'Enter' ? vistaPrevia(txtViscosidad, e.target.value) : {}} />


                                        <div style={{ padding: '12px 0px' }}></div>

                                        <Button
                                            key="submit"
                                            type="primary"
                                            block
                                            onClick={() => {
                                                vistaPrevia(txtViscosidad, txtCalidad)
                                            }}>{traducirPagina("Buscar")}</Button>


                                    </div>
                                </CPCard>

                                {
                                    referenciasAux !== undefined && referenciasAux !== null && referenciasAux.length > 0 ?
                                        <>
                                            <div style={{ margin: '30px' }}></div>

                                            <CPCard>
                                                <div className='container_form_lubricante'>
                                                    <span>{traducirPagina("Marcas")} </span>

                                                    <CPSelect
                                                        registers={filterLocal.marcas}
                                                        value={filterLocal.txt_marcas}
                                                        onChange={(e) => {
                                                            //setMarcasSelecciondas(e === undefined ? "" : e)
                                                            filterLocal.txt_marcas = e
                                                            filtrarReferencias(referenciasAux, filterLocal);
                                                            setFilterLocal({ ...filterLocal })
                                                        }}
                                                    />



                                                    <div className='container_form_separator'></div>


                                                    <span>{traducirPagina("txt_Estandar")} </span>


                                                    <CPSelect
                                                        registers={filterLocal.estandar}
                                                        value={filterLocal.txt_estandar}
                                                        onChange={(e) => {
                                                            // setMarcasSelecciondas(e === undefined ? "" : e)
                                                            filterLocal.txt_estandar = e
                                                            filtrarReferencias(referenciasAux, filterLocal);
                                                            setFilterLocal({ ...filterLocal })
                                                        }}
                                                    />

                                                    <div className='container_form_separator'></div>

                                                    <span>{traducirPagina("txt_Enbalaje")} </span>
                                                    <CPSelect
                                                        registers={filterLocal.embalaje}
                                                        value={filterLocal.txt_embalaje}
                                                        onChange={(e) => {
                                                            filterLocal.txt_embalaje = e
                                                            filtrarReferencias(referenciasAux, filterLocal);
                                                            setFilterLocal({ ...filterLocal })
                                                        }}
                                                    />


                                                    <div className='container_form_separator'></div>

                                                    <span>{traducirPagina("txt_Segmento_mercado")} </span>
                                                    <CPSelect
                                                        registers={filterLocal.segmento}
                                                        value={filterLocal.txt_segmento}
                                                        onChange={(e) => {
                                                            filterLocal.txt_segmento = e
                                                            filtrarReferencias(referenciasAux, filterLocal);
                                                            setFilterLocal({ ...filterLocal })
                                                        }}
                                                    />

                                                </div>


                                                <div style={{ padding: '12px 0px' }}></div>

                                                <Button
                                                    key="submit"
                                                    type="primary"
                                                    block
                                                    onClick={() => {

                                                        clearFiltersLocal()
                                                    }}>
                                                    {traducirPagina("Limpiar")}
                                                </Button>
                                            </CPCard>

                                        </>
                                        :
                                        null
                                }
                            </>
                        }
                    </div>


                    <div className='content_lubricantes'>
                        <div>
                            {referencias === null ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#fff' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
                                :
                                referencias.length > 0 ?

                                    <div>
                                        {
                                            filtroReferenciaLocal(referencias).map((item, i) =>
                                                (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final ?

                                                    <div key={i}>
                                                        <div className="row" style={{ padding: '10px', color: item.REFERENCIA_COMERCIAL === "" ? '#999' : '#000' }}>


                                                            <MDBCol md="2">
                                                                <div style={{ cursor: 'pointer' }}>
                                                                    <ImagenArticulo
                                                                        REGISTRO={
                                                                            {
                                                                                detalles: { IMAGEN_URL: item.IMAGEN_URL },
                                                                                LOADER: false
                                                                            }
                                                                        }
                                                                        DIRECTORIO_DOCUMENTO={""}
                                                                    />

                                                                    <div style={{ textAlign: 'center', fontSize: '16px', fontWeight: '500' }}>
                                                                        {item.MARCA}
                                                                    </div>
                                                                </div>
                                                            </MDBCol>

                                                            <MDBCol md="7">
                                                                <div className="nombreArticulo">
                                                                    <strong>
                                                                        <span id={"referenciaFABRICopiarAPortapapel" + item.REFERENCIA_COMERCIAL} className="refComercial"> {item.REFERENCIA_COMERCIAL} </span>

                                                                        <CopyOutlined onClick={(e) => { copiarAlPortapapeles("referenciaFABRICopiarAPortapapel" + item.REFERENCIA_COMERCIAL) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />
                                                                    </strong>
                                                                    <br />

                                                                    {item.CODIGO !== null ? <span id={"referenciaDisCopiarAPortapapel" + item.CODIGO}>{"Referencia distribuidor: " + item.CODIGO}</span> : null}
                                                                    {item.CODIGO !== null ? <CopyOutlined onClick={(e) => { copiarAlPortapapeles("referenciaDisCopiarAPortapapel" + item.CODIGO) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} /> : null}

                                                                    <div className="clearfix"></div>
                                                                </div>

                                                                <div className="clearfix"></div>



                                                                <div className="atributosMarca" >
                                                                    <span> {item.DESCRIPCION !== "" ? item.DESCRIPCION : ""} </span>
                                                                </div>


                                                                <div className="atributosMarca"> {item.PROPIEDADES !== undefined ? <div dangerouslySetInnerHTML={{ __html: propiedadesReferencia(item.PROPIEDADES) }} ></div> : null}</div>


                                                            </MDBCol>

                                                            <MDBCol md="3" style={{ textAlign: 'right' }}>

                                                                <Col md={configApp.width < 1450 ? 24 : 12}>
                                                                    {
                                                                        verificarCantidadCesta(item, COMPRAR_SIN_DISPONIBILIDAD) ?
                                                                            null
                                                                            :
                                                                            <CPCantidad
                                                                                value={item.CANTIDAD_CLIENTE}
                                                                                event_input={(e) => {
                                                                                    if (item.CANTIDAD_CLIENTE !== null) {
                                                                                        let valor = e.target.value;
                                                                                        if (valor === "") {
                                                                                            item.CANTIDAD_CLIENTE = e.target.value;
                                                                                        } else {
                                                                                            if (soloNumeros(valor)) {
                                                                                                let convertir = e.target.value
                                                                                                let resultado = item.STOCK_TOTAL > convertir ? e.target.value : item.STOCK_TOTAL;
                                                                                                item.CANTIDAD_CLIENTE = valor === "" ? "0" : resultado
                                                                                            } else {
                                                                                                item.CANTIDAD_CLIENTE = "1";
                                                                                            }
                                                                                        }
                                                                                        setReferencia([...referencias])
                                                                                    }
                                                                                }}

                                                                                event_minus={() => {
                                                                                    if (item.CANTIDAD_CLIENTE !== null) {
                                                                                        let valor = item.CANTIDAD_CLIENTE
                                                                                        if (valor) {
                                                                                            if (parseInt(valor) <= 1) {
                                                                                                item.CANTIDAD_CLIENTE = "1";
                                                                                            } else {
                                                                                                item.CANTIDAD_CLIENTE = parseInt(valor) - 1;
                                                                                            }
                                                                                        } else {
                                                                                            item.CANTIDAD_CLIENTE = "1";
                                                                                        }
                                                                                        setReferencia([...referencias])
                                                                                    }
                                                                                }}

                                                                                event_plus={() => {
                                                                                    if (item.CANTIDAD_CLIENTE !== null) {
                                                                                        let valor = item.CANTIDAD_CLIENTE

                                                                                        if (valor) {
                                                                                            item.CANTIDAD_CLIENTE = item.STOCK_TOTAL > parseInt(valor) ? parseInt(valor) + 1 : parseInt(valor);
                                                                                        } else {
                                                                                            item.CANTIDAD_CLIENTE = "1";
                                                                                        }
                                                                                        setReferencia([...referencias])
                                                                                    }
                                                                                }}

                                                                            />
                                                                    }

                                                                </Col>


                                                                <Col md={configApp.width < 1450 ? 24 : 12}>
                                                                    <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                        añadirALaCesta(item, item.CANTIDAD_CLIENTE === null ? "1" : item.CANTIDAD_CLIENTE)
                                                                    }}>
                                                                        <ShoppingOutlined style={{ fontSize: '20px' }} />
                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{!verificarSiEsMostrador() ? traducirPagina("Comprar") : "Vender"}</span>

                                                                    </button>
                                                                </Col>


                                                                {!verificarSiEsMostrador() ?
                                                                    <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', /*display: verficarSiEsCatalogo(usuario) ? 'none' : ''*/ }} onClick={(e) => {
                                                                        item.CANTIDAD = item.CANTIDAD_CLIENTE === null ? "1" : item.CANTIDAD_CLIENTE
                                                                        agregarAPresupuesto(item)

                                                                    }}>
                                                                        <FileAddOutlined style={{ fontSize: '20px' }} />
                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Añadir_presupuesto")}</span>
                                                                    </button>
                                                                    :
                                                                    null
                                                                }


                                                                <button onClick={(e) => {
                                                                    abrirDesglose(item)
                                                                }} className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red', backgroundColor: 'transparent', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }}>
                                                                    {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                        <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'sub' }} />
                                                                        : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'baseline' }} />}

                                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                                                        {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ? " " + traducirPagina("No_disponible") + " " : obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE ? traducirPagina("disponible") : obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? traducirPagina("disponible") : " " + traducirPagina("Stock") + " " + item.STOCK_ALMACEN + "/" + item.STOCK_TOTAL}
                                                                    </span>
                                                                </button>



                                                                <div>
                                                                    {
                                                                        /*
                                                                        verificarSiEsMostrador() && visualizarStocksRemotos() ?
                                                                            <div>
                                                                                <button className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'transparent', marginBottom: '5px' }}
                                                                                    onClick={(e) => {

                                                                                        setRegistroStockSocio({ REFERENCIA_TECDOC: item.CODIGO, MARCA_TECDOC: item.CODIGO_MARCA, TIPO_ARTICULO:2  })
                                                                                        setAbrirFichaStockSocio(true)


                                                                                    }}>
                                                                                    <DeploymentUnitOutlined style={{ fontSize: '24px', marginRight: '5px' }} />

                                                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Stock_grupo")}</span>
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                            */
                                                                    }
                                                                </div>



                                                                <div>
                                                                    <div style={{ height: '25px', width: '100%', display: verficarSiEsPVP(usuario) ? '' : 'none' }} >{traducirPagina("PVP").toUpperCase() + ": " + convertirMoneda(item.PRECIO, " €")}</div>
                                                                    <div style={{ height: '25px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>

                                                                    <div style={{ display: visualizacionDescuento ? '' : 'none' }}>
                                                                        <span style={{ height: '25px' }} >{traducirPagina("Dto") + ": " + item.DESCUENTO + " %"} </span>
                                                                        <br />
                                                                        <span style={{ height: '25px' }} >{traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}</span>
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                            </MDBCol>
                                                        </div>
                                                        <hr></hr>
                                                    </div>
                                                    :
                                                    null
                                            )}
                                    </div>

                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                            }
                        </div>


                    </div>
                </div>
            </Drawer>


            {fichaCesta ?
                <FichaAñadirCesta
                    title={traducirPagina("Añadir_referencia_a_la_cesta").toUpperCase()}
                    ABRIR_FICHA={fichaCesta}
                    CERRAR_FICHA={setFichaCesta}
                    DATOS_REFERENCIA={referenciaSeleccionada}
                    ES_CESTA_COMPRA={false}
                >
                </FichaAñadirCesta>
                :
                null
            }


            {
                abrirFichaStockSocio ?
                    <FichaStockSocio
                        title={`${traducirPagina("Stock_grupo").toUpperCase()}`}
                        ABRIR_FICHA={abrirFichaStockSocio}
                        CERRAR_FICHA={setAbrirFichaStockSocio}
                        DATOS_SELECCIONADO={registroStockSocio}
                    >
                    </FichaStockSocio>
                    :
                    null
            }

        </div>




    )
}

FichaLubricantesERP.propTypes = {
    TIPO: PropTypes.string,
    CERRAR: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    VISCOSIDAD: PropTypes.string,
    CALIDAD: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado


    };
}

export default connect(mapStateToProps)(FichaLubricantesERP);