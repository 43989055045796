import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple} from "../action/actionCreators"
import { traducirPagina, MSGOK, MSGERROR, efectoRemoveBuscadorReferencia, obtenerFechaLocal, convertirMoneda, dynamicSortDesc, crearLineasParaElPresupuesto } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';

import {
    ArrowDownOutlined
} from '@ant-design/icons';

import { Select, Card, Modal, Button, Checkbox, Row, Col, Drawer } from 'antd';

import store from "../store";
const { Option } = Select;
const FichaDetalleAlbaranCompra = ({ props, configApp, openDrawerFichaAlbaranesTaller }) => {

    const [hayDatos, setHayDatos] = React.useState(false);
    const [payLoadLocal, setpayLoadLocal] = React.useState({
        IMPORTE_BRUTO_1: 0,
        IMPORTE_DESCUENTOS_1: 0,
        IMPORTE_BASE_IMPONIBLE_1: 0,
        VALOR_IVA_1: 0,
        IMPORTE_IMPUESTOS_1: 0,


        IMPORTE_BRUTO: 0,
        IMPORTE_DESCUENTOS: 0,
        IMPORTE_BASE_IMPONIBLE: 0,
        IMPORTE_IMPUESTOS: 0,
        IMPORTE_TOTAL: 0

    });

    const albaranSeleccionado = (registro, estaSeleccionado, seleccionarTodos) => {
        props.REGISTRO.LINEAS.map((linea, i) => {
            if (seleccionarTodos) {
                linea.SELECCIONADO = estaSeleccionado;
            } else {
                if (linea.ID === registro.ID) {
                    linea.SELECCIONADO = estaSeleccionado;
                }
            }
        })
        props.MODIFICAR_LINEA({ ...props.REGISTRO })
    }

    const cargaAutomaticaDeLineas = () => {
        let LINEAS_SELECCIONADAS = props.REGISTRO.LINEAS.filter((linea) => linea.SELECCIONADO === true)

        LINEAS_SELECCIONADAS.map((articulo, indice) => {
            let cantidadLocal = parseFloat(articulo.cantidad)
            if (cantidadLocal > 0) {
                let itemArticulo = {};

                itemArticulo = {
                    PRECIO: parseFloat(articulo.precio),
                    REFERENCIA_PROVEEDOR: articulo.referencia,
                    REFERENCIA: articulo.referencia,
                    CODIGO_MARCA_ERP: articulo.marca,
                    NOMRE_MARCA: "",
                    CODIGO_MARCA: 0,
                    NOMBRE_GRUPO_MONTAJE: articulo.descripcion,
                    DESCUENTO: articulo.dto,
                    CANTIDAD: cantidadLocal
                }


                crearLineasParaElPresupuesto(itemArticulo, "A", true)
            }
        })



        MSGOK(traducirPagina("Referencia_agregada"))
        albaranSeleccionado(null, false, true)

    }


    if (!hayDatos && props.VER_FICHA) {
        setHayDatos(true);
        albaranSeleccionado(null, false, true)

        setpayLoadLocal({
            IMPORTE_BRUTO_1: 0,
            IMPORTE_DESCUENTOS_1: 0,
            IMPORTE_BASE_IMPONIBLE_1: 0,
            VALOR_IVA_1: 0,
            IMPORTE_IMPUESTOS_1: 0,


            IMPORTE_BRUTO: 0,
            IMPORTE_DESCUENTOS: 0,
            IMPORTE_BASE_IMPONIBLE: props.REGISTRO.CABECERA.baseImponible,
            IMPORTE_IMPUESTOS: props.REGISTRO.CABECERA.totalIva,
            IMPORTE_TOTAL: props.REGISTRO.CABECERA.totalAlbaran
        })


    } else {
        if (!props.VER_FICHA && hayDatos) {
            setHayDatos(false);

        }
    }



    return (
        <Drawer
            width={1000}
            onClose={() => { props.CERRAR_FICHA(false) }}
            visible={props.VER_FICHA}
            closable={false}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            props.CERRAR_FICHA(false);
                            props.MODIFICAR_LINEA({})
                            createRipple(e)
                        }}>
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>

                <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                    <button className="col-md-12" disabled={props.REGISTRO.LINEAS.filter((linea) => linea.SELECCIONADO === true).length > 0 ? false : true}
                        style={{
                            height: '40px', width: '300px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                            color: props.REGISTRO.LINEAS.filter((linea) => linea.SELECCIONADO === true).length > 0 ? '#fff' : '#999',
                            backgroundColor: props.REGISTRO.LINEAS.filter((linea) => linea.SELECCIONADO === true).length > 0 ? '#4285f4' : '#f7f7f7',
                            marginBottom: '5px', marginRight: '5px'
                        }} onClick={(e) => {
                            cargaAutomaticaDeLineas()
                        }}>
                        <ArrowDownOutlined style={{ fontSize: '20px' }} />
                        <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{"DESCARGAR EN PRESUPUESTO"}</span>
                    </button>
                </div>
            </div>


            <div className="p-2" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                <div style={{ height: (configApp.height - 170).toString() + "px" }} className="mdb-skin" >
                    <Card className="mb-2" bordered={false}>
                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                            <Col md={6}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Codigo_albaran")}</span>
                            </Col>

                            <Col md={6}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Codigo_serie")}</span>
                            </Col>

                            <Col md={6}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Fecha_albaran")}</span>
                            </Col>

                            <Col md={6}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Almacen")}</span>
                            </Col>


                            <Col md={6}>
                                <span>{props.REGISTRO.CABECERA.codigoalbaran}</span>
                            </Col>

                            <Col md={6}>

                                <span>{props.REGISTRO.CABECERA.codigoserie}</span>
                            </Col>

                            <Col md={6}>
                                <span>{props.REGISTRO.CABECERA.fechaAlbaran}</span>
                            </Col>

                            <Col md={6}>
                                <span>{props.REGISTRO.CABECERA.idCentroVenta}</span>
                            </Col>
                        </Row>

                    </Card>

                    <Card bordered={false}>
                        {props.REGISTRO !== undefined ?
                            props.REGISTRO === null ?
                                <div style={{ textAlign: 'center' }}>
                                    <h4>{traducirPagina("No_albaranes")}</h4>
                                </div>
                                :
                                Object.keys(props.REGISTRO).length > 0 ?

                                    <div style={{ height: (configApp.height - 400).toString() + "px", overflow: 'auto' }} >
                                        {props.REGISTRO.LINEAS.length === 0 ?
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>{traducirPagina("No_articulos")}</h4>
                                            </div>
                                            :
                                            <MDBTable responsive hover className="mdb-skin">
                                                <MDBTableHead>
                                                    <tr>
                                                        <th style={{ width: '20px', verticalAlign: 'baseline' }}>{<Checkbox style={{ marginLeft: '7px' }} onChange={(e) => { albaranSeleccionado(null, e.target.checked, true) }}> </Checkbox>}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'baseline' }}>{traducirPagina("Referencia")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline' }}>{traducirPagina("Descripción")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '40px', verticalAlign: 'baseline' }}>{traducirPagina("Uds")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '90px', verticalAlign: 'baseline' }}>{traducirPagina("Imp_bruto")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '100px', verticalAlign: 'baseline' }}>{traducirPagina("Dto")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110x', verticalAlign: 'baseline' }}>{traducirPagina("B_imponible")}</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {props.REGISTRO.LINEAS.length > 0 ?
                                                        props.REGISTRO.LINEAS.map((articulo, i) =>
                                                            <tr key={i} style={{ color: '#666' }}>
                                                                <td style={{ width: '20px', verticalAlign: 'inherit' }}>{<Checkbox checked={articulo.SELECCIONADO} onChange={(e) => { albaranSeleccionado(articulo, e.target.checked, false) }}> </Checkbox>}</td>

                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'inherit' }}>{articulo.referencia}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'inherit' }}>{articulo.descripcion}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '40px', verticalAlign: 'inherit', textAlign: 'right' }}>{articulo.cantidad}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '90px', verticalAlign: 'inherit', textAlign: 'right' }}>{convertirMoneda(articulo.precio, "€")}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '100px', verticalAlign: 'inherit', textAlign: 'right' }}>{convertirMoneda(articulo.dto, "%")}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'inherit', textAlign: 'right', color: '#000' }}>{convertirMoneda(articulo.netolinea, "€")}</td>

                                                            </tr>
                                                        )
                                                        : null
                                                    }
                                                </MDBTableBody>
                                            </MDBTable>
                                        }
                                    </div>

                                    :

                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                        <MDBCol md="9">
                                            <div>
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                            </div>
                                        </MDBCol>
                                    </div>
                            : null}
                    </Card>

                    <Card style={{ color: '#000', marginTop: '10px' }} bordered={false}>
                        <MDBTable>
                            <MDBTableHead>
                                <tr style={{ color: '#666' }}>
                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("B_imponible").toUpperCase()}</th>
                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("Impuestos").toUpperCase()}</th>
                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline', textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7' }}>{traducirPagina("Total").toUpperCase()}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr style={{ color: '#666' }}>
                                    <td style={{ textAlign: 'right' }}>{payLoadLocal.IMPORTE_BASE_IMPONIBLE}</td>
                                    <td style={{ textAlign: 'right' }}>{payLoadLocal.IMPORTE_IMPUESTOS}</td>
                                    <td style={{ textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7', fontSize: '20px' }}>{payLoadLocal.IMPORTE_TOTAL}</td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </Card>

                </div>
            </div>
        </Drawer>
    )
}


FichaDetalleAlbaranCompra.propTypes = {
    title: PropTypes.string,
    VER_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    REGISTRO: PropTypes.object,
    MODIFICAR_LINEA: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaAlbaranesTaller: state.estadoDrawer.openDrawerFichaAlbaranesTaller,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado
    };
}

export default connect(mapStateToProps)(FichaDetalleAlbaranCompra);