import React, { useState } from "react";
import PropTypes from 'prop-types';

import {
    MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody
} from 'mdbreact';
import ComponentInputCoche from '../components/cpInputCoche'
import { getFocus, traducirPagina } from "../librerias/funciones"
import { connect } from 'react-redux';
import store from "../store";
import { removeAllEngineType, setHistoricApp, filterList, changeColorListItem, showItemCar } from "../action/actionCreators";
import { getEngineType } from "../action/actionCreatorsRequests";
import { Tag } from "antd";
import CPSearch from "./utils/CPSearch";

const DrawerModelo = ({ fichaBusquedaVehiculo, props }) => {
    const [textoInput, setTextoInput] = useState("")


    if (Object.keys(fichaBusquedaVehiculo.models).length > 0) {
        getFocus("imputdropmodelos");
    }

    /*
    
      let resultadoFiltro = lista.aux.filter((item) => {
            let palabraClave = eval(clave).trim().toUpperCase();

            return palabraClave.toString().toUpperCase().includes(palabra) ||
            palabraClave.toString().toUpperCase() === 

        })
    */

    const cambiosTexbox = (texto) => {
        //console.log("pruebas texto",texto)
    }



    const verificarNull = (item) => {
        return item === null ? "" : item
    }

    return (
        <MDBRow>
            <MDBCol md="12">
                {
                    fichaBusquedaVehiculo.models.original === null || fichaBusquedaVehiculo.models.original === undefined ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                        :
                        Object.keys(fichaBusquedaVehiculo.models).length > 0 ?
                            Object.keys(fichaBusquedaVehiculo.models.aux).length > 0 ?
                                <div className="mdb-skin">

                                    <div>
                                        <CPSearch
                                            className='mdb-skin searchVehiculo'
                                            TEXTO_BUSCADOR={textoInput}
                                            placeholder={traducirPagina("TextoBuscadorDeModelos")}
                                            onChangeInput={(e) => {
                                                //buscarEnTabla(e.target.value);
                                                setTextoInput(e.target.value)
                                            }}
                                            onCloseInput={() => {
                                                setTextoInput("");
                                                //buscarEnTabla("")
                                            }}
                                        />
                                    </div>

                                    {/* 
                                    <div className="mt-3 pb-2">
                                        <ComponentInputCoche
                                            listaValoresDrop={'{"nombreDrop":"","placeholder":"Búscador de modelos","listaitem":[],"id":"dropmodelos"}'}
                                            EVENTO_CHANGE={cambiosTexbox}
                                        >
                                        </ComponentInputCoche>
                                    </div>
*/}

                                    <div className="container_grid_coche">
                                        <MDBTable hover>
                                            <MDBTableHead>
                                                <tr className="table_modelos_vehiculos">
                                                    <th style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</th>
                                                    <th style={{ width: '100px', textAlign: 'left' }}>{traducirPagina("Desde")}</th>
                                                    <th style={{ width: '100px', textAlign: 'left' }}>{traducirPagina("Hasta")}</th>
                                                    <th style={{ width: '100px', textAlign: 'center' }}></th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {fichaBusquedaVehiculo.models.original
                                                    .filter((item) =>
                                                        verificarNull(item.DESCRIPCION).toUpperCase() === textoInput.toUpperCase() ||
                                                        verificarNull(item.DESCRIPCION).toUpperCase().includes(textoInput.toUpperCase()))
                                                    .map((row, i) =>
                                                        <tr key={i} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                store.dispatch(getEngineType(row, props.TIPO_VEHICULO));
                                                                store.dispatch(removeAllEngineType()); autosc();
                                                                store.dispatch(setHistoricApp(row, "/modelos", "modelo"));
                                                                store.dispatch(filterList("", fichaBusquedaVehiculo.models, 'item.fullName', "GET_MODEL_VEHICLE"));
                                                                store.dispatch(showItemCar("3"))
                                                            }}>
                                                            <td>{row.DESCRIPCION}</td>
                                                            <td>{row.DESDE === "" ? "..." : row.DESDE}</td>
                                                            <td>{row.HASTA === "" ? "..." : row.HASTA}</td>
                                                            <td>{row.ETIQUETA !== undefined && row.ETIQUETA !== null && row.ETIQUETA !== "" ? <Tag className="btnColor">{row.ETIQUETA} </Tag> : null}</td>

                                                        </tr>
                                                    )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>




                                </div>
                                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>
                            : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                }
            </MDBCol>
        </MDBRow>

    );
}

DrawerModelo.propTypes = {
    TIPO_VEHICULO: PropTypes.number,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        configApp: state.configApp,

        localreferences: state.localreferences,
        localvehicles: state.localvehicles,
        childrenDrawerLevel2: state.childrenDrawerLevel2
    };
}

const autosc = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export default connect(mapStateToProps)(DrawerModelo);





