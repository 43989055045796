import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBCol, MDBTable, MDBTableBody } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer, Checkbox, Col, Layout, Pagination, Button, Modal } from 'antd';
import {
    createRipple, DrawerCarroCompra, DrawerMasInformacion,
    actulizarReferenciaMasInfo2, actulizarReferenciaSeleccionada,
    DrawerFichaMasInformacion2, DrawerStockAlmacen, efectoBotonRemove
} from "../action/actionCreators";

import { ObtenerReferenciasByNodoGraphicAndVehicleEnAPI, despiezeByReferenciaMarca, buscarDetallesReferencia, pad, abortFetch, ObtenerEquivalenciasByArtNoBrandNoEnAPI, ObtenerReferenciasMarcadeUnVehiculoEnAPI, ObtenerEquivalenciasArticuloByArtoOEMEnAPI, getStockDesglose, setCestaLineasEnAPI, ObtenerReferenciasByNodoGraphicCarroceriaAndVehicleEnAPI, buscarPrecioReferencia, ObtenerReferenciasBateriasByVehicleEnAPI, ObtenerArticulosSugeridosEnAPI, Obtener_CatalogoEnAPI } from "../action/actionCreatorsRequests";
import {
    traducirPagina, mostrarPaginaActual, obtenerListaOrdenadaPorStock, referenciaActualSelect, convertirMoneda, obtenerPaginas, limpiarGrupoDrawer, MSGOK, limpiarCocheStore, efectoLadoDeMontaje, verificarSiEsMostrador, verficarSiEsPVP,
    mostarReferenciasGenericasEnPantalla, verficarSiEsCatalogo, colorCabeceraPorElPrecioDeLaReferencia, colorCabeceraFuente, buscarReferenciaYMostrarEnPantalla, copiarAlPortapapeles, crearLineasParaElPresupuesto, MSGERROR, obtenerDatosUsuarioYLicenciaActual,
    soloNumeros, buscarVehiculoLocal, cestaAutomatica, condicionesAbrirCesta, dynamicSortAsc, recortarCadena, visualizarStocksRemotos, filtroLadoMontaje,
    datosLincenciaUsuario, verificarCantidadCesta, es_Sugerido, idModeloLatam, condicionesAbrirCestav2,
    dynamicSortDesc,
    ObtenerIDTipoVehiculo
} from "../librerias/funciones"
import FichaAñadirCesta from "./FichaAñadirCesta.jsx";
import FichaStockSocio from "./FichaStockSocio.jsx";
import { ImagenArticulo } from "./ImagenArticulo"



import store from '../store'
import {
    CloseOutlined,
    SearchOutlined,
    ShoppingOutlined,
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    FileImageOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    EyeInvisibleOutlined,
    ControlOutlined,
    ApartmentOutlined,
    CopyOutlined,
    ClockCircleOutlined,
    FileAddOutlined,
    MinusOutlined,
    PlusOutlined,
    DeploymentUnitOutlined,
    BulbOutlined
} from '@ant-design/icons';
import { FichaReferenciaSugerida } from './Compra/FichaReferenciaSugerida.jsx';
import CPLadoMontaje from './utils/CPLadoMontaje';
import CPCantidad from './utils/CPCantidad.jsx';

const { Content } = Layout;

const ContainerReferenciaGenerico = ({ props, configApp, openDrawerReferenciaGrafico, codmotorizacion, usuario, openDrawerMasInfo, openDrawerFichaReferenciaVehiculo, openDrawerFichaEquivalenciaArticulo, clienteSeleccionado, openDrawerReferenciaGraficoCarroceria, openDrawerFichaBateriasVehiculo, DIRECTORIO_DOCUMENTO }) => {

    const ALTURA_CONTENEDOR = props.ALTURA === undefined || props.ALTURA === null ? 158 : props.ALTURA
    const OBTENER_DATOS_USUARIO = datosLincenciaUsuario();
    const { ACTIVAR_BOTON_VER_REFERENCIAS_CON_CRUCE } = OBTENER_DATOS_USUARIO === undefined ? { ACTIVAR_BOTON_VER_REFERENCIAS_CON_CRUCE: false } : OBTENER_DATOS_USUARIO;
    const { COMPRAR_SIN_DISPONIBILIDAD } = OBTENER_DATOS_USUARIO === undefined ? { COMPRAR_SIN_DISPONIBILIDAD: false } : OBTENER_DATOS_USUARIO;
    const [referenciaSugerida, setReferenciaSugerida] = React.useState(false);



    const [abrirFichaStockSocio, setAbrirFichaStockSocio] = React.useState(false);
    const [registroStockSocio, setRegistroStockSocio] = React.useState({});


    const { PERMISO_DOCUMENTACION_TECNICA } = usuario.LoginSertec.List_LicenciaSERTE[0];

    const [colapseMarca, setColapseMarca] = React.useState(true);
    const [visualizacionDescuento, setVisualizacionDescuento] = React.useState(true);
    const [grupoMarcasLocal, setGrupoMarcasLocal] = React.useState({});
    const [esConLado, setEsConLado] = React.useState(false);
    const [hayReferencias, setHayReferencias] = React.useState(false);
    const [verFiltros, setVerFiltros] = React.useState(false);
    const [obtenerReferencia, setObtenerReferencia] = React.useState({});
    const [pagination, setPagination] = React.useState({});
    const [todasLasMarcas, setMarcas] = React.useState([]);
    const [todosLosGrupos, setGrupos] = React.useState([]);
    const [mostrarFilrosComponente, setMostrarFilrosComponente] = React.useState(true);
    const [checkedListMarcas, setCheckedListMarcas] = React.useState([]);
    const [checkedListGrupos, setCheckedListGrupos] = React.useState([]);
    const [listaLadosSeleccionados, setListaLadosSeleccionados] = React.useState([]);
    const [visible, setVisible] = React.useState(false);

    const [fichaCesta, setFichaCesta] = React.useState(false);
    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});


    const [listaDeReferenciasDeLaPaginaActual, setListaDeReferenciasDeLaPaginaActual] = React.useState([]);



    const [hayReferenicaAMostrar, setHayReferenicaAMostrar] = React.useState(false);
    const [vistaReferencia, setVistaReferencia] = React.useState({
        POR_PRECIO: false,
        POR_DISPONIBILIDAD: true,
        POR_REFERENCIA_CON_PRECIO: ACTIVAR_BOTON_VER_REFERENCIAS_CON_CRUCE
    });



    let listaDeMarcasDeLaPaginaActual = [];
    let listaDeReferencias = [];
    let crearListaTemporalDeReferencia = []


    const actulizarMarcasSeleccionadas = list => {
        setCheckedListMarcas(list);
        actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, list, checkedListGrupos, esConLado)
    };

    const actulizarGruposSeleccionadas = list => {
        setCheckedListGrupos(list);
        actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, checkedListMarcas, list, esConLado)
    };

    const buscarDetalleReferencia = async (grupo) => {
        const respuesta = await grupo.map(async (registro, i) => {
            const detalle = await registro.referencias.map(async (referencia) => {
                let data = undefined;
                referencia.LOADER = null

                switch (props.tipoComponente) {
                    case "articulosSugeridos":
                        let nuevoPayload = JSON.parse(JSON.stringify(referencia));
                        nuevoPayload.TIPO_VEHICULO = "ARTICULOS_SUGERIDOS"
                        nuevoPayload.CODIGO_MOTOR = props.referencia
                        nuevoPayload.ID_GRUPO_MONTAJE = props.marca


                        data = await buscarDetallesReferencia(nuevoPayload);
                        referencia.LOADER = true
                        referencia.detalles = data;
                        break;
                    default:
                        if (referencia.CODIGO_MARCA !== 0 || (referencia.CODIGO_MARCA === 0 && referencia.NOMRE_MARCA === "ADICIONAL" && referencia.CODIGO_MARCA_ERP !== "0")) {
                            data = await buscarDetallesReferencia(referencia);
                            referencia.LOADER = true
                            referencia.detalles = data;
                        }
                        break;
                }



                return data;
            })

            await Promise.all(detalle);
            return registro
        })

        Promise.all(respuesta).then(registros => {
            setListaDeReferenciasDeLaPaginaActual([...registros])
        })

    }

    const buscarPrecios = async (grupo) => {
        const respuesta = await grupo.map(async (registro, i) => {

            const precios = await registro.referencias.map(async (referencia) => {
                const data = await buscarPrecioReferencia(referencia);
                referencia.HAY_PRECIO = true


                if (data !== undefined) {
                    referencia.PRECIO = data.PRECIO
                    referencia.NETO = data.NETO
                    referencia.DESCUENTO = data.DESCUENTO
                    referencia.DESCUENTO_ACTUALIZADO = true
                    referencia.PVP_RECOMENDADO = data.PVP
                }


                /*
                                if (document.getElementById("CANTIDADGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                                    document.getElementById("CANTIDADGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA).value = "1";
                                }
                
                                if (document.getElementById("CANTIDADGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                                    document.getElementById("CANTIDADGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA).value = "1";
                                }
                
                                //Esperanndo que cargue los precios
                
                
                                if (document.getElementById("COMPRAGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                                    document.getElementById("COMPRAGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA).style.visibility = "visible";
                                }
                                if (document.getElementById("PRESUPUESTOGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                                    document.getElementById("PRESUPUESTOGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA).style.visibility = "visible";
                                }
                                if (document.getElementById("COMPRAGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                                    document.getElementById("COMPRAGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA).style.visibility = "visible";
                                }
                */

                return data;
            })

            await Promise.all(precios);
            return registro
        })


        Promise.all(respuesta).then(registros => {

            registros.map((gruposMarcas) => {
                let verdes = []
                let naranjas = []
                let rojos = []
                let rojos_sin_precio = []

                gruposMarcas.referencias.map((item) => {
                    if (item.STOCK_ALMACEN > 0) {
                        verdes.push(item)
                    }

                    if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0) {
                        naranjas.push(item)
                    }

                    if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO > 0) {
                        rojos.push(item)
                    }

                    if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0) {
                        rojos_sin_precio.push(item)
                    }

                })

                verdes.sort(dynamicSortAsc("NETO"))
                naranjas.sort(dynamicSortAsc("NETO"))
                rojos.sort(dynamicSortAsc("NETO"))
                let preciosFinal = verdes.concat(naranjas).concat(rojos).concat(rojos_sin_precio);

                gruposMarcas.referencias = preciosFinal;
            })

            setListaDeReferenciasDeLaPaginaActual([...registros])
        })
    }


    const visualizarReferencias = (listaDeMarcasDeLaPaginaActual) => {
        let listaDeReferenciasDeLaPaginaActual = [];
        let listaDeReferencias = [];

        listaDeMarcasDeLaPaginaActual.map((marca, i) => {
            listaDeReferencias = obtenerReferencia.referencias.filter(referencia => referencia.NOMRE_MARCA === marca);
            listaDeReferencias = obtenerListaOrdenadaPorStock(listaDeReferencias);

            if (listaDeReferencias.length > 0) {


                listaDeReferenciasDeLaPaginaActual.push({
                    nombreMarca: marca,
                    referencias: listaDeReferencias
                })
            }
        })


        return listaDeReferenciasDeLaPaginaActual;

    }


    const mostrarReferenciaEnPantalla = async (obtenerReferencia) => {

        if (obtenerReferencia !== null && !hayReferenicaAMostrar) {
            if (hayReferencias && Object.keys(obtenerReferencia).length > 0 && Object.keys(pagination).length > 0) {
                let REFERENCIAS_LOCAL = obtenerReferencia.referencias === undefined || obtenerReferencia.referencias === null ? [] : obtenerReferencia.referencias

                listaDeMarcasDeLaPaginaActual = mostrarPaginaActual(obtenerReferencia, pagination);
                const pagLocal = visualizarReferencias(listaDeMarcasDeLaPaginaActual);
                pagLocal.map((item) => { item.referencias.map((registro) => registro.CANTIDAD_CLIENTE = [0, undefined].includes(registro.CANTIDAD_MINIMA) ? 1 : registro.CANTIDAD_MINIMA) })

                setListaDeReferenciasDeLaPaginaActual(pagLocal)
                buscarDetalleReferencia(pagLocal)
                buscarPrecios(pagLocal)

                setHayReferenicaAMostrar(true)
                setMostrarFilrosComponente(true)

            }
        }

    }



    const actualizarRegistros = (referencia, marcasSeleccionadas, gruposSeleccionados, esConLado, ladosSeleccionados) => {
        let objetoRegistro = mostarReferenciasGenericasEnPantalla(referencia, marcasSeleccionadas, gruposSeleccionados, ladosSeleccionados)
        setHayReferenicaAMostrar(false)
        setEsConLado(esConLado);
        setGrupoMarcasLocal(objetoRegistro)
        setObtenerReferencia(objetoRegistro.Referencias)
        setPagination(objetoRegistro.Pagination)
        setMarcas(objetoRegistro.Referencias.marcas);
        setGrupos(objetoRegistro.Referencias.grupoMontaje);
        return objetoRegistro;
    }


    const limpiarEstadoLocal = () => {
        setMarcas([]);
        setGrupos([]);
        setObtenerReferencia({})
        setHayReferencias(false)
        setVerFiltros(false)

        setListaDeReferenciasDeLaPaginaActual([])
        setHayReferenicaAMostrar(false)
        setPagination({})
        abortFetch()

        setCheckedListMarcas([])
        setCheckedListGrupos([])
        setMostrarFilrosComponente(true)
    }


    if (props.tipoComponente === "masInfo") {
        if (props.tabActivado !== "6" && hayReferencias) {
            limpiarEstadoLocal()
        }

        if (!hayReferencias && openDrawerMasInfo && props.tabActivado === "6") {
            setHayReferencias(true);
            despiezeByReferenciaMarca(props.referencia, props.marca).then(referencia => {
                if (referencia.LISTADO_DESPIEZE !== null && referencia.LISTADO_DESPIEZE !== undefined) {
                    if (referencia.LISTADO_DESPIEZE.length > 0) {
                        actualizarRegistros(referencia.LISTADO_DESPIEZE, undefined, undefined, referencia.CON_LADO_MONTAJE)

                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                } else {
                    setObtenerReferencia(null)
                    setMarcas([]);
                    setGrupos([]);
                }
            })
        } else {
            if (!openDrawerMasInfo && hayReferencias) {
                limpiarEstadoLocal()
            }
        }

    } else if (props.tipoComponente === "graficos") {
        if (!hayReferencias && openDrawerReferenciaGrafico) {
            setHayReferencias(true);
            setObtenerReferencia({})
            ObtenerReferenciasByNodoGraphicAndVehicleEnAPI(props.referencia, props.marca).then(referencia => {
                if (referencia !== undefined && referencia !== null) {
                    if (referencia.ARTICULOS !== undefined && referencia.ARTICULOS !== null) {
                        if (referencia.ARTICULOS.length > 0) {
                            actualizarRegistros(referencia.ARTICULOS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                } else {
                    setObtenerReferencia(null)
                    setMarcas([]);
                    setGrupos([]);
                }
            })

        } else {
            if (!openDrawerReferenciaGrafico && hayReferencias) {
                limpiarEstadoLocal()
            }
        }

    } else if (props.tipoComponente === "graficosCarroceria") {
        if (!hayReferencias && openDrawerReferenciaGraficoCarroceria) {
            setHayReferencias(true);
            setObtenerReferencia({})
            ObtenerReferenciasByNodoGraphicCarroceriaAndVehicleEnAPI(props.referencia, props.marca).then(referencia => {
                if (referencia !== undefined) {
                    if (referencia.ARTICULOS !== undefined && referencia.ARTICULOS !== null) {
                        if (referencia.ARTICULOS.length > 0) {
                            actualizarRegistros(referencia.ARTICULOS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                } else {
                    setObtenerReferencia(null)
                    setMarcas([]);
                    setGrupos([]);
                }
            })
        } else {
            if (!openDrawerReferenciaGraficoCarroceria && hayReferencias) {
                limpiarEstadoLocal()
            }
        }

    } else if (props.tipoComponente === "referenciaMarca") {
        if (!hayReferencias && openDrawerFichaReferenciaVehiculo) {
            setHayReferencias(true);
            setObtenerReferencia({})

            let ID_MODELO_CATALOGO = store.getState().ID_MODELO_MOTO;
            if (ID_MODELO_CATALOGO === null) {
                ID_MODELO_CATALOGO = idModeloLatam()
            }

            ObtenerReferenciasMarcadeUnVehiculoEnAPI(props.referencia, props.marca, props.NOMBRE_MARCA, ID_MODELO_CATALOGO).then(referencia => {
                if (referencia !== undefined) {
                    if (referencia.REFERENCIAS !== null && referencia.REFERENCIAS !== undefined) {
                        if (referencia.REFERENCIAS.length > 0) {
                            actualizarRegistros(referencia.REFERENCIAS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                } else {
                    setObtenerReferencia(null)
                    setMarcas([]);
                    setGrupos([]);
                }
            })

        } else {
            if (!openDrawerFichaReferenciaVehiculo && hayReferencias) {
                limpiarEstadoLocal()
            }
        }
    } else if (props.tipoComponente === "equivalenciaArticulo") {
        if (!hayReferencias && openDrawerFichaEquivalenciaArticulo) {
            setHayReferencias(true);
            setObtenerReferencia({})
            ObtenerEquivalenciasArticuloByArtoOEMEnAPI(props.marca, props.referencia, props.artno,props.vehiculoTecdoc,props.idFabricante,props.tipoVehiculo).then(referencia => {

                if (referencia !== undefined) {
                    if (referencia.EQUIVALENCIAS !== null && referencia.EQUIVALENCIAS !== undefined) {
                        if (referencia.EQUIVALENCIAS.length > 0) {
                            actualizarRegistros(referencia.EQUIVALENCIAS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                }
            })
        } else {
            if (!openDrawerFichaEquivalenciaArticulo && hayReferencias) {
                limpiarEstadoLocal()
            }
        }
    } else if (props.tipoComponente === "Equivalencia") {
        if (props.tabActivado !== "8" && hayReferencias) {
            limpiarEstadoLocal()
        }
        if (!hayReferencias && openDrawerMasInfo && props.tabActivado === "8") {
            setHayReferencias(true);
            ObtenerEquivalenciasByArtNoBrandNoEnAPI(props.marca, props.referencia, props.catalogo).then(referencia => {
                if (referencia !== undefined) {
                    if (referencia.EQUIVALENCIAS !== null && referencia.EQUIVALENCIAS !== undefined) {
                        if (referencia.EQUIVALENCIAS.length > 0) {
                            actualizarRegistros(referencia.EQUIVALENCIAS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                }
            })
        } else {
            if (!openDrawerMasInfo && hayReferencias) {
                limpiarEstadoLocal()
            }
        }
    } else if (props.tipoComponente === "BateriasVehiculo") {
        if (!hayReferencias && openDrawerFichaBateriasVehiculo) {
            setHayReferencias(true);
            setObtenerReferencia({})
            ObtenerReferenciasBateriasByVehicleEnAPI(props.referencia).then(referencia => {
                if (referencia !== undefined) {

                    if (referencia.ARTICULOS !== null && referencia.ARTICULOS !== undefined) {
                        if (referencia.ARTICULOS.length > 0) {
                            actualizarRegistros(referencia.ARTICULOS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                        } else {
                            setObtenerReferencia(null)
                            setMarcas([]);
                            setGrupos([]);
                        }
                    } else {
                        setObtenerReferencia(null)
                        setMarcas([]);
                        setGrupos([]);
                    }
                }
            })
        } else {
            if (!openDrawerFichaBateriasVehiculo && hayReferencias) {
                limpiarEstadoLocal()
            }
        }
    } else if (props.tipoComponente === "CatalogoBateria") {

    }



    const aplicarFiltroPorMarca = (e) => {
        let texto = e.target.value.toUpperCase();
        if (texto.length > 2) {
            let marcas = obtenerReferencia.marcas.filter(marca => marca.toUpperCase().includes(texto));
            if (marcas.length > 0) {
                setMarcas(marcas);
            }
        }
    }

    const efectoBotonRemoveLocal = (idControl, idBoton, tipoId) => {
        let controlBuscador = idControl === "" ? "" : document.getElementById(idControl) !== null ? document.getElementById(idControl).value : "";
        let activarEfecto = controlBuscador.trim() === "" ? false : true;

        if (activarEfecto) {
            document.getElementById(idBoton).style.visibility = "visible";
        } else {
            if (tipoId === "MARCAS") {
                document.getElementById("buscadorFiltroMarcaGE").value = "";
                setMarcas(obtenerReferencia.marcas);

            } else {
                if (document.getElementById("buscadorFiltroGrupoGE") !== null) {
                    setGrupos(obtenerReferencia.grupoMontaje);
                }
            }

            document.getElementById(idBoton).style.visibility = "hidden";
        }
    }

    const aplicarFiltroPorGrupo = (e) => {
        let texto = e.target.value.toUpperCase();
        if (texto.length > 2) {
            let grupos = obtenerReferencia.grupoMontaje.filter(grupo => grupo.toUpperCase().includes(texto));
            if (grupos.length > 0) {
                setGrupos(grupos);

            }
        }
    }

    const updateNumberPage = (current, size) => {
        let listaPaginas = obtenerPaginas(grupoMarcasLocal.Referencias, size);
        //console.log("objeto ref", listaPaginas)
        setPagination({
            "numeroTotalRegistro": grupoMarcasLocal.Referencias.referencias.length - 1,
            "numeroTotalRegistroPaginacion": listaPaginas.length * size,
            "numeroRegistroPagina": size,
            "numeroDePaginas": listaPaginas.length,
            "paginas": listaPaginas,
            "paginaAMostrar": current - 1
        })

        setHayReferenicaAMostrar(false)
    }


    const añadirALaCesta = async (registro, CANTIDAD) => {
        if (CANTIDAD === "") {

            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.STOCK_TOTAL}`)
        } else {
            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.nombre === "") {
                //setVisible(true)
                MSGERROR(traducirPagina("Msg_vender"))
            } else {

                let VEHICULO_VACIO = {
                    MATRICULA: "",
                    BASTIDOR: "",
                    MARCA_VEHICULO: "",
                    MODELO_VEHICULO: "",
                    MOTOR_VEHICULO: ""
                }

                const VEHICULO_OEM = store.getState().VEHICULO_OEM;

                if (props.tipoComponente === "equivalenciaArticulo" && Object.keys(VEHICULO_OEM).length > 0) {
                    const { BASTIDOR, MARCA, MODELO } = VEHICULO_OEM;
                    VEHICULO_VACIO.BASTIDOR = BASTIDOR !== undefined ? BASTIDOR : ""
                    VEHICULO_VACIO.MARCA_VEHICULO = MARCA
                    VEHICULO_VACIO.MODELO_VEHICULO = MODELO

                    /*
                    console.log("VEHICULO_OEMMM",VEHICULO_OEM,"props.tipoComponente",props.tipoComponente)
                    buscarVehiculoLocal().then((result) =>{
                        console.log("previa result",result) 
                    })
                    */
                }

                const VEHICULO = props.tipoComponente !== "equivalenciaArticulo" ? await buscarVehiculoLocal() : VEHICULO_VACIO;

                const { TIPO_STOCK, ABRIR_FICHA } = condicionesAbrirCestav2(registro, CANTIDAD);

                if (ABRIR_FICHA || registro.TIENE_OFERTA) {
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD
                    registro.VEHICULO = VEHICULO;
                    setReferenciaSeleccionada(registro)
                    setFichaCesta(true)
                } else {

                    getStockDesglose(registro.REFERENCIA, registro.CODIGO_MARCA, registro.REFERENCIA_PROVEEDOR,registro.NOMRE_MARCA).then(stock => {
                        registro.DESCRIPCION_CESTA = "[ " + registro.NOMRE_MARCA.toUpperCase() + " ] - " + registro.NOMBRE_GRUPO_MONTAJE.toUpperCase();
                        cestaAutomatica(stock, TIPO_STOCK, CANTIDAD, registro, VEHICULO)
                    })
                }
            }

        }

    }




    const actualizarReferenciasEnPantalla = (page, pageSize) => {
        setHayReferenicaAMostrar(false)
        pagination.paginaAMostrar = page - 1;
        pagination.numeroRegistroPagina = pageSize;
        setPagination(pagination);
        mostrarReferenciaEnPantalla(obtenerReferencia);
    }


    const añadirLocalmenteLadoMontaje = (lado, estaActivo) => {
        let listaSeleccionados = [];

        /*
        if (estaActivo) {
            if (listaLadosSeleccionados.length === 0) {
                listaSeleccionados.push(lado)
                //setLadoMontajeSeleccionados(listaSeleccionados)
            } else {
                let seEncuentra = listaLadosSeleccionados.find(registro => registro === lado);
                if (seEncuentra === undefined) {
                    listaSeleccionados = listaLadosSeleccionados
                    listaSeleccionados.push(lado)
                    //setLadoMontajeSeleccionados(listaSeleccionados);
                }
            }

        } else {
            if (listaLadosSeleccionados.length > 0) {
                listaSeleccionados = listaLadosSeleccionados;
                let i = listaSeleccionados.indexOf(lado);
                listaSeleccionados.splice(i, 1);
                //setLadoMontajeSeleccionados(listaSeleccionados)

            }
        }

*/
        listaSeleccionados = lado


        setListaLadosSeleccionados(listaSeleccionados);
        let objetoRegistro = actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, checkedListMarcas, checkedListGrupos, esConLado, listaSeleccionados)


        if (objetoRegistro.Referencias.referencias.length === 0) {
            setListaDeReferenciasDeLaPaginaActual([])
            setHayReferenicaAMostrar(false)
            setMostrarFilrosComponente(false)
            setMarcas([]);
            setGrupos([]);
        }
    }





    const abrirDesglose = (item) => {
        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {
            store.dispatch(DrawerStockAlmacen(true));
            referenciaActualSelect({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REFERENCIA_PROVEEDOR: item.REFERENCIA_PROVEEDOR, REGISTROS: item });
        }
    }


    const propiedadesReferencia = (PROPIEDADES) => {
        let propiedades = "";
        if (PROPIEDADES !== undefined && PROPIEDADES !== null) {
            PROPIEDADES.split("|").map((item, i) => {
                if (i > 0) {
                    propiedades += '<span>' + item + '</span><br/>'
                }
            })
        }
        return propiedades
    }

    const modelarReferenciaComercial = (REFERENCIA_COMERCIAL) => {
        let refComerciales = "";
        if (REFERENCIA_COMERCIAL !== undefined && REFERENCIA_COMERCIAL !== null && REFERENCIA_COMERCIAL.trim() !== "") {
            refComerciales = '<span title="labelRefComercial">' + traducirPagina("Referencia_comerciales") + ":" + '</span>'
            REFERENCIA_COMERCIAL.split(",").map((item, i) => {
                if (i === REFERENCIA_COMERCIAL.split(",").length - 1) {
                    refComerciales += '<span class="refComercial"> ' + item + '</span>'

                } else {
                    refComerciales += '<span class="refComercial"> ' + item + " | " + '</span>'
                }
            })
        }
        return refComerciales;
    }

    const modelarSustituyrA = (SUSTITUYE_A) => {
        let sustituyeA = "";
        if (SUSTITUYE_A !== undefined && SUSTITUYE_A !== null && SUSTITUYE_A.trim() !== "") {
            sustituyeA = '<span title="labelSustituyeA">' + traducirPagina("Sustituye_A") + ":" + '</span>'

            SUSTITUYE_A.split(",").map((item, i) => {
                if (i === SUSTITUYE_A.split(",").length - 1) {
                    sustituyeA += '<span class="refComercial"> ' + item + '</span>'
                } else {
                    sustituyeA += '<span class="refComercial"> ' + item + " | " + '</span>'
                }
            })
        }
        return sustituyeA;
    }

    const modelarSustituyrPor = (SUSTITUYE_POR) => {
        let sustituyePor = "";
        if (SUSTITUYE_POR !== undefined && SUSTITUYE_POR !== null && SUSTITUYE_POR.trim() !== "") {
            sustituyePor = '<span title="labelSustituyePor">' + traducirPagina("Sustituido_Por") + ":" + '</span>'
            SUSTITUYE_POR.split(",").map((item, i) => {
                if (i === SUSTITUYE_POR.split(",").length - 1) {
                    sustituyePor += '<span class="refComercial"> ' + item + '</span>'
                } else {
                    sustituyePor += '<span class="refComercial"> ' + item + " | " + '</span>'
                }
            })
        }
        return sustituyePor;
    }


    const filtroReferenciaLocal = (listaDeReferenciasDeLaPaginaActual) => {
        let RESULTADO_FINAL = listaDeReferenciasDeLaPaginaActual;

        if (vistaReferencia.POR_PRECIO) {

            let nuevasReferencia = [];
            let GRUPO = { nombreMarca: "", referencias: [] }
            let verdes = []
            let naranjas = []
            let rojos = []
            let rojos_sin_precio = []
            let rojos_sin_precio_sin_proveedor = []


            listaDeReferenciasDeLaPaginaActual.map((registro) => {
                nuevasReferencia = [...nuevasReferencia, ...registro.referencias];
            })

            nuevasReferencia.map((item) => {
                if (item.STOCK_ALMACEN > 0) {
                    verdes.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0) {
                    naranjas.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO > 0) {
                    rojos.push(item)
                }
                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_PROVEEDOR !== "") {
                    rojos_sin_precio.push(item)
                }

                if (item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 && item.PRECIO === 0 && item.REFERENCIA_PROVEEDOR === "") {
                    rojos_sin_precio_sin_proveedor.push(item)
                }
            })

            verdes.sort(dynamicSortAsc("NETO"))
            naranjas.sort(dynamicSortAsc("NETO"))
            rojos.sort(dynamicSortAsc("NETO"))

            let preciosFinal = [...verdes, ...naranjas, ...rojos, ...rojos_sin_precio, ...rojos_sin_precio_sin_proveedor];
            GRUPO.nombreMarca = "";
            GRUPO.referencias = preciosFinal
            RESULTADO_FINAL = [GRUPO]
        }



        if (vistaReferencia.POR_DISPONIBILIDAD) {
            /*
                        let listaNueva = listaDeReferenciasDeLaPaginaActual.map((item) =>  {
                            return{ORDEN_MARCA: item.referencias !== null && item.referencias.length > 0 ? item.referencias[0].ORDEN_MARCA : 0,...item}
                        })
            
                        RESULTADO_FINAL = listaNueva.sort(dynamicSortDesc("ORDEN_MARCA"))
            */
            RESULTADO_FINAL = listaDeReferenciasDeLaPaginaActual

        }

        if (vistaReferencia.POR_REFERENCIA_CON_PRECIO) {
            let LISTA_FILTRADA = [];
            /*
            for(let i = 0; i < RESULTADO_FINAL.length; i++){
                const { nombreMarca, referencias } = RESULTADO_FINAL[i];

                let BUSCAR_PRECIO_CERO = referencias.filter((registro) => registro.PRECIO === 0)

                if (BUSCAR_PRECIO_CERO.length === 0) {
                    LISTA_FILTRADA = [...LISTA_FILTRADA,{ "nombreMarca": nombreMarca, "referencias": referencias }]
                    
                } else if (BUSCAR_PRECIO_CERO.length > 0 && BUSCAR_PRECIO_CERO.length < referencias.length) {
                    LISTA_FILTRADA = [...LISTA_FILTRADA,{ "nombreMarca": nombreMarca, "referencias": referencias.filter((registro) => registro.PRECIO > 0) }]
                }
            }
            */

            for (let i = 0; i < RESULTADO_FINAL.length; i++) {
                const { nombreMarca, referencias } = RESULTADO_FINAL[i];
                let BUSCAR_PRECIO_MAYOR = referencias.filter((registro) => registro.PRECIO > 0)
                if (BUSCAR_PRECIO_MAYOR.length > 0) {
                    LISTA_FILTRADA = [...LISTA_FILTRADA, { "nombreMarca": nombreMarca, "referencias": BUSCAR_PRECIO_MAYOR }]
                }
            }


            RESULTADO_FINAL = LISTA_FILTRADA

        }

        return RESULTADO_FINAL
    }


    const filtroReferenciasConPrecioPagination = (referencias) => {
        let BUSCAR_PRECIO_CERO = referencias.filter((registro) => registro.PRECIO === 0)
        let RESULTADO = []
        if (BUSCAR_PRECIO_CERO.length === 0) {
            RESULTADO = referencias
        } else if (BUSCAR_PRECIO_CERO.length > 0 && BUSCAR_PRECIO_CERO.length < referencias.length) {
            RESULTADO = referencias.filter((registro) => registro.PRECIO > 0)
        }

        return RESULTADO;
    }



    const inputCantidad = (item, cantidadPorUusario = "", cantidadMinima = 1) => {
        cantidadMinima = cantidadMinima === 0 ? 1 : cantidadMinima

        if (item.CANTIDAD_CLIENTE !== null) {
            let valor = cantidadPorUusario.trim();

            if (valor === "") {
                item.CANTIDAD_CLIENTE = valor;
            } else {
                const verificarNumero = parseInt(valor)

                item.CANTIDAD_CLIENTE = verificarNumero
            }

            setListaDeReferenciasDeLaPaginaActual([...listaDeReferenciasDeLaPaginaActual])
        }

    }

    const inputCantidadBlur = (item, cantidadMinima = 1) => {
        let valor = item.CANTIDAD_CLIENTE;

        if (valor === "") {
            item.CANTIDAD_CLIENTE = cantidadMinima
        } else {
            const verificarNumero = parseInt(valor)

            item.CANTIDAD_CLIENTE = (verificarNumero <= cantidadMinima) ? cantidadMinima : verificarNumero

        }
        setListaDeReferenciasDeLaPaginaActual([...listaDeReferenciasDeLaPaginaActual])

    }

    const gestionarCantidad = (item, cantidadMinima = 1, esNegativo) => {
        cantidadMinima = cantidadMinima === 0 ? 1 : cantidadMinima

        if (item.CANTIDAD_CLIENTE !== null) {
            let valor = item.CANTIDAD_CLIENTE
            if (esNegativo) {
                if (valor) {
                    if (parseInt(valor) <= cantidadMinima) {
                        item.CANTIDAD_CLIENTE = cantidadMinima;
                    } else {
                        item.CANTIDAD_CLIENTE = parseInt(valor) - 1;
                    }

                } else {
                    item.CANTIDAD_CLIENTE = cantidadMinima;
                }

            } else {

                if (valor) {
                    item.CANTIDAD_CLIENTE = parseInt(valor) + 1
                } else {
                    item.CANTIDAD_CLIENTE = cantidadMinima;
                }

            }


            setListaDeReferenciasDeLaPaginaActual([...listaDeReferenciasDeLaPaginaActual])
        }
    }




    function estadoDescripcion(ID_ESTADO) {
        let registros = {
            background: "",
            color: ""
        }
        switch (ID_ESTADO) {
            case 1:
                registros.background = "#4285f4"
                registros.color = "#fff"
                break;
            case 9:
                registros.background = "#D91E18"
                registros.color = "#fff"
                break;

            default:
                registros.background = "#dcdcdc"
                registros.color = "#000"
                break;
        }

        return registros;
    }



    mostrarReferenciaEnPantalla(obtenerReferencia);


    const PAGINACION_REF_CON_PRECIO = filtroReferenciasConPrecioPagination(obtenerReferencia === undefined || obtenerReferencia === null || obtenerReferencia.referencias === undefined || obtenerReferencia.referencias === null ? [] : obtenerReferencia.referencias);
    /*
        setTimeout(() => {
            PAGINACION_REF_CON_PRECIO.map((referencia) => {
    
                if (document.getElementById("CANTIDADGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                    document.getElementById("CANTIDADGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA).value = "1";
                }
    
                if (document.getElementById("CANTIDADGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                    document.getElementById("CANTIDADGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA).value = "1";
                }
    
                //Esperanndo que cargue los precios
    
    
                if (document.getElementById("COMPRAGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                    document.getElementById("COMPRAGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA).style.visibility = "visible";
                }
                if (document.getElementById("PRESUPUESTOGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                    document.getElementById("PRESUPUESTOGE" + referencia.CODIGO_MARCA + referencia.REFERENCIA).style.visibility = "visible";
                }
                if (document.getElementById("COMPRAGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA) !== null) {
                    document.getElementById("COMPRAGE_TD" + referencia.CODIGO_MARCA + referencia.REFERENCIA).style.visibility = "visible";
                }
            })
        }, 500)
    */


    React.useEffect(() => {

        setTimeout(() => { if (document.querySelector(".alertify-notifier") !== null) { document.querySelector(".alertify-notifier").remove() } }, 100)

        switch (props.tipoComponente) {
            case "articulosSugeridos":
                setHayReferencias(true); setObtenerReferencia({})
                ObtenerArticulosSugeridosEnAPI(props.referencia, props.marca).then(referencia => {
                    if (referencia !== undefined && referencia !== null && referencia.ARTICULOS !== undefined && referencia.ARTICULOS !== null && referencia.ARTICULOS.length > 0) {
                        actualizarRegistros(referencia.ARTICULOS, undefined, undefined, referencia.CON_LADO_MONTAJE)
                    } else {
                        setObtenerReferencia(null); setMarcas([]); setGrupos([]);
                    }
                })
                break;
            case "CatalogoBateria":
                setHayReferencias(true); setObtenerReferencia({})

                const { PAGINATION, PAYLOAD } = props.referencia;
                const { REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR, } = PAGINATION


                Obtener_CatalogoEnAPI(REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD).then((referencia) => {
                    if (referencia !== undefined && referencia !== null && referencia.REGISTROS !== null) {
                        actualizarRegistros(referencia.REGISTROS, undefined, undefined, false)

                    } else {
                        setObtenerReferencia(null); setMarcas([]); setGrupos([]);
                    }

                });

                break;
            default:
                break;
        }

    }, []);




    return (
        <>
            <Content>
                {Object.keys(pagination).length > 0 ?
                    <div className="flex-container p-2" style={{ backgroundColor: '#eee' }} >
                        <div className="flex-item-left" style={{ textAlign: 'left', padding: '0px', flex: '40%' }}>
                            <div className="classic-tabs">
                                <MDBNav classicTabs color="#808080" style={{ backgroundColor: '#eee' }} >
                                    <MDBNavItem>
                                        {
                                            colapseMarca ?
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setColapseMarca(false); setHayReferenicaAMostrar(false) }} >
                                                    <FullscreenOutlined className="estiloIcono" />
                                                </div>
                                                :
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setColapseMarca(true); setHayReferenicaAMostrar(false) }} >
                                                    <FullscreenExitOutlined className="estiloIcono" />
                                                </div>

                                        }
                                    </MDBNavItem>

                                    <MDBNavItem>
                                        {
                                            visualizacionDescuento ?
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => setVisualizacionDescuento(false)} >
                                                    <EyeOutlined className="estiloIcono" />
                                                </div>
                                                :
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => { setVisualizacionDescuento(true) }} >
                                                    <EyeInvisibleOutlined className="estiloIcono" />
                                                </div>

                                        }
                                    </MDBNavItem>

                                    <MDBNavItem>

                                        {
                                            verFiltros ?
                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => setVerFiltros(false)} >
                                                    <ControlOutlined className="estiloIcono" />
                                                </div>
                                                :

                                                <div style={{ padding: '8px', cursor: 'pointer', color: '#000', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }} onClick={() => {
                                                    setVerFiltros(true);
                                                }}>
                                                    <ApartmentOutlined className="estiloIcono" />
                                                </div>

                                        }
                                    </MDBNavItem>



                                    <CPLadoMontaje
                                        LADO_MONTAJE={esConLado}
                                        REGISTROS_REFERENCIA={obtenerReferencia.auxReferencia}
                                        EVENTO_SELECCIONADO={añadirLocalmenteLadoMontaje}
                                    >
                                    </CPLadoMontaje>



                                    {
                                        /*
                                    esConLado !== undefined && esConLado ?
                                        <>
                                            <MDBNavItem style={{ marginTop: '8px' }}>
                                                <img id={"imgDelantero" + props.tipoComponente}
                                                    title={filtroLadoMontaje("V", obtenerReferencia.referencias)}
                                                    src="./imagenes/delantero.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/delantero.svg) center center no-repeat' }}
                                                    onClick={() => {
                                                        let estaActivo = efectoLadoDeMontaje("imgDelantero" + props.tipoComponente);
                                                        añadirLocalmenteLadoMontaje("V", estaActivo);
                                                    }}></img>
                                            </MDBNavItem>
    
                                            <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                                                <img id={"imgDerecho" + props.tipoComponente} title={filtroLadoMontaje("D", obtenerReferencia.referencias)} src="./imagenes/derecha.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/derecha.svg) center center no-repeat' }} onClick={() => {
                                                    let estaActivo = efectoLadoDeMontaje("imgDerecho" + props.tipoComponente);
                                                    añadirLocalmenteLadoMontaje("D", estaActivo)
                                                }}></img>
                                            </MDBNavItem>
    
                                            <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                                                <img id={"imgIzquierdo" + props.tipoComponente} title={filtroLadoMontaje("L", obtenerReferencia.referencias)} src="./imagenes/izquierda.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/izquierda.svg) center center no-repeat' }} onClick={() => {
                                                    let estaActivo = efectoLadoDeMontaje("imgIzquierdo" + props.tipoComponente);
                                                    añadirLocalmenteLadoMontaje("L", estaActivo)
                                                }}></img>
                                            </MDBNavItem>
    
                                            <MDBNavItem style={{ marginTop: '8px', marginLeft: '10px' }}>
                                                <img id={"imgTrasero" + props.tipoComponente} title={filtroLadoMontaje("H", obtenerReferencia.referencias)} src="./imagenes/trasero.svg" style={{ height: '22px', cursor: 'pointer', opacity: '0.5', WebkitMask: 'url(./imagenes/trasero.svg) center center no-repeat' }} onClick={() => {
                                                    let estaActivo = efectoLadoDeMontaje("imgTrasero" + props.tipoComponente);
                                                    añadirLocalmenteLadoMontaje("H", estaActivo)
                                                }}></img>
                                            </MDBNavItem>
                                        </>
    
                                        : null
                                        */
                                    }





                                    <button title={traducirPagina("Precio").toUpperCase()}
                                        className={`btn_CP_Referencia   ${vistaReferencia.POR_PRECIO ? 'activarBoton' : 'desactivarBoton'}`} onClick={() => { vistaReferencia.POR_PRECIO = true; vistaReferencia.POR_DISPONIBILIDAD = false; setVistaReferencia({ ...vistaReferencia }) }}>  <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Precio").toUpperCase()}</span>
                                    </button>

                                    <button title={traducirPagina("Disponibilidad").toUpperCase()}
                                        className={`btn_CP_Referencia ${vistaReferencia.POR_DISPONIBILIDAD ? 'activarBoton' : 'desactivarBoton'}`} onClick={() => { vistaReferencia.POR_PRECIO = false; vistaReferencia.POR_DISPONIBILIDAD = true; setVistaReferencia({ ...vistaReferencia }) }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Disponibilidad").toUpperCase()}</span>
                                    </button>

                                    {verificarSiEsMostrador() &&
                                        <button
                                            className={`btn_CP_Referencia ${vistaReferencia.POR_REFERENCIA_CON_PRECIO ? 'activarBoton' : 'desactivarBoton'}`} onClick={() => {
                                                vistaReferencia.POR_REFERENCIA_CON_PRECIO = !vistaReferencia.POR_REFERENCIA_CON_PRECIO;
                                                setVistaReferencia({ ...vistaReferencia })

                                                /*
                                                let REFERENCIAS_LOCAL = obtenerReferencia.referencias === undefined || obtenerReferencia.referencias === null ?   [] : obtenerReferencia.referencias
    
                                                
                                                if (vistaReferencia.POR_REFERENCIA_CON_PRECIO) {
                                                    const PAGINACION_REF_CON_PRECIO_LOCAL = filtroReferenciasConPrecioPagination(REFERENCIAS_LOCAL);
                                                    actualizarRegistros(PAGINACION_REF_CON_PRECIO_LOCAL, checkedListMarcas, checkedListGrupos, esConLado, listaLadosSeleccionados)
     
                                                }else{
                                                    const {marcas,grupoMontaje,referencias,ladosSeleccionados} =  copiaEstadoInicial
    
                                                    actualizarRegistros(referencias, marcas, grupoMontaje, esConLado, ladosSeleccionados)
                                                }
                                                */


                                            }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{vistaReferencia.POR_REFERENCIA_CON_PRECIO ? traducirPagina("Mostrar_todas").toUpperCase() : traducirPagina("Mostrar_con_precio").toUpperCase()}</span>
                                        </button>
                                    }



                                </MDBNav>
                            </div>
                        </div>

                        <div className="flex-item-right" style={{ textAlign: 'right', padding: '0px', flex: '45%' }}>

                            {
                                vistaReferencia.POR_REFERENCIA_CON_PRECIO ?

                                    <Pagination style={{ marginTop: '5px' }}
                                        showSizeChanger
                                        total={PAGINACION_REF_CON_PRECIO.length}
                                        showTotal={() => ` ${(PAGINACION_REF_CON_PRECIO.length)} ${traducirPagina("Referencias").toLowerCase()} `}
                                        defaultPageSize={40}
                                        onChange={(page, pageSize) => { actualizarReferenciasEnPantalla(page, pageSize) }}
                                    > </Pagination>

                                    :

                                    Object.keys(pagination).length > 0 ?
                                        <Pagination style={{ marginTop: '5px' }}
                                            showSizeChanger
                                            onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}

                                            total={pagination.numeroDePaginas * pagination.numeroRegistroPagina}
                                            showTotal={() => ` ${(pagination.numeroTotalRegistro + 1) + " " + traducirPagina("Referencias").toLowerCase()} `}

                                            current={pagination.paginaAMostrar + 1}
                                            defaultPageSize={40}
                                            onChange={(page, pageSize) => { actualizarReferenciasEnPantalla(page, pageSize) }}
                                        > </Pagination>
                                        : null
                            }
                        </div>
                    </div>
                    :
                    null
                }

                <div style={["graficos", "graficosCarroceria", "referenciaMarca", "equivalenciaArticulo", "BateriasVehiculo", "articulosSugeridos", "CatalogoBateria"].includes(props.tipoComponente) ? { height: (configApp.height - ALTURA_CONTENEDOR) + "px", overflow: 'auto', overflowX: 'hidden' } : {}}>
                    <Col span={verFiltros ? 7 : 0} style={{ backgroundColor: '#fff', visibility: mostrarFilrosComponente ? 'visible' : 'hidden' }}>
                        {verFiltros ?
                            <div className="mdb-skin">
                                <div className="flex-container ">
                                    <div className="flex-item-left" style={{ textAlign: 'left', padding: '5px', flex: '45%' }}>
                                        <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                                            <div className="grid-item">
                                                <input id="buscadorFiltroMarcaGE" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_marcas")} style={{ backgroundColor: "#eee", width: '135px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { aplicarFiltroPorMarca(e); efectoBotonRemoveLocal("buscadorFiltroMarcaGE", "closeFiltroMarcaGE", "MARCAS") }} />
                                            </div>

                                            <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                <MDBBtn id="closeFiltroMarcaGE" onClick={e => { createRipple(e); efectoBotonRemoveLocal("", "closeFiltroMarcaGE", "MARCAS") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                            </div>

                                            <div className="grid-item barraLateral" ></div>

                                            <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-item-right" style={{ padding: '5px', flex: '10%' }}>
                                        <button onClick={(e) => {
                                            setCheckedListMarcas([])
                                            efectoBotonRemoveLocal("", "closeFiltroMarcaGE", "MARCAS")
                                            actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, [], checkedListGrupos, esConLado)
                                        }} className="activebtnMenu2 btnEstiloFiltro" style={{ width: '80px', height: '40px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Todas")}</span> </button>
                                    </div>
                                </div>

                                <Checkbox.Group value={checkedListMarcas} style={{ width: '100%', height: (configApp.height / 3).toString() + "px", overflow: 'auto', overflowX: 'hidden' }} onChange={(e) => { actulizarMarcasSeleccionadas(e);  /*mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia);*/ }}>
                                    {
                                        todasLasMarcas.map((row, i) =>
                                            <div key={i}>
                                                <Checkbox value={row} className="pl-2" >{row}</Checkbox>
                                                <br></br>
                                            </div>
                                        )
                                    }
                                </Checkbox.Group>

                                <div className="flex-container">
                                    <div className="flex-item-left" style={{ textAlign: 'left', padding: '5px', flex: '45%' }}>

                                        <div className="grid-container" style={{ backgroundColor: '#eee', marginTop: '2px' }}>
                                            <div className="grid-item">
                                                <input id="buscadorFiltroGrupoGE" className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_por_grupos")} style={{ backgroundColor: "#eee", width: '135px', border: '0px', height: '100%', color: '#000' }} autoComplete="off" onChange={(e) => { aplicarFiltroPorGrupo(e); efectoBotonRemoveLocal("buscadorFiltroGrupoGE", "closeFiltroGrupoGE", "GRUPOS") }} />
                                            </div>

                                            <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                <MDBBtn id="closeFiltroGrupoGE" onClick={e => { createRipple(e); efectoBotonRemoveLocal("", "closeFiltroGrupoGE", "GRUPOS") }} style={{ border: '0', padding: '6px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: 'hidden' }} className="btn-matriculav2">  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                            </div>

                                            <div className="grid-item barraLateral" ></div>

                                            <div className="grid-item" style={{ backgroundColor: '#eee' }}>
                                                <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matriculav2">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-item-right" style={{ padding: '5px', flex: '10%' }}>
                                        <button onClick={(e) => {
                                            setCheckedListGrupos([])
                                            efectoBotonRemoveLocal("", "closeFiltroGrupoGE", "GRUPOS")
                                            actualizarRegistros(grupoMarcasLocal.Referencias.auxReferencia, checkedListMarcas, [], esConLado)
                                        }} className="activebtnMenu2 btnEstiloFiltro" style={{ width: '80px', height: '40px' }}> <span style={{ verticalAlign: 'middle', fontSize: '16px' }} >{traducirPagina("Todas")}</span> </button>
                                    </div>
                                </div>

                                <Checkbox.Group value={checkedListGrupos} style={{ width: '100%', height: (configApp.height / 3).toString() + "px", overflow: 'auto', overflowX: 'hidden' }} onChange={(arrayGrupoMontaje) => { actulizarGruposSeleccionadas(arrayGrupoMontaje); /*mostarReferenciasEnPantalla(grupoDeMarcas.auxReferencia);*/ }}>
                                    {
                                        todosLosGrupos.map((row, i) =>
                                            <div key={i}>
                                                <Checkbox value={row} className=" pl-2">{row}</Checkbox>
                                                <br></br>
                                            </div>
                                        )
                                    }
                                </Checkbox.Group>
                            </div>
                            :
                            null
                        }
                    </Col>
                    <Col span={verFiltros ? 17 : 24} style={{ paddingLeft: '10px' }}>
                        <div className="containerReferencia">
                            {obtenerReferencia === null ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
                                :
                                Object.keys(obtenerReferencia).length > 0 ?
                                    filtroReferenciaLocal(listaDeReferenciasDeLaPaginaActual).map((marca, i) =>
                                        colapseMarca ?
                                            <div className="containerReferenciaItem" key={i}>
                                                <p className="marcaArticuloTitulo" style={{ backgroundColor: colorCabeceraPorElPrecioDeLaReferencia(marca), color: colorCabeceraFuente(marca) }}>{marca.nombreMarca}</p>
                                                {marca.referencias.map((item, i) =>
                                                    <div key={"titulo" + i}>
                                                        <div className="row" style={{ padding: '10px', color: item.REFERENCIA_PROVEEDOR === "" ? '#999' : '#000' }}>
                                                            <MDBCol md="2">
                                                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                                                    if (item.REFERENCIA !== "" && item.CODIGO_MARCA > 0) {
                                                                        store.dispatch(props.tipoComponente === "graficos" ? DrawerMasInformacion(true) : DrawerFichaMasInformacion2(true));
                                                                        store.dispatch(props.tipoComponente === "graficos" ? actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, NUMBERTAG: "3", REGISTROS: item }) : actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, NUMBERTAG: "3", REGISTROS: item }));
                                                                    }
                                                                }}>

                                                                    <ImagenArticulo REGISTRO={item} DIRECTORIO_DOCUMENTO={DIRECTORIO_DOCUMENTO}></ImagenArticulo>


                                                                    {/*    <img src={item.detalles === undefined ? "./imagenes/sinimagen.jpg" : item.detalles.IMAGEN} onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top' }} /> */}


                                                                    <div style={{ textAlign: 'center', backgroundColor: item.detalles === undefined ? '#dcdcdc' : item.detalles.ID_ESTADO === 1 ? '#4285f4' : item.detalles.ID_ESTADO === 9 ? '#D91E18' : '#dcdcdc', color: item.detalles === undefined ? '#000' : item.detalles.ID_ESTADO === 1 || item.detalles.ID_ESTADO === 9 ? '#fff' : '#000' }}>
                                                                        {item.detalles !== undefined ? item.detalles.DESCRIPCION_ESTADO : null}
                                                                    </div>


                                                                    {
                                                                        vistaReferencia.POR_PRECIO ?
                                                                            <div style={{ textAlign: 'center', backgroundColor: '#dcdcdc', color: '#000' }}>
                                                                                {item.NOMRE_MARCA}
                                                                            </div>
                                                                            : null
                                                                    }



                                                                    {/*
                                                                <img id={"IMGE" + item.CODIGO_MARCA + item.REFERENCIA} src={""} alt={item.CODIGO_MARCA + item.REFERENCIA} onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top' }} />
                                                                <div id={"ESTADOGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                
                                                                </div>
                                                        */}
                                                                </div>

                                                                {
                                                                    codmotorizacion !== "" && item.TIENE_ARTICULOS_SUGERIDOS ?
                                                                        <div className="herramientasAdicionales">
                                                                            <button onClick={(e) => {
                                                                                setReferenciaSugerida(true)
                                                                                setReferenciaSeleccionada(item)
                                                                            }}>

                                                                                <BulbOutlined></BulbOutlined> {traducirPagina("txt_sugeridas")}
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </MDBCol>

                                                            <MDBCol md="7">
                                                                <div className="nombreArticulo">
                                                                    <strong>
                                                                        <span id={"referenciaGECopiarAPortapapel" + item.CODIGO_MARCA + item.REFERENCIA} className="refComercial" onClick={(e) => {
                                                                            limpiarGrupoDrawer();
                                                                            limpiarCocheStore();

                                                                            setTimeout(() => {
                                                                                document.getElementById("buscadorReferencia").value = item.REFERENCIA
                                                                                store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: item.REFERENCIA });
                                                                                buscarReferenciaYMostrarEnPantalla(item.REFERENCIA)
                                                                                efectoBotonRemove(item.REFERENCIA, "closeBuscadorReferencia")
                                                                            }, 500)

                                                                        }}> {item.REFERENCIA + " "} </span>

                                                                        <CopyOutlined onClick={(e) => { copiarAlPortapapeles("referenciaGECopiarAPortapapel" + item.CODIGO_MARCA + item.REFERENCIA) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />

                                                                        <span>{item.NOMBRE_GRUPO_MONTAJE}</span>
                                                                    </strong>
                                                                    <br />

                                                                    <span id={"referenciaProveedorGECopiarAPortapapel" + item.CODIGO_MARCA + item.REFERENCIA}>{traducirPagina("Referencia_proveedor") + ": " + item.REFERENCIA_PROVEEDOR}</span>
                                                                    {item.REFERENCIA_PROVEEDOR !== "" ? <CopyOutlined onClick={(e) => { copiarAlPortapapeles("", item.REFERENCIA_PROVEEDOR) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} /> : null}

                                                                    <br />


                                                                    {item.detalles === undefined || item.detalles.EAN === "" ? null :
                                                                        <>
                                                                            EAN:
                                                                            <span className="eanNumber"> {item.detalles === undefined || item.detalles.EAN === "" ? null : item.detalles.EAN}</span>
                                                                            <CopyOutlined onClick={(e) => { copiarAlPortapapeles("", item.detalles.EAN) }} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />
                                                                        </>
                                                                    }
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <div className="clearfix"></div>

                                                                <div className="atributosMarca"> {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: propiedadesReferencia(item.detalles.PROPIEDADES) }} ></div> : null}</div>
                                                                {/**
                                                            <div className="atributosMarca" id={"PROPIEDADESGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                            </div>
 */}
                                                                <div id={"ContainerRefGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                    <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                        if (e.target.title !== "labelRefComercial") {
                                                                            document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                            buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                            efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                        }

                                                                    }} id={"RefComGE" + item.CODIGO_MARCA + item.REFERENCIA} className={"RefComGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                        {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: modelarReferenciaComercial(item.detalles !== undefined ? item.detalles.REFERENCIA_COMERCIAL : "") }} ></div> : null}

                                                                    </span>
                                                                </div>

                                                                <div id={"ContainerSustituyeGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                    <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                        if (e.target.title !== "labelSustituyeA") {
                                                                            document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                            buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                            efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                        }
                                                                    }
                                                                    } id={"SustituyeGE" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                        {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: modelarSustituyrA(item.detalles.SUSTITUYE_A) }} ></div> : null}

                                                                    </span>
                                                                </div>

                                                                <div id={"ContainerSustPorGE" + item.CODIGO_MARCA + item.REFERENCIA}>
                                                                    <span style={{ cursor: 'pointer' }} onClick={(e) => {
                                                                        if (e.target.title !== "labelSustituyePor") {
                                                                            document.getElementById("buscadorReferencia").value = e.target.innerText.replace("|", "")
                                                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: e.target.innerText.replace("|", "") });
                                                                            buscarReferenciaYMostrarEnPantalla(e.target.innerText.replace("|", ""))
                                                                            efectoBotonRemove(e.target.innerText.replace("|", ""), "closeBuscadorReferencia")
                                                                        }
                                                                    }
                                                                    } id={"SustPorGE" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                        {item.detalles !== undefined ? <div dangerouslySetInnerHTML={{ __html: modelarSustituyrPor(item.detalles !== undefined ? item.detalles.SUSTITUIDO_POR : "") }} ></div> : null}

                                                                    </span>
                                                                </div>

                                                                {item.detalles !== undefined && item.detalles.MAS_INFO !== undefined && item.detalles.MAS_INFO !== null && item.detalles.MAS_INFO !== "" ?
                                                                    <div className="containerMasInfo">
                                                                        {item.detalles.MAS_INFO}
                                                                    </div>
                                                                    :
                                                                    null}
                                                            </MDBCol>

                                                            <MDBCol md="3" style={{ textAlign: 'right' }}>
                                                                <div id={"COMPRAGE" + item.CODIGO_MARCA + item.REFERENCIA} style={{ textAlign: 'center', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }}  >

                                                                    <Col md={configApp.width < 1450 ? 24 : 12}>
                                                                        {
                                                                            verificarCantidadCesta(item, COMPRAR_SIN_DISPONIBILIDAD) ?
                                                                                null
                                                                                :
                                                                                <>
                                                                                    <CPCantidad
                                                                                        value={item.CANTIDAD_CLIENTE}
                                                                                        event_input={(e) => {
                                                                                            inputCantidad(item, e.target.value, item.CANTIDAD_MINIMA)
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            inputCantidadBlur(item, item.CANTIDAD_MINIMA)
                                                                                        }}
                                                                                        event_minus={() => {
                                                                                            gestionarCantidad(item, item.CANTIDAD_MINIMA, true)
                                                                                        }}

                                                                                        event_plus={() => {
                                                                                            gestionarCantidad(item, item.CANTIDAD_MINIMA, false)
                                                                                        }}
                                                                                    />

                                                                                    {/* 
                                                                                <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker" >
                                                                                    <span className="next-input-group">
                                                                                        <span className="next-input-group-addon next-before">
                                                                                            <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" onClick={() => {

                                                                                                if (document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {

                                                                                                    let valor = document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value
                                                                                                    if (valor) {
                                                                                                        if (parseInt(valor) <= 1) {
                                                                                                            document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                        } else {
                                                                                                            document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = parseInt(valor) - 1;
                                                                                                        }
                                                                                                    } else {
                                                                                                        document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                    }
                                                                                                }
                                                                                            }} >
                                                                                                <MinusOutlined style={{ fontSize: '16px' }} />
                                                                                            </button>
                                                                                        </span>
                                                                                        <span className="next-input next-medium next-input-group-auto-width">


                                                                                            <input id={"CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '5px', height: '24px' }} onChange={(e) => {
                                                                                                if (document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {

                                                                                                    let valor = document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value
                                                                                                    if (valor === "") {
                                                                                                        document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = e.target.value;
                                                                                                    } else {
                                                                                                        if (soloNumeros(valor)) {
                                                                                                            document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = valor === "0" ? "1" : e.target.value
                                                                                                        } else {
                                                                                                            document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                        }
                                                                                                    }

                                                                                                }
                                                                                            }} />


                                                                                        </span>
                                                                                        <span className="next-input-group-addon next-after">
                                                                                            <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp" onClick={() => {
                                                                                                if (document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                                                                    let valor = document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value
                                                                                                    if (valor) {
                                                                                                        document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = parseInt(valor) + 1;
                                                                                                    } else {
                                                                                                        document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                            >
                                                                                                <PlusOutlined style={{ fontSize: '16px' }} />
                                                                                            </button>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                */}
                                                                                </>
                                                                        }

                                                                    </Col>


                                                                    <Col md={configApp.width < 1450 ? 24 : 12}>
                                                                        <button className="activebtnMenu2" style={{ height: '40px', width: '150px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                            añadirALaCesta(item, item.CANTIDAD_CLIENTE);
                                                                        }}>
                                                                            <ShoppingOutlined style={{ fontSize: '20px' }} />
                                                                            <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{!verificarSiEsMostrador() ? traducirPagina("Comprar") : traducirPagina("Vender")}</span>
                                                                        </button>
                                                                    </Col>


                                                                    {/*

                                                                <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                    añadirALaCesta(item)
                                                                }}>
                                                                    <ShoppingOutlined style={{ fontSize: '20px' }} />
                                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{!verificarSiEsMostrador() ? traducirPagina("Comprar") : traducirPagina("Vender")}</span>

                                                                </button>
                                                                */}



                                                                </div>

                                                                {

                                                                    !verificarSiEsMostrador() ?

                                                                        !es_Sugerido() ?
                                                                            <div id={"PRESUPUESTOGE" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                                <button className="activebtnMenu2 col-md-12" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px' }} onClick={(e) => {
                                                                                    item.CANTIDAD = document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA) === null ? "1" : document.getElementById("CANTIDADGE" + item.CODIGO_MARCA + item.REFERENCIA).value;

                                                                                    crearLineasParaElPresupuesto(item, "A");
                                                                                }}>
                                                                                    <FileAddOutlined style={{ fontSize: '20px' }} />
                                                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Añadir_a_presupuesto")}</span>
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        :
                                                                        null
                                                                }


                                                                <button style={{
                                                                    display: verficarSiEsCatalogo(usuario) ? 'none' : '',
                                                                    height: '40px',
                                                                    width: '210px',
                                                                    border: '0',
                                                                    borderRadius: '3px',
                                                                    fontSize: '13px',
                                                                    verticalAlign: 'bottom',
                                                                    color: item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red',
                                                                    backgroundColor: 'transparent',
                                                                    display: verficarSiEsCatalogo(usuario) ? 'none' : '',
                                                                    marginBottom: '5px'
                                                                }} onClick={(e) => {
                                                                    abrirDesglose(item)

                                                                }} className="activebtnMenu2 col-md-12" >
                                                                    {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                        <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'sub' }} />
                                                                        :
                                                                        <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'baseline' }} />
                                                                    }
                                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                                                        {
                                                                            item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                                traducirPagina("No_disponible")
                                                                                :
                                                                                obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE ?
                                                                                    traducirPagina("disponible")
                                                                                    :
                                                                                    obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ?
                                                                                        traducirPagina("disponible")
                                                                                        :
                                                                                        traducirPagina("Stock") + item.STOCK_ALMACEN + "/" + item.STOCK_TOTAL
                                                                        }
                                                                    </span>
                                                                </button>



                                                                <div>
                                                                    {
                                                                        verificarSiEsMostrador() && visualizarStocksRemotos() ?
                                                                            !es_Sugerido() ?
                                                                                <div>
                                                                                    <button className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'transparent', marginBottom: '5px' }}
                                                                                        onClick={(e) => {


                                                                                            if (props.state_ficha !== undefined) {
                                                                                                props.state_ficha.setRegistroStockSocio({ REFERENCIA_TECDOC: item.REFERENCIA, MARCA_TECDOC: item.CODIGO_MARCA, TIPO_ARTICULO: 1 })
                                                                                                props.state_ficha.setAbrirFichaStockSocio(true)
                                                                                            } else {
                                                                                                setRegistroStockSocio({ REFERENCIA_TECDOC: item.REFERENCIA, MARCA_TECDOC: item.CODIGO_MARCA, TIPO_ARTICULO: 1 })
                                                                                                setAbrirFichaStockSocio(true)
                                                                                            }

                                                                                        }}>

                                                                                        <DeploymentUnitOutlined style={{ fontSize: '24px', marginRight: '5px' }} />

                                                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{`${traducirPagina("Stock_grupo").toUpperCase()}`}</span>
                                                                                    </button>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            :
                                                                            null
                                                                    }
                                                                </div>


                                                                {
                                                                    item.REFERENCIA_PROVEEDOR !== "" ?
                                                                        <div>
                                                                            <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px', width: '100%', display: verficarSiEsPVP(usuario) ? '' : 'none' }} >{traducirPagina("PVP").toUpperCase() + ": " + convertirMoneda(item.PVP_RECOMENDADO, " €")} </div>

                                                                            <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>

                                                                            <div>
                                                                                <span className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px', display: visualizacionDescuento ? '' : 'none' }} >{traducirPagina("Dto") + ": " + item.DESCUENTO + " %"} </span>
                                                                                <br />

                                                                                <div className="container_precio_ref">
                                                                                    {
                                                                                        item.TIENE_OFERTA && (
                                                                                            <div className="icono_oferta">
                                                                                                <div className="porcentaje_oferta">%</div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <div>
                                                                                        <span className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '25px', display: visualizacionDescuento ? '' : 'none' }} >{traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}</span>
                                                                                    </div>
                                                                                </div>

                                                                                <br />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                                {item.REFERENCIA === "" || item.CODIGO_MARCA === 0 ?
                                                                    null
                                                                    :
                                                                    <button className="activebtnMenu2 col-md-12 btnEstiloPersonalizado" onClick={(e) => {
                                                                        store.dispatch(props.tipoComponente === "graficos" ?
                                                                            DrawerMasInformacion(true)
                                                                            :
                                                                            DrawerFichaMasInformacion2(true)
                                                                        );
                                                                        store.dispatch(props.tipoComponente === "graficos" ?
                                                                            actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item })
                                                                            :
                                                                            actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REGISTROS: item })
                                                                        );

                                                                    }} style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: 'transparent', marginBottom: '5px' }}><EyeOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }} >{traducirPagina("Mas_informacion")}</span> </button>
                                                                }

                                                                {codmotorizacion !== "" && PERMISO_DOCUMENTACION_TECNICA ?
                                                                    !es_Sugerido() ?
                                                                        <div>
                                                                            <button  className={`activebtnMenu2 ${ObtenerIDTipoVehiculo() === 2 ? "disable_button" : ""}`} onClick={() => {
                                                                                store.dispatch(
                                                                                    props.tipoComponente === "graficos" ?
                                                                                        DrawerMasInformacion(true)
                                                                                        :
                                                                                        DrawerFichaMasInformacion2(true)
                                                                                );

                                                                                store.dispatch(
                                                                                    props.tipoComponente === "graficos" ?
                                                                                        actulizarReferenciaSeleccionada({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, VERTIEMPOS: true, GRUPO_MONTAJE: item.GRUPO_MONTAJE, REGISTROS: item })
                                                                                        :
                                                                                        actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, VERTIEMPOS: true, GRUPO_MONTAJE: item.GRUPO_MONTAJE, REGISTROS: item })
                                                                                );

                                                                            }}
                                                                                style={{ height: '40px', width: '200px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', marginBottom: '5px' }}><ClockCircleOutlined style={{ fontSize: '20px' }} /> <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Tiempos_sustitucion")}</span>
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    : null}

                                                            </MDBCol>
                                                        </div>
                                                        {marca.referencias.length > 1 ? (marca.referencias.length - 1) === i ? null : <hr></hr> : null}
                                                    </div>

                                                )}
                                            </div>
                                            :
                                            <div className="containerReferenciaItem" key={i}>
                                                <p className="marcaArticuloTitulo" style={{ backgroundColor: colorCabeceraPorElPrecioDeLaReferencia(marca), color: colorCabeceraFuente(marca) }}>{marca.nombreMarca}</p>
                                                {marca.referencias !== undefined ?
                                                    marca.referencias.map((item, i) =>
                                                        <div key={i}>
                                                            <MDBTable responsive style={{ marginBottom: 0, cursor: 'pointer' }}>
                                                                <MDBTableBody>
                                                                    <tr className="rowReferencia" style={{ color: item.REFERENCIA_PROVEEDOR !== "" ? '#000' : '#999' }}>
                                                                        <td className="columnaIcono">
                                                                            <button onClick={(e) => {
                                                                                store.dispatch(DrawerFichaMasInformacion2(true));
                                                                                store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }));
                                                                            }} className="activebtnMenu2" style={{ border: '0', fontSize: '20px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'transparent' }}><FileImageOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} /> <span style={{ fontSize: '18px' }}></span> </button>
                                                                        </td>

                                                                        {vistaReferencia.POR_PRECIO ?
                                                                            <td style={{ width: '95px', maxWidth: '95px', verticalAlign: 'middle' }} title={item.NOMRE_MARCA}>
                                                                                {recortarCadena(item.NOMRE_MARCA, 5, "...")}
                                                                            </td>
                                                                            :
                                                                            null
                                                                        }

                                                                        <td style={{ width: '500px' }} onClick={(e) => {
                                                                            store.dispatch(DrawerFichaMasInformacion2(true));
                                                                            store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }));
                                                                        }}>
                                                                            <strong>
                                                                                {item.REFERENCIA}
                                                                                <span>{" " + item.NOMBRE_GRUPO_MONTAJE}</span>
                                                                            </strong>
                                                                        </td>

                                                                        <td onClick={(e) => {
                                                                            store.dispatch(DrawerFichaMasInformacion2(true));
                                                                            store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }))
                                                                        }}
                                                                            style={{ width: '180px' }}>
                                                                            <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '20px' }} >{traducirPagina("Precio") + ": " + convertirMoneda(item.PRECIO, " €")} </div>

                                                                        </td>

                                                                        <td onClick={(e) => {
                                                                            store.dispatch(DrawerFichaMasInformacion2(true));
                                                                            store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }))

                                                                        }} style={{ width: '135px' }}>
                                                                            <div className={item.HAY_PRECIO === false ? "skeleton-box" : ""} style={{ height: '20px' }} >{traducirPagina("Dto") + ": " + item.DESCUENTO + " %"} </div>

                                                                        </td>

                                                                        <td onClick={(e) => {
                                                                            store.dispatch(DrawerFichaMasInformacion2(true));
                                                                            store.dispatch(actulizarReferenciaMasInfo2({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA }))

                                                                        }} style={{ width: '200px' }}>
                                                                            <div className={`container_precio_ref ${item.HAY_PRECIO === false ? "skeleton-box" : ""}`} style={{ height: '20px' }}>
                                                                                {
                                                                                    item.TIENE_OFERTA && (
                                                                                        <div className="icono_oferta">
                                                                                            <div className="porcentaje_oferta">%</div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                <div>
                                                                                    {traducirPagina("Neto") + ": " + convertirMoneda(item.NETO, " €")}
                                                                                </div>
                                                                            </div>

                                                                        </td>

                                                                        <td className="columnaIcono" style={{ display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                            abrirDesglose(item)

                                                                        }}>
                                                                            <button className="activebtnMenu2" style={{ border: '0', fontSize: '20px', color: item.REFERENCIA_PROVEEDOR !== "" ? item.STOCK_ALMACEN > 0 ? '#25c281' : item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL > 0 ? '#ff8000' : 'red' : '#999', backgroundColor: 'transparent' }} >
                                                                                {item.STOCK_ALMACEN === 0 && item.STOCK_TOTAL === 0 ?
                                                                                    <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} />
                                                                                    : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} />}
                                                                                <span style={{ fontSize: '18px' }}></span>
                                                                            </button>
                                                                        </td>

                                                                        <td className="columnaIcono" style={{ display: verficarSiEsCatalogo(usuario) ? 'none' : '' }} onClick={(e) => {
                                                                            createRipple(e);
                                                                            añadirALaCesta(item, item.CANTIDAD_CLIENTE);
                                                                        }}>
                                                                            <div id={"COMPRAGE_TD" + item.CODIGO_MARCA + item.REFERENCIA} >
                                                                                <button className="activebtnMenu2" style={{ border: '0', fontSize: '20px', color: '#000', backgroundColor: 'transparent' }}>
                                                                                    <ShoppingOutlined style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} /> <span style={{ fontSize: '18px' }}></span>
                                                                                </button>
                                                                            </div>
                                                                        </td>


                                                                        <td className="columnaIcono" style={{ width: '125px', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }}>

                                                                            {
                                                                                verificarCantidadCesta(item, COMPRAR_SIN_DISPONIBILIDAD) ?

                                                                                    null
                                                                                    :
                                                                                    <>
                                                                                        <CPCantidad
                                                                                            value={item.CANTIDAD_CLIENTE}
                                                                                            event_input={(e) => {
                                                                                                inputCantidad(item, e.target.value, item.CANTIDAD_MINIMA)
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                inputCantidadBlur(item, item.CANTIDAD_MINIMA)
                                                                                            }}
                                                                                            event_minus={() => {
                                                                                                gestionarCantidad(item, item.CANTIDAD_MINIMA, true)
                                                                                            }}

                                                                                            event_plus={() => {
                                                                                                gestionarCantidad(item, item.CANTIDAD_MINIMA, false)
                                                                                            }}
                                                                                        />

                                                                                        {/* 
                                                                                        <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker">
                                                                                            <span className="next-input-group" style={{ marginTop: '3px' }}>
                                                                                                <span className="next-input-group-addon next-before">
                                                                                                    <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" onClick={() => {
                                                                                                        if (document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {

                                                                                                            let valor = document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value
                                                                                                            if (valor) {
                                                                                                                if (parseInt(valor) <= 1) {
                                                                                                                    document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                                } else {
                                                                                                                    document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = parseInt(valor) - 1;
                                                                                                                }
                                                                                                            } else {
                                                                                                                document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                            }
                                                                                                        }
                                                                                                    }} >
                                                                                                        <MinusOutlined style={{ fontSize: '16px' }} />
                                                                                                    </button>
                                                                                                </span>
                                                                                                <span className="next-input next-medium next-input-group-auto-width">
                                                                                                    <input id={"CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '5px', height: '24px' }} onChange={(e) => {
                                                                                                        if (document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {

                                                                                                            let valor = document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value
                                                                                                            if (valor === "") {
                                                                                                                document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = e.target.value;
                                                                                                            } else {
                                                                                                                if (soloNumeros(valor)) {
                                                                                                                    document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = valor === "0" ? "1" : e.target.value
                                                                                                                } else {
                                                                                                                    document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                                }
                                                                                                            }

                                                                                                        }
                                                                                                    }} />
                                                                                                </span>
                                                                                                <span className="next-input-group-addon next-after">
                                                                                                    <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp" onClick={() => {
                                                                                                        if (document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA) !== null) {
                                                                                                            let valor = document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value
                                                                                                            if (valor) {
                                                                                                                document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = parseInt(valor) + 1;
                                                                                                            } else {
                                                                                                                document.getElementById("CANTIDADGE_TD" + item.CODIGO_MARCA + item.REFERENCIA).value = "1";
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    >
                                                                                                        <PlusOutlined style={{ fontSize: '16px' }} />
                                                                                                    </button>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                        */}
                                                                                    </>
                                                                            }

                                                                        </td>

                                                                    </tr>
                                                                </MDBTableBody>
                                                            </MDBTable>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                                }
                                            </div>
                                    )
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                            }
                        </div>
                    </Col>

                    <Modal
                        visible={visible}
                        width={600}
                        centered
                        title={"Falta el cliente"}
                        afterClose={() => { }}
                        onCancel={() => setVisible(false)}
                        style={{ zIndex: '999999999999999999999999' }}
                        footer={[
                            <Button className="btnColor" key="submit" type="primary" onClick={() => {
                                setVisible(false)
                            }}> OK</Button>
                        ]}

                    >
                        <p>{traducirPagina("Para_vender_seleccionar_cliente")}</p>
                    </Modal>





                </div>

                {fichaCesta ?
                    <FichaAñadirCesta
                        title={traducirPagina("Añadir_referencia_a_la_cesta").toUpperCase()}
                        ABRIR_FICHA={fichaCesta}
                        CERRAR_FICHA={setFichaCesta}
                        DATOS_REFERENCIA={referenciaSeleccionada}
                        ES_CESTA_COMPRA={false}
                    >
                    </FichaAñadirCesta>
                    :
                    null
                }


                {

                    abrirFichaStockSocio ?
                        <FichaStockSocio
                            title={`${traducirPagina("Stock_grupo").toUpperCase()}`}
                            ABRIR_FICHA={abrirFichaStockSocio}
                            CERRAR_FICHA={setAbrirFichaStockSocio}
                            DATOS_SELECCIONADO={registroStockSocio}
                        >
                        </FichaStockSocio>
                        :
                        null

                }

                {
                    referenciaSugerida &&
                    <FichaReferenciaSugerida
                        title={traducirPagina("txt_referencia_sugerida").toUpperCase()}
                        ABRIR_FICHA={referenciaSugerida}
                        CERRAR_FICHA={setReferenciaSugerida}
                        ID_VEHICULO={codmotorizacion}
                        GRUPO_MONTAJE={referenciaSeleccionada.GRUPO_MONTAJE}
                    >
                    </FichaReferenciaSugerida>
                }
            </Content>




        </>


    )
}

ContainerReferenciaGenerico.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    activeItemOuterTabs: PropTypes.string,
    referencia: PropTypes.string,
    ALTURA: PropTypes.number,
    marca: PropTypes.number,
    NOMBRE_MARCA: PropTypes.string,
    artno: PropTypes.string,
    tipoComponente: PropTypes.string,
    tabActivado: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        datosGraficos: state.datosGraficos,
        configApp: state.configApp,
        props: props,
        openDrawerReferenciaGrafico: state.estadoDrawer.openDrawerReferenciaGrafico,
        openDrawerReferenciaGraficoCarroceria: state.estadoDrawer.openDrawerReferenciaGraficoCarroceria,
        openDrawerFichaReferenciaVehiculo: state.estadoDrawer.openDrawerFichaReferenciaVehiculo,
        openDrawerFichaBateriasVehiculo: state.estadoDrawer.openDrawerFichaBateriasVehiculo,
        openDrawerFichaEquivalenciaArticulo: state.estadoDrawer.openDrawerFichaEquivalenciaArticulo,
        openDrawerMasInfo: state.estadoDrawer.openDrawerMasInfo,
        openDrawerMasInfo2: state.estadoDrawer.openDrawerMasInfo2,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor,

        buscadorTree: state.buscadorTree,
        nodoGraficoSeleccionado: state.nodoGraficoSeleccionado,
        codmotorizacion: state.codmotorizacion,
        filtrosReferencia: state.filtrosReferencia,
        usuario: state.usuario,
        clienteSeleccionado: state.clienteSeleccionado,
        DIRECTORIO_DOCUMENTO: state.DIRECTORIO_DOCUMENTO
    };
}

export default connect(mapStateToProps)(ContainerReferenciaGenerico);