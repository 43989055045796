import { ReadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { idModeloLatam } from "../../librerias/funciones";
import { Vehiculo_Documentacion_TecnicaEnAPI } from "../../action/actionCreatorsRequests";
import store from "../../store";

const CPBtndoc = ({ EVENTO_ABRIR_VISOR }) => {
    const [urlModelo, setUrlModelo] = useState("")
    const [documentos, setDocumentos] = useState([])


    const modeloLatam = idModeloLatam()



    const buscarDocumentos = async (modeloLatam) => {
        const registros = await Vehiculo_Documentacion_TecnicaEnAPI(modeloLatam);
        const { OK, URL_DOCUMENTO, DOCUMENTOS } = registros === undefined ? { OK: false, URL_DOCUMENTO: null, DOCUMENTOS: [] } : registros
        if (OK) {
            setDocumentos(DOCUMENTOS)
            /*
            const url_final = URL_DOCUMENTO === null || URL_DOCUMENTO === "" ? null : URL_DOCUMENTO
            setUrlModelo(url_final)
*/
            store.dispatch({ type: "STATE_REGISTROS_LATAM", REGISTROS_LATAM: registros })

        } else {
            //setUrlModelo(null)
            setDocumentos(null)

        }
    }


    useEffect(() => {
        buscarDocumentos(modeloLatam)
    }, [modeloLatam])




    return (
        documentos !== undefined && documentos !== null && documentos.length > 0 ?
            <div className="dropdownDoc">
                <button title="DOC" onClick={() => {
                    if (documentos.length === 1) {
                        EVENTO_ABRIR_VISOR(documentos[0])
                    }
                }}
                    className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#ccc', marginLeft: '10px' }}> <ReadOutlined style={{ fontSize: '25px' }} />
                </button>
                {
                    documentos.length > 1 && (
                        <div className="dropdownDoc-content">
                            {documentos.map((registro, i) =>
                                <p key={i} onClick={() => {
                                    if (registro.FORMATO !== "URL") {
                                        EVENTO_ABRIR_VISOR(registro)
                                    }
                                }}>

                                    {registro.FORMATO === "URL" ?
                                        <a href={registro.URL_DOCUMENTO} target="_blank" rel="noopener noreferrer">{registro.TIPO_DOCUMENTO}</a>
                                        :
                                        registro.TIPO_DOCUMENTO
                                    }
                                </p>

                            )}
                        </div>
                    )
                }

            </div>
            :
            null
    )
}

export default CPBtndoc;