import React, { useEffect, useState } from "react";

import {
  MDBBtn, MDBIcon, MDBBreadcrumbItem
} from 'mdbreact';

import { Drawer } from 'antd';

import { connect } from 'react-redux';
import store from "../store";
import { onChildrenDrawerCloseLevel2, showAllBrands, createRipple, deleteItemHistoricApp, selectCoche, changeCocheModal, showItemCar, removeAllEngineType, removeAllModel, changeColorListItemFalse, setHistoricApp } from "../action/actionCreators";
import { getFabricantesV2EnAPI, ObtenerMotorByCodigoMotorEnAPI, ObtenerFabricantesEnAPI, getModelVehicle } from "../action/actionCreatorsRequests";
import { traducirPagina, volverABuscarVehiculo, permisoMotos, datosLincenciaSertec } from "../librerias/funciones";
import DrawerModelo from '../components/DrawerModelo'
import DrawerMotorizacion from "../components/DrawerMotorizacion.js";
import DrawerMarcas from "../components/DrawerMarcas.js";
import ContainerFabricante from "./ContainerFabricante";
import {
  SelectOutlined,
  CloseOutlined,
  SearchOutlined,
  ImportOutlined
} from '@ant-design/icons';
import DrawerMotorMoto from "./DrawerMotorMoto";



const ContainerDrawerCoche = ({ activeItemCar, configApp, childrenDrawerLevel2, mostarTodasLasMarcas, historicapp, BusquedaPorModelo, TextoBuscadorPorModelo }) => {
  const calcularAlturaWeb = (configApp.height - 134).toString() + "px";
  const estadoBtnMarcas = store.getState().mostarTodasLasMarcas;

  const [fabricantes, setFabricantes] = React.useState([]);
  const [tipo_seleciionado, setTipo_seleciionado] = React.useState(1);

  const [nivelSeleccion, setNivelSeleccion] = React.useState(1);
  const [fichaAbierta, setFichaAbierta] = React.useState(false);
  const [componente, setComponente] = React.useState("FABRICANTE");
  const [motores, setMotores] = React.useState([]);
  const [modelos, setModelos] = React.useState([]);
  const [textoBusqueda, setTextoBusqueda] = React.useState("");
  const [fabricanteSeleccionado, setFabricanteSeleccionado] = React.useState({});
  const [idMercado, setIdMercado] = useState(0)


  const evenntoBuscarMotor = (textoBusqueda) => {
    buscaPorMotor(textoBusqueda);
    setComponente("MOTOR");
    setNivelSeleccion(2);
  }

  const ObtenerFabricantes = async () => {
    const registros = await ObtenerFabricantesEnAPI()
    if (registros !== undefined && registros.MARCASFABRICANTES !== undefined && registros.MARCASFABRICANTES !== null && registros.MARCASFABRICANTES.length > 0) {
      setFabricantes(registros.MARCASFABRICANTES)
    } else {
      setFabricantes(null)
    }
  }

  //Revisar método
  const getFabricantesV2 = async (TIPO) => {
    const registros = await getFabricantesV2EnAPI(TIPO)
    if (registros !== undefined && registros.MARCASFABRICANTES !== null && registros.MARCASFABRICANTES.length > 0) {
      setFabricantes(registros.MARCASFABRICANTES)
    } else {
      setFabricantes(null)
    }
  }

  const obtenerFabricantesMotor = (TIPO) => {
    setFabricantes([])

    //setComponente("FABRICANTE");


    if (permisoMotos() && TIPO === 3) {
      store.dispatch({ type: 'UPDATE_TIPO_BUSQUEDA_MODELO', tipoBusqueda: 'MT', descripcion: "Búsqueda por moto", })
      ObtenerFabricantes()
    } else {
      getFabricantesV2(TIPO)
    }
  }


  const buscaPorMotor = async (MOTOR) => {
    const registro = await ObtenerMotorByCodigoMotorEnAPI(MOTOR)
    if (registro !== undefined && registro.MOTORES !== null && registro.MOTORES.length > 0) {
      setMotores(registro.MOTORES)
    } else {
      setMotores(null)
    }
  }


  const EVENTO_FABRICANTE = (LINEA_SELECCIONADA) => {

    if (permisoMotos() && tipo_seleciionado === 3) {
      store.dispatch(showItemCar("4"))
      setFabricanteSeleccionado(LINEA_SELECCIONADA)

    } else {
      store.dispatch(getModelVehicle(LINEA_SELECCIONADA, tipo_seleciionado));
      store.dispatch(removeAllEngineType());
      store.dispatch(removeAllModel());
      store.dispatch(changeColorListItemFalse());

      store.dispatch(showItemCar("2"))
    }

    store.dispatch(setHistoricApp({ ID: LINEA_SELECCIONADA.ID, DESCRIPCION: LINEA_SELECCIONADA.Marca }, "/allbrands", "brand"));
  }




  useEffect(() => {
    let tipoSeleccion = BusquedaPorModelo.tipoBusqueda
    let numTipo = 5

    //datosLincenciaSertec
    const { ID_MERCADO } = datosLincenciaSertec()
    setIdMercado(ID_MERCADO)

    if (tipoSeleccion === 'C') {
      numTipo = 1
      setFabricantes([])
      store.dispatch(showAllBrands(false))
    } else if (tipoSeleccion === 'M') {
      numTipo = 4
      if (TextoBuscadorPorModelo !== "") {
        setTextoBusqueda(TextoBuscadorPorModelo);
        evenntoBuscarMotor(TextoBuscadorPorModelo);
      }
    } else if (tipoSeleccion === 'I') {
      numTipo = 2
    } else if (tipoSeleccion === 'T' || tipoSeleccion === 'MT') {
      numTipo = 3
    }

    setTipo_seleciionado(numTipo);


    if (tipoSeleccion !== "C") {
      setNivelSeleccion(1)
      obtenerFabricantesMotor(numTipo);
    }

    return () => {
      //console.log("numTipo",numTipo,tipo_seleciionado,"BusquedaPorModelo.tipoBusqueda",BusquedaPorModelo.tipoBusqueda)
      /*
      if ([1, 2, 3].includes(numTipo)) {
        setTipo_seleciionado(1); actualizarTipoBusqueda(1); store.dispatch(showAllBrands(false));
        store.dispatch(showItemCar("1"));
      }
      */
    }
  }, [])


  /*
    if (!fichaAbierta && childrenDrawerLevel2) {
      setFichaAbierta(true);
  
      let tipoSeleccion = BusquedaPorModelo.tipoBusqueda
      if (tipoSeleccion === 'C') {
        setTipo_seleciionado(1);
        setFabricantes([])
        store.dispatch(showAllBrands(false))
      } else if (tipoSeleccion === 'M') {
        setNivelSeleccion(1)
  
        setTipo_seleciionado(4);
        obtenerFabricantesMotor(4);
        if (TextoBuscadorPorModelo !== "") {
          setTextoBusqueda(TextoBuscadorPorModelo);
          evenntoBuscarMotor(TextoBuscadorPorModelo);
        }
      } else if (tipoSeleccion === 'I') {
        setNivelSeleccion(1)
  
        setTipo_seleciionado(2);
        obtenerFabricantesMotor(2);
      } else if (tipoSeleccion === 'T' || tipoSeleccion === 'MT') {
        setNivelSeleccion(1)
  
        setTipo_seleciionado(3);
        obtenerFabricantesMotor(3);
      } else {
        setNivelSeleccion(1)
        setTipo_seleciionado(5);
        obtenerFabricantesMotor(5);
      }
  
    } else {
      if (!childrenDrawerLevel2 && fichaAbierta) {
        setFichaAbierta(false);
        setComponente("FABRICANTE")
        setTextoBusqueda("")
  
      }
    }
  */

  const actualizarTipoBusqueda = (TIPO) => {
    let registros = { tipo: "E", descripcion: "Búsqueda por eje" }

    if ([2, 3, 4].includes(TIPO)) {
      setComponente("FABRICANTE")
    }


    if (TIPO === 1) {
      registros.tipo = 'C'
      registros.descripcion = "Búsqueda por vehiculo"
    } else if (TIPO === 2) {
      registros.tipo = 'I'
      registros.descripcion = "Búsqueda por camión"
    } else if (TIPO === 3) {
      registros.tipo = 'T'
      registros.descripcion = "Búsqueda por moto"
    } else if (TIPO === 4) {
      registros.tipo = 'M'
      registros.descripcion = "Búsqueda por motor"
    } else if (TIPO === 6) {
      registros.tipo = 'CODM'
      registros.descripcion = "Búsqueda por codigo motor"
    }


    const { tipo, descripcion } = registros

    store.dispatch({
      type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
      tipoBusqueda: tipo,
      descripcion: descripcion,
    })

  }


  return (
    <div>

      <Drawer
        width={700}
        onClose={() => {
          store.dispatch(onChildrenDrawerCloseLevel2(false)); setFabricantes([]); store.dispatch({ type: "SET_HISTORIC_APP", historicapp: [] }); store.dispatch({ type: "UPDATE_FILTRO_BUSQUEDA", TextoBuscadorPorModelo: "" })
          setTipo_seleciionado(1); actualizarTipoBusqueda(1); store.dispatch(showAllBrands(false)); store.dispatch(showItemCar("1"));
        }}
        visible={childrenDrawerLevel2}
        closable={false}
        placement={"right"}
        bodyStyle={{ padding: '0px' }}
        style={{ zIndex: '999999999999999999999', color: '#fff' }}>


        <div style={{ backgroundColor: '#000 ', color: '#010910' }}>

          <div className="flex-container-Grow">
            <div style={{ flexGrow: '1', textAlign: 'inherit' }}>
              <MDBBtn className="backgroundGris soloColorNegro" aria-label="Close" style={{ border: 'none', width: '55px', height: '55px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                createRipple(e); store.dispatch(onChildrenDrawerCloseLevel2(false)); setFabricantes([]); store.dispatch({ type: "SET_HISTORIC_APP", historicapp: [] }); store.dispatch({ type: "UPDATE_FILTRO_BUSQUEDA", TextoBuscadorPorModelo: "" });


                setTipo_seleciionado(1); actualizarTipoBusqueda(1); store.dispatch(showAllBrands(false)); store.dispatch(showItemCar("1"));


              }}>
                <i aria-label="icon: close" className="anticon anticon-close">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </i>
              </MDBBtn>
            </div>

            <div style={{ flexGrow: '1', textAlign: 'left', color: '#fff' }}>
              {tipo_seleciionado === 1 ? traducirPagina("SELECCION_TURISMO") : tipo_seleciionado === 2 ? traducirPagina("Seleccion_camion").toUpperCase() : tipo_seleciionado === 3 ? traducirPagina("Seleccion_moto").toUpperCase() : tipo_seleciionado === 4 ? traducirPagina("Seleccione_cod_motor").toUpperCase() : tipo_seleciionado === 5 ? traducirPagina("Buscar_por_eje").toUpperCase() : ""}
            </div>

            <div style={{ flexGrow: '8', textAlign: 'end' }}>

              <button className={tipo_seleciionado === 1 ? "estilosbotonesModal mr-1 botonRedondoDrawer activeModal" : "estilosbotonesModal mr-1 botonRedondoDrawer"}>
                <img src="./imagenes/coche.svg" style={{ height: '26px' }} alt={"coche"} onClick={(e) => { setTipo_seleciionado(1); actualizarTipoBusqueda(1); store.dispatch(showAllBrands(false)); store.dispatch(showItemCar("1")); }} ></img>
              </button>

              <button className={tipo_seleciionado === 2 ? "estilosbotonesModal mr-1 botonRedondoDrawer activeModal" : "estilosbotonesModal mr-1 botonRedondoDrawer"}>
                <img src="./imagenes/camion.svg" style={{ height: '26px' }} onClick={(e) => { setTipo_seleciionado(2); actualizarTipoBusqueda(2); obtenerFabricantesMotor(2); store.dispatch(showAllBrands(true)); store.dispatch(showItemCar("1")); }}></img>
              </button>

              {
                idMercado !== 2 && (
                  <button className={tipo_seleciionado === 3 ? "estilosbotonesModal mr-1 botonRedondoDrawer activeModal" : "estilosbotonesModal mr-1 botonRedondoDrawer"}>
                    <img src="./imagenes/motocicletav2.svg" style={{ height: '26px', marginTop: '7px' }} onClick={(e) => { setTipo_seleciionado(3); actualizarTipoBusqueda(3); obtenerFabricantesMotor(3); store.dispatch(showAllBrands(true)); store.dispatch(showItemCar("1")); }}></img>
                  </button>
                )
              }

              <button className={tipo_seleciionado === 4 ? "estilosbotonesModal mr-1 botonRedondoDrawer activeModal" : "estilosbotonesModal mr-1 botonRedondoDrawer"} >
                <img src="./imagenes/motorv2.svg" style={{ height: '26px' }} onClick={(e) => { setTipo_seleciionado(4); obtenerFabricantesMotor(4); actualizarTipoBusqueda(4); setFichaAbierta(false); setNivelSeleccion(1); setComponente("FABRICANTE"); }} ></img>
              </button>
              {
                idMercado !== 2 && (
                  <button className={tipo_seleciionado === 5 ? "estilosbotonesModal mr-1 botonRedondoDrawer activeModal" : "estilosbotonesModal mr-1 botonRedondoDrawer "}>
                    <img src="./imagenes/eje.svg" style={{ height: '26px' }} onClick={(e) => { setTipo_seleciionado(5); obtenerFabricantesMotor(5); actualizarTipoBusqueda(5); setFichaAbierta(false); setNivelSeleccion(1); setComponente("FABRICANTE"); }}></img>
                  </button>
                )
              }
            </div>
          </div>
        </div>

        <div className="ant-drawer-header" style={{ backgroundColor: '#dcdcdc', color: '#021323', borderBottom: '0px', borderRadius: '0px', padding: '8px 24px' }}>
          <div className="flex-container row">
            <div className="flex-item-left" style={{ textAlign: 'left' }}>
              {[1, 2, 3].includes(tipo_seleciionado) ?
                <div style={{ fontSize: '16px' }}>
                  <SelectOutlined style={{ fontSize: '20px', verticalAlign: 'unset', marginRight: '10px' }} />
                  <span>
                    {activeItemCar === "1" ? traducirPagina("Seleccione_un_fabricante") : activeItemCar === "2" ? traducirPagina("Seleccione_un_modelo") : traducirPagina("Seleccione_una_motorización")}
                  </span>
                </div>
                :
                <div style={{ fontSize: '16px' }} >
                  <SelectOutlined style={{ fontSize: '20px', verticalAlign: 'unset', marginRight: '10px' }} />
                  <span>
                    {nivelSeleccion === 1 ? traducirPagina("Seleccione_un_fabricante") : nivelSeleccion === 2 ? traducirPagina("Seleccione_cod_motor") : traducirPagina("Seleccione_un_modelo")}
                  </span>
                </div>
              }
            </div>

            <div className="flex-item-right">
              <div style={{ textAlign: 'right' }}>
                {[1, 2, 3].includes(tipo_seleciionado) ?
                  activeItemCar === "1" && tipo_seleciionado === 1 ?
                    <MDBBtn className="btnColor" color="primary" style={{ margin: '0px' }} size="sm" onClick={() => { obtenerFabricantesMotor(1); store.dispatch(showAllBrands(estadoBtnMarcas ? false : true)) }}><MDBIcon icon="search" /> {mostarTodasLasMarcas ? traducirPagina("Marcas_principales") : traducirPagina("Mas_fabricantes")}  </MDBBtn>
                    :
                    activeItemCar === "2" || activeItemCar === "3" || activeItemCar === "4" ?
                      <div className="breadcrumbNew" style={{ display: historicapp.length !== undefined ? historicapp.length > 0 ? '' : 'none' : 'none' }}>
                        {historicapp.length !== undefined ? historicapp.map((item, id) =>
                          <MDBBreadcrumbItem key={id} style={{ background: 'fff' }} >{item.urlimagen !== "" ? <img src={item.urlimagen !== "" ? item.urlimagen : ""} style={{ width: '100px', position: 'absolute', right: '0', marginTop: "-20px", objectFit: 'cover', cursor: "pointer" }} /> : null} <a onClick={() => { store.dispatch(deleteItemHistoricApp(item.posicion, historicapp)); store.dispatch(selectCoche(historicapp)); store.dispatch(changeCocheModal(item.link)); volverABuscarVehiculo(item) }}> {item.posicion === "modelo" ? item.ruta.substring(0, item.ruta.length > 7 ? 7 : item.ruta.length) + " ..." : item.ruta}</a></MDBBreadcrumbItem>
                        ) : []}
                      </div>
                      : <div></div>
                  :
                  tipo_seleciionado === 4 ?

                    <div className="mdb-skin">
                      <div className="grid-container">
                        <div className="grid-item">
                          <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Seleccione_cod_motor")} style={{ backgroundColor: "#eee", width: '230px', border: '0' }} autoComplete="off" value={textoBusqueda} onKeyPress={(e) => e.key === 'Enter' ? evenntoBuscarMotor(textoBusqueda) : {}} onChange={(e) => { setTextoBusqueda(e.target.value) }} />
                        </div>

                        <div className="grid-item">
                          <button onClick={e => { setTextoBusqueda("") }} className="btn-matricula  estiloBotonRemove" style={{ visibility: textoBusqueda.length > 0 ? 'visible' : 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </button>
                        </div>

                        <div className="grid-item barraLateral" ></div>

                        <div className="grid-item">
                          <button onClick={() => { evenntoBuscarMotor(textoBusqueda) }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                        </div>
                      </div>

                      {componente !== "FABRICANTE" ?
                        <button id="btnMantenimiento" title="Mantenimientos" onClick={() => { setComponente("FABRICANTE"); setNivelSeleccion(1); }}
                          className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: '#ccc', marginLeft: '10px' }}><ImportOutlined style={{ fontSize: '28px' }} />
                        </button>
                        : null
                      }
                    </div>

                    :
                    tipo_seleciionado === 5 ?
                      componente !== "FABRICANTE" ?
                        <button id="btnMantenimiento" title="Mantenimientos" onClick={() => { setComponente(componente === "MODELOS" ? "FABRICANTE" : "MODELOS"); setNivelSeleccion(componente === "MODELOS" ? 1 : 3); }}
                          className="activebtnMenu2" style={{ height: '40px', width: '50px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#1890ff', backgroundColor: '#ccc', marginLeft: '10px' }}><ImportOutlined style={{ fontSize: '28px' }} />
                        </button>
                        : null

                      :
                      null
                }
              </div>

            </div>
          </div>
        </div>


        {[1, 2, 3].includes(tipo_seleciionado) ?
          (() => {

            switch (activeItemCar) {
              case '1':
                return (<div style={{ height: calcularAlturaWeb, overflowY: 'auto' }} className="col-md-12"> <DrawerMarcas FABRICANTES={fabricantes} TIPO_VEHICULO={tipo_seleciionado} EVENTO_FABRICANTE={EVENTO_FABRICANTE} /> </div>);
              case '2':
                return (<div style={{ height: calcularAlturaWeb, overflowY: 'auto' }} className="col-md-12"> <DrawerModelo TIPO_VEHICULO={tipo_seleciionado} /> </div>);
              case '3':
                return (<div style={{ height: calcularAlturaWeb, overflowY: 'auto' }} className="col-md-12"> <DrawerMotorizacion TIPO_VEHICULO={tipo_seleciionado} /> </div>);
              case '4':
                return (<div style={{ height: calcularAlturaWeb, overflowY: 'auto' }} className="col-md-12"> <DrawerMotorMoto TIPO_VEHICULO={tipo_seleciionado} ID_FABRICANTE={fabricanteSeleccionado.ID} FABRICANTE={fabricanteSeleccionado} /> </div>);

            }

          })()
          :
          <div style={{ height: calcularAlturaWeb, overflowY: 'auto' }} className="col-md-12">
            <ContainerFabricante
              FICHA_ABIERTA={fichaAbierta}
              FABRICANTES={fabricantes}
              MODIFICAR_NIVEL={setNivelSeleccion}
              COMPONENTE={componente}
              MODIFICAR_COMPONENTE={setComponente}
              MOTORES={motores}
              MODIFICAR_MOTORES={setMotores}
              MODELOS={modelos}
              MODIFICAR_MODELOS={setModelos}
            ></ContainerFabricante>


          </div>
        }

      </Drawer>

    </div>
  );
}
const mapStateToProps = state => {
  return {
    brands: state.brands,
    allBrands: state.allbrands,
    models: state.models,
    visibleDrawer: state.visibleDrawer,
    configApp: state.configApp,
    localreferences: state.localreferences,
    localvehicles: state.localvehicles,
    childrenDrawerLevel2: state.childrenDrawerLevel2,
    mostarTodasLasMarcas: state.mostarTodasLasMarcas,
    activeItemCar: state.activeItemCar,
    historicapp: state.fichaBusquedaVehiculo.historicapp,
    BusquedaPorModelo: state.BusquedaPorModelo,
    TextoBuscadorPorModelo: state.TextoBuscadorPorModelo,

  };
}


export default connect(mapStateToProps)(ContainerDrawerCoche);





