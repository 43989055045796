import { Drawer, Input, Select } from "antd";
import { MDBBtn } from "mdbreact";
import React, { useEffect } from "react";
import { traducirPagina } from "../../librerias/funciones";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import {

    Obtener_FiltrosEnAPI
} from "../../action/actionCreatorsRequests";
import ContainerReferenciaGenerico from "../ContainerReferenciaGenerico";
import { useRef } from "react";
import './index.css'
const { Option } = Select;

export const CatalogoBaterias = (props) => {
    const selectRef1 = useRef(null)
    const selectRef2 = useRef(null)
    const selectRef3 = useRef(null)
    const selectRef4 = useRef(null)
    const selectRef5 = useRef(null)
    const selectRef6 = useRef(null)
    const selectRef7 = useRef(null)
    const selectRef8 = useRef(null)


    const [pantallaInicial, setPantallaInicial] = React.useState(1);
    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            MARCA: [],
            MARCA_SELECCIONADO: 0,
            VOLTAJE: [],
            VOLTAJE_SELECCIONADO: 0,
            AMPERIOS: [],
            AMPERIOS_SELECCIONADO: 0,
            LONGITUD: [],
            LONGITUD_SELECCIONADO: 0,
            ANCHO: [],
            ANCHO_SELECCIONADO: 0,
            ALTURA: [],
            ALTURA_SELECCIONADO: 0,
            CAPACIDAD: [],
            CAPACIDAD_SELECCIONADO: 0,
            REFERENCIA: "",
            REGISTROS: []
        }
    )

    const [datosPaginacion, setDatosPaginacion] = React.useState({ iniPage: 1, inicio: 1, final: 40, numPagina: 0 });


    const verificarSeleccionado = (payLoadLocal) => {
        const { CAPACIDAD_SELECCIONADO, MARCA_SELECCIONADO, VOLTAJE_SELECCIONADO, AMPERIOS_SELECCIONADO, LONGITUD_SELECCIONADO, ANCHO_SELECCIONADO, ALTURA_SELECCIONADO, REFERENCIA } = payLoadLocal

        let lista = [CAPACIDAD_SELECCIONADO, AMPERIOS_SELECCIONADO, VOLTAJE_SELECCIONADO, LONGITUD_SELECCIONADO, ANCHO_SELECCIONADO, ALTURA_SELECCIONADO, MARCA_SELECCIONADO]
        let resultado = true;
        let count = lista.filter((item) => item !== 0);

        if (count.length > 0) {
            resultado = false;
        } else {

            if (CAPACIDAD_SELECCIONADO === 0) {
                selectRef1.current.focus({ cursor: 'start' });
            }

            if (CAPACIDAD_SELECCIONADO !== 0) {
                selectRef2.current.focus({ cursor: 'start' });
            }

            if (AMPERIOS_SELECCIONADO !== 0) {
                selectRef3.current.focus({ cursor: 'start' });
            }
            if (VOLTAJE_SELECCIONADO !== 0) {
                selectRef4.current.focus({ cursor: 'start' });
            }
            if (LONGITUD_SELECCIONADO !== 0) {
                selectRef5.current.focus({ cursor: 'start' });
            }
            if (ANCHO_SELECCIONADO !== 0) {
                selectRef6.current.focus({ cursor: 'start' });
            }
            if (ALTURA_SELECCIONADO !== 0) {
                selectRef7.current.focus({ cursor: 'start' });
            }
            if (MARCA_SELECCIONADO !== 0) {
                selectRef8.current.focus({ cursor: 'start' });
            }
        }



        if (REFERENCIA !== "") {
            resultado = false;
        }

        return resultado
    }

    const cargarFiltros = async (payLoadLocal, activarFiltro = true) => {
        let registros = []
        /*
        if (activarFiltro) {
            setPantallaInicial(2)
        }
        */

        datosPaginacion.iniPage = 1
        datosPaginacion.inicio = 1
        datosPaginacion.final = 40

        setDatosPaginacion({ ...datosPaginacion })

        registros = await Obtener_FiltrosEnAPI(payLoadLocal, 0)
        if (registros !== undefined && registros !== null) {
            const { ALTURA = null, AMPERIOS = null, ANCHO = null, LONGITUD = null, MARCAS = null, VOLTAJES = null, CAPACIDAD = null } = registros

            payLoadLocal.MARCA = MARCAS === null ? [] : MARCAS
            payLoadLocal.VOLTAJE = VOLTAJES === null ? [] : VOLTAJES
            payLoadLocal.AMPERIOS = AMPERIOS === null ? [] : AMPERIOS
            payLoadLocal.LONGITUD = LONGITUD === null ? [] : LONGITUD
            payLoadLocal.ANCHO = ANCHO === null ? [] : ANCHO
            payLoadLocal.ALTURA = ALTURA === null ? [] : ALTURA
            payLoadLocal.CAPACIDAD = CAPACIDAD === null ? [] : CAPACIDAD
        }

        setPayLoadLocal({ ...payLoadLocal })

        /*
        if (activarFiltro) {
            setPantallaInicial(3)
        }
        */
    }





    const FiltrosLocales = (REGSPAGE, PAGE, ORDERCOLUM, ORDERDIR) => {
        const { CAPACIDAD_SELECCIONADO, AMPERIOS_SELECCIONADO, VOLTAJE_SELECCIONADO, LONGITUD_SELECCIONADO, ANCHO_SELECCIONADO, ALTURA_SELECCIONADO, MARCA_SELECCIONADO, REFERENCIA } = payLoadLocal



        return {
            PAYLOAD: {

                CAPACIDAD: CAPACIDAD_SELECCIONADO === 0 ? "" : CAPACIDAD_SELECCIONADO,
                AMPERIOS: AMPERIOS_SELECCIONADO === 0 ? "" : AMPERIOS_SELECCIONADO,
                VOLTAJE: VOLTAJE_SELECCIONADO === 0 ? "" : VOLTAJE_SELECCIONADO,
                LONGITUD: LONGITUD_SELECCIONADO === 0 ? "" : LONGITUD_SELECCIONADO,
                ANCHO: ANCHO_SELECCIONADO === 0 ? "" : ANCHO_SELECCIONADO,
                ALTURA: ALTURA_SELECCIONADO === 0 ? "" : ALTURA_SELECCIONADO,
                ID_MARCA: MARCA_SELECCIONADO === 0 ? "" : MARCA_SELECCIONADO,
                REFERENCIA: REFERENCIA === 0 ? "" : REFERENCIA,
            },
            PAGINATION: {
                REGSPAGE: REGSPAGE,
                PAGE: PAGE,
                ORDERCOLUM: ORDERCOLUM,
                ORDERDIR: ORDERDIR
            }
        }
    }
    const iniciarBusqueda = () => {
        setPantallaInicial(2)

        setTimeout(() => {
            setPantallaInicial(3)
        }, 200)
    }

    const comprobarFiltros = () => {

        const { PAYLOAD } = FiltrosLocales(datosPaginacion.numPagina, datosPaginacion.iniPage, 1, "A")
        const { CAPACIDAD, AMPERIOS, VOLTAJE, LONGITUD, ANCHO, ALTURA, ID_MARCA, REFERENCIA } = PAYLOAD

        const listFilter = [CAPACIDAD, AMPERIOS, VOLTAJE, LONGITUD, ANCHO, ALTURA, ID_MARCA, REFERENCIA];
     

        let vacios = listFilter.filter((item) => item !== "")
        setPantallaInicial(1)

        if (vacios.length > 0) {
            setTimeout(() => {
                setPantallaInicial()
            }, 200)
        }
        return vacios.length > 0
        
    }



    const volverEstadoInicial = () => {
        payLoadLocal.MARCA = []
        payLoadLocal.MARCA_SELECCIONADO = 0
        payLoadLocal.VOLTAJE = []
        payLoadLocal.VOLTAJE_SELECCIONADO = 0
        payLoadLocal.AMPERIOS = []
        payLoadLocal.AMPERIOS_SELECCIONADO = 0
        payLoadLocal.LONGITUD = []
        payLoadLocal.LONGITUD_SELECCIONADO = 0
        payLoadLocal.ANCHO = []
        payLoadLocal.ANCHO_SELECCIONADO = 0
        payLoadLocal.ALTURA = []
        payLoadLocal.ALTURA_SELECCIONADO = 0
        payLoadLocal.CAPACIDAD = []
        payLoadLocal.CAPACIDAD_SELECCIONADO = 0

        payLoadLocal.REFERENCIA = ""

        setPantallaInicial(1)
        cargarFiltros(payLoadLocal, false)
        selectRef1.current.focus({ cursor: 'start' });


    }




    useEffect(() => {
        cargarFiltros(payLoadLocal, false)

        setTimeout(() => {
            selectRef1.current.focus({ cursor: 'start' });
        }, 1000)
    }, [])


    return (
        <>

            <Drawer
                width={"80%"}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_CATALOGO_BATERIAS: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }
                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>



                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>

                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_CATALOGO_BATERIAS: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="menu_filtros_neumaticos">
                    <div className="menu_filtros_neumaticos_1"></div>
                    <div className="menu_filtros_neumaticos_2">

                        <div>


                            {payLoadLocal.CAPACIDAD !== null &&
                                <Select
                                    showSearch
                                    allowClear={true}
                                    ref={selectRef1}

                                    value={payLoadLocal.CAPACIDAD_SELECCIONADO === 0 ? traducirPagina("txt_Capacidad") : payLoadLocal.CAPACIDAD_SELECCIONADO}
                                    size={"large"}
                                    placeholder={traducirPagina("txt_Capacidad")}
                                    style={{ width: "120px", textAlign: 'left', }}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        let SELECCIONADO = e === undefined || e === null ? 0 : e;
                                        payLoadLocal.CAPACIDAD_SELECCIONADO = SELECCIONADO;
                                        setPayLoadLocal({ ...payLoadLocal })
                                        cargarFiltros(payLoadLocal)

                                        let permitirBuscar = comprobarFiltros()
                                        //console.log("selectRef3.current",selectRef3.current)
                                        if (!permitirBuscar) {
                                            selectRef2.current.focus({ cursor: 'start' });

                                        }


                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLocaleString().toLowerCase().indexOf(input.toLocaleString().toLowerCase()) >= 0
                                    }
                                >
                                    {payLoadLocal.CAPACIDAD.length > 0 ?
                                        payLoadLocal.CAPACIDAD.map((registro, i) =>
                                            <Option key={i} value={registro}>{registro}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>

                            }
                        </div>





                        <div>
                            {payLoadLocal.AMPERIOS !== null &&
                                <Select
                                    showSearch
                                    allowClear={true}
                                    ref={selectRef2}

                                    value={payLoadLocal.AMPERIOS_SELECCIONADO === 0 ? traducirPagina("txt_potencia_arranque") : payLoadLocal.AMPERIOS_SELECCIONADO}
                                    size={"large"}
                                    placeholder={traducirPagina("txt_potencia_arranque")}
                                    style={{ width: "180px", textAlign: 'left', }}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        let SELECCIONADO = e === undefined || e === null ? 0 : e;
                                        payLoadLocal.AMPERIOS_SELECCIONADO = SELECCIONADO;
                                        setPayLoadLocal({ ...payLoadLocal })
                                        cargarFiltros(payLoadLocal)

                                        let permitirBuscar = comprobarFiltros()
                                        if (!permitirBuscar) {
                                            selectRef3.current.focus({ cursor: 'start' });
                                        }
                                        //console.log("selectRef3.current",selectRef3.current)

                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLocaleString().toLowerCase().indexOf(input.toLocaleString().toLowerCase()) >= 0
                                    }
                                >
                                    {payLoadLocal.AMPERIOS.length > 0 ?
                                        payLoadLocal.AMPERIOS.map((registro, i) =>
                                            <Option key={i} value={registro}>{registro}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>

                            }
                        </div>




                        <div >
                            {payLoadLocal.VOLTAJE !== null &&
                                <Select
                                    ref={selectRef3}
                                    showSearch
                                    allowClear={true}
                                    value={payLoadLocal.VOLTAJE_SELECCIONADO === 0 ? traducirPagina("Voltaje") : payLoadLocal.VOLTAJE_SELECCIONADO}
                                    size={"large"}
                                    placeholder={traducirPagina("Voltaje")}
                                    style={{ width: "120px", textAlign: 'left' }}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        let SELECCIONADO = e === undefined || e === null ? 0 : e;
                                        payLoadLocal.VOLTAJE_SELECCIONADO = SELECCIONADO;
                                        setPayLoadLocal({ ...payLoadLocal })
                                        cargarFiltros(payLoadLocal)

                                        let permitirBuscar = comprobarFiltros()
                                        if (!permitirBuscar) {
                                            selectRef4.current.focus({ cursor: 'start' });
                                        }


                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLocaleString().toLowerCase().indexOf(input.toLocaleString().toLowerCase()) >= 0
                                    }
                                >
                                    {payLoadLocal.VOLTAJE.length > 0 ?
                                        payLoadLocal.VOLTAJE.map((registro, i) =>
                                            <Option key={i} value={registro}>{registro}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>

                            }
                        </div>


                        <div >
                            {payLoadLocal.LONGITUD !== null &&
                                <Select
                                    ref={selectRef4}

                                    showSearch
                                    allowClear={true}
                                    value={payLoadLocal.LONGITUD_SELECCIONADO === 0 ? traducirPagina("txt_Longitud") : payLoadLocal.LONGITUD_SELECCIONADO}
                                    size={"large"}
                                    placeholder={traducirPagina("txt_Longitud")}
                                    style={{ width: "120px", textAlign: 'left', }}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        let SELECCIONADO = e === undefined || e === null ? 0 : e;
                                        payLoadLocal.LONGITUD_SELECCIONADO = SELECCIONADO;
                                        setPayLoadLocal({ ...payLoadLocal })

                                        cargarFiltros(payLoadLocal)

                                        let permitirBuscar = comprobarFiltros()

                                        if (!permitirBuscar) {
                                            selectRef5.current.focus({ cursor: 'start' });
                                        }

                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLocaleString().toLowerCase().indexOf(input.toLocaleString().toLowerCase()) >= 0
                                    }
                                >
                                    {payLoadLocal.LONGITUD.length > 0 ?
                                        payLoadLocal.LONGITUD.map((registro, i) =>
                                            <Option key={i} value={registro}>{registro}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>

                            }
                        </div>

                        <div>
                            {payLoadLocal.ANCHO !== null &&
                                <Select
                                    ref={selectRef5}

                                    showSearch
                                    allowClear={true}
                                    value={payLoadLocal.ANCHO_SELECCIONADO === 0 ? traducirPagina("Ancho") : payLoadLocal.ANCHO_SELECCIONADO}
                                    size={"large"}
                                    placeholder={traducirPagina("Ancho")}
                                    style={{ width: "120px", textAlign: 'left', }}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        let SELECCIONADO = e === undefined || e === null ? 0 : e;
                                        payLoadLocal.ANCHO_SELECCIONADO = SELECCIONADO;
                                        setPayLoadLocal({ ...payLoadLocal })

                                        cargarFiltros(payLoadLocal)

                                        let permitirBuscar = comprobarFiltros()
                                        if (!permitirBuscar) {
                                            selectRef6.current.focus({ cursor: 'start' });
                                        }

                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLocaleString().toLowerCase().indexOf(input.toLocaleString().toLowerCase()) >= 0
                                    }
                                >
                                    {payLoadLocal.ANCHO.length > 0 ?
                                        payLoadLocal.ANCHO.map((registro, i) =>
                                            <Option key={i} value={registro}>{registro}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>

                            }
                        </div>

                        <div>
                            {payLoadLocal.ALTURA !== null &&
                                <Select
                                    ref={selectRef6}

                                    showSearch
                                    allowClear={true}
                                    value={payLoadLocal.ALTURA_SELECCIONADO === 0 ? traducirPagina("txt_Altura") : payLoadLocal.ALTURA_SELECCIONADO}
                                    size={"large"}
                                    placeholder={traducirPagina("txt_Altura")}
                                    style={{ width: "120px", textAlign: 'left', }}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        let SELECCIONADO = e === undefined || e === null ? "" : e;
                                        payLoadLocal.ALTURA_SELECCIONADO = SELECCIONADO;
                                        setPayLoadLocal({ ...payLoadLocal })

                                        cargarFiltros(payLoadLocal)

                                        let permitirBuscar = comprobarFiltros()
                                        if (!permitirBuscar) {
                                            selectRef7.current.focus({ cursor: 'start' });
                                        }


                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLocaleString().toLowerCase().indexOf(input.toLocaleString().toLowerCase()) >= 0
                                    }
                                >
                                    {payLoadLocal.ALTURA.length > 0 ?
                                        payLoadLocal.ALTURA.map((registro, i) =>
                                            <Option key={i} value={registro}>{registro}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>

                            }
                        </div>

                        <div>
                            {payLoadLocal.MARCA !== null &&
                                <Select
                                    ref={selectRef7}

                                    showSearch
                                    allowClear={true}
                                    value={payLoadLocal.MARCA_SELECCIONADO === 0 ? traducirPagina("Marca") : payLoadLocal.MARCA_SELECCIONADO}
                                    size={"large"}
                                    placeholder={traducirPagina("Marca")}
                                    style={{ width: "200px", textAlign: 'left', }}
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        let SELECCIONADO = e === undefined || e === null ? 0 : parseInt(e);
                                        payLoadLocal.MARCA_SELECCIONADO = SELECCIONADO;
                                        setPayLoadLocal({ ...payLoadLocal })
                                        cargarFiltros(payLoadLocal)

                                        let permitirBuscar = comprobarFiltros()
                                        if (!permitirBuscar) {
                                            selectRef8.current.focus({ cursor: 'start' });
                                        }

                                        /*
                                        if (payLoadLocal.MARCA_SELECCIONADO === 0) {
                                            volverEstadoInicila()
                                        } else {
                                            cargarFiltros(payLoadLocal.MARCA_SELECCIONADO)
                                        }
                                        */
                                    }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >

                                    {payLoadLocal.MARCA.length > 0 ?
                                        payLoadLocal.MARCA.map((registro) =>
                                            <Option key={registro.ID_MARCA} value={registro.ID_MARCA}>{registro.DESCRIPCION}</Option>
                                        )
                                        :
                                        null
                                    }
                                </Select>
                            }
                        </div>



                        <div>
                            <Input
                                ref={selectRef8}
                                placeholder={traducirPagina("Referencia")}
                                allowClear={true}
                                size="large"
                                style={{ width: '160px' }} value={payLoadLocal.REFERENCIA}
                                onChange={(e) => {
                                    payLoadLocal.REFERENCIA = e.target.value
                                    setPayLoadLocal({ ...payLoadLocal })

                                    //comprobarFiltros()

                                }} />
                        </div>


                        <div className='container_btn_filtro_neumatico'>
                            <div className='btn_limpiar_filtros' onClick={() => {
                                volverEstadoInicial()

                            }}>
                                <ClearOutlined></ClearOutlined>
                            </div>


                            <div className={`btn_buscar_filtros`} onClick={() => {

                                let permitirBuscar = verificarSeleccionado(payLoadLocal)
                               

                                if (!permitirBuscar) {
                                    iniciarBusqueda()
                                } 

                            }}>
                                <SearchOutlined></SearchOutlined>
                                {traducirPagina("Buscar")}
                            </div>

                        </div>
                    </div>
                </div>

                <div>

                    {
                        pantallaInicial === 1 ?
                            <div className="ContainerInitCatalogo">
                                <div>
                                    <div>
                                        <img src="./imagenes/bateria_medidas.png"></img>
                                    </div>

                                    <div className="texto_bateria">
                                        <h1>12V 80Ah 55A</h1>
                                    </div>

                                    <div>
                                        <h1>{traducirPagina("Msg_criterio_neumatico")}</h1>
                                    </div>
                                </div>
                            </div>
                            :
                            pantallaInicial === 2 ?
                                <div></div>
                                :
                                <div className="p-1">
                                    <ContainerReferenciaGenerico
                                        tabActivado={""}
                                        tipoComponente={"CatalogoBateria"}
                                        referencia={FiltrosLocales(datosPaginacion.numPagina, datosPaginacion.iniPage, 1, "A")}
                                        ALTURA={185}
                                    ></ContainerReferenciaGenerico>
                                </div>

                    }
                </div>
            </Drawer>
        </>

    );
}