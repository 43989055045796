import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, } from "../action/actionCreators"
import {  ObtenerHistoricoPedidosEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, MSGOK, MSGERROR, recortarCadena, obtenerFechaLocal, convertirMoneda } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';



import { Select, Card, Modal, Button, Input, Row, Col, Drawer } from 'antd';

import store from "../store";
const { Option } = Select;
const DetalleHistorialPedido = ({ props, configApp }) => {
    const [hayDatos, setHayDatos] = React.useState(false);
    const [historial, setHistorial] = React.useState([]);

    const [payLoadLocal, setpayLoadLocal] = React.useState({
        IMPORTE_BRUTO_1: 0,
        IMPORTE_DESCUENTOS_1: 0,
        IMPORTE_BASE_IMPONIBLE_1: 0,
        VALOR_IVA_1: 0,
        IMPORTE_IMPUESTOS_1: 0,
        IMPORTE_BRUTO: 0,
        IMPORTE_DESCUENTOS: 0,
        IMPORTE_BASE_IMPONIBLE: 0,
        IMPORTE_IMPUESTOS: 0,
        IMPORTE_TOTAL: 0

    });



    const buscarHistorialDePedidos = (PAGE, ORDERCOLUM, ORDERDIR, PAYLOAD) => {
        ObtenerHistoricoPedidosEnAPI(PAGE, ORDERCOLUM, ORDERDIR, { ID: PAYLOAD.ID, CODIGO: PAYLOAD.CODIGO, FECHA_DESDE: PAYLOAD.FECHA_DESDE, FECHA_HASTA: PAYLOAD.FECHA_HASTA }).then((pedidos) => {
            if (pedidos !== undefined) {
                if (pedidos.REGISTROS !== null && pedidos.REGISTROS.length > 0) {
                    setHistorial(pedidos.REGISTROS)

                } else {
                    setHistorial(null)
                }
            } else {
                setHistorial(null)
            }

        })
    }



    if (!hayDatos && props.VER_FICHA) {
        setHayDatos(true);

        buscarHistorialDePedidos(1, 1, 1, { ID: props.REGISTRO.ID, CODIGO: "", FECHA_DESDE: "", FECHA_HASTA: "" });



    } else {
        if (!props.VER_FICHA && hayDatos) {
            setHayDatos(false);

        }
    }


    return (
        <Drawer
            width={1200}
            onClose={() => { props.CERRAR_FICHA(false) }}
            visible={props.VER_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                            props.CERRAR_FICHA(false);

                            createRipple(e)
                        }}>
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>


            <div className="p-2" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                <div style={{ height: (configApp.height - 170).toString() + "px" }} className="mdb-skin" >

                    <div style={{ margin: '24px 24px 4px 24px' }}>
                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                            <Col md={7} style={{ backgroundColor: '#e6e6e6', border: '1px solid #999' }}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Codigo").toUpperCase()}</span>
                            </Col>

                            <Col md={4} style={{ backgroundColor: '#e6e6e6', border: '1px solid #999' }}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Mi_pedido").toUpperCase()}</span>
                            </Col>

                            <Col md={3} style={{ backgroundColor: '#e6e6e6', border: '1px solid #999' }}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Fecha").toUpperCase()}</span>
                            </Col>

                            <Col md={6} style={{ backgroundColor: '#e6e6e6', border: '1px solid #999' }}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Pedido_por").toUpperCase()}</span>
                            </Col>

                            <Col md={4} style={{ backgroundColor: '#e6e6e6', border: '1px solid #999' }}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Almacen").toUpperCase()}</span>
                            </Col>


                            <Col md={7} style={{ backgroundColor: '#fff', border: '1px solid #999', marginTop: '-1px', height: '35px' }}>
                                <span>{historial !== null && historial.length > 0 ? historial[0].CODIGO_PEDIDO_SOCIO : ""}</span>
                            </Col>

                            <Col md={4} style={{ backgroundColor: '#fff', border: '1px solid #999', marginTop: '-1px', height: '35px' }}>

                                <span>{historial !== null && historial.length > 0 ? recortarCadena(historial[0].NUMERO_PEDIDO, 15, " ...") : ""}</span>
                            </Col>

                            <Col md={3} style={{ backgroundColor: '#fff', border: '1px solid #999', marginTop: '-1px', height: '35px' }}>
                                <span>{historial !== null && historial.length > 0 ? historial[0].FECHA : ""}</span>
                            </Col>
                            <Col md={6} style={{ backgroundColor: '#fff', border: '1px solid #999', marginTop: '-1px', height: '35px' }}>
                                <span>{historial !== null && historial.length > 0 ? historial[0].PEDIDO_POR : ""}</span>
                            </Col>
                            <Col md={4} style={{ backgroundColor: '#fff', border: '1px solid #999', marginTop: '-1px', height: '35px' }}>
                                <span>{historial !== null && historial.length > 0 ? historial[0].DESCRIPCION_ALMACEN_SOCIO : ""}</span>
                            </Col>

                        </Row>

                    </div>

                    <div style={{ margin: '24px 24px 4px 24px' }}>
                        <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                            <Col md={24} style={{ backgroundColor: '#e6e6e6', border: '1px solid #999' }}>
                                <span style={{ fontWeight: "bold" }} >{traducirPagina("Observaciones").toUpperCase()}</span>
                            </Col>
                            <Col md={24} style={{ backgroundColor: '#fff', border: '1px solid #999', marginTop: '-1px', height: '35px' }}>
                                <span>{historial !== null && historial.length > 0 ? recortarCadena(historial[0].OBSERVACIONES_PEDIDO,110," ...") : ""}</span>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ margin: '24px 24px 4px 24px' }}>
                        {historial[0] !== undefined ?
                            historial[0] === null ?
                                <div style={{ textAlign: 'center' }}>
                                    <h4>{"No se encontraron resultados"}</h4>
                                </div>
                                :
                                historial[0].LINEAS !== undefined && historial[0].LINEAS !== null ?

                                    <div style={{ height: (configApp.height - 390).toString() + "px", overflow: 'auto' }} >
                                        {historial[0].LINEAS.length === 0 ?
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>{"No hay lineas a mostrar."}</h4>
                                            </div>
                                            :
                                            <MDBTable responsive hover className="mdb-skin">
                                                <MDBTableHead>
                                                    <tr>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'baseline' }}>{traducirPagina("Referencia")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'baseline' }}>{traducirPagina("Identificador")}</th>


                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline' }}>{traducirPagina("Descripción")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '40px', verticalAlign: 'baseline' }}>{traducirPagina("Uds")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '90px', verticalAlign: 'baseline' }}>{traducirPagina("Imp_bruto")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '100px', verticalAlign: 'baseline' }}>{traducirPagina("Dto")}</th>
                                                        <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110x', verticalAlign: 'baseline' }}>{traducirPagina("B_imponible")}</th>

                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {historial[0].LINEAS.length > 0 ?
                                                        historial[0].LINEAS.map((linea, i) =>
                                                            <tr key={i} style={{ color: '#666' }}>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'inherit' }}>{linea.REFERENCIA_SOCIO}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'inherit' }}>{linea.IDENTIFICADOR}</td>

                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'inherit' }}>{linea.REFERENCIA_DESCRIPCION}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '40px', verticalAlign: 'inherit', textAlign: 'right' }}>{linea.CANTIDAD}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '90px', verticalAlign: 'inherit', textAlign: 'right' }}>{convertirMoneda(linea.PRECIO, "€")}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '100px', verticalAlign: 'inherit', textAlign: 'right' }}>{convertirMoneda(linea.DTO, "%")}</td>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '110px', verticalAlign: 'inherit', textAlign: 'right', color: '#000' }}>{convertirMoneda(linea.NETO, "€")}</td>

                                                            </tr>
                                                        )
                                                        : null
                                                    }
                                                </MDBTableBody>
                                            </MDBTable>
                                        }
                                    </div>

                                    :

                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                        <MDBCol md="9">
                                            <div>
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '800px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '700px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                                <br />
                                                <span className="skeleton-box" style={{ width: '600px' }}></span>
                                            </div>
                                        </MDBCol>
                                    </div>
                            : null}
                    </div>

                    <div style={{ color: '#000', margin: '24px 24px 4px 24px' }} bordered={false}>
                        <MDBTable>
                            <MDBTableHead>
                                <tr style={{ color: '#666' }}>
                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("B_imponible").toUpperCase()}</th>
                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", width: '120px', verticalAlign: 'baseline', textAlign: 'right' }}>{traducirPagina("Impuesto").toUpperCase()}</th>
                                    <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "500", verticalAlign: 'baseline', textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7' }}>{traducirPagina("Total").toUpperCase()}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr style={{ color: '#666' }}>
                                    <td style={{ textAlign: 'right' }}>{ }</td>
                                    <td style={{ textAlign: 'right' }}>{ }</td>
                                    <td style={{ textAlign: 'right', color: '#4285f4', backgroundColor: '#f7f7f7', fontSize: '20px' }}>{historial !== null && historial.length > 0 ? historial[0].IMPORTE : ""}</td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </div>

                </div>
            </div>
        </Drawer>
    )
}


DetalleHistorialPedido.propTypes = {
    title: PropTypes.string,
    VER_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    REGISTRO: PropTypes.object,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaAlbaranesTaller: state.estadoDrawer.openDrawerFichaAlbaranesTaller,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado
    };
}

export default connect(mapStateToProps)(DetalleHistorialPedido);