import React, { useEffect } from "react";
import PropTypes from 'prop-types';

import {
    MDBRow, MDBCol
} from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import { connect } from 'react-redux';
import store from "../store";
import { removeAllEngineType, removeAllModel, setHistoricApp, showItemCar } from "../action/actionCreators";
import { getModelVehicle, Obtener_Fabricantes_ImagenesEnAPI } from "../action/actionCreatorsRequests";
import { getFocus, traducirPagina } from "../librerias/funciones"
import {
    CloseOutlined,
    SearchOutlined,
    ImportOutlined
} from '@ant-design/icons';
import CPSearch from "./utils/CPSearch.jsx";

const { useState, useRef } = React;
const DrawerMarcas = ({ fichaBusquedaVehiculo, mostarTodasLasMarcas, configApp, props }) => {
    const [textoFabricante, setTextoFabricante] = React.useState("");
    const [fichaAbierta, setFichaAbierta] = React.useState(false);
    const [listaFabricante, setListaFabricante] = React.useState([]);
    const [listaFabricanteOrig, setListaFabricanteOrig] = React.useState([]);
    const [fabricantes, setFabricantes] = React.useState([]);


    const calcularAlturaWeb = (configApp.height - 115).toString() + "px";
    const [loading, setLoading] = useState(true);

    const counter = useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= fichaBusquedaVehiculo.manufactur.length) {
            setLoading(false);
        }
    }

    const Obtener_Fabricantes_Imagenes = async () => {
        let respuesta = await Obtener_Fabricantes_ImagenesEnAPI();
        if (respuesta !== undefined && respuesta !== null) {
            setFabricantes(respuesta.REGISTROS)
        } else {
            setFabricantes(null)
        }
    }

    const modelarDatos = (marcas) => {
        let listaMarca = [];
        let objetoNuevoMarcas = [];
        let contAux = 0;

        for (var i = 0; i < marcas.length; i++) {
            contAux += 1;
            if (contAux <= 3) {
                listaMarca.push({ "ID": marcas[i].ManNo, "Marca": marcas[i].Term })
                if ((marcas.length - 1) === i) {
                    objetoNuevoMarcas.push({ "lista": listaMarca });
                }
            } else {
                objetoNuevoMarcas.push({ "lista": listaMarca });
                contAux = 0;
                listaMarca = []
                i = (i - 1)
            }
        }
        return objetoNuevoMarcas.length === 0 ? null : objetoNuevoMarcas;
    }


    const buscarEnTabla = (palabraABuscar) => {
        let palabra = palabraABuscar;
        if (palabra !== "") {
            if (listaFabricanteOrig !== null) {
                let resultadoFiltro = listaFabricanteOrig.filter((item) => {
                    return item.Term.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.Term.toUpperCase() === palabra.toUpperCase()
                })
                if (resultadoFiltro.length > 0) {
                    //setListaFabricante(modelarDatos(resultadoFiltro));
                    setListaFabricante(resultadoFiltro);
                } else {
                    setListaFabricante(null);
                }

            }
        } else {
            //setListaFabricante(modelarDatos(listaFabricanteOrig));
            setListaFabricante(listaFabricanteOrig);
        }
    }




    if (props.FABRICANTES !== undefined && props.FABRICANTES !== null && props.FABRICANTES.length > 0 && mostarTodasLasMarcas && !fichaAbierta) {
        getFocus("imputdropmarcas");
        setFichaAbierta(true)

        //setListaFabricante(modelarDatos(props.FABRICANTES))
        setListaFabricante(props.FABRICANTES)

        setListaFabricanteOrig(props.FABRICANTES);

    } else {
        if (fichaAbierta && props.FABRICANTES.length === 0) {
            setFichaAbierta(false);
            setTextoFabricante("")

            setListaFabricante([])
            setListaFabricanteOrig([]);
        }
    }






    useEffect(() => {
        Obtener_Fabricantes_Imagenes()
    }, [])


    return (
        <>
            {mostarTodasLasMarcas ?


                <div>
                    <CPSearch
                        className='mdb-skin searchVehiculo'
                        TEXTO_BUSCADOR={textoFabricante}
                        placeholder={traducirPagina("Busqueda_por_fabricante")}
                        onChangeInput={(e) => {
                            buscarEnTabla(e.target.value);
                            setTextoFabricante(e.target.value)
                        }}
                        onCloseInput={() => {
                            setTextoFabricante(""); buscarEnTabla("")
                        }}

                    />


                    {/*
                    <div className="mt-3 pb-2">
                        <div className="grid-container">
                            <div className="grid-item">
                                <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Busqueda_por_fabricante")} style={{ backgroundColor: "#eee", width: '500px', border: '0' }} autoComplete="off" onKeyPress={(e) => { }} onChange={(e) => { buscarEnTabla(e.target.value); setTextoFabricante(e.target.value) }} value={textoFabricante} />
                            </div>

                            <div className="grid-item">
                                <button onClick={e => { setTextoFabricante(""); buscarEnTabla("") }} className="btn-matricula  estiloBotonRemove" style={{ visibility: textoFabricante.length > 0 ? 'visible' : 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </button>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <button onClick={e => { }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                            </div>
                        </div>
                    </div>

                */}


                    {listaFabricante === null || listaFabricante === undefined ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_fabricantes_para_mostrar")}</h3> </div>
                        :
                        listaFabricante.length > 0 ?
                            <>

                                {/*  
listaFabricante.map((item, i) =>
                                <div key={i} id={i} style={{ color: '#000', fontSize: '16px', fontWeight: '500', marginBottom: '10px' }}>
                                    {item.lista.map((row, i) =>
                                        <div style={{ float: 'left', padding: '5px', width: '33%', cursor: 'pointer' }}
                                            className="efectoContainerVehiculo"
                                            onClick={() => {
                                                props.EVENTO_FABRICANTE(row);
                                            }}>
                                            {row.Marca}
                                        </div>
                                    )}
                                </div>

                            )
*/}
                                <div className="container_grid_coche">
                                    <div className='container_list_marca_familia' >
                                        {listaFabricante.map((item, i) =>
                                            <div key={i} className={"item_rating item_rating_fondo"} onClick={() => {
                                                props.EVENTO_FABRICANTE({ "ID": item.ManNo, "Marca": item.Term });
                                            }}>
                                                <div style={{ float: 'left', padding: '5px', cursor: 'pointer' }}>
                                                    {item.Term}
                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>

                            </>


                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
                    }
                </div>

                :
                <>
                    {
                        fabricantes === null ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <h3>{traducirPagina("Sin_referencias")}</h3> </div>
                            :
                            fabricantes.length === 0 ?

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>

                                :
                                <div className="containerFabricantes">
                                    {fabricantes.map((registro, i) =>
                                        <div key={i}>
                                            <img className="img-fluid rounded float-left"
                                                src={registro.IMAGEN}
                                                onError={addDefaultSrc}
                                                onLoad={imageLoaded}
                                                style={{ width: '90px', cursor: "pointer" }}
                                                onClick={() => {
                                                    store.dispatch(getModelVehicle({ id: registro.ID_FABRICANTE }));
                                                    store.dispatch(removeAllEngineType());
                                                    store.dispatch(removeAllModel());
                                                    store.dispatch(setHistoricApp({ ID: registro.ID_FABRICANTE, DESCRIPCION: registro.NOMBRE }, "/marcas", "brand"));
                                                    store.dispatch(showItemCar("2"))
                                                }}>
                                            </img>

                                        </div>
                                    )}
                                </div>
                    }
                </>
            }
        </>

    );
}

DrawerMarcas.propTypes = {
    FABRICANTES: PropTypes.array,
    TIPO_VEHICULO: PropTypes.number,
    EVENTO_FABRICANTE: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        mostarTodasLasMarcas: state.mostarTodasLasMarcas,
        childrenDrawerLevel2: state.childrenDrawerLevel2
    };
}



const autosc = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export default connect(mapStateToProps)(DrawerMarcas);