import React from "react";
import {
    traducirPagina,
    obtenerDatosUsuarioYLicenciaActual,
    convertirMoneda,
    MSGOK,
    MSGERROR,
    soloNumeros,
    etiquetaSinDisponibilidad,
    etiquetaBtnSolicitar

} from "../../librerias/funciones"
import store from '../../store'

import { Drawer, Input } from 'antd';
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';

import { LikeOutlined, DislikeOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ObtenerDesgloseStockNeumaticoEnAPI, setCestaLineasEnAPI, ObtenerArticuloERPEnAPI } from "../../action/actionCreatorsRequests";
import BtnSolicitar from "../utils/BtnSolicitar";

export const FichaAgregarArticuloGenerico = (props) => {
    const [payLoadLocal, setPayLoadLocal] = React.useState({
        IMAGEN_URL: props.DATOS_REFERENCIA.IMAGEN,

        CODIGO_MARCA: props.DATOS_REFERENCIA.CODIGO_MARCA,
        CODIGO_MARCA_ERP: "",
        DESCUENTO_1: 0,
        DESCUENTO_2: 0,
        DESCUENTO_3: 0,
        EAN: "",
        GRUPO_MONTAJE: 0,
        IMAGEN_MARCA: "",
        LADO_MONTAJE: "",
        MARCA_PATROCINADA: false,
        NOMBRE_GRUPO_MONTAJE: "",
        NOMRE_MARCA: "",
        PVP: 0,
        PRECIO: 0,
        DESCUENTO: 0,
        NETO: 0,

        REFERENCIA: props.DATOS_REFERENCIA.REFERENCIA,
        REFERENCIA_PROVEEDOR: "",
        STOCK_ALMACEN: 0,
        STOCK_TOTAL: 0
    })
    const [textoIdentificador, setTextoIdentificador] = React.useState("");



    const [initComponent, setInitComponent] = React.useState({
        REGISTROS: [],
        HTML_MANUAL: "",
        ABRIR_MODAL: false,
        TITULO_MODAL: "",
        ID_GRUPO_SELECCIONADO: 0,
        RUTA: []
    })



    const buscarFechaEntrega = (registros) => {
        const resultado = registros.filter((registro) => (registro.FechaEntrega !== undefined && registro.FechaEntrega !== null && registro.FechaEntrega !== ""));
        return resultado;
    }



    const añadirALaCesta = (datosReferencia, desglose) => {
        let resultaCesta = []
        if (desglose !== null && desglose !== undefined) {
            const DESCRIPCION = datosReferencia.NOMRE_MARCA === undefined ? props.DATOS_REFERENCIA.REGISTROS.DESCRIPCION : "[ " + datosReferencia.NOMRE_MARCA.toUpperCase() + " ] - " + datosReferencia.NOMBRE_GRUPO_MONTAJE.toUpperCase();
            const sePuedeComprar = desglose.filter((item) => item.CANTIDAD > 0).length > 0
            if (sePuedeComprar) {
                desglose.map((registro) => {
                    if (registro.CANTIDAD > 0) {
                        const esRegualador = registro.Regulador;
                        const precioRegulador = registro.PrecioDecimal;

                        /*
                        let precioRegulador = registro.Precio !== null && registro.Precio !== "" ? registro.Precio.replace(",", ".") : "";
                        precioRegulador = Number(precioRegulador)
                        */
                       
                        const { PRECIO, NETO, DESCUENTO } = esRegualador && precioRegulador > 0 ? { PRECIO: precioRegulador, NETO: precioRegulador, DESCUENTO: 0 } : datosReferencia
                        setCestaLineasEnAPI(
                            datosReferencia.REFERENCIA_PROVEEDOR,
                            datosReferencia.CODIGO_MARCA_ERP,
                            datosReferencia.REFERENCIA,
                            datosReferencia.CODIGO_MARCA,
                            DESCRIPCION,
                            registro.CANTIDAD,//Cantidad
                            0,//texto libre

                            PRECIO,
                            DESCUENTO,
                            NETO,
                            //calcularPrecioNeto(registro.CANTIDAD, datosReferencia.PRECIO, datosReferencia.DESCUENTO),
                            registro.CodigoAlmacen,//ALMACEN
                            registro.DescripcionAlmacen,//DESCRIPCION ALMACEN
                            props.ES_CESTA_COMPRA ? 1 : 0,//ACCION
                            props.DATOS_REFERENCIA.IDCESTA,
                            props.ES_CESTA_COMPRA ? props.DATOS_REFERENCIA.CLIENTE : "",

                            props.DATOS_REFERENCIA.VEHICULO,
                            textoIdentificador //IDENTIFICADOR

                        ).then(data => {
                            if (data.OK) {

                                resultaCesta.push(data)
                            }
                        })
                    }
                })

                let estadoCesta = resultaCesta.filter((resultado) => resultado.OK === false)
                if (estadoCesta.length === 0) {
                    if (props.ES_CESTA_COMPRA) {
                        setTimeout(() => {
                            props.CARGAR_CESTA()
                        }, 1000)
                    }

                    props.CERRAR_FICHA(false)
                    MSGOK(traducirPagina("Referencia_agregada"));

                } else {
                    MSGOK(JSON.stringify(estadoCesta));
                }

            } else {
                MSGERROR(traducirPagina("ingrese_cantidad"))
            }

        } else {
            MSGERROR(traducirPagina("msg_stock_suficiente"));
        }
    }


    const insertarConsula = (datosReferencia) => {
        let almacen = obtenerDatosUsuarioYLicenciaActual();

        // [ " + datosReferencia.REFERENCIA.toUpperCase() + " ] - " + datosReferencia.NOMRE_MARCA.toUpperCase() + " - " + datosReferencia.NOMBRE_GRUPO_MONTAJE.toUpperCase(),


        //sa agrego la referencia de proveedor.
        setCestaLineasEnAPI(
            datosReferencia.REFERENCIA_PROVEEDOR,
            "",
            "CONSULTA",
            "",
            `[ ${datosReferencia.NOMRE_MARCA.toUpperCase()} ] - ( ${datosReferencia.REFERENCIA} ) ${datosReferencia.NOMBRE_GRUPO_MONTAJE.toUpperCase()}`,
            1,
            1,
            datosReferencia.PRECIO,
            datosReferencia.DESCUENTO,
            datosReferencia.NETO,
            almacen.ALMACEN,
            "",
            props.ES_CESTA_COMPRA ? 1 : 0,
            "",
            "",
            props.DATOS_REFERENCIA.VEHICULO,

        ).then(data => {
            if (data.OK) {
                MSGOK("Consulta añadida.", "botton-left");
                props.CERRAR_FICHA(false)
            }
        })
    }




    const ObtenerDesgloseStockNeumatico = async (REFERENCIA_FABRICANTE, REFERENCIA_ERP, EAN, ID_MARCA) => {

        let registro = await ObtenerDesgloseStockNeumaticoEnAPI(REFERENCIA_FABRICANTE, REFERENCIA_ERP, EAN, ID_MARCA)
        if (registro !== undefined && registro !== null && registro.Stock.length > 0) {


            let STOCK_DISPONIBLE = registro.Stock.filter((registros) => registros.Stock > 0);

            if (STOCK_DISPONIBLE.length > 0) {
                initComponent.REGISTROS = registro.Stock

                if (!props.ES_CESTA_COMPRA) {

                    let CANTIDAD_SELECCIONADA = props.DATOS_REFERENCIA.CANTIDAD_SELECCIONADA;

                    registro.Stock.map((item, i) => {

                        let result = CANTIDAD_SELECCIONADA - item.Stock
                        if (CANTIDAD_SELECCIONADA === 0) {
                            item.CANTIDAD = 0;
                        } else if (result < 0) {
                            item.CANTIDAD = CANTIDAD_SELECCIONADA;
                            CANTIDAD_SELECCIONADA = 0;
                        } else {
                            CANTIDAD_SELECCIONADA = CANTIDAD_SELECCIONADA - item.Stock
                            item.CANTIDAD = item.Stock;
                        }

                    })
                } else {
                    registro.Stock.map((item, i) => {
                        item.CANTIDAD = 0;
                        if (item.CodigoAlmacen === props.DATOS_REFERENCIA.ALMACEN) {
                            item.CANTIDAD = props.DATOS_REFERENCIA.CANTIDAD;
                        }
                    })

                    let almacen = registro.Stock.filter((registro) => registro.CANTIDAD > 0);
                    initComponent.REGISTROS = almacen

                }
            } else {
                initComponent.REGISTROS = null
            }

        } else {
            initComponent.REGISTROS = null
        }


        setInitComponent({ ...initComponent })

    }



    const cargarInformacion = (DATOS_REFERENCIA) => {


        if (DATOS_REFERENCIA.DETALLE_ERP_SOCIO !== null && DATOS_REFERENCIA.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO !== "") {

            if (DATOS_REFERENCIA.CLIENTE === undefined) {
                let cliente = store.getState().clienteSeleccionado;
                DATOS_REFERENCIA.CLIENTE = cliente !== undefined && cliente !== null && Object.keys(cliente).length > 0 && cliente.codigo !== "" ? cliente.codigo : ""
            }

            const buscarNombreMarca = DATOS_REFERENCIA.NOMRE_MARCA === undefined ? "" : DATOS_REFERENCIA.DESCRIPCION_1

            ObtenerArticuloERPEnAPI(false, DATOS_REFERENCIA.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, "", DATOS_REFERENCIA.CLIENTE,undefined,buscarNombreMarca).then(registros => {
                if (registros !== null && registros !== undefined && Object.keys(registros).length > 0) {
                    let referencia = registros[0];

                    if (referencia !== undefined) {
                        //payLoadLocal.CODIGO_MARCA = referencia.Codigo.MarcaTecdoc

                        payLoadLocal.CODIGO_MARCA = DATOS_REFERENCIA.ID_MARCA
                        payLoadLocal.EAN = DATOS_REFERENCIA.EAN


                        payLoadLocal.CODIGO_MARCA_ERP = referencia.Codigo.marcaERPCodigo


                        payLoadLocal.NOMBRE_GRUPO_MONTAJE = DATOS_REFERENCIA.NOMBRE_GRUPO_MONTAJE === undefined ? "" : DATOS_REFERENCIA.NOMBRE_GRUPO_MONTAJE
                        payLoadLocal.NOMRE_MARCA = DATOS_REFERENCIA.NOMRE_MARCA === undefined ? referencia.Codigo.MarcaERPDescripcion : DATOS_REFERENCIA.DESCRIPCION_1

                        //payLoadLocal.NOMBRE_GRUPO_MONTAJE = referencia.Codigo.DescripcionERP
                        //payLoadLocal.NOMRE_MARCA = referencia.Codigo.MarcaERPDescripcion

                        payLoadLocal.PRECIO = referencia.precio
                        payLoadLocal.DESCUENTO = referencia.descuento
                        payLoadLocal.NETO = referencia.precioNeto


                        //payLoadLocal.REFERENCIA = DATOS_REFERENCIA.REFERENCIA

                        //Verificar si el socio envia bien la referencia
                        payLoadLocal.REFERENCIA = referencia.Codigo.ReferenciaTecdoc

                        payLoadLocal.REFERENCIA_PROVEEDOR = referencia.Codigo.CodigoERP

                        payLoadLocal.STOCK_ALMACEN = 0
                        payLoadLocal.STOCK_TOTAL = 39
                        setPayLoadLocal({ ...payLoadLocal })
                    }

                } else {

                    if (DATOS_REFERENCIA !== undefined && DATOS_REFERENCIA !== null && Object.keys(DATOS_REFERENCIA).length > 0) {
                        payLoadLocal.CODIGO_MARCA = DATOS_REFERENCIA.ID_MARCA
                        payLoadLocal.EAN = DATOS_REFERENCIA.EAN


                        if (DATOS_REFERENCIA.DETALLE_ERP_SOCIO !== null) {
                            const { NETO, PRECIO, PVP, DESCUENTO, REFERENCIA_SOCIO, CODIGO_MARCA_SOCIO, REFERENCIA_FABRICANTE } = DATOS_REFERENCIA.DETALLE_ERP_SOCIO
                            payLoadLocal.CODIGO_MARCA_ERP = CODIGO_MARCA_SOCIO

                            payLoadLocal.PVP = PVP
                            payLoadLocal.PRECIO = PRECIO
                            payLoadLocal.DESCUENTO = DESCUENTO
                            payLoadLocal.NETO = NETO
                            payLoadLocal.REFERENCIA_PROVEEDOR = REFERENCIA_SOCIO !== "" ? REFERENCIA_SOCIO : ""

                            payLoadLocal.REFERENCIA = REFERENCIA_FABRICANTE

                        }
                        payLoadLocal.NOMRE_MARCA = DATOS_REFERENCIA.DESCRIPCION_1

                        payLoadLocal.NOMBRE_GRUPO_MONTAJE = ""

                        setPayLoadLocal({ ...payLoadLocal })

                    }

                }
            })
        } else {
            if (DATOS_REFERENCIA !== undefined && DATOS_REFERENCIA !== null && Object.keys(DATOS_REFERENCIA).length > 0) {
                payLoadLocal.CODIGO_MARCA = DATOS_REFERENCIA.ID_MARCA
                payLoadLocal.EAN = DATOS_REFERENCIA.EAN

                if (DATOS_REFERENCIA.DETALLE_ERP_SOCIO !== null) {
                    const { NETO, PRECIO, PVP, DESCUENTO, REFERENCIA_SOCIO, CODIGO_MARCA_SOCIO, REFERENCIA_FABRICANTE } = DATOS_REFERENCIA.DETALLE_ERP_SOCIO
                    payLoadLocal.CODIGO_MARCA_ERP = CODIGO_MARCA_SOCIO

                    payLoadLocal.PVP = PVP
                    payLoadLocal.PRECIO = PRECIO
                    payLoadLocal.DESCUENTO = DESCUENTO
                    payLoadLocal.NETO = NETO
                    payLoadLocal.REFERENCIA_PROVEEDOR = REFERENCIA_SOCIO !== "" ? REFERENCIA_SOCIO : ""

                    payLoadLocal.REFERENCIA = REFERENCIA_FABRICANTE

                }
                payLoadLocal.NOMRE_MARCA = DATOS_REFERENCIA.DESCRIPCION_1

                payLoadLocal.NOMBRE_GRUPO_MONTAJE = ""

                setPayLoadLocal({ ...payLoadLocal })
            }
        }



        let ID_MARCA = props.ID_MARCA === undefined || props.ID_MARCA === null ? "" : props.ID_MARCA;
        ObtenerDesgloseStockNeumatico(props.REFERENCIA_FABRICANTE, props.REFERENCIA_ERP, props.EAN, ID_MARCA)


    }






    React.useEffect(() => {
        cargarInformacion(props.DATOS_REFERENCIA)
    }, [])




    const {
        IMAGEN_URL,

        REFERENCIA,
        NOMBRE_GRUPO_MONTAJE,
        REFERENCIA_PROVEEDOR,
        EAN,
        PVP,
        DESCUENTO,
        NETO,


        CODIGO_MARCA,
        CODIGO_MARCA_ERP,
        DESCUENTO_1,
        DESCUENTO_2,
        DESCUENTO_3,
        GRUPO_MONTAJE,
        IMAGEN_MARCA,
        LADO_MONTAJE,
        MARCA_PATROCINADA,
        NOMRE_MARCA,
        PRECIO,
        STOCK_ALMACEN,
        STOCK_TOTAL,

    } = payLoadLocal




    return <div>
        <Drawer
            width={800}
            onClose={() => {

                props.CERRAR_FICHA(false)

            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >

                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {

                            props.CERRAR_FICHA(false)

                        }} >
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            <div className="containerArticuloGenerico">

                <div className="ContainerDetalles" style={{ height: '217px' }}>

                    <div className="grupoImagenReferencia">

                        <img
                            onError={(e) => { e.target.src = "./imagenes/neumatico_ng.png"; }}
                            src={`https://sertec.servicenext.eu/GRAFICOS_NEUMATICOS/48x48/${IMAGEN_URL}`}
                        >
                        </img>
                    </div>

                    <div>

                        <div className="infoDetalleReferencia">
                            <div>{REFERENCIA} <span>{NOMBRE_GRUPO_MONTAJE}</span></div>
                            <div>{traducirPagina("Referencia_proveedor")} <span>{REFERENCIA_PROVEEDOR}</span></div>
                            <div>{"EAN"} <span>{EAN}</span></div>
                        </div>


                        <div className="infoDetalleReferencia">
                            <div>{`${traducirPagina("Precio")} : ${PVP === 0 ? "" : convertirMoneda(PVP, " €")}`} </div>

                            <div>{`${traducirPagina("Dto")} : ${DESCUENTO + " %"}`}</div>

                            <div>{`${traducirPagina("Neto")} : ${NETO === 0 ? "" : convertirMoneda(NETO, " €")} `}</div>

                        </div>
                    </div>

                </div>

                {props.ES_CESTA_COMPRA ?

                    <div className="ContainerDetalles" style={{ display: 'block' }}>

                        <span className='tituloIdentificador'> Identificador de la línea por parte del cliente</span>

                        <Input className='textoidentificador' maxlength="16" type='text' onChange={(e) => { setTextoIdentificador(e.target.value) }} value={textoIdentificador} placeholder={traducirPagina("Identificador")}></Input>

                        <span className='subtituloIdentificador'>Dispone de 16 caracteres máximos</span>

                    </div>
                    :
                    null
                }



                <div className="ContainerDetalles" style={{ display: 'block', height: initComponent.REGISTROS === null ? "" : props.ES_CESTA_COMPRA ? 'calc(100% - 390px)' : 'calc(100% - 236px)' }}>
                    {
                        initComponent.REGISTROS === null ?
                            null
                            :
                            initComponent.REGISTROS.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center', textAlign: 'center', color: '#000' }}> <div color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                :
                                <div style={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
                                    <MDBTable responsive hover>
                                        <MDBTableHead>
                                            <tr>
                                                <th></th>
                                                <th style={{ textAlign: 'left', fontWeight: '500' }}>{traducirPagina("Almacen").toUpperCase()}</th>
                                                {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <th style={{ textAlign: 'right', width: '85px', fontWeight: '500' }}>{traducirPagina("Cantidad").toUpperCase()}</th>}
                                                <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Disponibilidad").toUpperCase()}</th>
                                                <th style={{ textAlign: 'center', width: '80px', fontWeight: '500' }}>{traducirPagina("Precio").toUpperCase()}</th>

                                                {buscarFechaEntrega(initComponent.REGISTROS).length > 0 ? <th style={{ textAlign: 'center', width: '170px', fontWeight: '500' }}>{traducirPagina("Fecha_entrega").toUpperCase()}</th> : null}

                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {initComponent.REGISTROS.map((item, i) =>
                                                <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }} >
                                                    <td style={{ textAlign: 'left' }}>
                                                        {
                                                            item.Stock > 0 &&

                                                            <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker" style={{ color: '#000' }}>
                                                                <span className="next-input-group">
                                                                    <span className="next-input-group-addon next-before">
                                                                        <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" style={{ height: '27px', width: '27px' }} onClick={() => {

                                                                            if (item.CANTIDAD <= 1) {
                                                                                //item.CANTIDAD = 1;
                                                                                item.CANTIDAD = 0;
                                                                            } else {
                                                                                item.CANTIDAD = item.CANTIDAD - 1;
                                                                            }

                                                                            initComponent.REGISTROS = initComponent.REGISTROS
                                                                            setInitComponent({ ...initComponent })


                                                                        }}>
                                                                            <MinusOutlined style={{ fontSize: '12px' }} />
                                                                        </button>
                                                                    </span>
                                                                    <span className="next-input next-medium next-input-group-auto-width">
                                                                        <input value={item.CANTIDAD} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '2px 5px', height: '24px' }} onChange={(e) => {
                                                                            let valor = e.target.value;
                                                                            if (valor === "") {
                                                                                item.CANTIDAD = e.target.value;

                                                                            } else {
                                                                                if (soloNumeros(valor)) {
                                                                                    let convertir = parseInt(e.target.value);
                                                                                    let resultado = item.Stock > convertir ? e.target.value : item.Stock;

                                                                                    //item.CANTIDAD = valor === "0" ? 1 : parseInt(resultado)
                                                                                    item.CANTIDAD = parseInt(resultado)
                                                                                } else {
                                                                                    item.CANTIDAD = 1;
                                                                                }
                                                                            }

                                                                            initComponent.REGISTROS = initComponent.REGISTROS
                                                                            setInitComponent({ ...initComponent })

                                                                        }} />
                                                                    </span>
                                                                    <span className="next-input-group-addon next-after">
                                                                        <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp" style={{ height: '27px', width: '27px' }} onClick={() => {
                                                                            if (item.CANTIDAD < item.Stock) {
                                                                                item.CANTIDAD = item.CANTIDAD + 1;

                                                                                initComponent.REGISTROS = initComponent.REGISTROS
                                                                                setInitComponent({ ...initComponent })

                                                                            }

                                                                        }}
                                                                        >
                                                                            <PlusOutlined style={{ fontSize: '12px' }} />
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        }
                                                    </td>

                                                    <td style={{ textAlign: 'left' }}>{item.DescripcionAlmacen}</td>

                                                    {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <td style={{ textAlign: 'right' }}>{item.Stock}</td>}

                                                    <td style={{ textAlign: 'center' }}>
                                                        <button className="activebtnMenu2" style={{ border: '0', borderRadius: '3px', fontSize: '13px', color: item.Stock === 0 ? "red" : '#25c281', backgroundColor: 'transparent', verticalAlign: 'text-bottom' }}>  {item.Stock === 0 ? <DislikeOutlined style={{ fontSize: '20px' }}></DislikeOutlined> : <LikeOutlined style={{ fontSize: '20px' }} />}  </button>
                                                    </td>

                                                    <td style={{ textAlign: 'right' }}>{item.Precio === null ? "" : convertirMoneda(item.Precio, "€")}</td>


                                                    {buscarFechaEntrega(initComponent.REGISTROS).length > 0 ? <td style={{ textAlign: 'center' }}>  {item.FechaEntrega !== null ? `${item.FechaEntrega} ${item.HoraEntrega}` : ""} </td> : null}

                                                </tr>
                                            )
                                            }
                                        </MDBTableBody>
                                    </MDBTable>

                                </div>


                    }



                    {
                        initComponent.REGISTROS === null ?
                            <>
                                <div style={{ fontSize: '22px', fontWeight: '500', color: "#dc3545" }} className="grupoBtnArticulo">
                                    <div>
                                        <img src="./imagenes/no_disponible.svg" style={{ height: '170px' }}></img>
                                    </div>
                                    <div className="col-md-6">
                                        <span>{etiquetaSinDisponibilidad()}</span>
                                    </div>
                                </div>

                                <div className="grupoBtnArticulo">
                                    <div>
                                        <BtnSolicitar
                                            texto={etiquetaBtnSolicitar()}
                                            onClick={() => insertarConsula(payLoadLocal)}
                                        />

                                    </div>

                                    <div >
                                        <button style={{ height: '60px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#eee' }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("btn_pedido_referencia")}</span>  </button>
                                    </div>
                                </div>
                            </>
                            :
                            initComponent.REGISTROS !== null && initComponent.REGISTROS.length > 0 ?
                                <div className="grupoBtnArticulo">
                                    <div style={{ marginTop: '10px' }}>
                                        <button className="activebtnMenu3" style={{ height: '40px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: '#4285f4' }}
                                            onClick={() => { añadirALaCesta(payLoadLocal, initComponent.REGISTROS); }}>

                                            <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{
                                                !props.ES_CESTA_COMPRA ?
                                                    traducirPagina("Añadir_a_la_cesta")

                                                    :
                                                    traducirPagina("Modificar_a_la_cesta")
                                            }
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                : null

                    }



                </div>


            </div>


        </Drawer>

    </div>;
};