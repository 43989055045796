import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import 'antd/dist/antd.css';
import { Drawer } from 'antd';
import {
    DrawerFichaEquivalenciaArticulo, createRipple
} from "../action/actionCreators";

import ContainerReferenciaGenerico from './ContainerReferenciaGenerico';
import store from '../store'
import { FichaReferenciaSugerida } from './Compra/FichaReferenciaSugerida';
import FichaStockSocio from './FichaStockSocio';
import { traducirPagina } from '../librerias/funciones';

const FichaEquivalenciaArticulo = ({ props, openDrawerFichaEquivalenciaArticulo, codmotorizacion, ReferenciaMarcaVehiculoSeleccionado, EquivalenciaArticuloSeleccionado }) => {
    const [abrirFichaStockSocio, setAbrirFichaStockSocio] = React.useState(false);
    const [registroStockSocio, setRegistroStockSocio] = React.useState({});


    return (
        <>
            <Drawer
                width={1200}
                onClose={() => { store.dispatch(DrawerFichaEquivalenciaArticulo(false)); }}
                visible={openDrawerFichaEquivalenciaArticulo}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff', zIndex: '99999999' }}>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaEquivalenciaArticulo(false)); createRipple(e) }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div className="p-3">
                    <ContainerReferenciaGenerico
                        state_ficha={{ setAbrirFichaStockSocio, setRegistroStockSocio }}
                        tabActivado={""}
                        tipoComponente={"equivalenciaArticulo"}
                        referencia={EquivalenciaArticuloSeleccionado.referencia}
                        marca={EquivalenciaArticuloSeleccionado.marca}
                        artno={EquivalenciaArticuloSeleccionado.artno}
                        vehiculoTecdoc={EquivalenciaArticuloSeleccionado?.vehiculoTecdoc}
                        idFabricante={EquivalenciaArticuloSeleccionado?.idFabricante}
                        tipoVehiculo={EquivalenciaArticuloSeleccionado?.tipoVehiculo}
                    ></ContainerReferenciaGenerico>
                </div>
            </Drawer>

            {abrirFichaStockSocio ?
                <FichaStockSocio
                    title={`${traducirPagina("Stock_grupo").toUpperCase()}`}
                    ABRIR_FICHA={abrirFichaStockSocio}
                    CERRAR_FICHA={setAbrirFichaStockSocio}
                    DATOS_SELECCIONADO={registroStockSocio}
                    Z_INDEX="999999999"
                >

                </FichaStockSocio>
                :
                null
            }
        </>

    )
}

FichaEquivalenciaArticulo.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    activeItemOuterTabs: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        datosGraficos: state.datosGraficos,

        props: props,
        openDrawerFichaEquivalenciaArticulo: state.estadoDrawer.openDrawerFichaEquivalenciaArticulo,
        EquivalenciaArticuloSeleccionado: state.EquivalenciaArticuloSeleccionado,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor,
        buscadorTree: state.buscadorTree,
        codmotorizacion: state.codmotorizacion,
        ReferenciaMarcaVehiculoSeleccionado: state.ReferenciaMarcaVehiculoSeleccionado
    };
}

export default connect(mapStateToProps)(FichaEquivalenciaArticulo);