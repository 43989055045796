import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import { ObtenerNeumaticoInfoEnAPI, ObtenerNeumaticoImagenOriginalEnAPI, ObtenerDesgloseStockNeumaticoEnAPI } from "../action/actionCreatorsRequests"

import store from '../store'
import {
    traducirPagina, convertirMoneda, obtenerDatosUsuarioYLicenciaActual, verficarSiEsPVP, visualizarStocksRemotos, verificarSiEsMostrador, verficarSiEsCatalogo, soloNumeros,
    crearLineasParaElPresupuesto, referenciaActualSelect, MSGERROR, condicionesAbrirCesta, cestaAutomatica, condicionesAbrirCestav2
} from "../librerias/funciones"
import {
    DislikeOutlined,
    DeploymentUnitOutlined,
    FileAddOutlined,
    LikeOutlined,
    MinusOutlined,
    PlusOutlined,
    ShoppingOutlined,
    EyeOutlined,
    CheckSquareOutlined,
    CloseSquareOutlined,
    CheckOutlined,
    CloseOutlined

} from '@ant-design/icons';
import { Tabs, Drawer, Row, List, Select, Card, Col, Button, Collapse } from 'antd';
import FichaStockSocio from './FichaStockSocio';
import { FileView } from './Visor/FileView';
import { ViewIframe } from './Visor/ViewIframe';
import FichaAñadirCesta from './FichaAñadirCesta';
import { FichaStockNeumatico } from './Compra/FichaStockNeumatico';
import { FichaAgregarArticuloGenerico } from './Compra/FichaAgregarArticuloGenerico';
const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;
const FichaDetalleNeumatico = ({ props, usuario, clienteSeleccionado }) => {
    const [abrirStockNeumatico, setAbrirStockNeumatico] = React.useState(false);
    const [abrirCestaNeumatico, setAAbrirCestaNeumatico] = React.useState(false);
    const [neumaticoSeleccionado, setNeumaticoSeleccionado] = React.useState({});



    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});
    const [CANTIDAD_CLIENTE, setCANTIDAD_CLIENTE] = React.useState(1);



    const [registroStockSocio, setRegistroStockSocio] = React.useState({});
    const [fichaCesta, setFichaCesta] = React.useState(false);
    const [abrirFichaStockSocio, setAbrirFichaStockSocio] = React.useState(false);
    const [propiedadesFileView, setPropiedadesFileView] = React.useState({
        ABRIR_FICHA: false,
        TITULO: "FICHA IMAGEN",
        SELECCIONADO: {
            ENCLACE: ""
        }
    });

    const [propiedadesIframeView, setPropiedadesIframeView] = React.useState({
        ABRIR_FICHA: false,
        TITULO: traducirPagina("Mas_informacion").toUpperCase(),
        WIDTH: "",
        SELECCIONADO: {
            ENCLACE: ""
        }
    });


    const [verMasInfo, setVerMasInfo] = React.useState({
        MAS_INFO_NEUMATICO: false,
        MAS_INFO_MARKETING: false

    });


    const [informacion, setInformacion] = React.useState({});
    const {
        IMAGENES,
        EAN,
        REFERENCIA_FABRICANTE,
        DESCRIPCION_1,
        DESCRIPCION_2,
        DESCRIPCION_3,
        ENLACES,
        ID_MARCA,
        MARCA_DESCRIPCION,
        ID_MODELO,
        MODELO_DESCRIPCION,
        CLASE,
        NOMBRE_CLASE,
        PRODUCTO_DESCRIPCION,

        TITULO_MARKETING,
        TEXTO_MARKETING,

        ANCHO,
        LARGO,
        LLANTA,
        CARGA,
        VELOCIDAD,

        TEMPORADA,
        MS_NIEVE,
        PMSF_ALPINO,
        CAMARA,
        SALVA_LLANTAS,
        LONAS,
        REFORZADA,
        LATERAL,
        CAPAS,
        DIRECCIONAL,
        ASIMETRICO,

        DECIBELIOS,
        ONDAS,
        CONSUMO,
        AGARRE,
        REGLAMENTO,

        PVP,
        PVP_VIGENCIA,
        ECO_TASA,
        ANCHO_LARGO_LLANTA,

        TIPO_VEHICULO,
        RUN_FLAT,

    } = informacion !== undefined && Object.keys(informacion).length > 0 ? informacion : {};


    const { ID, NEUMATICO_LIPKO } = props.DATOS_SELECCIONADO
    const item = props.DATOS_SELECCIONADO


    const abrirDesglose = (item) => {
        store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: true, REFERENCIA_FABRICANTE: item.REFERENCIA_FABRICANTE } });


        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {
            //store.dispatch(DrawerStockAlmacen(true));
            setNeumaticoSeleccionado(item)
            setAbrirStockNeumatico(true)

            referenciaActualSelect({ REFERENCIA: "", CODIGO_MARCA: item.ID_MARCA, REFERENCIA_PROVEEDOR: item.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, REGISTROS: item.DETALLE_ERP_SOCIO });
        }
    }


    const añadirALaCesta = (registro, CANTIDAD) => {
        store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: true, REFERENCIA_FABRICANTE: registro.REFERENCIA_FABRICANTE } })

        if (CANTIDAD === "") {
            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.DETALLE_ERP_SOCIO.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.DETALLE_ERP_SOCIO.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.DETALLE_ERP_SOCIO.STOCK_TOTAL}`)
        } else {
            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.nombre === "") {
                //setVisible(true)
                MSGERROR(traducirPagina("Msg_vender"))
            } else {
                const { TIPO_STOCK, ABRIR_FICHA } = condicionesAbrirCestav2(registro, CANTIDAD);

                if (ABRIR_FICHA) {
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD;

                    setReferenciaSeleccionada({
                        CANTIDAD_SELECCIONADA: CANTIDAD, REFERENCIA: "", CODIGO_MARCA: "", REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, REGISTROS: registro.DETALLE_ERP_SOCIO
                    })

                    setNeumaticoSeleccionado(registro)
                    setAAbrirCestaNeumatico(true)


                } else {
                
                    ObtenerDesgloseStockNeumaticoEnAPI(registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE, registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, registro.EAN).then(item => {

                        const REGISTRO = {
                            REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO,
                            REFERENCIA: registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE,
                            CODIGO_MARCA: registro.ID_MARCA,
                            NOMRE_MARCA: registro.DESCRIPCION_1,
                            CANTIDAD: CANTIDAD,
                            PRECIO: registro.DETALLE_ERP_SOCIO.PRECIO,
                            DESCUENTO: registro.DETALLE_ERP_SOCIO.DESCUENTO,
                            NETO: registro.DETALLE_ERP_SOCIO.NETO,
                            DESCRIPCION_CESTA: "[ " + registro.MARCA_DESCRIPCION.toUpperCase() + " ] - " + registro.DESCRIPCION_1.toUpperCase(),
                        }

                        cestaAutomatica(item.Stock, TIPO_STOCK, CANTIDAD, REGISTRO);
                    })
                }


                /*
                if (!ABRIR_FICHA) {
                    ObtenerDesgloseStockNeumaticoEnAPI(registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE, registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, registro.EAN).then(item => {

                        const REGISTRO = {
                            REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO,
                            REFERENCIA: registro.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE,
                            CODIGO_MARCA: registro.ID_MARCA,
                            NOMRE_MARCA: registro.DESCRIPCION_1,
                            CANTIDAD: CANTIDAD,
                            PRECIO: registro.DETALLE_ERP_SOCIO.PRECIO,
                            DESCUENTO: registro.DETALLE_ERP_SOCIO.DESCUENTO,
                            NETO: registro.DETALLE_ERP_SOCIO.NETO,
                            DESCRIPCION_CESTA: "[ " + registro.MARCA_DESCRIPCION.toUpperCase() + " ] - " + registro.DESCRIPCION_1.toUpperCase(),
                        }

                        cestaAutomatica(item.Stock, TIPO_STOCK, CANTIDAD, REGISTRO);
                    })
                } else {
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD;


                    setReferenciaSeleccionada({
                        CANTIDAD_SELECCIONADA: CANTIDAD, REFERENCIA: "", CODIGO_MARCA: "", REFERENCIA_PROVEEDOR: registro.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO, REGISTROS: registro.DETALLE_ERP_SOCIO
                    })
                    //setFichaCesta(true)


                    setNeumaticoSeleccionado(registro)
                    setAAbrirCestaNeumatico(true)
                }

*/
            }

        }
    }

    const agregarAPresupuesto = (ITEM) => {

        if (ITEM.DETALLE_ERP_SOCIO === undefined || ITEM.DETALLE_ERP_SOCIO === null) {
            ITEM.DETALLE_ERP_SOCIO = { PRECIO: ITEM.PRECIO, REFERENCIA_SOCIO: ITEM.REFERENCIA_DISTRIBUIDOR === null ? "" : ITEM.REFERENCIA_DISTRIBUIDOR, REFERENCIA_FABRICANTE: ITEM.REFERENCIA_FABRICANTE }
        }

        const { PRECIO, REFERENCIA_SOCIO, REFERENCIA_FABRICANTE, DESCRIPCION_1 } = ITEM.DETALLE_ERP_SOCIO


        let objeto = {
            REFERENCIA: "",
            REFERENCIA_PROVEEDOR: "",
            CODIGO_MARCA_ERP: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            NOMBRE_GRUPO_MONTAJE: "",
            PRECIO: 0,
            CANTIDAD: 1
        };


        objeto.REFERENCIA = REFERENCIA_FABRICANTE;
        objeto.REFERENCIA_PROVEEDOR = REFERENCIA_SOCIO
        objeto.CODIGO_MARCA_ERP = "";
        objeto.NOMRE_MARCA = ITEM.MARCA_DESCRIPCION;
        objeto.CODIGO_MARCA = 0;
        objeto.NOMBRE_GRUPO_MONTAJE = traducirPagina("Neumatico").toUpperCase() + ": " + ITEM.DESCRIPCION_1;
        objeto.PRECIO = PRECIO;
        objeto.CANTIDAD = ITEM.CANTIDAD === "" ? 1 : ITEM.CANTIDAD;
        objeto.ES_NEUMATICO = true

        crearLineasParaElPresupuesto(objeto, "A");
    }




    const ObtenerNeumaticoInfo = async (ID, EAN, NEUMATICO_LIPKO) => {
        let registro = await ObtenerNeumaticoInfoEnAPI(ID, EAN, NEUMATICO_LIPKO)

        if (registro !== undefined && registro !== null && registro.NEUMATICO !== null && Object.keys(registro.NEUMATICO).length > 0) {
            registro.NEUMATICO.IMAGENES = registro.IMAGENES

            setInformacion(registro.NEUMATICO)
        }
    }

    const ObtenerNeumaticoImagenOriginal = async (URL) => {
        let registro = await ObtenerNeumaticoImagenOriginalEnAPI(URL)
        return registro;

    }





    React.useEffect(() => {
        ObtenerNeumaticoInfo(ID, props.DATOS_SELECCIONADO.EAN, NEUMATICO_LIPKO)
    }, [])



    return (
        <>
            <Drawer
                width={1200}
                onClose={() => { props.CERRAR_FICHA(false) }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            props.CERRAR_FICHA(false)
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="layout_ficha_detalle_neumatico">
                    <div className='layout_datos_neumatico'>
                        <h1 className='h1_titulo_neumatico'>
                            <span className='sp_titulo_neumatico'> {`${MARCA_DESCRIPCION} ${DESCRIPCION_1} - ${DESCRIPCION_2}`}</span>
                            <span className='sp_subtitulo_neumatico'>{`${TIPO_VEHICULO} ${PRODUCTO_DESCRIPCION}`}</span>
                        </h1>

                        {IMAGENES !== undefined ?
                            <>
                                <div className='layout_imagenes_neumaticos'>
                                    {
                                        IMAGENES.map((registro, i) =>
                                            <div style={{ position: 'relative' }} onClick={() => {
                                                if (NEUMATICO_LIPKO) {
                                                    propiedadesFileView.SELECCIONADO.ENCLACE = "";

                                                    ObtenerNeumaticoImagenOriginal(registro.URL).then(() => {
                                                        const { BYTES, CONTENT_TYPE, OK } = registro
                                                        if (OK) {
                                                            propiedadesFileView.SELECCIONADO.ENCLACE = `data:${CONTENT_TYPE};base64,${BYTES}`

                                                        } else {
                                                            propiedadesFileView.SELECCIONADO.ENCLACE = registro.URL_LOCAL;
                                                        }
                                                        setPropiedadesFileView({ ...propiedadesFileView })
                                                    })

                                                } else {
                                                    propiedadesFileView.SELECCIONADO.ENCLACE = registro.URL_LOCAL
                                                    setPropiedadesFileView({ ...propiedadesFileView })
                                                }

                                                propiedadesFileView.ABRIR_FICHA = true;
                                                propiedadesFileView.TITULO = MODELO_DESCRIPCION;
                                                setPropiedadesFileView({ ...propiedadesFileView })


                                            }}>
                                                <img
                                                    onError={(e) => { e.target.src = "./imagenes/sinimagen.jpg"; }}
                                                    alt={registro.TIPO}
                                                    src={`${registro.URL_LOCAL}`}></img>



                                                <div className="image-mask" style={{ height: 'auto' }}>
                                                    <div className="image-mask-info">
                                                        <EyeOutlined title='Visualizar' style={{ fontSize: '30px', display: 'inline-block', marginRight: '20px' }}></EyeOutlined>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>

                                <hr></hr>
                            </>

                            :
                            null
                        }

                        <div className='informacion_neumatico'>
                            <div className='informacion_neumatico_item1'>
                                <table style={{ width: '100%' }} className={"tabla_generica_detalle"}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '250px' }}></th>
                                            <th style={{}}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>{traducirPagina("Referencia_fabricante").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{REFERENCIA_FABRICANTE}</span></td></tr>
                                        <tr><td>EAN </td><td><span className='txt_resgistro_tabla'>{EAN}</span></td></tr>
                                        <tr><td>{traducirPagina("Modelo").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{MODELO_DESCRIPCION}</span></td></tr>
                                        <tr><td>{traducirPagina("Enlaces").toUpperCase()} </td><td>{ENLACES !== undefined && <ul className='txt_enlace'>{ENLACES.map(item => <li
                                            onClick={(e) => {

                                                propiedadesIframeView.ABRIR_FICHA = true;
                                                propiedadesIframeView.SELECCIONADO.ENCLACE = item;
                                                propiedadesIframeView.WIDTH = "1200px"
                                                setPropiedadesIframeView({ ...propiedadesIframeView })
                                            }}>{item}</li>)}</ul>}</td></tr>
                                        <tr><td>{traducirPagina("Clase").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{NOMBRE_CLASE}</span></td></tr>
                                        <tr><td>{traducirPagina("Temporadas").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{TEMPORADA}</span></td></tr>
                                        <tr><td>{traducirPagina("Eficiencia_combustible").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{CONSUMO}</span></td></tr>
                                        <tr><td>{traducirPagina("Adherencia_superficie").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{AGARRE}</span></td></tr>
                                        <tr><td>{traducirPagina("Nivel_ruido_exterior").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{ONDAS} {DECIBELIOS}</span></td></tr>
                                        <tr><td>{traducirPagina("Reglamento_de").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{REGLAMENTO}</span></td></tr>
                                        <tr><td>{traducirPagina("Tamanio_llanta").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{LLANTA}</span></td></tr>
                                        {
                                            verMasInfo.MAS_INFO_NEUMATICO || TEXTO_MARKETING === "" ?
                                                <>
                                                    <tr><td>{traducirPagina("Ancho_seccion").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{ANCHO}</span></td></tr>
                                                    <tr><td>{traducirPagina("Relacion_aspecto").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{LARGO}</span></td></tr>
                                                    <tr><td>{traducirPagina("Clasificacion_indice_carga").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{CARGA}</span></td></tr>
                                                    <tr><td>{traducirPagina("Clasificacion_velocidad").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{VELOCIDAD}</span></td></tr>

                                                    <tr><td>RUNFLAT </td><td><span className='txt_resgistro_tabla'>{RUN_FLAT ? <CheckOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseOutlined style={{ fontSize: '18px', color: 'red' }} />}</span></td></tr>
                                                    <tr><td>{`M + S ${traducirPagina("Nieve").toUpperCase()}`}  </td><td><span className='txt_resgistro_tabla'>{MS_NIEVE ? <CheckOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseOutlined style={{ fontSize: '18px', color: 'red' }} />}</span></td></tr>
                                                    <tr><td>{`PMSF ${traducirPagina("Alpino").toUpperCase()}`}</td><td><span className='txt_resgistro_tabla'>{PMSF_ALPINO ? <CheckOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseOutlined style={{ fontSize: '18px', color: 'red' }} />}</span></td></tr>
                                                    <tr><td>{traducirPagina("Camara").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{CAMARA ? <CheckOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseOutlined style={{ fontSize: '18px', color: 'red' }} />}</span></td></tr>
                                                    <tr><td>{traducirPagina("Salva_Llantas").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{SALVA_LLANTAS ? <CheckOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseOutlined style={{ fontSize: '18px', color: 'red' }} />}</span></td></tr>

                                                    <tr><td>{traducirPagina("LLonas").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{LONAS}</span></td></tr>
                                                    <tr><td>{traducirPagina("Reforzada").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{REFORZADA}</span></td></tr>

                                                    <tr><td>{traducirPagina("Direccional").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{DIRECCIONAL ? <CheckOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseOutlined style={{ fontSize: '18px', color: 'red' }} />}</span></td></tr>
                                                    <tr><td>{traducirPagina("Asimetrico").toUpperCase()} </td><td><span className='txt_resgistro_tabla'>{ASIMETRICO ? <CheckOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseOutlined style={{ fontSize: '18px', color: 'red' }} />}</span></td></tr>
                                                </>
                                                :
                                                null
                                        }

                                    </tbody>
                                </table>

                                {TEXTO_MARKETING !== "" ?
                                    <div className='ver_mas_info' onClick={() => { verMasInfo.MAS_INFO_NEUMATICO = !verMasInfo.MAS_INFO_NEUMATICO; setVerMasInfo({ ...verMasInfo }) }}>
                                        {
                                            !verMasInfo.MAS_INFO_NEUMATICO ?
                                                <span>{traducirPagina("Ver_mas")}</span>
                                                :
                                                <span>{traducirPagina("Ver_menos")}</span>
                                        }
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>

                        {TEXTO_MARKETING !== undefined && TEXTO_MARKETING !== "" ?
                            <>
                                <hr></hr>
                                <div className='marketing_producto_neumatico'>
                                    <h1 className='marketing_producto_titulo'>{TITULO_MARKETING}</h1>

                                    <div className='descripcion_producto_titulo'>
                                        {
                                            TEXTO_MARKETING.split("\n").length < 10 ?
                                                TEXTO_MARKETING.split("\n").map((registro, i) =>
                                                    <p>{registro}</p>

                                                )

                                                :
                                                <>
                                                    {TEXTO_MARKETING.split("\n").map((registro, i) =>
                                                        i < 6 ?
                                                            <p>{registro}</p>
                                                            :
                                                            null
                                                    )}


                                                    {verMasInfo.MAS_INFO_MARKETING ?
                                                        TEXTO_MARKETING.split("\n").map((registro, i) =>
                                                            i > 6 ?
                                                                <p>{registro}</p>
                                                                :
                                                                null
                                                        )
                                                        :
                                                        null
                                                    }

                                                    <div className='ver_mas_info' onClick={() => { verMasInfo.MAS_INFO_MARKETING = !verMasInfo.MAS_INFO_MARKETING; setVerMasInfo({ ...verMasInfo }) }}>
                                                        {
                                                            !verMasInfo.MAS_INFO_MARKETING ?
                                                                <span>{traducirPagina("Ver_mas")} </span>
                                                                :
                                                                <span>{traducirPagina("Ver_menos")} </span>
                                                        }
                                                    </div>

                                                </>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>

                    <div className='layout_compra_neumatico'>
                        <>
                            {item.DETALLE_ERP_SOCIO !== null ?
                                <>
                                    {
                                        item.DETALLE_ERP_SOCIO.STOCK_ALMACEN === 0 && item.DETALLE_ERP_SOCIO.STOCK_TOTAL === 0 ?
                                            null
                                            :
                                            <div className='container_catidad_compra'>
                                                <div>
                                                    {traducirPagina("Cantidad")}
                                                </div>
                                                <div>
                                                    <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker" >
                                                        <span className="next-input-group">
                                                            <span className="next-input-group-addon next-before">
                                                                <button disabled="" type="button" className="next-btn next-medium next-btn-normal" role="button" onClick={() => {
                                                                    if (CANTIDAD_CLIENTE !== null) {

                                                                        let valor = CANTIDAD_CLIENTE
                                                                        if (valor) {
                                                                            if (parseInt(valor) <= 1) {
                                                                                valor = "1";
                                                                            } else {
                                                                                valor = parseInt(valor) - 1;
                                                                            }
                                                                        } else {
                                                                            valor = "1";
                                                                        }
                                                                        setCANTIDAD_CLIENTE(valor)

                                                                    }

                                                                }}>
                                                                    <MinusOutlined style={{ fontSize: '16px' }} />
                                                                </button>
                                                            </span>
                                                            <span className="next-input next-medium next-input-group-auto-width">
                                                                <input id={"CANTIDAD_NEUMATICO" + item.MARCA_DESCRIPCION + item.REFERENCIA_DISTRIBUIDOR} className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" style={{ width: '50px', margin: '5px', height: '24px' }}
                                                                    value={CANTIDAD_CLIENTE}

                                                                    onChange={(e) => {
                                                                        if (CANTIDAD_CLIENTE !== null) {

                                                                            let valor = e.target.value;
                                                                            if (valor === "") {
                                                                                valor = e.target.value;
                                                                            } else {
                                                                                if (soloNumeros(valor)) {
                                                                                    valor = valor === "0" ? "1" : e.target.value
                                                                                } else {
                                                                                    valor = "1";
                                                                                }
                                                                            }
                                                                            setCANTIDAD_CLIENTE(valor)
                                                                        }

                                                                    }} />
                                                            </span>
                                                            <span className="next-input-group-addon next-after">
                                                                <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp"
                                                                    onClick={() => {
                                                                        if (CANTIDAD_CLIENTE !== null) {
                                                                            let valor = CANTIDAD_CLIENTE
                                                                            if (valor) {
                                                                                valor = parseInt(valor) + 1;
                                                                            } else {
                                                                                valor = "1";
                                                                            }
                                                                            setCANTIDAD_CLIENTE(valor)
                                                                        }
                                                                    }}
                                                                >
                                                                    <PlusOutlined style={{ fontSize: '16px' }} />
                                                                </button>
                                                            </span>
                                                        </span>

                                                    </span>
                                                </div>

                                            </div>
                                    }

                                    <div className='botonGenerico' style={{ display: verficarSiEsCatalogo(usuario) ? 'none' : '', justifyContent: 'center' }} onClick={(e) => {
                                        añadirALaCesta(item, CANTIDAD_CLIENTE === null ? "1" : CANTIDAD_CLIENTE)

                                    }}>
                                        <ShoppingOutlined style={{ fontSize: '20px' }} />
                                        {!verificarSiEsMostrador() ? traducirPagina("Comprar") : traducirPagina("Vender")}
                                    </div>

                                </>

                                : null
                            }


                            {!verificarSiEsMostrador() ?
                                <div className='botonGenerico' style={{ justifyContent: 'center', /*display: verficarSiEsCatalogo(usuario) ? 'none' : ''*/ }} onClick={(e) => {
                                    item.CANTIDAD = CANTIDAD_CLIENTE === null ? "1" : CANTIDAD_CLIENTE;

                                    agregarAPresupuesto(item)

                                }}>
                                    <FileAddOutlined style={{ fontSize: '20px' }} />
                                    {traducirPagina("Añadir_presupuesto")}
                                </div>
                                :
                                null
                            }


                            {item.DETALLE_ERP_SOCIO !== null ?
                                <div>
                                    <button onClick={(e) => {
                                        abrirDesglose(item)
                                    }} className="activebtnMenu2" style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: item.DETALLE_ERP_SOCIO.STOCK_ALMACEN > 0 ? '#25c281' : item.DETALLE_ERP_SOCIO.STOCK_ALMACEN === 0 && item.DETALLE_ERP_SOCIO.STOCK_TOTAL > 0 ? '#ff8000' : 'red', backgroundColor: 'transparent', display: verficarSiEsCatalogo(usuario) ? 'none' : '' }}>
                                        {item.DETALLE_ERP_SOCIO.STOCK_ALMACEN === 0 && item.DETALLE_ERP_SOCIO.STOCK_TOTAL === 0 ?
                                            <DislikeOutlined style={{ fontSize: '20px', verticalAlign: 'sub' }} />
                                            : <LikeOutlined style={{ fontSize: '20px', verticalAlign: 'baseline' }} />}

                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                            {item.DETALLE_ERP_SOCIO.STOCK_ALMACEN === 0 && item.DETALLE_ERP_SOCIO.STOCK_TOTAL === 0 ? " " + traducirPagina("No_disponible") + " " : obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE ? traducirPagina("disponible") : obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? traducirPagina("disponible") : " " + traducirPagina("Stock") + " " + item.DETALLE_ERP_SOCIO.STOCK_ALMACEN + "/" + item.DETALLE_ERP_SOCIO.STOCK_TOTAL}
                                        </span>
                                    </button>
                                </div>
                                :
                                null
                            }

                         

                            {item.REFERENCIA_DISTRIBUIDOR !== "" ?
                                item.DETALLE_ERP_SOCIO !== null ?
                                    <>
                                        <table style={{ width: '100%' }} className={"tabla_generica_detalle"}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}></th>
                                                    <th style={{ textAlign: 'right' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td style={{ textAlign: 'right' }} className="txt_resgistro_tabla_titulo">{`Eco tasa :`} </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <span className='txt_resgistro_tabla_precio'>{convertirMoneda(item.ECOTASA, " €")}</span>
                                                    </td>
                                                </tr>

                                                <tr style={{ display: verficarSiEsPVP(usuario) ? '' : 'none' }}>
                                                    <td style={{ textAlign: 'right' }} className="txt_resgistro_tabla_titulo">{`${traducirPagina("PVP")}: `} </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <span className='txt_resgistro_tabla_precio'>{convertirMoneda(item.DETALLE_ERP_SOCIO.PRECIO, " €")}</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'right' }} className="txt_resgistro_tabla_titulo">{`${traducirPagina("Precio")}: `} </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <span className='txt_resgistro_tabla_precio'>{convertirMoneda(item.DETALLE_ERP_SOCIO.PRECIO, " €")}</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'right' }} className="txt_resgistro_tabla_titulo">{`${traducirPagina("Dto")}: `} </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <span className='txt_resgistro_tabla_precio'>{item.DETALLE_ERP_SOCIO.DESCUENTO + " %"}</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'right' }} className="txt_resgistro_tabla_titulo">{`${traducirPagina("Neto")}: `} </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <span className='txt_resgistro_tabla_precio'>{convertirMoneda(item.DETALLE_ERP_SOCIO.NETO, " €")}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                    : null
                                :
                                null
                            }


                        </>
                    </div>

                </div>
            </Drawer>

            {fichaCesta ?
                <FichaAñadirCesta
                    title={traducirPagina("Editar_referencia_a_la_cesta").toUpperCase()}
                    ABRIR_FICHA={fichaCesta}
                    CERRAR_FICHA={setFichaCesta}
                    DATOS_REFERENCIA={referenciaSeleccionada}
                    ES_CESTA_COMPRA={false}
                >
                </FichaAñadirCesta>
                :
                null
            }

            {
                abrirFichaStockSocio ?
                    <FichaStockSocio
                        title={`${traducirPagina("Stock_grupo").toUpperCase()}`}
                        ABRIR_FICHA={abrirFichaStockSocio}
                        CERRAR_FICHA={setAbrirFichaStockSocio}
                        DATOS_SELECCIONADO={registroStockSocio}
                    >
                    </FichaStockSocio>
                    :
                    null
            }

            {
                propiedadesFileView.ABRIR_FICHA ?
                    <FileView
                        PROPIEDADES={propiedadesFileView}
                        MODIFICAR_PROPIEDADES={setPropiedadesFileView}
                    >

                    </FileView>
                    :
                    null
            }

            {
                propiedadesIframeView.ABRIR_FICHA ?
                    <ViewIframe
                        PROPIEDADES={propiedadesIframeView}
                        MODIFICAR_PROPIEDADES={setPropiedadesIframeView}
                    >
                    </ViewIframe>
                    :
                    null
            }

            {

                abrirStockNeumatico &&
                <FichaStockNeumatico
                    title={traducirPagina("Desglose_disponibilidad").toUpperCase()}
                    ABRIR_FICHA={abrirStockNeumatico}
                    CERRAR_FICHA={setAbrirStockNeumatico}
                    REFERENCIA_FABRICANTE={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE}
                    REFERENCIA_ERP={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO}
                    EAN={neumaticoSeleccionado.EAN}


                ></FichaStockNeumatico>
            }

            {
                abrirCestaNeumatico &&
                <FichaAgregarArticuloGenerico
                    title={traducirPagina("Desglose_disponibilidad").toUpperCase()}
                    ABRIR_FICHA={abrirCestaNeumatico}
                    CERRAR_FICHA={setAAbrirCestaNeumatico}
                    REFERENCIA_FABRICANTE={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE}
                    REFERENCIA_ERP={neumaticoSeleccionado.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO}
                    EAN={neumaticoSeleccionado.EAN}
                    DATOS_REFERENCIA={neumaticoSeleccionado}
                    ES_CESTA_COMPRA={false}

                >

                </FichaAgregarArticuloGenerico>
            }
        </>


    )
}

FichaDetalleNeumatico.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    DATOS_SELECCIONADO: PropTypes.object,
};

const mapStateToProps = (state, props) => {
    return {

        props: props,
        usuario: state.usuario,
        clienteSeleccionado: state.clienteSeleccionado,



    };
}

export default connect(mapStateToProps)(FichaDetalleNeumatico);