import React from "react";
import { addDefaultSrc } from "../../../librerias/herramientas";
import { convertirMoneda, traducirPagina } from "../../../librerias/funciones";
import { pad } from "../../../action/actionCreatorsRequests";



const HeaderCesta = ({ detalles = {}, directorio = "", codigoMarca = "", datosReferencia = {}, esperandoDesglose = false, referencia = "", pvp = "", neto = "" }) => {


    const { IMAGEN, ID_ESTADO, DESCRIPCION_ESTADO, EAN } = detalles
    const { NOMBRE_GRUPO_MONTAJE, REFERENCIA_PROVEEDOR, NETO, DESCUENTO } = datosReferencia
    const DIRECTORIO_DOCUMENTO = IMAGEN === "" ? "./imagenes/sinimagen.jpg" : `${directorio}${pad(parseInt(codigoMarca), 4).toString()}/${IMAGEN}`



    const stylesDescripcionImagen = (ID_ESTADO) => {
        let styles = { backgroundColor: '#dcdcdc', color: '#000' }
        if (ID_ESTADO === 1) {
            styles = { backgroundColor: '#4285f4', color: '#fff' }
        } else if (ID_ESTADO = 9) {
            styles = { backgroundColor: '#D91E18', color: '#fff' }
        }
        return styles
    }


    return (
        <div className="ContainerDetalles" style={{ height: '200px' }}>
            <div className='container_info_cesta'>
                <div>

                    {Object.keys(detalles).length > 0 ?

                        <>
                            <img src={DIRECTORIO_DOCUMENTO} onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top', height: '100px' }} loading="lazy" />

                            <div style={{ ...stylesDescripcionImagen(ID_ESTADO), textAlign: 'center' }}>
                                {DESCRIPCION_ESTADO}
                            </div>
                        </>

                        :
                        <img src={"./imagenes/sinimagen.jpg"} onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top', height: '100px' }} />
                    }
                </div>

                <div>

                    <div className='descripcion_registro_info'>
                        <span className='titulo_registro_info'>{`${referencia} `}</span>
                        {NOMBRE_GRUPO_MONTAJE}
                    </div>

                    <div className='descripcion_registro_info'>
                        <span className='titulo_registro_info'>{`${traducirPagina("Referencia_proveedor")}: `}</span>
                        {REFERENCIA_PROVEEDOR}
                    </div>

                    <div className='descripcion_registro_info'>
                        <span className='titulo_registro_info'>{`${traducirPagina("EAN")}: `}</span>
                        {EAN}
                    </div>


                    {
                        (pvp > 0 && NETO > 0 && esperandoDesglose) &&
                        (
                            <>

                                <div className='descripcion_registro_info'>
                                    <span className='titulo_registro_info'>{`${traducirPagina("Precio")}: `}</span>
                                    {convertirMoneda(pvp, " €")}
                                </div>

                                <div className='descripcion_registro_info'>
                                    <span className='titulo_registro_info'>{`${traducirPagina("Dto")}: `}</span>
                                    {`${DESCUENTO} %`}
                                </div>

                                <div className='descripcion_registro_info'>
                                    <span className='titulo_registro_info'>{`${traducirPagina("Neto")}: `}</span>
                                    {convertirMoneda(neto, " €")}
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>

    )
}

export default HeaderCesta;