import React, { useState } from "react";
import { LeftCircleOutlined } from "@ant-design/icons"
import { buscarTipoCombustible, obtenerGraficos, traducirPagina } from "../librerias/funciones"
import grafico_moto from "../graficos/moto.json"
import grafico_accesorios from "../graficos/accesorios.json"
import carroceria from "../graficos/procesado_carroceria.json"

import store from "../store";
import {
    DrawerReferenciaGrafico, DrawerReferenciaGraficoCarroceria, estadoAgrupacionDeMarcas
} from "../action/actionCreators";



export const GraficoVehiculo = ({ esGraficoMoto = false, descripcionCombustible = "", eventoNeumatico = () => { } }) => {

    const [initComponent, setInitComponent] = React.useState({
        graficosVehiculo: {},
        graficosVehiculoCopia: {},
        NIVEL_ACTUAL: 1,
        HISTORIAL_GRAFICO: [],
        GRAFICO_SELECCIONADO: 1
    })

    const [esgraficoCarroceria, setEsgraficoCarroceria] = useState(false)


    const stylingObject = {
        container_grafico: {
            margin: '20px',
            background: '#fff'
        },
        header_acciones: {
            padding: "20px",
            display: "flex",
            justifyContent: "space-between"
        },
        header_acciones_item2: {
            display: "flex",
            alignItems: "center",
            gap: '5px',
            cursor: 'pointer'
        },

        body_grafico: {
            height: "calc(100vh - 253px)",
            padding: "10px"
        },

        btn_menu: {
            padding: "10px",
            border: "0px",
            borderRadius: "3px",
            fontSize: "16px",
            color: "#fff",
            minWidth: '120px'
        },
        container_btn: {
            display: "flex",
            gap: '10px',
            alignItems: "center",

        }
    }

    const eventoGraficoSeleccionado = (ID_NODO, NOMBRE_NODO, ES_ACCESORIO) => {

        if (ID_NODO !== undefined && ID_NODO !== null && ID_NODO !== 0) {

            if (esGraficoMoto) {
                NOMBRE_NODO = NOMBRE_NODO.toUpperCase()
            } else {
                ID_NODO = ID_NODO.replace("g", "")
            }

            if (ID_NODO === "NEU") {
                eventoNeumatico()
            } else {
                store.dispatch({ type: "STATE_NODO_GRAFICO", id: ID_NODO, nombreGrupo: NOMBRE_NODO, es_accesorio: ES_ACCESORIO });
                if (esgraficoCarroceria) {
                    store.dispatch(DrawerReferenciaGraficoCarroceria(true));
                } else {
                    store.dispatch(DrawerReferenciaGrafico(true));
                }
                store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [], "grupoMontaje": [] }))
                store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });

            }

        }
    }

    const initGraficos = (registros, graficoSeleccionado) => {
        registros.children.map((item) => item.SELECCIONADO = false)

        initComponent.NIVEL_ACTUAL = 1
        initComponent.graficosVehiculo = registros
        initComponent.graficosVehiculoCopia = registros


        initComponent.HISTORIAL_GRAFICO = [registros];
        initComponent.GRAFICO_SELECCIONADO = graficoSeleccionado

        setInitComponent({ ...initComponent })
    }

    const cargarGraficoMoto = () => {
        initGraficos(grafico_moto, 1)
    }

    const cargarGraficoAccesorios = () => {
        initGraficos(grafico_accesorios, 2)
    }

    const cargarGraficoMecanica = () => {
        setEsgraficoCarroceria(false)

        const tipoCombustible = buscarTipoCombustible()
        const graficoMecanicaActual = obtenerGraficos(tipoCombustible);
        initGraficos(graficoMecanicaActual, 3)
    }

    const cargarGraficoCarroceria = () => {
        setEsgraficoCarroceria(true)

        initGraficos(carroceria, 4)
    }

    React.useEffect(() => {
        setEsgraficoCarroceria(false)

        if (esGraficoMoto) {
            cargarGraficoMoto()
        } else {
            cargarGraficoMecanica()
        }
      
    }, [])


    const { container_grafico, header_acciones, header_acciones_item2, body_grafico, btn_menu, container_btn } = stylingObject

    const { rootImage, children } = Object.keys(initComponent.graficosVehiculoCopia).length === 0 ? { rootImage: "", children: [] } : initComponent.graficosVehiculoCopia;

    return (

        <div style={container_grafico}>
            <div style={header_acciones}>

                {esGraficoMoto ?
                    <div style={container_btn}>
                        <button className="activebtnMenu3 btnColor btn_menu_grafico" style={btn_menu} onClick={() => { cargarGraficoMoto() }}> {traducirPagina("txt_moto")} </button>
                        <button className="activebtnMenu3 btnColor btn_menu_grafico" style={btn_menu} onClick={() => { cargarGraficoAccesorios() }}> {traducirPagina("txt_accesorios")} </button>
                    </div>
                    :
                    <div style={container_btn}>
                        <button className="activebtnMenu3 btnColor btn_menu_grafico" style={btn_menu} onClick={() => { cargarGraficoMecanica() }}> {traducirPagina("Mecanica")} </button>
                        <button className="activebtnMenu3 btnColor btn_menu_grafico" style={btn_menu} onClick={() => { cargarGraficoCarroceria() }}> {traducirPagina("CARROCERIA")} </button>

                        <span style={{ marginLeft: '10px' }}>{descripcionCombustible}</span>
                    </div>
                }


                {
                    initComponent.NIVEL_ACTUAL > 1 &&
                    <div style={header_acciones_item2} onClick={() => {

                        let ACTUALIZAR_HISTORIAL = []
                        let NAVEGAR = initComponent.NIVEL_ACTUAL - 1
                        let NUEVA_LISTA = initComponent.HISTORIAL_GRAFICO[NAVEGAR - 1]

                        NUEVA_LISTA.children.map((item) => item.SELECCIONADO = false)
                        initComponent.graficosVehiculoCopia = NUEVA_LISTA
                        initComponent.NIVEL_ACTUAL = NAVEGAR

                        for (let i = 0; i < initComponent.HISTORIAL_GRAFICO.length; i++) {
                            if (i < NAVEGAR) {
                                ACTUALIZAR_HISTORIAL.push(initComponent.HISTORIAL_GRAFICO[i])
                            }
                        }

                        initComponent.HISTORIAL_GRAFICO = ACTUALIZAR_HISTORIAL;

                        setInitComponent({ ...initComponent })
                    }}>
                        <LeftCircleOutlined></LeftCircleOutlined> {traducirPagina("Volver_atras")}
                    </div>
                }
            </div>

            <div style={body_grafico}>

                <svg width="100%" height="100%" viewBox={esgraficoCarroceria ? "0 0 1366 768" : "0 0 1920 1080"} >
                    {
                        rootImage !== "" &&
                        <>
                            <image width="100%" height="100%" href={rootImage} useMap="#image_map"></image>

                            {children.map((item, pos) =>
                                <polygon key={pos}
                                    style={{ fill: item.level > 1 ? typeof item.key === 'string' ? '#4285f4' : '#fff' : '' }}
                                    name={item.tagNombre}
                                    id={item.tagNombre}
                                    points={item.coordenadas[0]}
                                    className={`estiloGrafico ${item.SELECCIONADO ? "activeArcauto" : ""}`}
                                    onMouseOver={() => { item.SELECCIONADO = true; setInitComponent({ ...initComponent }) }}
                                    onMouseOut={() => { item.SELECCIONADO = false; setInitComponent({ ...initComponent }) }}
                                    onClick={(e) => {

                                        if (item.children.length > 0) {
                                            let NUEVA_LISTA = item.children[0];
                                            NUEVA_LISTA.children.map((item) => item.SELECCIONADO = false)
                                            initComponent.graficosVehiculoCopia = NUEVA_LISTA
                                            initComponent.NIVEL_ACTUAL = initComponent.NIVEL_ACTUAL + 1
                                            initComponent.HISTORIAL_GRAFICO.push(NUEVA_LISTA);
                                            setInitComponent({ ...initComponent })
                                        } else {
                                            eventoGraficoSeleccionado(item.key, item.tooltip, initComponent.GRAFICO_SELECCIONADO === 2)
                                        }

                                    }}

                                ><title>{traducirPagina(`${item.tooltip}`).toUpperCase()}</title></polygon>

                            )}
                        </>
                    }
                </svg>
            </div>
        </div>);
};