import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerFichaMantenimientoClienteTaller, DrawerFichaClienteTaller } from "../action/actionCreators"
import { getClienteTallerEnAPI, setClienteTallerEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, MSGOK, efectoRemoveBuscadorReferencia } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import FichaMantenimientoCliente from '../components/MantenimientoClienteTaller'

import {
    PlusOutlined,
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    ImportOutlined,
    CloudUploadOutlined
} from '@ant-design/icons';

import { Select, Card, Drawer, Pagination } from 'antd';

import store from "../store";
import FichaMasivaCliente from './Cliente/FichaMasivaCliente';
import CPSearch from './utils/CPSearch';
const { Option } = Select;
const FichaClienteTaller = ({ props, configApp, openDrawerFichaClienteTaller, clienteTallerSeleccionado, openDrawerFichaMantenimientoVehiculo }) => {
    const [ordenarPorCodigo, setOrdenarPorCodigo] = React.useState(false);
    const [ordenarPorNombre, setOrdenarPorNombre] = React.useState(false);
    const [ordenarPorCIF, setOrdenarPorCIF] = React.useState(false);
    const [hayClientes, setHayClientes] = React.useState(false);

    const [clientesTaller, setClientesTaller] = React.useState([]);
    const [obtenerClientesAux, setObtenerClientesAux] = React.useState([]);
    const [actualizarClientes, setActualizarClientes] = React.useState(false);
    const [textoFiltroCliente, setTextoFiltroCliente] = React.useState("");
    const [mantenimientoCliente, setMantenimientoCliente] = React.useState(false);
    const [masivaCliente, setMasivaCliente] = React.useState(false);

    const [datosPaginacion, setDatosPaginacion] = React.useState({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 });



    const paginarTabla = (page, pageSize) => {
        let pInicio = (pageSize * page) - (datosPaginacion.numPagina);
        let pFinal = pageSize * page;
        setDatosPaginacion(
            {
                iniPage: page,
                inicio: pInicio,
                final: pFinal,
                numPagina: datosPaginacion.numPagina
            }
        )
    }


    const updateNumberPage = (current, size) => {
        setDatosPaginacion(
            {
                iniPage: 1,
                inicio: 1,
                final: size,
                numPagina: size
            }
        )
    }



    const buscarRegistros = async (ID="", ORDERCOLUM=1, ORDERDIR="A") => {
        let registrosAPI = []
        setClientesTaller([])
        setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

        const registros = await getClienteTallerEnAPI(ID, ORDERCOLUM, ORDERDIR)
        if (registros !== undefined && registros.CLIENTES !== null && registros.CLIENTES.length > 0) {
            registrosAPI = registros.CLIENTES
        } else {
            registrosAPI = null
        }
        return registrosAPI
    }


    if (!hayClientes && openDrawerFichaClienteTaller) {
        setHayClientes(true);
        buscarRegistros().then((registros) => { setClientesTaller(registros); setObtenerClientesAux(registros) })
    } else {
        if (!openDrawerFichaClienteTaller && hayClientes) {
            setTextoFiltroCliente("")
            setHayClientes(false);
            setClientesTaller([])
            setObtenerClientesAux([])
        }
    }

    if (!actualizarClientes && clienteTallerSeleccionado.actualizarListaCliente) {
        store.dispatch({ type: "UPDATE_LISTA_TALLER_CLIENTE", actualizarListaCliente: false })
        setActualizarClientes(true);

        if (clienteTallerSeleccionado.esNuevoCliente) {

            buscarRegistros().then((registros) => {
                if (registros !== null) {
                    setClientesTaller(registros)
                    setObtenerClientesAux(registros)
                    setOrdenarPorCodigo(false)
                    setOrdenarPorNombre(false)
                    setOrdenarPorCIF(false)
                }
            })
        } else {
            let modificarCliente = clienteTallerSeleccionado.clienteTaller;
            clientesTaller.map((item, index) => {
                if (item.ID == modificarCliente.id) {
                    clientesTaller[index].CODIGO = modificarCliente.codigo;
                    clientesTaller[index].CIF = modificarCliente.cif;
                    clientesTaller[index].CP = modificarCliente.cp;
                    clientesTaller[index].DIRECCION = modificarCliente.direccion;
                    clientesTaller[index].ID = modificarCliente.id;
                    clientesTaller[index].MAIL = modificarCliente.email;
                    clientesTaller[index].NOMBRE = modificarCliente.nombre;
                    clientesTaller[index].OBSERVACIONES = modificarCliente.observacion;
                    clientesTaller[index].PAIS = modificarCliente.pais;
                    clientesTaller[index].POBLACION = modificarCliente.poblacion;
                    clientesTaller[index].PROVINCIA = modificarCliente.provincia;
                    clientesTaller[index].TELEFONO = modificarCliente.telefono1;
                    clientesTaller[index].TELEFONO_2 = modificarCliente.telefono2;
                    clientesTaller[index].TELEFONO_3 = modificarCliente.telefono3
                }
            })
            setClientesTaller(clientesTaller)
            setObtenerClientesAux(clientesTaller)
        }

        store.dispatch({ type: "UPDATE_CLIENTE_TALLER", clienteTaller: {} })
    } else {
        if (!clienteTallerSeleccionado.actualizarListaCliente && actualizarClientes) {
            setActualizarClientes(false);
            setOrdenarPorCodigo(false)
            setOrdenarPorNombre(false)
            setOrdenarPorCIF(false)
        }
    }


    const ordenarTablaCliente = (ID, ORDERCOLUM, ORDERDIR) => {
        buscarRegistros(ID, ORDERCOLUM, ORDERDIR).then((registros) => {
            if (registros !== null) {
                setClientesTaller(registros)
                setObtenerClientesAux(registros)
                if (textoFiltroCliente.trim() !== "") {
                    buscarEnTabla(textoFiltroCliente, registros);
                }
            }
        })
    }

    const eliminarCliente = async (ID) => {
        const respuesta = await setClienteTallerEnAPI(2, { id: ID })
        if (respuesta.OK) {
            MSGOK("Cliente eliminado correctamente.")
            setTextoFiltroCliente("")
            buscarRegistros().then((registros) => { setClientesTaller(registros); setObtenerClientesAux(registros) })
        }
    }



    const buscarEnTabla = (palabraABuscar, registros) => {
        setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

        const palabra = palabraABuscar;
        if (palabra !== "") {
            if (registros !== undefined && registros !== null && registros.length > 0) {
                let resultadoFiltro = registros.filter((item) => {
                    return item.CODIGO.toUpperCase().includes(palabra.toUpperCase()) || item.CODIGO.toUpperCase() === palabra.toUpperCase() ||
                        item.NOMBRE.toUpperCase().includes(palabra.toUpperCase()) || item.NOMBRE.toUpperCase() === palabra.toUpperCase() ||
                        item.CIF.toUpperCase().includes(palabra.toUpperCase()) || item.CIF.toUpperCase() === palabra.toUpperCase() ||
                        item.TELEFONO.toUpperCase().includes(palabra.toUpperCase()) || item.TELEFONO.toUpperCase() === palabra.toUpperCase() ||
                        item.CP.toUpperCase().includes(palabra.toUpperCase()) || item.CP.toUpperCase() === palabra.toUpperCase() ||
                        item.MAIL.toUpperCase().includes(palabra.toUpperCase()) || item.MAIL.toUpperCase() === palabra.toUpperCase() ||
                        item.MATRICULAS.toUpperCase().includes(palabra.toUpperCase()) || item.MATRICULAS.toUpperCase() === palabra.toUpperCase()
                })

                if (resultadoFiltro.length > 0) {
                    setClientesTaller(resultadoFiltro);
                } else {
                    setClientesTaller(null);
                }
            }
        } else {
            setClientesTaller(obtenerClientesAux);
        }
    }


    return (
        <div>
            <Drawer
                width={clienteTallerSeleccionado.esDesdeFichaPresupuesto ? 900 : 1300}
                onClose={() => { store.dispatch(DrawerFichaClienteTaller(false)); }}
                visible={openDrawerFichaClienteTaller}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '9999999999', color: '#fff' }}>


                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                            onClick={(e) => {
                                store.dispatch(DrawerFichaClienteTaller(false));
                                createRipple(e)
                            }}>
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div className="container_ficha_taller" >

                    <div className="containerHeaderClientes">
                        <CPSearch
                            className='mdb-skin searchCliente'
                            TEXTO_BUSCADOR={textoFiltroCliente}
                            placeholder={traducirPagina("Buscar_en_la_tabla")}
                            onChangeInput={(e) => {
                                buscarEnTabla(e.target.value, obtenerClientesAux);
                                setTextoFiltroCliente(e.target.value)
                            }}
                            onCloseInput={() => {
                                setTextoFiltroCliente(""); buscarEnTabla("", obtenerClientesAux)
                            }}

                        />



                        <div style={{ display: clienteTallerSeleccionado.esDesdeFichaPresupuesto ? 'none' : '' }} className='itemHeaderClientes'>
                            <button
                                onClick={() => {
                                    setMasivaCliente(true)
                                }
                                }><CloudUploadOutlined style={{ fontSize: '20px' }} /> {traducirPagina("txt_Masiva")} </button>
                            <button
                                onClick={() => {
                                    store.dispatch(DrawerFichaMantenimientoClienteTaller(true))
                                    store.dispatch({ type: "UPDATE_MODO_FICHA_CLIENTE_TALLER", esNuevoCliente: true })
                                    setMantenimientoCliente(true)
                                }

                                }><PlusOutlined style={{ fontSize: '20px' }} /> {traducirPagina("Nuevo_Cliente")}  </button>
                        </div>
                    </div>


                    {clientesTaller === null ?
                        <div style={{ textAlign: 'center' }} className='center_msg_clientes'>
                            <h4>{traducirPagina("No_clientes")}</h4>
                        </div>
                        :
                        clientesTaller.length > 0 ?
                            <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="containerTablaClientes">
                                <MDBTable responsive hover >
                                    <MDBTableHead>
                                        <tr>
                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 1, ordenarPorCodigo ? "A" : "D"); setOrdenarPorCodigo(ordenarPorCodigo ? false : true); setOrdenarPorNombre(false); setOrdenarPorCIF(false) }} >
                                                {traducirPagina("Codigo")} {ordenarPorCodigo ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}
                                            </th>
                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 2, ordenarPorNombre ? "A" : "D"); setOrdenarPorNombre(ordenarPorNombre ? false : true); setOrdenarPorCodigo(false); setOrdenarPorCIF(false) }}>{traducirPagina("Nombre")}{ordenarPorNombre ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>
                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '110px', verticalAlign: 'baseline', cursor: 'pointer' }} onClick={() => { ordenarTablaCliente("", 3, ordenarPorCIF ? "A" : "D"); setOrdenarPorCIF(ordenarPorCIF ? false : true); setOrdenarPorCodigo(false); setOrdenarPorNombre(false) }}>{traducirPagina("Cif").toUpperCase()}{ordenarPorCIF ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline' }}>{traducirPagina("Email")}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline' }}>{traducirPagina("Telefono")}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '100px', verticalAlign: 'baseline' }}>{traducirPagina("CP")}</th>
                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: '240px', verticalAlign: 'baseline' }}>{traducirPagina("Matriculas")}</th>


                                            <th style={{ paddingLeft: 0, fontSize: "14px", fontWeight: "bold", width: !clienteTallerSeleccionado.esDesdeFichaPresupuesto ? '75px' : '0px', verticalAlign: 'baseline' }}></th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {clientesTaller.map((cliente, i) =>
                                            (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final && (
                                                <tr key={cliente.ID} onClick={(e) => {
                                                    if (clienteTallerSeleccionado.esDesdeFichaPresupuesto) {
                                                        store.dispatch(DrawerFichaClienteTaller(false));
                                                        store.dispatch({ type: "UPDATE_CLIENTE_SELECCIONADO_PARA_PRESUPUESTO_TALLER", cliente: cliente })
                                                    }
                                                }}>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.CODIGO}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.NOMBRE}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.CIF}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.MAIL}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.TELEFONO}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.CP}</td>
                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>{cliente.MATRICULAS}</td>


                                                    <td style={{ paddingLeft: 0, fontSize: "14px", verticalAlign: 'middle' }}>
                                                        {!clienteTallerSeleccionado.esDesdeFichaPresupuesto ?
                                                            <div className="flex-container-Grow-Simple">
                                                                <div style={{ textAlign: 'left', margin: '0px 2px' }}>
                                                                    <button onClick={() => {
                                                                        store.dispatch({ type: "UPDATE_ID_CLIENTE_ID", id: cliente.ID });
                                                                        store.dispatch({ type: "UPDATE_MODO_FICHA_CLIENTE_TALLER", esNuevoCliente: false });
                                                                        store.dispatch(DrawerFichaMantenimientoClienteTaller(true));
                                                                        setMantenimientoCliente(true)

                                                                    }}
                                                                        className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                        <EditOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </button>
                                                                </div>

                                                                <div style={{ textAlign: 'left', margin: '0px 2px' }}>
                                                                    <button onClick={() => {
                                                                        eliminarCliente(cliente.ID)
                                                                    }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                        <DeleteOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                            :
                            <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                <MDBCol md="9">
                                    <div>
                                        <span className="skeleton-box" style={{ width: '900px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '900px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '800px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '800px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '800px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '800px' }}></span>
                                    </div>
                                </MDBCol>
                            </div>
                    }

                    <div className='center_msg_clientes' style={{height:'auto',marginTop:'20px'}}>
                        {clientesTaller !== null && clientesTaller.length > 0 ?
                            <Pagination
                                showSizeChanger
                                current={datosPaginacion.iniPage}
                                onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}
                                total={clientesTaller.length}
                                showTotal={() => ` ${clientesTaller.length} ${traducirPagina("txt_registros")}`}
                                defaultPageSize={40}
                                onChange={(page, pageSize) => { paginarTabla(page, pageSize) }}
                            />
                            : null
                        }
                    </div>

                </div>
            </Drawer>

            {mantenimientoCliente ?
                <FichaMantenimientoCliente
                    ABRIR_FICHA={mantenimientoCliente}
                    CERRAR_FICHA={setMantenimientoCliente}
                    GUARDAR_INTERNO={true}
                >
                </FichaMantenimientoCliente>
                :
                null
            }


            {
                masivaCliente &&
                <FichaMasivaCliente
                    ABRIR_FICHA={masivaCliente}
                    CERRAR_FICHA={setMasivaCliente}
                    BUSCAR_CLIENTES={() => {
                        buscarRegistros().then((registros) => {
                            setClientesTaller(registros)
                            setObtenerClientesAux(registros)
                            setHayClientes(true);
                        })
                    }}
                >
                </FichaMasivaCliente>
            }

        </div>
    )
}


FichaClienteTaller.propTypes = {
    title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaClienteTaller: state.estadoDrawer.openDrawerFichaClienteTaller,
        clienteTallerSeleccionado: state.clienteTallerSeleccionado,
        openDrawerFichaMantenimientoVehiculo: state.estadoDrawer.openDrawerFichaMantenimientoVehiculo

    };
}

export default connect(mapStateToProps)(FichaClienteTaller);