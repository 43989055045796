import React from "react";
import PropTypes from 'prop-types';
import {
    MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBCol
} from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import { connect } from 'react-redux';
import store from "../store";
import { removeAllEngineType, removeAllModel, changeColorListItemFalse, setHistoricApp, showItemCar, estadoAgrupacionDeMarcas, showChildrenDrawerLevel2, showItemReferencia, mostrarVistaGrafica } from "../action/actionCreators";
import { getFocus, traducirPagina, obtenerTipoDeBusquedaActual } from "../librerias/funciones"
import {
    CloseOutlined,
    SearchOutlined
} from '@ant-design/icons';
import CPSearch from "./utils/CPSearch.jsx";

const ContainerModelo = ({ configApp, props, childrenDrawerLevel2 }) => {
    const [textoMODELO, setTextoMODELO] = React.useState("");
    const [fichaAbierta, setFichaAbierta] = React.useState(false);
    const [modelosAUX, setmodelosAUX] = React.useState([]);

    if (props.MODELOS !== null) {
        if (!fichaAbierta && childrenDrawerLevel2 && props.MODELOS.length > 0) {
            setFichaAbierta(true);
            setmodelosAUX(props.MODELOS)

        } else {
            if (!childrenDrawerLevel2 && fichaAbierta) {
                setFichaAbierta(false);

            }
        }
    }



    const buscarEnTabla = (palabra, listaCliente) => {
        let registrosEncontrados = [];
        if (palabra !== "") {
            if (listaCliente !== null) {
                listaCliente.find(function (registro, i) {
                    if (registro.DESCRIPCION.toString().toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.DESDE.toString().toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                    if (registro.HASTA.toString().toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = registrosEncontrados.find(regi => regi.ID === registro.ID)
                        if (existeRegistro === undefined) {
                            registrosEncontrados.push(registro)
                        }
                    }

                })
            }
            if (registrosEncontrados.length > 0) {
                props.MODIFICAR_MODELOS(registrosEncontrados);
            } else {
                props.MODIFICAR_MODELOS(null);
            }
        } else {
            props.MODIFICAR_MODELOS(modelosAUX);
        }
    }



    return (
        <div>
            <CPSearch
                className='mdb-skin searchVehiculo'
                TEXTO_BUSCADOR={textoMODELO}
                placeholder={traducirPagina("Busqueda_por_modelo")}
                onChangeInput={(e) => {
                    buscarEnTabla(e.target.value, props.MODELOS);
                    setTextoMODELO(e.target.value)
                }}
                onCloseInput={() => {
                    setTextoMODELO("");
                    props.MODIFICAR_MODELOS(modelosAUX);
                }}

            />

            {/* 
            <div className="mdb-skin">
                <div className="mt-3 pb-2">
                    <div className="grid-container">
                        <div className="grid-item">
                            <input className="form-control boxCoche" type="text" aria-label="Search" placeholder={traducirPagina("Busqueda_por_modelo")} style={{ backgroundColor: "#eee", width: '500px', border: '0' }} autoComplete="off" onKeyPress={(e) => { }} onChange={(e) => { buscarEnTabla(e.target.value, props.MODELOS); setTextoMODELO(e.target.value) }} value={textoMODELO} />
                        </div>

                        <div className="grid-item">
                            <button onClick={e => { setTextoMODELO(""); props.MODIFICAR_MODELOS(modelosAUX); }} className="btn-matricula  estiloBotonRemove" style={{ visibility: textoMODELO.length > 0 ? 'visible' : 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </button>
                        </div>

                        <div className="grid-item barraLateral" ></div>

                        <div className="grid-item">
                            <button onClick={e => { }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                        </div>
                    </div>
                </div>
            </div>
*/}



            {props.MODELOS === null ?
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_modelos_para_mostrar")}</h3> </div>
                :
                props.MODELOS.length > 0 ?
                    <div className="container_grid_coche">

                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</th>
                                    <th>{traducirPagina("Desde")}</th>
                                    <th>{traducirPagina("Hasta")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {props.MODELOS.map((row, i) =>
                                    <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }} onClick={(e) => {
                                        props.MODIFICAR_COMPONENTE("MOTOR")
                                        props.BUSCAR_MOTORES(row.ID, obtenerTipoDeBusquedaActual())
                                        props.MODELO_SELECCIONADO(row)

                                    }}>
                                        <td style={{ textAlign: 'left' }}>{row.DESCRIPCION}</td>
                                        <td>{row.DESDE === "" ? "..." : row.DESDE}</td>
                                        <td>{row.HASTA === "" ? "..." : row.HASTA}</td>
                                    </tr>
                                )}
                            </MDBTableBody>
                        </MDBTable>
                    </div>

                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
            }

        </div>
    );
}

ContainerModelo.propTypes = {
    MODELOS: PropTypes.array,
    MODIFICAR_MODELOS: PropTypes.func,
    FABRICANTE: PropTypes.object,
    MODIFICAR_COMPONENTE: PropTypes.func,
    BUSCAR_MOTORES: PropTypes.func,
    MODELO_SELECCIONADO: PropTypes.func,

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        childrenDrawerLevel2: state.childrenDrawerLevel2,
    };
}



export default connect(mapStateToProps)(ContainerModelo);